import SingleSelect from '../SingleSelect';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectCfop(props) {
	const {
		url,
		label,
		useFormTouched = true,
		useFormErrors = true,
		onlyCodigo = false,
		operacao,
		isCte = false,
	} = props;

	function buscarUrlPesquisa(pesquisa, page) {
		let query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*")${
			operacao === 'ENTRADA' ? ';codigo<"5000"' : operacao === 'SAIDA' ? ';codigo>"5000"' : ''
		}&page=${page}&size=50&sort=codigo`;

		if (url) {
			return `${url}${query}`;
		}
		if (isCte) {
			let queryCte = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");usaCte=="true"&page=${page}&size=50&sort=codigo`;
			return `${services.GESTOR}/v1/cfops${queryCte}`;
		}
		return `${services.GESTOR}/v1/cfops${query}`;
	}

	function montarLabel(row) {
		if (onlyCodigo) {
			return row.codigo;
		} else {
			return row.codigo + ' - ' + row.descricao;
		}
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				{...props}
				onChange={props.onChange}
				esconderBotao
				menuPortalTarget={document.body.div}
				menuPosition={'fixed'}
				styles={{
					menu: (base) => ({
						...base,
						width: 'max-content',
						maxWidth: '200%',
						zIndex: 99999,
					}),
				}}
				useFormErrors={useFormErrors}
				useFormTouched={useFormTouched}
			/>
		</>
	);
}

SingleSelectCfop.defaultProps = {
	label: 'CFOP',
};
