function converterMercadoriaTransporteParaFormulario(value) {
	return {
		id: value.id,
		codigo: value.codigo,
		codigoBarra: value.codigoBarra,
		nome: value.nome,
		favorito: value.favorito,
		situacao: value.situacao,
	};
}

function converterMercadoriaTransporteParaApi(value) {
	return {
		id: value.id ? value.id : null,
		codigo: value.codigo,
		codigoBarra: value.codigoBarra || null,
		nome: value.nome,
		favorito: value.favorito,
		situacao: value.situacao,
	};
}

export { converterMercadoriaTransporteParaFormulario, converterMercadoriaTransporteParaApi };
