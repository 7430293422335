import {
	DADOSLOCALSTORAGE,
	baixarArquivo,
	buscarItemLocalStorage,
	copiarParaAreaTransferencia,
	services,
} from 'Common';
import { If, notify } from 'components';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { FaDownload, FaPrint, FaShareAlt } from 'react-icons/fa';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { readImprimirMDFe, readXmlMDFe } from 'views/transporte/MDFe/Requests';
import { ModalCompartilharPorEmail } from './componentes/ModalCompartilharPorEmail';
import { ModalCompartilharPorWhatsApp } from './componentes/ModalCompartilharPorWhatsapp';
import { MDFE_SITUACAO } from '../../Util/constantes';

function HeaderForm({ title }) {
	const { values } = useFormikContext();
	const { isMobile } = useContextMDFe();
	const [organizacaoId] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id);
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsAppVisible, setModalEnviarPorWhatsAppVisible] = useState(false);
	const menuCompartilhar = useRef(null);

	const enabled = [MDFE_SITUACAO.ENCERRADO, MDFE_SITUACAO.TRANSMITIDO].includes(values.situacao);

	const styleButton = {
		padding: '0px 10px',
		color: '#333333',
		fontWeight: 'bold',
		fontSize: '15px',
		display: 'flex',
		alignItems: 'center',
		border: 'none',
		marginTop: isMobile ? '8px' : '0',
	};

	const menuCompartilharModel = [
		{
			label: 'Enviar por e-mail',
			icon: 'fa fa-send',
			command: () => {
				setModalEnviarPorEmailVisible(true);
			},
		},
		{
			label: 'Enviar por WhatsApp',
			icon: 'fa fa-whatsapp',
			command: () => {
				setModalEnviarPorWhatsAppVisible(true);
			},
		},
		{
			label: 'Copiar link compartilhável',
			icon: 'fa fa-link',
			command: copyLinkShareable,
		},
	];

	async function handleClickImprimir() {
		if (enabled) {
			await readImprimirMDFe(values.id, (document) => {
				const arquivoPDF = new Blob([document.data], {
					type: 'application/pdf',
				});
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const relatorio = window.open(arquivoURL);
				if (relatorio) {
					relatorio.onload = () => {
						setTimeout(() => {
							relatorio.document.title = `MDF-e Nº ${values.numero}`;
						}, 250);
					};
				}
			});
		}
	}

	function copyLinkShareable() {
		copiarParaAreaTransferencia(
			`${services.GESTOR}/v1/mdfes/${values.id}/organizacoes/${organizacaoId}/imprimir`,
			() => {
				notify('Link copiado para área de transferência');
			}
		);
	}

	function handleClickBaixarXml() {
		if (enabled) {
			readXmlMDFe(values.id, ({ data: file }) => {
				baixarArquivo(file, values.chave, 'application/xml');
			});
		}
	}

	return (
		<>
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: isMobile ? 'column' : 'row',
				}}
			>
				{title}
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '16px',
						cursor: 'pointer',
					}}
				>
					<If test={enabled}>
						<span
							style={enabled ? { ...styleButton } : { ...styleButton, opacity: '0.5', cursor: 'default' }}
							onClick={(event) => {
								enabled && menuCompartilhar.current.toggle(event);
							}}
						>
							<Menu
								style={{ minWidth: '290px' }}
								model={menuCompartilharModel}
								popup
								ref={menuCompartilhar}
								id="menu_compartilhar"
							/>
							<FaShareAlt size="15px" style={{ marginRight: '5px' }} />
							<span>Compartilhar</span>
						</span>
						<span
							style={enabled ? { ...styleButton } : { ...styleButton, opacity: '0.5', cursor: 'default' }}
							title="Transmita o MDF-e para baixar o XML"
							onClick={handleClickBaixarXml}
						>
							<FaDownload size="15px" style={!isMobile && { marginRight: '5px' }} />
							{!isMobile && <span>Baixar XML</span>}
						</span>
						<span
							style={enabled ? { ...styleButton } : { ...styleButton, opacity: '0.5', cursor: 'default' }}
							onClick={handleClickImprimir}
						>
							<FaPrint size="15px" style={{ marginRight: '5px' }} />
							<span>Imprimir DAMDF-e</span>
						</span>
					</If>
				</span>
			</span>
			<If test={modalEnviarPorEmailVisible}>
				<ModalCompartilharPorEmail
					visible={modalEnviarPorEmailVisible}
					onHide={() => setModalEnviarPorEmailVisible(false)}
					valuesForm={values}
					isMobile={isMobile}
				/>
			</If>
			<If test={modalEnviarPorWhatsAppVisible}>
				<ModalCompartilharPorWhatsApp
					idMdfe={values.id}
					cliente={values.cliente}
					numero={values.numero}
					visible={modalEnviarPorWhatsAppVisible}
					onHide={() => setModalEnviarPorWhatsAppVisible(false)}
				/>
			</If>
		</>
	);
}

export { HeaderForm };
