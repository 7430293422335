function converterRegistroParaApi(registro) {
	const registroConvertido = {
		id: registro.id ?? null,
		dataInicial: registro.dataInicial,
		dataFinal: registro.dataFinal,
		tipo: registro.tipo,
		situacao: registro.situacao,
	};

	return registroConvertido;
}

function converterRegistroPorPeriodoParaApi(registro) {
	const registroConvertido = {
		acao: registro.acao,
		dataInicial: registro.dataInicial,
		dataFinal: registro.dataFinal,
	};

	return registroConvertido;
}

function converterLoteRegistrosParaApi(registros, acao) {
	const registroConvertido = {
		acao: acao,
		fechamentos: registros
	};

	return registroConvertido;
}

export { converterRegistroParaApi, converterRegistroPorPeriodoParaApi, converterLoteRegistrosParaApi };
