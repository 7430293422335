import { mensagensDeValidacao, services } from 'Common';
import {	
	Grid,	
	ModalRelatorio,
	MultipleSelectSetores,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { asyncBaixarRelatorioMdfeNaoEncerrados } from 'views/transporte/MDFe/Requests';

const initialValue = {
	setores: [],
};

function ModalMdfeNaoEncerradoView({ visible, onHide }) {
	const { values, setFieldValue, handleSubmit, validateForm, isValid, errors } = useFormikContext();

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			try {
				let setores = '';

				if (values.setores) {
					setores = values.setores.map((item) => `'${item.value}'`).join(',');
				}

				await asyncBaixarRelatorioMdfeNaoEncerrados(setores, ({ data: pdf }) => {
					const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					const arquivoURL = URL.createObjectURL(arquivoPDF);
					const relatorio = window.open(arquivoURL);
					if (relatorio) {
						relatorio.onload = () => {
							setTimeout(() => {
								relatorio.document.title = "Relatório MDF-es não encerrados";
							}, 250);
						};
					}
					onHide();
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={MultipleSelectSetores}
				label="Setor(es)"
				name="setores"
				obrigatorio
				url={`${services.GESTOR}/v1/mdfes/relacoes/setores`}
				onChange={(e) => setFieldValue('setores', e)}
				value={values.setores}
				showClear={false}
				utilizaFavorito
			/>
		</Grid>
	);


	return (
		<ModalRelatorio
		  header="Relatório de MDF-es não encerrados"
		  visible={visible}
		  onHide={onHide}
		  onImprimir={imprimir}
		  parametros={parametros}
		  isValid={Object.keys(errors).length === 0}
		/>
	);
}

const ModalMdfeNaoEncerrado = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (values.setores.length === 0) {
			errors.setores = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalMdfeNaoEncerradoView);

export { ModalMdfeNaoEncerrado };
