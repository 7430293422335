import { Col, Form, FormActions, FormContent, Modal } from 'components';
import { ActionButtons } from './components/ActionButtons';

function ModalHistoricoComprovantesEntrega({ visible, onHide }) {
	return (
		<Modal
			header="Comprovantes de entrega realizadas no CT-e"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} />
					</Col>
				</FormActions>
				<FormContent>
					<h1>ModalHistoricoComprovantesEntrega</h1>
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalHistoricoComprovantesEntrega };
