import { copiarObjeto } from 'Common';
import { CTE_CARTA_CORRECAO_CAMPOS, CTE_CARTA_CORRECAO_GRUPO } from './constantes';

async function converterCorrecaoCteParaApi(data) {
	if (data) {
		const correcao = copiarObjeto(data);

		await correcao.correcoes?.forEach((item) => {
			item.campo = CTE_CARTA_CORRECAO_CAMPOS[item.grupo][item.campo];
			item.grupo = CTE_CARTA_CORRECAO_GRUPO[item.grupo];
		});

		return correcao;
	}
	return null;
}

export { converterCorrecaoCteParaApi };
