import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { divideValorPorQuantidade } from '../Util/functions';
import { useGenerateParcelas } from './useGenerateParcelas';

function useRecalculatePagamentos(
	{ valorTotal, dataBaseParcela, favoritos, desabilitarRecalculoParcelas = false },
	dependencies,
	propertyPagamentos = 'pagamentos'
) {
	const { values, dirty, setFieldValue } = useFormikContext();

	const [generateParcelas] = useGenerateParcelas({
		dataBaseParcela,
		favoritos,
	});

	useEffect(() => {
		if (dirty && !desabilitarRecalculoParcelas) {
			setFieldValue(propertyPagamentos, recalculatePagamentos());
		}
	}, [...dependencies]);

	function recalculatePagamentos() {
		const newPagamentos = [];
		const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(
			valorTotal,
			values[propertyPagamentos].length
		);

		values[propertyPagamentos].forEach((pagamento, index) => {
			const valor = index === values[propertyPagamentos].length - 1 ? valorUltimaQuantidade : valorPorQuantidade;
			newPagamentos.push({
				...pagamento,
				valor,
				parcelas: getParcelas(pagamento, valor),
			});
		});

		return newPagamentos;
	}

	function getParcelas(e, valorPagamento) {
		const isAPrazo = e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
		return isAPrazo
			? generateParcelas({
					qtdParcelas: e.quantidadeParcelas?.value,
					parcelas: e.parcelas,
					valorPagamento,
				})
			: [];
	}
}

export { useRecalculatePagamentos };
