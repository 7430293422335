import { Field } from 'formik';

import { ButtonExcluirTable, Col, Divider, Dropdown, Grid, InputDouble } from 'components';
import { buscarDadosLoginLocalStorage, generateOptionsQtdParcelas } from 'Common';

import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';

function AlteracaoPercentualPorQuantidade({
	isInput,
	isAVista = false,
	quantidadeMinima = 1,
	quantidadeMaxima = 48,
	isAcrescimo,
	registro,
	onChangeValorInicial,
	onChangeValorFinal,
	onChangePercentualAplicado,
	excluirItem,
	errors,
}) {
	const { isMobile, isTablet, informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();
	const decimaisQtd = buscarDadosLoginLocalStorage()?.parametrosCadastros?.decimaisQtd ?? 2;

	function onClickExcluir() {
		if (typeof excluirItem === 'function') {
			excluirItem();
		}
	}

	return (
		<Grid>
			{isInput ? (
				<>
					<Field
						sm="3"
						md="3"
						lg="3"
						xl="3"
						obrigatorio={!isAVista}
						label="Valor inicial"
						name="valorInicial"
						component={InputDouble}
						decimalScale={decimaisQtd}
						size={13}
						allowNegative={false}
						value={registro?.valorInicial}
						onChange={(e) => onChangeValorInicial(e.target.value)}
						touched
						errors={errors?.valorInicial}
						useFormErrors={false}
						disabled={desabilitarCampos}
					/>
					<Field
						sm="3"
						md="3"
						lg="3"
						xl="3"
						obrigatorio={!isAVista}
						label="Valor final"
						name="valorFinal"
						component={InputDouble}
						decimalScale={decimaisQtd}
						size={13}
						allowNegative={false}
						value={registro?.valorFinal}
						onChange={(e) => onChangeValorFinal(e.target.value)}
						touched
						errors={errors?.valorFinal}
						useFormErrors={false}
						disabled={desabilitarCampos}
					/>
				</>
			) : (
				<>
					<Field
						sm="6"
						md="6"
						lg="3"
						xl="3"
						obrigatorio={!isAVista}
						label="Valor inicial"
						name="valorInicial"
						component={Dropdown}
						value={registro?.valorInicial}
						onChange={(e) => onChangeValorInicial(e.value)}
						options={generateOptionsQtdParcelas(quantidadeMinima, quantidadeMaxima)}
						showClear
						touched
						errors={errors?.valorInicial}
						useFormErrors={false}
						disabled={desabilitarCampos || isAVista}
					/>
					<Field
						sm="6"
						md="6"
						lg="3"
						xl="3"
						obrigatorio={!isAVista}
						label="Valor final"
						name="valorFinal"
						component={Dropdown}
						value={registro?.valorFinal}
						onChange={(e) => onChangeValorFinal(e.value)}
						options={generateOptionsQtdParcelas(quantidadeMinima, quantidadeMaxima)}
						showClear
						touched
						errors={errors?.valorFinal}
						useFormErrors={false}
						disabled={desabilitarCampos || isAVista}
					/>
				</>
			)}

			<Field
				sm="6"
				md="6"
				lg="3"
				xl="3"
				obrigatorio
				label={`Percentual de ${isAcrescimo ? 'acréscimo' : 'desconto'}`}
				name="percentualAplicado"
				component={InputDouble}
				decimalScale={2}
				size={6}
				allowNegative={false}
				value={registro?.percentualAplicado}
				onChange={(e) => onChangePercentualAplicado(e.target.value)}
				touched
				errors={errors?.percentualAplicado}
				useFormErrors={false}
				disabled={desabilitarCampos}
			/>
			<Col
				sm="2"
				md="1"
				lg="1"
				xl="1"
				style={
					isMobile || isTablet
						? { display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }
						: { height: '70px', display: 'flex' }
				}
			>
				<ButtonExcluirTable
					onClick={() => (informacoesPermissoes.podeEditar ? onClickExcluir() : null)}
					title="Excluir item"
					disabled={desabilitarCampos || isAVista}
					styleContentButton={{ marginTop: '24px' }}
				/>
			</Col>
			{isMobile || isTablet ? (
				<Divider styleContainer={{ marginBottom: '16px' }} styleLine={{ borderBottom: '0.5px solid' }} />
			) : null}
		</Grid>
	);
}

export { AlteracaoPercentualPorQuantidade };
