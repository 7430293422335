import { format, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatarMonetario } from 'Common';
import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	NenhumRegistroEncontrado,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';
import { TIPO_DOCUMENTO_OUTROS_OPTIONS } from 'views/transporte/CTe/Form/Util/constantes';

function TabelaOutrosDocumentos({ setRegistroSelecionado }) {
	const { setVisibleModalDadosOutrosDocumentos, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue } = useFormikContext();

	function renderNumero(row) {
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
				<p>{row.numero}</p>
			</div>
		);
	}
	function renderTipo(row) {
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
				<p>
					{TIPO_DOCUMENTO_OUTROS_OPTIONS.find((item) => item.value === row.tipoDocumento)?.label || row.tipoDocumento}
				</p>
			</div>
		);
	}
	function renderDescricao(row) {
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
				<p>{row.descricao}</p>
			</div>
		);
	}
	function renderEmissao(row) {
		const label = row.emissao ? format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-';
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
				<p>{label}</p>
			</div>
		);
	}
	function renderValor(row) {
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
				<p>{formatarMonetario(row.valor)}</p>
			</div>
		);
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalDadosOutrosDocumentos(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const outrosDocumentos = values.documento?.outros || [];
		const updatedNotas = outrosDocumentos.filter((documento) =>
			documento.tempId ? documento.tempId !== row.tempId : documento.id !== row.id
		);
		setFieldValue('documento.outros', updatedNotas);
	}

	return (
		<>
			<DataTable
				className="table"
				responsive
				value={values.documento?.outros || []}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="numero"
					header="Número"
					body={(e) => renderNumero(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="tipo"
					header="Tipo"
					body={(e) => renderTipo(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="descricao"
					header="Descrição"
					body={(e) => renderDescricao(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="emissao"
					header="Emissão"
					body={(e) => renderEmissao(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="valor"
					header="Valor"
					body={(e) => renderValor(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					className="step-listagem-acoes"
					body={(e, options) => renderOpcoes(e, options)}
					header="Ações"
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar outro documento"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setVisibleModalDadosOutrosDocumentos(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>
		</>
	);
}

export { TabelaOutrosDocumentos };
