import { Field, useFormikContext, withFormik } from "formik";
import { useEffect } from "react";
import { format } from "date-fns";
import { Col, Dropdown, Form, FormActions, FormContent, Grid, InputDate, Modal } from "components";
import { mensagensDeValidacao } from "Common";
import { validarFormulario } from "views/Util";
import { ActionButtons } from "./components/ActionButtons";
import { INITIAL_VALUES, SITUACAO_OPTIONS, TIPO_OPTIONS } from "../../Util/constantes";
import { converterRegistroParaApi } from "../../Util/gestaoFechamentoConverter";
import { createFechamento, updateFechamento } from "../../Requests";

function ModalGestaoFechamentoImpl({ visible, onHide }) {
	const { values, setFieldValue, validateForm, handleSubmit } = useFormikContext();

	useEffect(() => {
		if(!values.id) {
			setFieldValue('dataInicial', format(new Date(), 'yyyy-MM-dd'))
			setFieldValue('dataFinal', format(new Date(), 'yyyy-MM-dd'))
		}
	}, []);
	
	async function handleSalvarDados() {
		handleSubmit()
		if (await validarFormulario({ validateForm, values })) {
			const registro = converterRegistroParaApi(values);
			!values.id ?
			createFechamento(
				registro,
				() => {
					onHide();
				}
			) :
			updateFechamento(
				registro,
				() => {
					onHide();
				}
			)
		}
	}

	return (
		<Modal
			header= { !values.id ? "Novo fechamento" : "Editar fechamento"}
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} onHandleSave={handleSalvarDados} />
					</Col>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="3"
							md="3"
							lg="3"
							xl="3"
							component={InputDate}
							label="Data início"
							name="dataInicial"
							obrigatorio
							touched
							value={values?.dataInicial}
							onChange={(e) => setFieldValue('dataInicial', e.target.value)}
						/>
						<Field
							sm="3"
							md="3"
							lg="3"
							xl="3"
							component={InputDate}
							label="Data final"
							name="dataFinal"
							obrigatorio
							touched
							value={values?.dataFinal}
							onChange={(e) => setFieldValue('dataFinal', e.target.value)}
						/>
						<Field
							sm="3"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							showClear={false}
							label="Situação"
							name="situacao"
							obrigatorio
							touched
							options={SITUACAO_OPTIONS}
							allowNegative={false}
							value={values.situacao}
							onChange={(e) => setFieldValue('situacao', e.value)}
						/>
                        <Field
							sm="3"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Tipo"
							name="tipo"
							obrigatorio
							touched
							options={TIPO_OPTIONS}
							value={values?.tipo}
							onChange={(e) => setFieldValue('tipo', e.value)}
							disabled
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalGestaoFechamento = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ registroSelecionado }) {
		if (registroSelecionado) {
			return { ...registroSelecionado };
		} else {
			return { ...INITIAL_VALUES };
		}
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial) {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.dataFinal < values.dataInicial) {
			errors.dataFinal = 'Data final deve ser maior que a inicial';
		}
		if (!values.dataFinal) {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.situacao) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalGestaoFechamentoImpl);

export { ModalGestaoFechamento };