import { get, del, exibirToast, put, post, buscarDadosLoginLocalStorage, services } from 'Common';
import { formatISO } from 'date-fns';

export async function asyncBuscarSetorResponsavel() {
	let dados = {};
	const dadosLogin = buscarDadosLoginLocalStorage();

	dados.setor = dadosLogin.setores[0];
	dados.setores = dadosLogin.setores;

	return dados;
}

export async function asyncGetResumoNfse(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError);
}

export async function asyncGetNfse(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/nfses/${id}`, null, onSuccess, onError);
}

export async function asyncCreateNfse(data, messageOnSuccess = 'Registro criado com sucesso', onSuccess, onError) {
	await post(`${services.GESTOR}/v1/nfses`, data, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncUpdateNfse(data, messageOnSuccess = 'Registro atualizado com sucesso', onSuccess, onError) {
	await put(`${services.GESTOR}/v1/nfses/${data.id}`, data, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncDeleteNfse(id, messageOnSuccess = 'Registro excluído com sucesso', onSuccess, onError) {
	await del(`${services.GESTOR}/v1/nfses/${id}`, null, exibirToast(onSuccess, messageOnSuccess), onError);
}

export async function asyncTransmitirNFse(id, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/nfses/${id}/transmitir`, null, null, onSuccess, onError, false, false);
}

export async function imprimirXMLNfse(id, onSuccess, onError) {	
	return get(`${services.GESTOR}/v1/nfses/${id}/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function asyncConsultarNfse(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/nfses/${id}/consultar`, null, onSuccess, onError, false, false);
}

export async function imprimirDANFSE(id, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/nfses/${id}/imprimir`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function asyncGetCodigoServicoComTributacao(codigoServico, onSuccess, onError) {
	const url = `${services.GESTOR}/v1/tributacoes/codigo_servico?query=codigoServico.codigo=contains=${codigoServico}`;

	return await get(url, null, onSuccess, onError);
}

export async function asyncGetOperacoesFiscais(
	{ tipoOperacao = 'SAIDA', tipoCliente = false, favoritaSaida = true, favoritaEntrada = false },
	onSuccess,
	onError
) {
	const query = `?query=situacao=="ATIVO";indicacaoMovimento==${tipoOperacao};vendaConsumidor==${tipoCliente};favoritoSaida==${favoritaSaida};favoritoEntrada==${favoritaEntrada}`;
	await get(`${services.GESTOR}/v1/nfses/relacoes/operacoes_fiscais${query}`, null, onSuccess, onError);
}

export async function asyncGetVendedor(onSuccess) {
	await get(`${services.GESTOR}/v1/nfses/relacoes/vendedores?filtro=&page=0&size=500&sort=codigo,asc`, null, onSuccess);
}

export async function asyncGetConta(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfses/relacoes/contas?query=situacao=="ATIVA";favoritaReceita==true`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCategoria(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfses/relacoes/categorias/receitas?query=ativa==true;favoritaReceita==true`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetCondicaoPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfses/relacoes/condicoes_pagamento?query=(favorita==true);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfses/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBuscarTabelaPreco(dadosRequest, onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/nfses/relacoes/buscar_tabela_a_utilizar?municipioId=${
			dadosRequest?.municipioId ?? ''
		}&clienteId=${dadosRequest?.clienteId ?? ''}&vendedorId=${dadosRequest?.vendedorId ?? ''}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncGetTotalizadoresCard(interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/nfses/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}

export async function asyncPostCancelarNFSe(id, motivoCancelamento, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/nfses/${id}/cancelar`, { motivoCancelamento }, null, onSuccess, onError);
}

export async function asyncEnviarNfsePorEmail(nfseId, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/nfses/${nfseId}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'Email(s) enviado(s) com sucesso!'),
		onError
	);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/nfses/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}
