import { Menu } from 'primereact/menu';

import { If } from 'components';

import ModalEntradaEstoque from 'views/estoque/Movimentacoes/components/ModalEntradaEstoque';
import ModalSaidaEstoque from 'views/estoque/Movimentacoes/components/ModalSaidaEstoque';
import { tipoSaldo } from 'views/estoque/Movimentacoes/Util/constantes';

export default function ModalMovimentacaoEstoque(props) {
	const {
		myRef,
		setExibirModalEntradaEstoque,
		setExibirModalSaidaEstoque,
		setExibirModalReservaEstoque,
		exibirModalEntradaEstoque,
		exibirModalSaidaEstoque,
		exibirModalReservaEstoque,
		setAjustarSaldoEmEstoque,
		values,
	} = props;

	const { sku, codigo, nome, id, ultimoCusto } = values;

	const produtoSelecionado = {
		label: codigo + ' - ' + nome + (sku ? ' - [Ref: ' + sku + ']' : ''),
		value: id,
		registro: {
			...values,
			custo: ultimoCusto,
		},
	};

	return (
		<>
			<Menu
				style={{ width: '212px' }}
				model={[
					{
						label: 'Nova entrada de estoque',
						icon: 'fa fa-plus',
						command: () => setExibirModalEntradaEstoque(true),
						disabled: false,
					},
					{
						label: 'Nova saída de estoque',
						icon: 'fa fa-minus',
						command: () => setExibirModalSaidaEstoque(true),
						disabled: false,
					},
					{
						label: 'Nova reserva de estoque',
						icon: 'fa fa-plus',
						command: () => setExibirModalReservaEstoque(true),
						disabled: false,
					},
					{
						label: values.filiais.length > 1 ? 'Visualizar movimentações' : 'Ajustar saldo em estoque',
						icon: values.filiais.length > 1 ? 'fa fa-retweet' : 'fa fa-pencil',
						command: () => {
							if (values.filiais.length > 1) {
								props.history.push({
									pathname: '/movimentacoes_estoque',
									state: {
										sku: values.sku,
										nome: values.nome,
										id: values.id,
										codigo: values.codigo,
									},
								});
							} else {
								setAjustarSaldoEmEstoque(true);
							}
						},
						disabled: false,
					},
				]}
				popup={true}
				ref={myRef}
			/>

			<If test={exibirModalEntradaEstoque}>
				<ModalEntradaEstoque
					visible={exibirModalEntradaEstoque}
					produto={produtoSelecionado}
					onHide={onHideModalEstoque}
				/>
			</If>
			<If test={exibirModalSaidaEstoque}>
				<ModalSaidaEstoque visible={exibirModalSaidaEstoque} produto={produtoSelecionado} onHide={onHideModalEstoque} />
			</If>
			<If test={exibirModalReservaEstoque}>
				<ModalEntradaEstoque
					saldoTipo={tipoSaldo.RESERVADO}
					visible={exibirModalReservaEstoque}
					produto={produtoSelecionado}
					onHide={onHideModalEstoque}
				/>
			</If>
		</>
	);

	function onHideModalEstoque() {
		setExibirModalEntradaEstoque(false);
		setExibirModalSaidaEstoque(false);
		setExibirModalReservaEstoque(false);
	}
}
