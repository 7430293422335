import { Field, useFormikContext } from 'formik';

import { services } from 'Common';
import { Checkbox, Grid, SingleSelectSetor, SingleSelectUsuario, TextArea } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function TabInformacoesComplementares() {
	const { values, setFieldValue, errors } = useFormikContext();
	const { informacoesPermissoes, isSituacaoFinalCTe } = useContextCTe();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectUsuario}
				label="Usuário responsável"
				name="informacoesComplementares.usuario"
				obrigatorio
				isClearable={false}
				value={values?.informacoesComplementares?.usuario}
				url={`${services.GESTOR}/v1/ctes/relacoes/usuarios`}
				onChange={(event) => setFieldValue('informacoesComplementares.usuario', event)}
				errors={errors.informacoesComplementares?.usuario}
				touched
				useFormErrors={false}
				esconderBotao
				disabled={isSituacaoFinalCTe}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={SingleSelectSetor}
				label="Setor"
				name="informacoesComplementares.setor"
				obrigatorio
				value={values?.informacoesComplementares?.setor}
				url={`${services.GESTOR}/v1/ctes/relacoes/setores`}
				onChange={(event) => setFieldValue('informacoesComplementares.setor', event)}
				esconderBotao
				isClearable={false}
				id="selectSetor"
				errors={errors.informacaoComplementar?.setor}
				touched
				useFormErrors={false}
				disabled={isSituacaoFinalCTe}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva informações complementares referente ao conhecimento de transporte eletrônico"
				label="Informações complementares"
				name="informacoesComplementares.informacaoComplementar"
				maxLength={2000}
				style={{ minHeight: '110px' }}
				rows={4}
				onBlur={(value) => setFieldValue('informacoesComplementares.informacaoComplementar', value)}
				disabled={isSituacaoFinalCTe}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Recebedor retira no areporto, filial, porto ou estação de destino"
				name="informacoesComplementares.recebedorRetira"
				checked={values.informacoesComplementares?.recebedorRetira}
				colStyle={{ marginTop: '0px' }}
				style={{ fontWeight: 'normal' }}
				disabled={isSituacaoFinalCTe}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva observações extras referente a retirada"
				label="Observações de retirada"
				name="informacoesComplementares.recebedorRetiraDetalhes"
				maxLength={160}
				rows={4}
				value={values.informacoesComplementares?.recebedorRetiraDetalhes}
				onBlur={(value) => setFieldValue('informacoesComplementares.recebedorRetiraDetalhes', value)}
				disabled={isSituacaoFinalCTe || !values.informacoesComplementares?.recebedorRetira}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { TabInformacoesComplementares };
