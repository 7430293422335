import { Message } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function ErrorsParametrosFiscais({ mensagemParametroFiscal }) {
	const { history } = useContextCTe();

	function montarMensagemParametroFiscal() {
		return (
			<span>
				{`${mensagemParametroFiscal} `}
				<b
					style={{ cursor: 'pointer' }}
					onClick={() =>
						history.push({
							pathname: '/configuracoes_gerais',
							state: { configurarSerieCte: true },
						})
					}
				>
					Clique aqui para configurar
				</b>
			</span>
		);
	}

	return <Message severity="error" text={montarMensagemParametroFiscal()} />;
}

export { ErrorsParametrosFiscais };
