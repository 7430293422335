import { Field, useFormikContext, withFormik } from 'formik';

import {
	AutoProgressBar,
	Divider,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputCep,
	InputField,
	InputMask,
	Modal,
	SingleSelectMunicipio,
} from 'components';
import { buscarMascaraTelefone, mensagensDeValidacao } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { INITIAL_VALUE_PESSOA_MODAL } from 'views/transporte/CTe/Form/Util/constantes';
import { INDICADOR_INSCRICAO_ESTADUAL, TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { ActionButtons } from './components/ActionButtons';

function ModalDadosPessoaImpl({ visible, onHide, setFieldValueCte }) {
	const { informacoesPermissoes, isSituacaoFinalCTe } = useContextCTe();
	const { values, errors, setFieldValue } = useFormikContext();
	function getTitleParticipante() {
		switch (values?.participante) {
			case 'remetente':
				return 'remetente';
			case 'destinatario':
				return 'destinatário';
			case 'expedidor':
				return 'expedidor';
			case 'recebedor':
				return 'recebedor';
			case 'outros':
				return 'outro participante';
			default:
				return 'participante';
		}
	}

	function onChangeValores(field, event) {
		setFieldValue(`${field}`, event?.target?.value ?? null);
	}

	function onChangeSelect(field, event) {
		setFieldValue(`${field}`, event ?? null);
	}

	function onChangeCep(data) {
		if (data.pesquisou) {
			data.bairro && setFieldValue('bairro', data.bairro);
			data.complemento && setFieldValue('complemento', data.complemento);
			data.logradouro && setFieldValue('logradouro', data.logradouro);
			data.municipio &&
				setFieldValue('municipio', {
					label: `${data.municipio.nome} - ${data.municipio.estado.sigla}`,
					value: data.municipio.id,
					registro: data.municipio,
				});
		} else {
			setFieldValue('cep', data.value);
		}
	}

	return (
		<Modal
			header={`Dados do ${getTitleParticipante()}`}
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<AutoProgressBar />
			<Form className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons onHide={onHide} setFieldValueCte={setFieldValueCte} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="8"
							lg="9"
							xl="9"
							component={InputField}
							label="Nome"
							value={values.nome}
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Tipo"
							name="tipoPessoa"
							onChange={(elemento) => setFieldValue('tipoPessoa', elemento.value)}
							options={[
								{ label: 'Física', value: 'FISICA' },
								{ label: 'Jurídica', value: 'JURIDICA' },
							]}
							value={values.tipoPessoa}
							errors={errors.tipoPessoa}
							showClear={false}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<If test={values.tipoPessoa === TIPO_PESSOA.FISICA}>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label="CPF"
								name="cpf"
								obrigatorio
								useFormTouched={false}
								useFormErrors={false}
								value={String(values.cpf)}
								errors={errors.cpf}
								onChange={(elemento) => onChangeValores('cpf', elemento)}
								disabled={isSituacaoFinalCTe}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipoPessoa === TIPO_PESSOA.JURIDICA}>
							<Field
								sm="12"
								md="4"
								lg="4"
								xl="4"
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label="CNPJ"
								name="cnpj"
								obrigatorio
								useFormTouched={false}
								useFormErrors={false}
								value={String(values.cnpj)}
								onChange={(elemento) => onChangeValores('cnpj', elemento)}
								touched={errors.cnpj}
								errors={errors.cnpj}
								disabled={isSituacaoFinalCTe}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Indicador IE"
							name="indicadorInscricaoEstadual"
							onChange={(e) => {
								setFieldValue('indicadorInscricaoEstadual', e.value);
								if (e.value === 'CONTRIBUINTE_ISENTO') {
									setFieldValue('inscricaoEstadual', null);
								}
							}}
							options={INDICADOR_INSCRICAO_ESTADUAL}
							value={values.indicadorInscricaoEstadual}
							showClear={false}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							size={14}
							type={values && values.indicadorInscricaoEstadual === 'CONTRIBUINTE' ? 'number' : 'text'}
							label="Inscrição estadual"
							name="inscricaoEstadual"
							onBlur={(e) => {
								if (e.target.value && e.target.value.toString().trim() !== '') {
									if (values.indicadorInscricaoEstadual === 'NAO_CONTRIBUINTE' && !isNaN(e.target.value)) {
										setFieldValue('indicadorInscricaoEstadual', 'CONTRIBUINTE');
									}
								}
							}}
							value={
								values && values.inscricaoEstadual && values.indicadorInscricaoEstadual === 'NAO_CONTRIBUINTE'
									? values.inscricaoEstadual.toString().toUpperCase()
									: values.inscricaoEstadual
							}
							disabled={values.indicadorInscricaoEstadual === 'CONTRIBUINTE_ISENTO' || isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							component={InputField}
							size={60}
							label="E-mail"
							name="email"
							useFormTouched={false}
							useFormErrors={false}
							errors={errors.email}
							touched={errors.email}
							value={values.email}
							onChange={(elemento) => onChangeValores('email', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputMask}
							mask={buscarMascaraTelefone(values?.telefone)}
							label="Telefone"
							name="telefone"
							placeholder="(  )      -    "
							value={values?.telefone}
							onChange={(elemento) => onChangeValores('telefone', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
					</Grid>
					<Divider
						label="Endereço"
						styleContainer={{ margin: '1rem 0' }}
						styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
					/>
					<Grid>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputCep}
							label="CEP"
							name="cep"
							type="tel"
							value={values.cep}
							errors={errors.cep}
							obrigatorio
							onChange={(elemento) => onChangeCep(elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							component={InputField}
							size={60}
							label="Logradouro"
							name="logradouro"
							obrigatorio
							errors={errors.logradouro}
							value={values.logradouro}
							onChange={(elemento) => onChangeValores('logradouro', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="8"
							lg="8"
							xl="8"
							component={InputField}
							size={60}
							label="Bairro"
							name="bairro"
							obrigatorio
							errors={errors.bairro}
							value={values.bairro}
							onChange={(elemento) => onChangeValores('bairro', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							component={InputField}
							size={60}
							label="Número"
							name="numero"
							obrigatorio
							errors={errors.numero}
							value={values.numero}
							onChange={(elemento) => onChangeValores('numero', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={InputField}
							size={60}
							label="Complemento"
							name="complemento"
							value={values.complemento}
							onChange={(elemento) => onChangeValores('complemento', elemento)}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							component={SingleSelectMunicipio}
							label="Município "
							name="municipio"
							isClearable={false}
							obrigatorio
							onChange={(elemento) => onChangeSelect('municipio', elemento)}
							disabled={isSituacaoFinalCTe}
							value={values.municipio}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosPessoa = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.pessoa) {
			return props.pessoa?.registro;
		}
		return INITIAL_VALUE_PESSOA_MODAL;
	},

	validate(values) {
		const errors = {};

		if (!values.tipoPessoa) {
			errors.tipo = mensagensDeValidacao.OBRIGATORIO;
		} else if (values.tipoPessoa === TIPO_PESSOA.FISICA) {
			if (!values.cpf) {
				errors.cpf = 'CPF obrigatorio';
			}
		} else if (values.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			if (!values.cnpj) {
				errors.cnpj = 'CNPJ obrigatorio';
			}
		}

		if (values.email && !isValidEmail(values.email)) {
			errors.email = mensagensDeValidacao.EMAIL_INVALIDO;
		}

		if (!values.cep) {
			errors.cep = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.logradouro) {
			errors.logradouro = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.bairro) {
			errors.bairro = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.numero) {
			errors.numero = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.municipio) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosPessoaImpl);

export { ModalDadosPessoa };
