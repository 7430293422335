import { createContext, useContext } from 'react';

import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

const ContextCodigoServico = createContext();

export default function CodigoServicoProvider(props) {
	const { children } = props;

	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);

	return (
		<ContextCodigoServico.Provider value={{ podeInserir, podeExcluir, podeEditar }}>
			{children || null}
		</ContextCodigoServico.Provider>
	);
}

export const useContextCodigoServico = () => useContext(ContextCodigoServico);
