import Col from 'components/Col';
import { removerCaracteresInvalidosRsql } from 'Common/Rsql';

import InputSearchMask from 'components/input/InputSearchMask';

export default function InputNcmDestino(props) {
	const { placeholder, name, label, className, value, disabled, sm, md, lg, xl, id, size, errors, touched } = props;

	function onKeyDown(e) {
		if (props.onKeyDown) props.onKeyDown(e);
		else if (e.key === 'Enter') {
			props.onPesquisar();
		}
	}

	function onClickPesquisar() {
		props.onPesquisar();
	}

	function handleChangePesquisa(e) {
		if (e.target.value?.length > size) {
			const value = String(e.target.value).substr(0, size);
			props.onChange(value);
		} else {
			props.onChange(removerCaracteresInvalidosRsql(e.target.value));
		}
	}

	function montarLabel() {
		return <label title={props.helpMessage}> {label} </label>;
	}
	return (
		<Col sm={sm} md={md} lg={lg} xl={xl}>
			{montarLabel()}
			<InputSearchMask
				id={id}
				className={className}
				placeholder={placeholder}
				name={name}
				style={{
					width: '100%',
					marginRight: '-36px',
				}}
				colStyle={{ flex: 'auto', padding: '0' }}
				value={value}
				disabled={disabled}
				onChange={handleChangePesquisa}
				mask="0000.00.00"
				errors={errors}
				touched={touched}
				useFormErrors={props.useFormErrors}
				useFormTouched={props.useFormTouched}
				onClickPesquisar={onClickPesquisar}
				onKeyDown={onKeyDown}
			/>
		</Col>
	);
}
