import { Checkbox, FormContent, Grid, InputField } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextCTe } from 'views/transporte/CTe/Context';

function TabDocumentosSubstituicao() {
	const { values, setFieldValue } = useFormikContext();
	const { isSituacaoFinalCTe } = useContextCTe();

	return (
		<FormContent>
			<Grid style={{ display: 'flex', alignItems: 'center' }}>
				<Field
					sm="12"
					md="8"
					lg="8"
					xl="8"
					label="Chave de acesso do CT-e original"
					name="cteSubstituicao.chaveAcesso"
					component={InputField}
					size={44}
					useOnBlurPerformatico
					onBlur={(value) => {
						setFieldValue('cteSubstituicao.chaveAcesso', value || null);
					}}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					label="Tomador informado diferente do CT-e substituído"
					name="tomadorInformadoDiferente"
					component={Checkbox}
					style={{ marginTop: '16px' }}
					value={values.cteSubstituicao?.tomadorInformadoDiferente}
					onChange={(e) => setFieldValue('cteSubstituicao.tomadorInformadoDiferente', e.checked)}
					checked={values.cteSubstituicao?.tomadorInformadoDiferente}
					disabled={isSituacaoFinalCTe}
				/>
			</Grid>
		</FormContent>
	);
}

export { TabDocumentosSubstituicao };
