import { gerarUUID } from 'Common';

function converterDadosDocumentoFiscalEletronicoParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		chaveAcesso: values.chaveAcesso,
	};
}

function converterDadosNotaFiscalParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		modeloDocumento: values.modeloDocumento,
		emissao: values.emissao,
		cfop: converterCfopParaFormulario(values.cfop),
		serie: values.serie,
		numero: values.numero,
		numeroRomaneio: values.numeroRomaneio,
		numeroPedido: values.numeroPedido,
		valorBaseCalculoIcms: values.valorBaseCalculoIcms,
		valorIcms: values.valorIcms,
		valorBaseCalculoIcmsSt: values.valorBaseCalculoIcmsSt,
		valorIcmsSt: values.valorIcmsSt,
		peso: values.peso,
		valorTotalProdutos: values.valorTotalProdutos,
		valorTotalNotaFiscal: values.valorTotalNotaFiscal,
	};
}

function converterDadosOutrosDocumentosParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		tipoDocumento: values.tipoDocumento,
		numero: values.numero,
		valor: values.valor,
		emissao: values.emissao,
		descricao: values.descricao,
	};
}

function converterDadosDocumentoPapelParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		tipoDocumento: values.tipoDocumento,
		serie: values.serie,
		subSerie: values.subSerie,
		numeroDocumento: values.numeroDocumento,
		emissao: values.emissao,
	};
}

function converterCfopParaFormulario(cfop) {
	if (cfop.registro) {
		return {
			...cfop,
		};
	} else if (cfop?.id) {
		return {
			label: cfop.codigo + ' - ' + cfop.descricao,
			value: cfop.id,
			registro: cfop,
		};
	} else {
		return null;
	}
}

export {
	converterDadosDocumentoFiscalEletronicoParaForm,
	converterDadosNotaFiscalParaForm,
	converterDadosOutrosDocumentosParaForm,
	converterDadosDocumentoPapelParaForm,
};
