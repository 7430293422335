import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Grid, If } from 'components';
import { SelectButton } from 'primereact/selectbutton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OPTIONS_SELECT, TRANSPORTES_MODULOS } from './Util/constantes';
import { DashboardMDFe } from './components/DashboardMDFe';
import { DashboardCTe } from './components/DashboardCTe';

const selectButtonStyle = {
	whiteSpace: 'nowrap',
	overflowY: 'hidden',
	width: '100%',
};

function DashboardTransportesImpl({ dataInicial, dataFinal, isMobile, history }) {
	const [podeVisualizarMDFe] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR));
	const [podeVisualizarCTe] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.VISUALIZAR));
	const [optionsSelect] = useState(
		OPTIONS_SELECT.filter((e) => {
			switch (e.value) {
				case TRANSPORTES_MODULOS.MDFE:
					return podeVisualizarMDFe;
				case TRANSPORTES_MODULOS.CTE:
					return podeVisualizarCTe;
				default:
					return false;
			}
		})
	);

	const [currentOption, setCurrentOption] = useState(optionsSelect[0]?.value);

	function handleChangeOption(event) {
		if (event.value) {
			setCurrentOption(event.value);
		}
	}

	if (dataInicial && dataFinal) {
		return (
			<Grid style={{ margin: '0px', width: '99.8%' }}>
				<SelectButton
					style={selectButtonStyle}
					className="grid-select-filtro select-button"
					options={optionsSelect}
					value={currentOption}
					onChange={handleChangeOption}
				/>
				<If test={currentOption === TRANSPORTES_MODULOS.MDFE}>
					<DashboardMDFe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
				<If test={currentOption === TRANSPORTES_MODULOS.CTE}>
					<DashboardCTe dataInicial={dataInicial} dataFinal={dataFinal} history={history} isMobile={isMobile} />
				</If>
			</Grid>
		);
	} else {
		return <span>Carregando informações...</span>;
	}
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const DashboardTransportes = connect(mapStateToProps)(withRouter(DashboardTransportesImpl));
