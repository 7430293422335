import { If, Modal } from 'components';
import ProdutosForm from 'views/cadastros/produtos/Produtos/Form';

function ModalProduto(props) {
	const { visible, onHide, idProdutoVariacao, tipoPredefinido, service } = props;

	function getHeader() {
		switch (tipoPredefinido) {
			case 'PRODUTO':
				return 'Cadastro de produto';
			case 'SERVICO':
				return 'Cadastro de serviço';
			default:
				return 'Cadastro de produto';
		}
	}

	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header={getHeader()}
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={visible}>
				<ProdutosForm
					hideModal={onHide}
					idProdutoVariacao={idProdutoVariacao}
					isModal={true}
					tipoPredefinido={tipoPredefinido}
					service={service}
				/>
			</If>
		</Modal>
	);
}

export default ModalProduto;
