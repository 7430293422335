import { Col } from 'components';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { TRANSPORTES_CORES } from '../../../../Util/constantes';
import { CardResultado } from './components/CardResultado';

function CardsResultado({ data, isMobile }) {
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	return (
		<>
			<Col sm="6" md="3" lg="3" xl="3" style={styleCards}>
				<CardResultado
					title="Pendentes no período"
					helpMessage="Valor total de pendentes no período selecionado"
					valor={data.pendentes?.valor}
					percentualEvolucao={data.pendentes?.evolucao}
					colorValor={TRANSPORTES_CORES.azul}
					colorEvolucao={data.pendentes?.evolucao > 0 ? TRANSPORTES_CORES.verde : TRANSPORTES_CORES.vermelho}
					backgroundEvolucao={
						data.pendentes?.evolucao > 0 ? TRANSPORTES_CORES.verdeClaro : TRANSPORTES_CORES.vermelhoClaro
					}
					icon={<MdAttachMoney size={50} color={TRANSPORTES_CORES.azul} />}
				/>
			</Col>
			<Col sm="6" md="3" lg="3" xl="3" style={styleCards}>
				<CardResultado
					title="Rejeitados no período"
					helpMessage="Valor total de rejeitados no período selecionado"
					valor={data.pendentes?.valor}
					percentualEvolucao={data.pendentes?.evolucao}
					colorValor={TRANSPORTES_CORES.amarelo}
					colorEvolucao={data.pendentes?.evolucao > 0 ? TRANSPORTES_CORES.verde : TRANSPORTES_CORES.vermelho}
					backgroundEvolucao={
						data.pendentes?.evolucao > 0 ? TRANSPORTES_CORES.verdeClaro : TRANSPORTES_CORES.vermelhoClaro
					}
					icon={<MdAttachMoney size={50} color={TRANSPORTES_CORES.amarelo} />}
				/>
			</Col>
			<Col sm="6" md="3" lg="3" xl="3" style={styleCards}>
				<CardResultado
					title="Transmitidos no período"
					helpMessage="Valor total de transmitidos no período selecionado"
					valor={data.finalizados?.valor}
					percentualEvolucao={data.finalizados?.evolucao}
					colorValor={TRANSPORTES_CORES.verde}
					colorEvolucao={data.finalizados?.evolucao > 0 ? TRANSPORTES_CORES.verde : TRANSPORTES_CORES.vermelho}
					backgroundEvolucao={
						data.finalizados?.evolucao > 0 ? TRANSPORTES_CORES.verdeClaro : TRANSPORTES_CORES.vermelhoClaro
					}
					icon={<MdAttachMoney size={50} color={TRANSPORTES_CORES.verde} />}
				/>
			</Col>
			<Col sm="6" md="3" lg="3" xl="3" style={styleCards}>
				<CardResultado
					title="Cancelados no período"
					helpMessage="Valor total de cancelados no período selecionado"
					valor={data.cancelados?.valor}
					percentualEvolucao={data.cancelados?.evolucao}
					colorValor={TRANSPORTES_CORES.vermelho}
					colorEvolucao={data.cancelados?.evolucao > 0 ? TRANSPORTES_CORES.verde : TRANSPORTES_CORES.vermelho}
					backgroundEvolucao={
						data.cancelados?.evolucao > 0 ? TRANSPORTES_CORES.verdeClaro : TRANSPORTES_CORES.vermelhoClaro
					}
					icon={<MdMoneyOff size={50} color={TRANSPORTES_CORES.vermelho} />}
				/>
			</Col>
		</>
	);
}

export { CardsResultado };
