import { Field } from 'formik';
import InputDouble from 'components/input/InputDouble';
import { buscarDadosLoginLocalStorage } from 'Common';

function FieldsModalIcmsMonofasicoRetido(props) {
	const { validacaoNovaTributacao, setFieldValue, values } = props;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	return (
		<>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="ICMS Retido"
				name="icmsMonofasicoRetido"
				value={values.icmsMonofasicoRetido}
				onChange={(event) => setFieldValue('icmsMonofasicoRetido', event.target.value)}
				prefix="R$ "
				allowNegative={false}
				decimalScale={decimaisPreco <= 4 ? decimaisPreco : 4}
				maxValue={100}
				disabled={validacaoNovaTributacao}
			/>
		</>
	);
}

export default FieldsModalIcmsMonofasicoRetido;
