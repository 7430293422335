import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';

import {
	Checkbox,
	Col,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Prompt,
	Tutorial,
	tutorialStepsMercadoriaTransporte,
} from 'components';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { CADASTRO_URL, CODIGO_STYLE, INITIAL_VALUES, SECAO_STYLE, SITUACAO_OPTIONS } from '../Util/constantes';
import { ActionButtons } from './components/ActionButtons';
import { converterMercadoriaTransporteParaFormulario } from '../Util/MercadoriaTransporteConverter';
import { getMercadoriaTransporte } from '../Request';

function MercadoriaTransporteFormImpl({ history, match, isModal, hideModal }) {
	const { dirty, values, errors, resetForm, setFieldValue } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MERCADORIA_TRANSPORTE);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const id = match?.params?.id;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MERCADORIA_TRANSPORTE, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('mercadoria-valor-prestacao-input-descricao')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idMercadoria) {
		await getMercadoriaTransporte(idMercadoria, ({ data: mercadoria }) => {
			resetForm({ values: converterMercadoriaTransporteParaFormulario(mercadoria) });

			if (!isModal) {
				atualizarUrl(history, `/${CADASTRO_URL}`, mercadoria.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsMercadoriaTransporte}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de mercadoria de transporte" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						isModal={isModal}
						history={history}
						hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Col sm="3" md="3" lg="3" xl="3" style={{ height: '4rem' }}>
							<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
								<div
									style={SECAO_STYLE}
									title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}
								>
									<div>Código</div>
									<div style={CODIGO_STYLE}>{values.codigo || '-'}</div>
								</div>
							</div>
						</Col>
					</Grid>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							label="Mercadoria de transporte favorito"
							title="Marque esta opção caso este mercadoria de transporte seja o favorito"
							name="favorito"
							onChange={() => setFieldValue('favorito', !values.favorito)}
							errors={errors.favorito}
							touched
							checked={values.favorito}
							{...informacoesPermissoes}
						/>
						<Field
							id="mercadoria-valor-prestacao-input-nome"
							sm="12"
							md="6"
							lg="7"
							xl="7"
							component={InputField}
							label="Nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							size={60}
							onChange={(e) => setFieldValue('nome', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={InputField}
							label="Código de barras"
							type="number"
							name="codigoBarra"
							onChange={(e) => setFieldValue('codigoBarra', e.target.value)}
							value={values.codigoBarra}
							size={60}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							onChange={(event) => setFieldValue('situacao', event.value)}
							options={SITUACAO_OPTIONS}
							showClear={false}
							disabled={isModal}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const MercadoriaTransporteFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.situacao === 'INATIVO' && values.favorito) {
			errors.favorito = 'Mercadoria de transporte inativa não pode ser favorito';
		}

		return errors;
	},

	handleSubmit: () => {},
})(MercadoriaTransporteFormImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const MercadoriaTransporteForm = connect(mapStateToProps)(MercadoriaTransporteFormFormik);
