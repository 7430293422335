export const modulos = {
	ADMINISTRACAO: 'ADMINISTRACAO',
	DOCUMENTOS: 'DOCUMENTOS',
	FINANCAS: 'FINANCAS',
	VENDAS: 'VENDAS',
	ESTOQUE: 'ESTOQUE',
	COMPRAS: 'COMPRAS',
	CONTROLADORIA: 'CONTROLADORIA',
	SERVICOS: 'SERVICOS',
	TRANSPORTE: 'TRANSPORTE',
};

export const recursos = {
	ORGANIZACOES: 'ORGANIZACOES',
	PAPEIS: 'PAPEIS',
	PLANOS: 'PLANOS',
	PLANOS_ADMINISTRACAO: 'PLANOS_ADMINISTRACAO',
	AGENTES_AUTORIZADOS: 'AGENTES_AUTORIZADOS',
	RELATORIOS_ADMINISTRACAO: 'RELATORIOS_ADMINISTRACAO',

	USUARIOS: 'USUARIOS',
	EMPRESA_CONFIGURACOES: 'EMPRESA_CONFIGURACOES',

	PESSOAS: 'PESSOAS',
	PRODUTOS: 'PRODUTOS',
	ETIQUETAS: 'ETIQUETAS',
	TRIBUTACAO: 'CADASTROS_TRIBUTACAO',
	CADASTROS_CONTAS_FINANCEIRAS: 'CADASTROS_CONTAS_FINANCEIRAS',
	CADASTROS_CATEGORIAS_FINANCEIRAS: 'CADASTROS_CATEGORIAS_FINANCEIRAS',
	SETORES: 'SETORES',
	TECNICOS: 'TECNICOS',
	SERVICOS: 'CADASTROS_SERVICOS',
	TRANSPORTE: 'CADASTROS_TRANSPORTE',
	FORMAS_PAGAMENTO: 'FORMAS_PAGAMENTO',
	CONDICOES_PAGAMENTO: 'CONDICOES_PAGAMENTO',
	CADASTROS_REGIOES: 'CADASTROS_REGIOES',
	CADASTROS_TABELAS_PRECO: 'CADASTROS_TABELAS_PRECO',
	CADASTROS_VENDEDORES: 'CADASTROS_VENDEDORES',
	CADASTROS_MARCAS: 'CADASTROS_MARCAS',

	TRANSPORTE_DASHBOARD: 'TRANSPORTE_DASHBOARD',
	TRANSPORTE_CTE: 'TRANSPORTE_CTE',
	TRANSPORTE_MDFE: 'TRANSPORTE_MDFE',
	TRANSPORTE_RELATORIOS: 'TRANSPORTE_RELATORIOS',

	SERVICOS_ORDEM_SERVICO: 'SERVICOS_ORDEM_SERVICO',
	SERVICOS_NFSE: 'SERVICOS_NFSE',
	SERVICOS_DASHBOARD: 'SERVICOS_DASHBOARD',
	SERVICOS_RELATORIOS: 'SERVICOS_RELATORIOS',

	DOCUMENTOS_DASHBOARD: 'DOCUMENTOS_DASHBOARD',
	DOCUMENTOS_ASSINATURA: 'DOCUMENTOS_ASSINATURA',
	GESTAO_DOCUMENTOS: 'GESTAO_DOCUMENTOS',

	COMPRAS_DASHBOARD: 'COMPRAS_DASHBOARD',
	COMPRAS_MANIFESTACAO: 'COMPRAS_MANIFESTACAO',
	COMPRAS_NOTA_ENTRADA: 'COMPRAS_NOTA_ENTRADA',

	ESTOQUE_DASHBOARD: 'ESTOQUE_DASHBOARD',
	ESTOQUE_MOVIMENTACOES: 'ESTOQUE_MOVIMENTACOES',
	ESTOQUE_RELATORIOS: 'ESTOQUE_RELATORIOS',

	VENDAS_DASHBOARD: 'VENDAS_DASHBOARD',
	VENDAS_PEDIDOS: 'VENDAS_PEDIDOS',
	VENDAS_ORCAMENTOS: 'VENDAS_ORCAMENTOS',
	VENDAS_NOTAS: 'VENDAS_NOTAS',
	VENDAS_NOTAS_CONSUMIDOR: 'VENDAS_NOTAS_CONSUMIDOR',
	VENDAS_RELATORIOS: 'VENDAS_RELATORIOS',

	FINANCAS_DASHBOARD: 'FINANCAS_DASHBOARD',
	FINANCAS_CONTAS_RECEBER: 'FINANCAS_CONTAS_RECEBER',
	FINANCAS_CONTAS_PAGAR: 'FINANCAS_CONTAS_PAGAR',
	FINANCAS_LANCAMENTOS: 'FINANCAS_LANCAMENTOS',
	FINANCAS_RELATORIOS: 'FINANCAS_RELATORIOS',

	CONTROLADORIA_SPED: 'CONTROLADORIA_SPED',
	CONTROLADORIA_GESTAO_FECHAMENTO: 'CONTROLADORIA_GESTAO_FECHAMENTO',
	DOCUMENTOS_RELATORIOS: 'DOCUMENTOS_RELATORIOS',
};

export const permissoes = {
	INSERIR: 'INSERIR',
	VISUALIZAR: 'VISUALIZAR',
	EDITAR: 'EDITAR',
	EXCLUIR: 'EXCLUIR',
	EXCLUIR_EMPRESA: 'EXCLUIR_EMPRESA',
	EDITAR_EMPRESA: 'EDITAR_EMPRESA',
	GERAR: 'GERAR',
	FINALIZAR: 'FINALIZAR',
	ESTORNAR: 'ESTORNAR',
	CANCELAR: 'CANCELAR',
	DEVOLVER: 'DEVOLVER',	
};

export const estadosCadastro = {
	INCLUSAO: 'INCLUSAO',
	EDICAO: 'EDICAO',
};

export const SEMPERMISSAO = '(Sem permissão)';
