import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	If,
	NenhumRegistroEncontrado,
} from 'components';
import { formatarMonetario } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';
import { ModalComponenteValorPrestacao } from './components/ModalComponenteValorPrestacao';
import { converterDadosComponenteValorPrestacaoParaForm } from '../../Utils/converterCargas';

function TabServicos() {
	const {
		setVisibleModalComponenteValorPrestacao,
		visibleModalComponenteValorPrestacao,
		isSituacaoFinalCTe,
		setAtualizarTotalizadores,
	} = useContextCTe();
	const { values, setFieldValue } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalComponenteValorPrestacao(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const servicos = values.servicos || [];
		const updatedServicos = servicos.filter((servico) =>
			servico.tempId ? servico.tempId !== row.tempId : servico.id !== row.id
		);

		setFieldValue('servicos', updatedServicos);
		setAtualizarTotalizadores(true);
	}

	function renderComponente(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.componente?.label}</div>;
	}

	function renderValor(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{formatarMonetario(row.valor)}</div>;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	function onModalSave(componente) {
		const servicos = values.servicos || [];
		const newItem = converterDadosComponenteValorPrestacaoParaForm(componente);
		let updatedServicos;

		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedServicos = servicos.map((servico) => {
				const servicoId = servico.id || servico.tempId;
				if (servicoId === registroId) {
					return newItem;
				}
				return servico;
			});
		} else {
			updatedServicos = [...servicos, newItem];
		}

		setFieldValue('servicos', updatedServicos);
		setRegistroSelecionado(null);
		setVisibleModalComponenteValorPrestacao(false);
		setAtualizarTotalizadores(true);
	}

	function onHideModalComponente() {
		setRegistroSelecionado(null);
		setVisibleModalComponenteValorPrestacao(false);
	}

	return (
		<>
			<DataTable className="table" responsive value={values.servicos || []} emptyMessage={<NenhumRegistroEncontrado />}>
				<Column
					field="componente"
					header="Componente"
					body={(e) => renderComponente(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="valor"
					header="Valor"
					body={(e) => renderValor(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word', width: '230px' }}
				/>
				<Column
					className="step-listagem-acoes"
					body={(e, options) => renderOpcoes(e, options)}
					header="Ações"
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar componente do valor de prestação"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setRegistroSelecionado(null);
					setVisibleModalComponenteValorPrestacao(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>
			<If test={visibleModalComponenteValorPrestacao}>
				<ModalComponenteValorPrestacao
					onHide={onHideModalComponente}
					componente={registroSelecionado}
					onSave={onModalSave}
				/>
			</If>
		</>
	);
}

export { TabServicos };
