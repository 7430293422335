import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Column } from 'primereact/column';

import {
	Tutorial,
	Grid,
	InputField,
	Dropdown,
	Divider,
	Prompt,
	Form,
	FormActions,
	FormContent,
	NenhumRegistroEncontrado,
	Badge,
	ButtonExcluirTable,
	ButtonNovo,
	Button,
	ButtonEditar,
	tutorialStepsCodigoServico,
	If,
	SingleSelectCodigoServico,
} from 'components';

import {
	removerCaracteres,
	validarUUID,
	estadosCadastro,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	mensagensDeValidacao,
	usuarioPossuiPermissao,
	recursos,
	permissoes,
} from 'Common';

import { asyncGetCodigoServico } from '../Requests';
import { converterCodigoServicoParaFormulario } from '../Util/converter';
import { helpCodigoServicoForm } from './Help';

import ActionButton from './components/ActionButtons';
import ModalOperacoesTributacoes from './components/ModalOperacoesTributacoes';
import ModalNovoModeloCodigoServico from './components/ModalNovoModeloCodigoServico';
import ModalImportarModeloCodigoServico from './components/ModalImportarModeloCodigoServico';

const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

const Colors = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

const PESQUISAURL = '/tributacoes/codigo_servico';
const CADASTROURL = '/tributacoes/codigo_servico/cadastro';

const initialValue = {
	id: null,
	codigoServico: null,
	descricao: null,
	situacao: 'ATIVO',
	tributacoes: [],
};

function CodigoServicoView(props) {
	const { values, dirty, setFieldValue, resetForm, errors } = useFormikContext();

	const { isMobile, isModal, setHideBackground, codigoServico } = props;

	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);

	const [dadosBase, setDadosBase] = useState(null);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isNewCodigoServico, setIsNewCodigoServico] = useState(false);
	const [visibleModalOperacoesTributacoes, setVisibleModalOperacoesTributacoes] = useState(false);
	const [visibleModalNovoModeloCodigoServico, setVisibleModalNovoModeloCodigoServico] = useState(false);
	const [visibleModalImportartModeloCodigoServico, setVisibleModalImportarModeloCodigoServico] = useState(false);
	const [podeEditarCodigo, setPodeEditarCodigo] = useState(true);

	const menu = useRef(null);

	const idURL = props.match?.params.id;

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const itensOpcoes = montarItensMenu();

	useEffect(() => {
		if (codigoServico) {
			setCodigoServico(codigoServico);
		}
	}, []);

	useEffect(() => {
		if (setHideBackground) {
			setHideBackground(
				visibleModalOperacoesTributacoes ||
					visibleModalNovoModeloCodigoServico ||
					visibleModalImportartModeloCodigoServico
			);
		}
	}, [visibleModalOperacoesTributacoes, visibleModalNovoModeloCodigoServico, visibleModalImportartModeloCodigoServico]);

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CADASTRO_CODIGO_SERVICO);

		if (idURL) {
			setTimeout(() => {
				document.getElementById('InputDescricaoCodigoServico')?.focus();
			}, 500);
		}

		if (validarUUID(idURL) && !isNewCodigoServico && !isModal) {
			getCodigoServicoSelecionado();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CADASTRO_CODIGO_SERVICO, false, null, false);
		}
	}, [idURL]);

	function setCodigoServico(event) {
		if (event !== null) {
			setFieldValue('codigoServico', {
				label: event.registro.codigo,
				value: event.registro.id,
				registro: { codigo: event.registro.codigo },
			});
			setFieldValue(
				'descricao',
				event.registro.descricao?.length > 120 ? event.registro.descricao.substring(0, 120) : event.registro.descricao
			);
		} else {
			setFieldValue('codigoServico', null);
			setFieldValue('descricao', null);
		}
	}

	function getCodigoServicoSelecionado() {
		asyncGetCodigoServico(idURL, async ({ data: codigoServico }) => {
			const codigoServicoConvertido = converterCodigoServicoParaFormulario(codigoServico);
			resetForm({ values: codigoServicoConvertido });
			setDadosBase(codigoServicoConvertido);
			setPodeEditarCodigo(false);
		});
	}

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function renderSituacaoTributacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 0',
		};

		const styleDescription = {
			opacity: row.registro?.situacao ?? row.situacao === 'INATIVO' ? '0.7' : '',
			padding: '5px 47px',
		};

		switch (row.registro?.situacao ?? row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.registro?.situacao ?? row.situacao;
		}
	}
	function montarItensMenu() {
		const itens = [];
		const existeTributacoes = values.tributacoes.length === 0;

		itens.push({
			label: 'Salvar tributação do Código de Serviço em novo modelo',
			icon: 'pi pi-plus',
			disabled: existeTributacoes,
			command: () => {
				setVisibleModalNovoModeloCodigoServico(true);
			},
		});
		itens.push({
			label: 'Importar modelo de tributação para o Código de Serviço',
			icon: 'pi pi-download',
			command: () => {
				setVisibleModalImportarModeloCodigoServico(true);
			},
		});

		return itens;
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ButtonExcluirTable onClick={() => onExcluirTributacao(row)} disabled={!podeEditar} />
			</div>
		);
	}

	function onExcluirTributacao(row) {
		const tributacoesCodigoServico = values.tributacoes.filter((item) => item !== row);
		setFieldValue('tributacoes', tributacoesCodigoServico);
	}

	function onSalvarTributacoes(tributacoes) {
		setFieldValue('tributacoes', tributacoes);
	}

	function onChangeCodigoServico(valorSelecionado) {
		setFieldValue('codigoServico', converterCodigoServicoInput(valorSelecionado));
		setFieldValue('descricao', valorSelecionado.registro.descricao);
	}

	function converterCodigoServicoInput(valorSelecionado) {
		const formatado = {
			...valorSelecionado,
			label: valorSelecionado.registro.codigo.toString(),
		};
		return formatado;
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsCodigoServico}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Menu model={itensOpcoes} style={{ minWidth: '320px' }} popup ref={menu} id="popup_menu" />
			<Form header="Cadastro de código de serviço" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButton
						{...props}
						informacoesPermissoes={informacoesPermissoes}
						initialValue={initialValue}
						dadosBase={dadosBase}
						setDadosBase={setDadosBase}
						CADASTROURL={CADASTROURL}
						PESQUISAURL={PESQUISAURL}
						setIsNewCodigoServico={setIsNewCodigoServico}
						isModal={isModal}
						// hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid className="step-codigo-servico-principais">
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							obrigatorio
							component={SingleSelectCodigoServico}
							label="Código serviço"
							name="codigoServico"
							value={values.codigoServico}
							onChange={(e) => onChangeCodigoServico(e)}
							errors={errors.codigoServico}
							esconderBotao
							styles={{
								menuPortal: (base) => ({
									...base,
									width: isMobile ? '90%' : '50%',
								}),
								placeholder: (defaultStyles) => ({
									...defaultStyles,
									whiteSpace: 'nowrap',
								}),
							}}
							disabled={!podeEditarCodigo}
							listarTributacaoCodigoServico={false}
							mostrarWarnning={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							id="InputDescricaoCodigoServico"
							component={InputField}
							label="Descrição"
							name="descricao"
							value={values.descricao}
							obrigatorio
							onChange={(e) => setFieldValue('descricao', removerCaracteres(e.target?.value, ['─']))}
							size={120}
							helpMessage={helpCodigoServicoForm.descricao}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							value={values.situacao}
							obrigatorio
							options={situacaoOptions}
							onChange={(event) => setFieldValue('situacao', event?.value)}
							showClear={false}
							helpMessage={helpCodigoServicoForm.situacao}
							{...informacoesPermissoes}
						/>
						<Divider label="Tributações" styleLabel={{ fontSize: '20px', fontWeight: '500' }} />
					</Grid>
					<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
						{values.tributacoes?.length ? (
							<ButtonEditar
								className="step-editar-tributacoes"
								label="Editar tributações"
								color="primary"
								title="Editar os registros"
								onClick={() => setVisibleModalOperacoesTributacoes(true)}
							/>
						) : (
							<ButtonNovo
								className="step-editar-tributacoes"
								label="Novas tributações"
								title="Adicionar novo registro"
								onClick={() => setVisibleModalOperacoesTributacoes(true)}
							/>
						)}
						<Button
							className="p-button-success"
							type="button"
							label="Modelo de tributação"
							icon="fa fa-list"
							style={{ margin: '5px' }}
							hidden={itensOpcoes.length === 0}
							onClick={(event) => menu.current.toggle(event)}
							aria-controls="popup_menu"
							aria-haspopup
						/>
					</div>
					<DataTable
						className="table step-listagem-tributacoes"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={values.tributacoes}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							className="step-listagem-order"
							field="operacaoFiscal.label"
							header="Operação"
							body={(row) => row.operacaoFiscal?.label}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: 'calc(100% - 66%)',
								wordBreak: 'break-all',
							}}
						/>
						<Column
							field="tributacaoMunicipal.label"
							header="Tributação"
							body={(row) => row.tributacaoMunicipal?.label}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: 'calc(100% - 53%)',
								wordBreak: 'break-all',
							}}
						/>
						<Column
							field="situacaoTributacao"
							header="Status tributação"
							body={(row) => renderSituacaoTributacao(row.tributacaoMunicipal)}
							sortable
							style={{ width: '17rem' }}
						/>
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
				</FormContent>
				<If test={visibleModalOperacoesTributacoes}>
					<ModalOperacoesTributacoes
						visible={visibleModalOperacoesTributacoes}
						onHide={() => setVisibleModalOperacoesTributacoes(false)}
						tributacoes={values.tributacoes}
						permissoes={informacoesPermissoes}
						onSalvarTributacoes={(e) => onSalvarTributacoes(e)}
						idURL={idURL}
					/>
				</If>
				<If test={visibleModalNovoModeloCodigoServico}>
					<ModalNovoModeloCodigoServico
						visible={visibleModalNovoModeloCodigoServico}
						tributacoes={values.tributacoes}
						onHide={() => setVisibleModalNovoModeloCodigoServico(false)}
						showCloseIcon={false}
						permissoes={informacoesPermissoes}
					/>
				</If>
				<If test={visibleModalImportartModeloCodigoServico}>
					<ModalImportarModeloCodigoServico
						visible={visibleModalImportartModeloCodigoServico}
						tributacoes={values.tributacoes}
						onHide={() => setVisibleModalImportarModeloCodigoServico(false)}
						setFieldValue={setFieldValue}
						atualizarListaCodigoServico={!visibleModalNovoModeloCodigoServico}
					/>
				</If>
			</Form>
		</>
	);
}

const CodigoServicoForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.codigoServico) {
			errors.codigoServico = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(CodigoServicoView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isDesktop: state.dispositivo.isDesktop,
});

export default withRouter(connect(mapStateToProps)(CodigoServicoForm));
