import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	mensagensDeValidacao,
	permissoes,
	recursos,
	removerCaracteres,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Prompt,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsModeloServico,
	SingleSelectMarca,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { asyncGetModeloDeServico } from '../Request';
import { converterModeloServicoParaFormulario, converterParaDuplicar } from '../Util/servicoModeloConverter';
import { situacaoOptions } from '../Util/constantes';
import { buscarEstadoCadastro } from '../Util/function';
import ActionButtons from './components/ActionButtons';

const initialValue = {
	id: null,
	nome: '',
	marca: null,
	situacao: 'ATIVO',
};

function ModeloServicoFormView({ match, location, history, isModal, hideModal, marcaJaCadastrada }) {
	const { values, dirty, setFieldValue, setValues, resetForm } = useFormikContext();

	const podeInserir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EXCLUIR);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MODELO_SERVICO);
		const idURL = match?.params.id;
		const modeloIdParaDuplicar = location?.state?.modeloId;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MODELO_SERVICO, false, null, false);
		}

		if (modeloIdParaDuplicar) {
			duplicarModelo(modeloIdParaDuplicar);
		} else if (validarUUID(idURL)) {
			getModeloServico(idURL);
		}

		setTimeout(() => {
			document.getElementById('InputNomeModeloServico').focus();
		}, 300);
	}, []);

	function duplicarModelo(modeloId) {
		asyncGetModeloDeServico(modeloId, ({ data: modelo }) => {
			atualizarUrl(history, '/servicos/modelo/cadastro', null, metodosAtualizarUrl.POP);
			const modeloFormulario = converterModeloServicoParaFormulario(modelo);

			setValues(converterParaDuplicar(modeloFormulario));
		});
	}

	function getModeloServico(idURL) {
		asyncGetModeloDeServico(
			idURL,
			({ data: modelo }) => {
				const modeloConvertido = converterModeloServicoParaFormulario(modelo);
				resetForm({ values: modeloConvertido });
			},
			(error) => {
				notify('Erro ao buscar registro', ToastTypes.ERROR);
				console.error(error);
			}
		);
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsModeloServico}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Cadastro de modelo" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						history={history}
						marcaJaCadastrada={marcaJaCadastrada}
						isModal={isModal}
						hideModal={hideModal}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="9"
							lg="10"
							xl="10"
							id="InputNomeModeloServico"
							component={InputField}
							label="Nome"
							obrigatorio
							name="nome"
							onBlur={() => {
								if (values.nome) {
									setFieldValue('nome', values.nome.trim());
								}
							}}
							onChange={(e) => setFieldValue('nome', removerCaracteres(e.target?.value, ['─']))}
							value={values.nome}
							size={120}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							obrigatorio
							name="situacao"
							value={values.situacao}
							onChange={(event) => setFieldValue('situacao', event?.value)}
							options={situacaoOptions}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={SingleSelectMarca}
							label="Marca"
							obrigatorio
							name="marca"
							url={`${services.GESTOR}/v1/servicos/modelo/relacoes/marcas`}
							value={values.marca}
							disabled={!!marcaJaCadastrada}
							onChange={(event) => setFieldValue('marca', event)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const ModeloServicoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.marcaJaCadastrada) {
			return { ...initialValue, marca: props.marcaJaCadastrada };
		}
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.marca) {
			errors.marca = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.marca?.registro?.situacao === 'INATIVO') {
			errors.marca = 'Marca selecionada está INATIVA';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModeloServicoFormView);

export default ModeloServicoForm;
