import { buscarDadosLoginLocalStorage, del, exibirToast, get, post, put, services } from 'Common';
import { formatISO } from 'date-fns';

export async function getCTes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function getCTe(idCTe, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/ctes/${idCTe}`, null, onSuccess, onError);
}

export async function createCTe(cte, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/ctes`,
		cte,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export async function updateCTe(cte, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/ctes/${cte.id}`,
		cte,
		null,
		exibirToast(onSuccess, 'Registro atualizado com sucesso'),
		onError
	);
}

export async function deleteCTe(idCTe, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/ctes/${idCTe}`, null, exibirToast(onSuccess, 'Registro excluído'), onError);
}

export async function transmitirCTe(idCTe, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/ctes/${idCTe}/transmitir`, null, null, onSuccess, onError, false, false);
}

export async function cancelarCTe(idCTe, motivoCancelamento, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/ctes/${idCTe}/cancelar`, motivoCancelamento, null, onSuccess, onError);
}

export async function getImprimirCTe(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/ctes/${id}/imprimir`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function enviarCTePorEmail(id, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/ctes/${id}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'E-mail(s) enviado(s)'),
		onError
	);
}

export async function getCTeImportarNFes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function buscarCadastroPessoaEmitenteNFe(listaEmitentes, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/ctes/buscar_pessoa_importacao_nfe`,
		listaEmitentes,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function getXmlCTe(id, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/ctes/${id}/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function getXmlCTeCartaCorrecao(id, idCorrecao, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/ctes/${id}/xml_carta_correcao/${idCorrecao}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function getPdfCTeCartaCorrecao(id, idCorrecao, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/ctes/${id}/pdf_carta_correcao/${idCorrecao}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/ctes/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function readTotalizadoresCard(interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/ctes/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}

export async function getParticipante(id, onSuccess, onError) {
	if (id) {
		return await get(`${services.GESTOR}/v1/pessoas/${id}`, null, onSuccess, onError, false);
	}
	return null;
}

export async function getSetores(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/ctes/relacoes/setores?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getUsuarioFavorito(onSuccess) {
	const { email } = buscarDadosLoginLocalStorage();
	await get(`${services.GESTOR}/v1/ctes/relacoes/usuarios?query=credencial.email==${email}`, null, onSuccess);
}

export async function getUsuarios(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/ctes/relacoes/usuarios?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getMercadoriaTransporteFavorito(onSuccess) {
	await get(`${services.GESTOR}/v1/ctes/relacoes/mercadoria_transporte?query=favorito==true`, null, onSuccess);
}

export async function getMercadoriaTransportes(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/ctes/relacoes/mercadoria_transporte?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getComponenteValorPrestacaoFavorito(onSuccess) {
	await get(`${services.GESTOR}/v1/ctes/relacoes/componente_valor_prestacao?query=favorito==true`, null, onSuccess);
}

export async function getComponenteValorPrestacaos(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/ctes/relacoes/componente_valor_prestacao?size=500&sort=descricao,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getContaFavorita(onSucess, onError) {
	await get(`${services.GESTOR}/v1/ctes/relacoes/contas?query=favoritaReceita==true`, null, onSucess, onError);
}

export async function getCondicaoPagamentoFavorita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ctes/relacoes/condicoes_pagamento?query=(favorita==true);(movimentacao==SAIDA)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ctes/relacoes/formas_pagamento?query=(formaPagamentoFiscal==DINHEIRO)`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function getCategoriaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/ctes/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioCtePorPeriodo(
	dataInicial,
	dataFinal,
	tomador,
	situacao,
	filiais,
	setores,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/ctes/imprimir/cte_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tomadorId=${
			tomador ? tomador.value : ''
		}&situacao=${situacao}& filiais=${filiais}&setores=${setores}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}
