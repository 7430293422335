import { tipoCampos } from 'components';

export const CTE_SITUACAO = {
	REJEITADO: 'REJEITADO',
	TRANSMITIDO: 'TRANSMITIDO',
	CANCELADO: 'CANCELADO',
	NAO_ENVIADO: 'NAO_ENVIADO',
	AGUARDANDO_AUTORIZACAO: 'AGUARDANDO_AUTORIZACAO',
};

export const CTE_SITUACAO_OPTIONS = [	
	{
		value: CTE_SITUACAO.NAO_ENVIADO,
		label: 'Não enviado',
	},
	{
		value: CTE_SITUACAO.AGUARDANDO_AUTORIZACAO,
		label: 'Aguardando autorização',
	},
	{
		value: CTE_SITUACAO.TRANSMITIDO,
		label: 'Transmitido',
	},
	{
		value: CTE_SITUACAO.REJEITADO,
		label: 'Rejeitado',
	},
	{
		value: CTE_SITUACAO.CANCELADO,
		label: 'Cancelado',
	},
];

export const CTE_INFORMACOES_SITUACAO = {
	[CTE_SITUACAO.NAO_ENVIADO]: {
		name: 'Não enviado',
		description: 'CT-e não enviado para o sefaz',
		lightColor: '#B3E5FC',
		strongColor: '#006095',
	},
	[CTE_SITUACAO.AGUARDANDO_AUTORIZACAO]: {
		name: 'Aguardando',
		description: 'O CT-e está sendo processado pelo sefaz',
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
	[CTE_SITUACAO.TRANSMITIDO]: {
		name: 'Transmitido',
		description: 'CT-e transmitido para o sefaz com sucesso',
		lightColor: '#DCEDC8',
		strongColor: '#1B5E20',
	},
	[CTE_SITUACAO.REJEITADO]: {
		name: 'Rejeitado',
		description: 'CT-e rejeitado pelo sefaz',
		lightColor: '#FFECB3',
		strongColor: '#765100',
	},
	[CTE_SITUACAO.CANCELADO]: {
		name: 'Cancelado',
		description: 'CT-e cancelado',
		lightColor: '#FFCDD2',
		strongColor: '#B71C1C',
	},
};

export const CTE_OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Série', name: 'serie', type: tipoCampos.STRING },
	{ label: 'Número', name: 'numero', type: tipoCampos.STRING },
	{ label: 'Chave', name: 'chave', type: tipoCampos.STRING },
	{ label: 'Protocolo', name: 'protocolo', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: CTE_SITUACAO_OPTIONS,
	},
	{ label: 'Data emissão', name: 'emissao', type: tipoCampos.DATE },
	{ label: 'Data transmissão', name: 'dataTransmissao', type: tipoCampos.DATE },
	{ label: 'Valor do frete', name: 'valorFrete', type: tipoCampos.DECIMAL },
];

export const CADASTRO_URL = '/ctes/cadastro';
export const PESQUISA_URL = '/ctes';
