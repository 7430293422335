import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';

import { AutoProgressBar, Col, Dropdown, Form, FormActions, FormContent, Grid, InputField, Modal } from 'components';
import { copiarObjeto, mensagensDeValidacao } from 'Common';

import { asyncGetCamposDisponiveis } from 'views/cadastros/produtos/Etiquetas/components/configuracao/Request';
import { ActionButtons } from './components/ActionButtons';
import { RichTextConfiguration } from './components/RichTextConfiguration';
import { useContextEtiquetaConfiguracao } from '../../../../Context';
import {
	INITIAL_VALUES_CAMPO,
	OPTIONS_MOSTRAR_CODIGO_BARRAS,
	OPTIONS_TIPO_CODIGO_BARRA_CAMPO_ETIQUETA,
} from '../../../../Util/constantes';
import { isCodigoBarra, isCampoTextoLivre } from '../../../../Util/functions';

function ModalCampoViewImpl({
	visible,
	onHide,
	indexCampo,
	informacoesPermissoes,
	addNewCampoModal,
	editCampoModal,
	deleteCampoModal,
	isMobile,
	isTablet,
	isLessHd,
}) {
	const { values, dirty, handleReset, setFieldValue } = useFormikContext();
	const { camposEtiquetasUtilizados } = useContextEtiquetaConfiguracao();
	const [optionsTipoCampos, setOptionsTipoCampos] = useState([]);

	const isTextoLivre = isCampoTextoLivre(values?.campo?.registro?.nome);
	const isCodigoDeBarras = isCodigoBarra(values?.campo?.registro?.nome);
	const isCampoLivre = values?.campo?.registro?.nome?.includes('textoLivre');

	useEffect(async () => {
		await asyncGetCamposDisponiveis(async ({ data: camposRequisicao }) => {
			setOptionsTipoCampos(
				await encontrarCamposNaoUtilizados(camposRequisicao?.content, camposEtiquetasUtilizados.current)
			);
		});
	}, []);

	async function encontrarCamposNaoUtilizados(camposRequisicao, camposJaUsados) {
		const camposNaoUtilizados = await camposRequisicao?.filter(
			(itemRequisicao) => !camposJaUsados?.includes(itemRequisicao.nome)
		);
		return converterCamposNaoUtilizadosParaOptions(camposNaoUtilizados);
	}

	async function converterCamposNaoUtilizadosParaOptions(camposNaoUtilizados) {
		const convertedCamposNaoUtilizados = await camposNaoUtilizados?.map((campos) => ({
			label: campos.descricao,
			value: campos.nome,
			registro: campos,
		}));
		return convertedCamposNaoUtilizados;
	}

	function onHideModal() {
		dirty ? handleReset() : onHide();
	}

	function onChangeTipoCampo(event) {
		const isTexto = event.registro?.nome?.includes('textoLivre');
		setFieldValue('campo', event);

		if (!isTexto && !!values.textoLivre) {
			setFieldValue('textoLivre', null);
		}
	}

	return (
		<Modal header="Dados do campo da etiqueta" visible={visible} onHide={onHideModal}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						indexCampo={indexCampo}
						onHide={onHide}
						addNewCampoModal={addNewCampoModal}
						editCampoModal={editCampoModal}
						deleteCampoModal={deleteCampoModal}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Tipo campo"
							name="campo"
							value={values.campo}
							options={optionsTipoCampos}
							onChange={(event) => onChangeTipoCampo(event)}
							showClear={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							label="Texto livre"
							name="textoLivre"
							obrigatorio
							component={InputField}
							value={values?.textoLivre}
							onChange={(event) => setFieldValue('textoLivre', event.target.value)}
							disabled={!isTextoLivre}
							hidden={!isCampoLivre}
							{...informacoesPermissoes}
						/>
						<Col sm="12" md="12" lg="12" xl="12">
							<RichTextConfiguration {...informacoesPermissoes} isMobile={isMobile} />
						</Col>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Altura"
							name="altura"
							obrigatorio
							component={InputField}
							value={values?.altura}
							type="number"
							onChange={(event) => setFieldValue('altura', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Largura"
							name="largura"
							obrigatorio
							component={InputField}
							value={values?.largura}
							type="number"
							onChange={(event) => setFieldValue('largura', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Posição vertical"
							name="posicaoVertical"
							obrigatorio
							component={InputField}
							value={values?.posicaoVertical}
							type="number"
							onChange={(event) => setFieldValue('posicaoVertical', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Posição horizontal"
							name="posicaoHorizontal"
							obrigatorio
							component={InputField}
							value={values?.posicaoHorizontal}
							type="number"
							onChange={(event) => setFieldValue('posicaoHorizontal', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Rotação"
							name="rotacao"
							obrigatorio
							component={InputField}
							value={values?.rotacao}
							type="number"
							onChange={(event) => setFieldValue('rotacao', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label={isTablet ? 'Tipo cód. de barras' : 'Tipo código de barras'}
							name="tipoCodigoDeBarra"
							value={values.tipoCodigoDeBarra}
							options={OPTIONS_TIPO_CODIGO_BARRA_CAMPO_ETIQUETA}
							onChange={(event) => setFieldValue('tipoCodigoDeBarra', event?.value)}
							showClear={false}
							disabled={!isCodigoDeBarras}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label={
								isLessHd ? 'Número no cód. barras' : isTablet ? 'Núm. no cód. barra' : 'Mostrar número no cód. barras'
							}
							name="mostrarCodigoNaBarra"
							value={values.mostrarCodigoNaBarra}
							options={OPTIONS_MOSTRAR_CODIGO_BARRAS}
							onChange={(event) => setFieldValue('mostrarCodigoNaBarra', event?.value)}
							showClear={false}
							disabled={!isCodigoDeBarras}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Zoom"
							name="zoom"
							obrigatorio
							component={InputField}
							value={values?.zoom}
							type="number"
							onChange={(event) => setFieldValue('zoom', event.target.value)}
							disabled={!isCodigoDeBarras}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCampoViewFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props?.registro) {
			return copiarObjeto(props.registro);
		} else {
			return INITIAL_VALUES_CAMPO;
		}
	},

	validate(values) {
		const errors = {};

		function validarSeCampoVazio(value) {
			return value === null || value === undefined;
		}

		if (!values.campo) {
			errors.campo = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values?.campo && values?.campo?.registro?.nome?.includes('textoLivre')) {
			if (!values.textoLivre) {
				errors.textoLivre = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (
			values?.campo &&
			['produtoBarrasCodigo', 'produtoImagemBarras', 'produtoBarrasReferencia'].includes(values?.campo?.registro?.nome)
		) {
			if (!values.tipoCodigoDeBarra) {
				errors.tipoCodigoDeBarra = mensagensDeValidacao.OBRIGATORIO;
			}
			if (validarSeCampoVazio(values.mostrarCodigoNaBarra)) {
				errors.mostrarCodigoNaBarra = mensagensDeValidacao.OBRIGATORIO;
			}
			if (validarSeCampoVazio(values.zoom)) {
				errors.zoom = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (!values.tamanhoFonte) {
			errors.richText = 'Tamanho de fonte obrigatória';
		}
		if (values.tamanhoFonte <= 0) {
			errors.richText = mensagensDeValidacao.VALOR_INVALIDO;
		}
		if (values?.altura <= 0) {
			errors.altura = mensagensDeValidacao.VALOR_INVALIDO;
		}
		if (values.largura <= 0) {
			errors.largura = mensagensDeValidacao.VALOR_INVALIDO;
		}
		if (values.posicaoVertical < 0) {
			errors.posicaoVertical = mensagensDeValidacao.VALOR_INVALIDO;
		}
		if (values.posicaoHorizontal < 0) {
			errors.posicaoHorizontal = mensagensDeValidacao.VALOR_INVALIDO;
		}
		if (validarSeCampoVazio(values.rotacao)) {
			errors.rotacao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalCampoViewImpl);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export const ModalCampo = connect(mapStateToProps)(ModalCampoViewFormik);
