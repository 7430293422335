import { Field, useFormikContext } from 'formik';

import { Checkbox, Dropdown, Fieldset, Grid, If, InputField } from 'components';

import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import {
	TIPO_EMISSAO_CTE_OPTIONS,
	TIPO_IMPRESSAO_DACTE_OPTIONS,
} from 'views/configuracao/ConfiguracoesGerais/Util/constantes';

function TabConfiguracoesCte() {
	const { informacoesPermissoes, alterarNumeroInicialESerieCte, setAlterarNumeroInicialESerieCte } =
		useContextConfiguracoesGerais();

	const { values, setFieldValue } = useFormikContext();

	return (
		<If test={values.emiteCte}>
			<Fieldset legend="Configurações CT-e" className="fieldset">
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de emissão CT-e"
						name="tipoEmissaoCte"
						value={values.tipoEmissaoCte}
						showClear={false}
						onChange={(e) => setFieldValue('tipoEmissaoCte', e.value)}
						options={TIPO_EMISSAO_CTE_OPTIONS}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de impressão da DACTE"
						name="tipoImpressaoDacte"
						value={values.tipoImpressaoDacte}
						showClear={false}
						onChange={(e) => setFieldValue('tipoImpressaoDacte', e.value)}
						options={TIPO_IMPRESSAO_DACTE_OPTIONS}
						{...informacoesPermissoes}
					/>
				</Grid>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar número e série da CT-e"
						name="alterarNumeroInicialESerieCte"
						onChange={() => {
							setAlterarNumeroInicialESerieCte(!alterarNumeroInicialESerieCte);
							setFieldValue('alterarNumeroInicialESerieCte', alterarNumeroInicialESerieCte);
						}}
						checked={alterarNumeroInicialESerieCte}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Serie"
						name="serieCte"
						obrigatorio
						value={values.serieCte}
						disabled={!alterarNumeroInicialESerieCte}
						onChange={(e) => setFieldValue('serieCte', e.target.value)}
						size={30}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="Número inicial da CT-e"
						name="numeroInicialCte"
						obrigatorio
						value={values.numeroInicialCte}
						disabled={!alterarNumeroInicialESerieCte}
						onChange={(e) => setFieldValue('numeroInicialCte', e.target.value)}
						size={30}
						component={InputField}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Fieldset>
		</If>
	);
}

export { TabConfiguracoesCte };
