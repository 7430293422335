import { If, Modal } from 'components';
import ModeloServicoForm from '../Form';

export default function ModalModeloServico(props) {
	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de modelo"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={props.visible}>
				<ModeloServicoForm
					hideModal={props.onHide}
					isModal
					history={props.history}
					marcaJaCadastrada={props.marcaJaCadastrada}
				/>
			</If>
		</Modal>
	);
}
