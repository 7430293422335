import { useState } from 'react';
import { Divider, Button, If, ButtonAdicionarItem } from 'components';
import ModalAjudaConvenio from './ModalAjudaConvenio';
import ModalNovoConvenio from './ModalNovoConvenio';
import TableConvenios from './components/TableConvenios';

const buttonAdicionarStyle = {
	background: 'none',
	border: 'none',
	fontWeight: 'bold',
	boxShadow: 'none',
};

function FormConvenios(props) {
	const {
		values,
		camposObrigatorios,
		setFieldValue,
		resetForm,
		isMobile,
		isTablet,
		isLessHd,
		informacoesPermissoes,
		emitirBoletos,
	} = props;

	const [exibirAjudaConvenio, setExibirAjudaConvenio] = useState(false);
	const [exibirModalNovoConvenio, setExibirModalNovoConvenio] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [indexConvenio, setIndexConvenio] = useState(null);

	const podeAdicionarConvenio = camposObrigatorios && !!values.contaBancaria?.banco;

	async function excluirConvenio(convenio) {
		let convenios = [];
		convenios = await values?.convenios.filter((e) => e.numero !== convenio.numero);

		setFieldValue('convenios', convenios);
	}

	return (
		<>
			<div className={'card-default'} style={{ padding: '8px', margin: '8px 0 8px 0' }}>
				<div style={{ position: 'relative', width: '100%' }}>
					<Divider
						label="Convênios"
						styleContainer={{ marginLeft: '0', paddingLeft: '0' }}
						styleLabel={{ display: 'flex', alignItems: 'center', height: '32px' }}
						styleLine={{ margin: '0 0 8px 0' }}
					/>
					<Button
						type="button"
						color="info"
						icon="fa fa-question"
						title={'Ajuda'}
						hidden={false}
						raised={true}
						rounded={true}
						style={{
							position: 'absolute',
							zIndex: '9999',
							width: '32px',
							height: '32px',
							right: '0rem',
							top: '-0.2rem',
						}}
						onClick={() => setExibirAjudaConvenio(true)}
						enableShortCut={false}
						disabled={!values.contaBancaria?.banco || !emitirBoletos}
					/>
				</div>
				<TableConvenios
					values={values}
					setRegistroSelecionado={setRegistroSelecionado}
					setExibirModalNovoConvenio={setExibirModalNovoConvenio}
					setIndexConvenio={setIndexConvenio}
					excluirConvenio={excluirConvenio}
					informacoesPermissoes={informacoesPermissoes}
				/>
				<ButtonAdicionarItem
					label="Adicionar convênio"
					style={{
						...buttonAdicionarStyle,
						color: '#006095',
						padding: '1rem 0',
					}}
					onClick={() => {
						setExibirModalNovoConvenio(true), setRegistroSelecionado(null), setIndexConvenio(null);
					}}
					disabled={!podeAdicionarConvenio}
				/>
			</div>
			<If test={exibirAjudaConvenio}>
				<ModalAjudaConvenio
					visible={exibirAjudaConvenio}
					onHide={() => setExibirAjudaConvenio(false)}
					bancoSelecionado={values.contaBancaria?.banco}
				/>
			</If>
			<If test={exibirModalNovoConvenio}>
				<ModalNovoConvenio
					visible={exibirModalNovoConvenio}
					onHide={() => setExibirModalNovoConvenio(false)}
					onExcluirRegistro={(e) => excluirConvenio(e)}
					setFieldValueForm={setFieldValue}
					resetFormPrincipal={resetForm}
					dadosFormPrincipal={values}
					convenios={values.convenios}
					contaId={values.id}
					bancoSelecionado={values.contaBancaria?.banco}
					registroSelecionado={registroSelecionado}
					informacoesPermissoes={informacoesPermissoes}
					indexConvenio={indexConvenio}
					isMobile={isMobile}
					isTablet={isTablet}
					isLessHd={isLessHd}
				/>
			</If>
		</>
	);
}

export default FormConvenios;
