import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	Grid,
	If,
	InputField,
	InputMoney,
	NenhumRegistroEncontrado,
	SingleSelectMercadoriaTransporte,
} from 'components';
import { formatarDecimais, services } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';
import { ModalQuantidadeCarga } from './components/ModalQuantidadeCarga';
import { converterDadosQuantidadeCargaParaForm } from '../../Utils/converterCargas';

function TabInformacaoCarga({ totalizadores, setTotalizadores }) {
	const { visibleModalQuantidadeCarga, setVisibleModalQuantidadeCarga, informacoesPermissoes, isSituacaoFinalCTe } =
		useContextCTe();
	const { values, setFieldValue, errors } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(false);

	function onHideModalQuantidadeCarga() {
		setRegistroSelecionado(null);
		setVisibleModalQuantidadeCarga(false);
	}

	function onModalSave(quantidadeCarga) {
		const quantidades = values.informacaoCarga?.quantidades || [];
		const newItem = converterDadosQuantidadeCargaParaForm(quantidadeCarga);
		let updatedQuantidadesCarga;

		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedQuantidadesCarga = quantidades.map((quantidade) => {
				const docsId = quantidade.id || quantidade.tempId;
				if (docsId === registroId) {
					return newItem;
				}
				return quantidade;
			});
		} else {
			updatedQuantidadesCarga = [...quantidades, newItem];
		}

		setFieldValue('informacaoCarga.quantidades', updatedQuantidadesCarga);
		setRegistroSelecionado(null);
		setVisibleModalQuantidadeCarga(false);
	}

	function renderUnidade(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.unidadeMedida.label}</div>;
	}

	function renderTipoMedida(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.tipoMedida}</div>;
	}

	function renderQuantidade(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{formatarDecimais(row.quantidade, 3)}</div>;
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalQuantidadeCarga(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const quantidades = values.informacaoCarga?.quantidades || [];
		const updatedQuantidades = quantidades.filter((quantidade) =>
			quantidade.tempId ? quantidade.tempId !== row.tempId : quantidade.id !== row.id
		);
		setFieldValue('informacaoCarga.quantidades', updatedQuantidades);
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="9"
					lg="9"
					xl="9"
					label="Produto predominante"
					name="informacaoCarga.produtoPredominante"
					component={SingleSelectMercadoriaTransporte}
					url={`${services.GESTOR}/v1/ctes/relacoes/mercadoria_transporte`}
					value={values?.informacaoCarga?.produtoPredominante}
					onChange={(value) => setFieldValue('informacaoCarga.produtoPredominante', value)}
					disabled={isSituacaoFinalCTe}
					touched
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					label="Valor da carga"
					name="informacaoCarga.valorCarga"
					value={values?.informacaoCarga?.valorCarga}
					allowNegative={false}
					useOnBlurPerformatico
					onBlur={(valor) => {
						setFieldValue('informacaoCarga.valorCarga', valor);
						setTotalizadores({
							...totalizadores,
							totalCarga: valor,
						});
					}}
					size={13}
					useFormErrors={false}
					useFormTouched={false}
					touched={!!errors?.informacaoCarga?.valorCarga}
					errors={errors?.informacaoCarga?.valorCarga}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					label="Outras características do produto"
					name="informacaoCarga.outraCaracteristicaCarga"
					touched
					allowNegative={false}
					onBlur={(event) => setFieldValue('informacaoCarga.outraCaracteristicaCarga', event.target.value)}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
			</Grid>
			<DataTable
				className="table"
				responsive
				value={values.informacaoCarga?.quantidades || []}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="unidade"
					header="Unidade"
					body={(e) => renderUnidade(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word', width: '280px' }}
				/>
				<Column
					field="tipoMedida"
					header="Tipo da medida"
					body={(e) => renderTipoMedida(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="quantidade"
					header="Quantidade"
					body={(e) => renderQuantidade(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word', width: '250px' }}
				/>
				<Column
					className="step-listagem-acoes"
					body={(e, options) => renderOpcoes(e, options)}
					header="Ações"
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar quantidade de carga"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setRegistroSelecionado(null);
					setVisibleModalQuantidadeCarga(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>

			<If test={visibleModalQuantidadeCarga}>
				<ModalQuantidadeCarga
					onHide={onHideModalQuantidadeCarga}
					quantidadeCarga={registroSelecionado}
					onSave={onModalSave}
				/>
			</If>
		</>
	);
}

export { TabInformacaoCarga };
