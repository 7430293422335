import { parseFloatNumber } from 'Common/ConverterFormat';

function concatenate(value) {
	let str1 = value?.unidade ? value.unidade : value?.codigo ? value.codigo : '';
	let str2 = value?.descricao ? value.descricao : value?.nome ? value.nome : '';
	let str3 = `${str1} - ${str2}${value?.sigla ? ' (' + value.sigla + ')' : ''}`;
	return { ...value, label: str3, value: value?.id };
}

function calcularConversao(value, fator, operacao) {
	let total = 0;
	if (operacao === 'MULTIPLICACAO') {
		total = value * fator;
	} else if (operacao === 'DIVISAO') {
		total = value / fator;
	}
	return parseFloatNumber(total);
}

function calcularTotal(operacao, unitario, quantidade, icmsSt, ipi, frete, seguro, acessorias, desconto) {
	let total =
		(operacao === 'MULTIPLICACAO' ? unitario * quantidade : unitario / quantidade) -
		desconto +
		frete +
		acessorias +
		seguro +
		icmsSt +
		ipi;

	return parseFloatNumber(total);
}

export { concatenate, calcularConversao, calcularTotal };
