import { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Divider, Grid, InputSearch, Modal } from 'components';

import ListaModelo from './components/ListaModelo';
import { asyncGetmodelosNcm } from '../ModalNovoModeloNcm/Requests';

export default function ModalImportarModeloNcm(props) {
	const { visible, onHide, setFieldValue, tributacoes, atualizarListaNcm } = props;

	const [valorPesquisa, setValorPesquisa] = useState('');
	const [listaModelos, setListaModelos] = useState(null);
	const [listaModelosFirstRender, setListaModelosFirstRender] = useState(null);
	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			if (document.getElementById('InputSearchImportarModeloNcm')) {
				document.getElementById('InputSearchImportarModeloNcm').focus();
			}
		}, 500);

		setIsFirstRender(false);
	}, []);

	useEffect(() => {
		if (atualizarListaNcm) {
			asyncGetmodelosNcm('', ({ data: modelos }) => {
				setListaModelos(modelos);
				setListaModelosFirstRender(modelos);
			});
		}
	}, [atualizarListaNcm]);

	function pesquisar() {
		const filteredlistaModelo = [];
		listaModelos?.forEach((modelo) => {
			if (modelo.descricao.includes(valorPesquisa)) {
				filteredlistaModelo.push(modelo);
			}
		});

		if (filteredlistaModelo.length > 0) {
			setListaModelos(filteredlistaModelo);
		}

		if (!isFirstRender && !valorPesquisa) {
			if (listaModelosFirstRender) {
				setListaModelos(listaModelosFirstRender);
			}
		}
	}

	return (
		<Modal
			header="Selecionar modelo de tributação"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '720px' }}
			closeOnEsc
			showCloseIcon
		>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					id="InputSearchImportarModeloNcm"
					name="pesquisa"
					component={InputSearch}
					onPesquisar={() => pesquisar()}
					value={valorPesquisa}
					onChange={(value) => setValorPesquisa(value)}
				/>
				<Divider
					label="Modelos:"
					styleLabel={{ fontSize: '18px', fontWeight: '500' }}
					styleContainer={{ margin: '1rem 0.5rem' }}
				/>
				<ListaModelo
					listaModelos={listaModelos}
					setFieldValue={setFieldValue}
					tributacoes={tributacoes}
					onHide={onHide}
				/>
			</Grid>
		</Modal>
	);
}
