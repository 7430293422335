import { useEffect, useState } from 'react';
import { withFormik, Field } from 'formik';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import {
	Grid,
	ButtonNovo,
	estadosBotaoNovo,
	ButtonSalvar,
	estadosBotaoSalvar,
	ButtonCancelar,
	estadosBotaoCancelar,
	InputField,
	Prompt,
	ButtonExcluir,
	Form,
	FormActions,
	FormContent,
	tutorialStepsSetor,
	Tutorial,
} from 'components';

import {
	mensagensDeValidacao,
	recursos,
	permissoes,
	estadosCadastro,
	validarUUID,
	usuarioPossuiPermissao,
	keyFilterConsultaRsql,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	buscarConfiguracaoUsuario,
} from 'Common';

import {
	asyncGetSetor,
	asyncDeleteSetor,
	asyncUpdateSetor,
	asyncCreateSetor,
	asyncGetSetorMesmoNome,
} from '../Requests';
import { confirmarExclusao } from '../../../../Util/ExclusaoDeRegistros';
import { atualizarUrl, metodosAtualizarUrl, voltarParaAPesquisa, validarFormulario } from '../../../../Util';
import { helpSetorForm } from './Help';

const initialValues = {
	id: '',
	nome: '',
};

const CADASTRO_URL = '/setores/cadastro';
const PESQUISA_URL = '/setores';

function SetorFormImpl(props) {
	const { values, history, resetForm, dirty, isModal } = props;

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SETOR);

	const podeInserir = usuarioPossuiPermissao(recursos.SETORES, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.SETORES, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.SETORES, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	useEffect(() => {
		const { id } = props.match.params;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SETOR, false, null, false);
		}

		if (validarUUID(id) && !props.isModal) {
			asyncSelectRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('SetoresInputFieldNome')?.focus();
		}, 500);
	}, []);

	function onClickNovo() {
		dirty ? salvar(novo) : novo();
	}

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	async function novo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		await resetForm({ values: initialValues });
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function salvar(novoOnSuccess) {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			if (await validarNome()) {
				const dadosFormulario = values;

				if (values.id) {
					asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
				} else {
					asyncCreateRegistro(dadosFormulario, novoOnSuccess);
				}
			} else {
				props.setFieldError('nome', 'O nome informado já existe');
			}
		}
	}

	async function validarNome() {
		let nomeJaExiste = false;
		await asyncGetSetorMesmoNome(values.nome, (e) => {
			if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
				nomeJaExiste = true;
			}
		});
		return !nomeJaExiste;
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else if (isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(props.history, PESQUISA_URL);
		}
	}

	function fecharModal() {
		if (values.id) {
			props.hideModal(props.values);
		} else {
			props.hideModal();
		}
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteSetor(values.id, () => {
			props.resetForm();
			voltarParaAPesquisa(props.history, PESQUISA_URL);
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdateSetor(dadosFormulario, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				props.resetForm({ values: values });
			}
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateSetor(dadosFormulario, ({ data: setor }) => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				props.resetForm({ values: { ...values, id: setor.id } });
				atualizarUrl(props.history, CADASTRO_URL, setor.id, metodosAtualizarUrl.POP);
			}
		});
	}

	async function asyncSelectRegistro(idSetor) {
		await asyncGetSetor(
			idSetor,
			({ data: setor }) => {
				props.resetForm({ values: setor });

				if (!props.isModal) {
					atualizarUrl(props.history, CADASTRO_URL, setor.id, metodosAtualizarUrl.POP);
				}
			},
			() => {
				novo();
			}
		);
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsSetor}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de setor" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ButtonCancelar
						{...informacoesPermissoes}
						hidden={isModal && values.id && !dirty}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<ButtonSalvar
						{...informacoesPermissoes}
						estadoBotao={estadoBotaoSalvar}
						disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
						onClick={() => salvar()}
					/>
					<ButtonNovo
						{...informacoesPermissoes}
						estadoBotao={estadoBotaoNovo}
						hidden={(!dirty && !values.id) || isModal}
						onClick={() => onClickNovo()}
					/>
					<ButtonExcluir hidden={!values.id || isModal} onClick={excluir} {...informacoesPermissoes} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Nome"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							helpMessage={helpSetorForm.nome}
							size={60}
							id="SetoresInputFieldNome"
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const SetorForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValues;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(60, 'O campo não pode ter mais de 60 caracteres.'),
	}),

	handleSubmit: () => {},
})(SetorFormImpl);

export default withRouter(SetorForm);
