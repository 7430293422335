import { formatISO } from 'date-fns';
import { CTE_SITUACAO } from '../../Util/constantes';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { gerarUUID } from 'Common';

const CTE_TIPO = {
	NORMAL: 'NORMAL',
	COMPLEMENTO_VALORES: 'COMPLEMENTO_VALORES',
	SUBSTITUICAO: 'SUBSTITUICAO',
};

const CTE_TIPO_OPTIONS = [
	{
		value: CTE_TIPO.NORMAL,
		label: 'Normal',
	},
	{
		value: CTE_TIPO.COMPLEMENTO_VALORES,
		label: 'Complemento de valores',
	},
	{
		value: CTE_TIPO.SUBSTITUICAO,
		label: 'Substituição',
	},
];

const CTE_FINALIDADE = {
	NORMAL: 'NORMAL',
	SUBCONTRATACAO: 'SUBCONTRATACAO',
	REDESPACHO: 'REDESPACHO',
	REDESPACHO_INTERMEDIARIO: 'REDESPACHO_INTERMEDIARIO',
};

const CTE_FINALIDADE_OPTIONS = [
	{
		value: CTE_FINALIDADE.NORMAL,
		label: 'Normal',
	},
	{
		value: CTE_FINALIDADE.SUBCONTRATACAO,
		label: 'Subcontratação',
	},
	{
		value: CTE_FINALIDADE.REDESPACHO,
		label: 'Redespacho',
	},
	{
		value: CTE_FINALIDADE.REDESPACHO_INTERMEDIARIO,
		label: 'Redespacho intermediário',
	},
];

const CTE_GLOBALIZADO_OPTIONS = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

const CTE_TOMADOR = {
	REMETENTE: 'REMETENTE',
	DESTINATARIO: 'DESTINATARIO',
	EXPEDIDOR: 'EXPEDIDOR',
	RECEBEDOR: 'RECEBEDOR',
	OUTROS: 'OUTROS',
};

const CTE_TOMADOR_OPTIONS = [
	{
		value: CTE_TOMADOR.REMETENTE,
		label: 'Remetente',
	},
	{
		value: CTE_TOMADOR.DESTINATARIO,
		label: 'Destinatário',
	},
	{
		value: CTE_TOMADOR.EXPEDIDOR,
		label: 'Expedidor',
	},
	{
		value: CTE_TOMADOR.RECEBEDOR,
		label: 'Recebedor',
	},
	{
		value: CTE_TOMADOR.OUTROS,
		label: 'Outros',
	},
];

const CTE_UNIDADE_MEDIDA = {
	M3: 'M3',
	KG: 'KG',
	TON: 'TON',
	UNIDADE: 'UNIDADE',
	LITROS: 'LITROS',
	MMBTU: 'MMBTU',
};

const CTE_UNIDADE_MEDIDA_OPTIONS = [
	{
		value: CTE_UNIDADE_MEDIDA.M3,
		label: '00 - M3',
	},
	{
		value: CTE_UNIDADE_MEDIDA.KG,
		label: '01 - KG',
	},
	{
		value: CTE_UNIDADE_MEDIDA.TON,
		label: '02 - TON',
	},
	{
		value: CTE_UNIDADE_MEDIDA.UNIDADE,
		label: '03 - UNIDADE',
	},
	{
		value: CTE_UNIDADE_MEDIDA.LITROS,
		label: '04 - LITROS',
	},
	{
		value: CTE_UNIDADE_MEDIDA.MMBTU,
		label: '05 - MMBTU',
	},
];

const CTE_TIPO_DOCUMENTOS = {
	NOTA_FISCAL: 'NOTA_FISCAL',
	NFE: 'NFE',
	OUTROS: 'OUTROS',
};

const CTE_TIPO_DOCUMENTOS_NOTA_FISCAL = {
	MODELO_01: '01',
	PRODUTOR: '04',
};

const CTE_TIPO_DOCUMENTOS_NOTA_FISCAL_OPTIONS = [
	{
		value: CTE_TIPO_DOCUMENTOS_NOTA_FISCAL.MODELO_01,
		label: '01 - Modelo 01/1A e avulsa',
	},
	{
		value: CTE_TIPO_DOCUMENTOS_NOTA_FISCAL.PRODUTOR,
		label: '04 - NF de produtor',
	},
];

const CTE_TIPO_DOCUMENTOS_OPTIONS = [
	{
		value: CTE_TIPO_DOCUMENTOS.NFE,
		label: 'NFe',
	},
	{
		value: CTE_TIPO_DOCUMENTOS.NOTA_FISCAL,
		label: 'Nota Fiscal',
	},
	{
		value: CTE_TIPO_DOCUMENTOS.OUTROS,
		label: 'Outros',
	},
];

const TIPO_DOCUMENTO_ANTERIOR = {
	CTE: 'CTE',
	OUTROS: 'OUTROS',
};

const TIPO_DOCUMENTO_ANTERIOR_OPTIONS = [
	{
		value: TIPO_DOCUMENTO_ANTERIOR.CTE,
		label: 'CT-e',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR.OUTROS,
		label: 'Outros',
	},
];

const TIPO_DOCUMENTO_ANTERIOR_PAPEL = {
	ATRE: 'ATRE',
	DESPACHO_TRANSITO_ADUANEIRO: 'DESPACHO_TRANSITO_ADUANEIRO',
	CONHECIMENTO_AEREO_INTERNACIONAL: 'CONHECIMENTO_AEREO_INTERNACIONAL',
	CONHECIMENTO_CARTA_DE_PORTE_INTERNACIONAL: 'CONHECIMENTO_CARTA_DE_PORTE_INTERNACIONAL',
	CONHECIMENTO_AVULSO: 'CONHECIMENTO_AVULSO',
	TRANSPORTE_INTERNACIONAL_FERROVIARIO: 'TRANSPORTE_INTERNACIONAL_FERROVIARIO',
	BILL_OF_LADING: 'BILL_OF_LADING',
};

const TIPO_DOCUMENTO_ANTERIOR_PAPEL_OPTIONS = [
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.ATRE,
		label: '07 - ATRE',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.DESPACHO_TRANSITO_ADUANEIRO,
		label: '08 - DTA (Despacho de Trânsito Aduaneiro)',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.CONHECIMENTO_AEREO_INTERNACIONAL,
		label: '09 - Conhecimento Aéreo Internacional',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.CONHECIMENTO_CARTA_DE_PORTE_INTERNACIONAL,
		label: '10 - Conhecimento Carta de Porte Internacional',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.CONHECIMENTO_AVULSO,
		label: '11 - Conhecimento Avulso',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.TRANSPORTE_INTERNACIONAL_FERROVIARIO,
		label: '12 - TIF (Transporte Internacional Ferroviário)',
	},
	{
		value: TIPO_DOCUMENTO_ANTERIOR_PAPEL.BILL_OF_LADING,
		label: '13 - Bill of Lading',
	},
];

const TIPO_DOCUMENTO_OUTROS = {
	DECLARACAO: 'DECLARACAO',
	DUTOVIARIO: 'DUTOVIARIO',
	CFE_SAT: 'CFE_SAT',
	NFCE: 'NFCE',
	OUTROS: 'OUTROS',
};

const TIPO_DOCUMENTO_OUTROS_OPTIONS = [
	{
		value: TIPO_DOCUMENTO_OUTROS.DECLARACAO,
		label: '00 - Declaração',
	},
	{
		value: TIPO_DOCUMENTO_OUTROS.DUTOVIARIO,
		label: '10 - Dutoviário',
	},
	{
		value: TIPO_DOCUMENTO_OUTROS.CFE_SAT,
		label: '59 - CFE-SAT',
	},
	{
		value: TIPO_DOCUMENTO_OUTROS.NFCE,
		label: '65 - NFCE',
	},
	{
		value: TIPO_DOCUMENTO_OUTROS.OUTROS,
		label: '99 - Outros',
	},
];

const INITIAL_VALUE_DOCUMENTO_NFES = {
	id: null,
	chaveAcesso: null,
};

const INITIAL_VALUE_DOCUMENTO_CTES = {
	id: null,
	chaveAcesso: null,
};

const INITIAL_VALUE_NOTAS_FISCAIS = {
	id: null,
	modeloDocumento: CTE_TIPO_DOCUMENTOS_NOTA_FISCAL.MODELO_01,
	emissao: null,
	cfop: null,
	serie: null,
	numero: null,
	numeroRomaneio: null,
	numeroPedido: null,
	valorBaseCalculoIcms: 0,
	valorIcms: 0,
	valorBaseCalculoIcmsSt: 0,
	valorIcmsSt: 0,
	peso: 0,
	valorTotalProdutos: 0,
	valorTotalNotaFiscal: 0,
};

const INITIAL_VALUE_OUTROS_DOCUMENTOS = {
	id: null,
	tipoDocumento: TIPO_DOCUMENTO_OUTROS.DECLARACAO,
	numero: null,
	valor: null,
	emissao: null,
	descricao: null,
};

const INITIAL_VALUE_DADOS_CTE = {
	id: null,
	chaveAcesso: null,
};

const INITIAL_VALUE_TRANSPORTE_PAPEL = {
	id: null,
	tipoDocumento: TIPO_DOCUMENTO_ANTERIOR_PAPEL.CONHECIMENTO_AVULSO,
	serie: null,
	subSerie: null,
	numeroDocumento: null,
	emissao: null,
};

const INITIAL_VALUE_QUANTIDADE_CARGA = {
	id: null,
	unidadeMedida: CTE_UNIDADE_MEDIDA_OPTIONS[1],
	tipoMedida: CTE_UNIDADE_MEDIDA.KG,
	quantidade: 0,
};

const INITIAL_VALUE_COMPONENTE_VALOR_PRESTACAO = {
	id: null,
	componente: null,
	valor: 0,
};

const INITIAL_VALUE_PAGAMENTO = {
	id: null,
	tempKey: gerarUUID(),
	sequencial: 1,
	condicaoPagamento: null,
	conta: null,
	formaPagamento: null,
	categoria: null,
	quantidadeParcelas: 0,
	valor: 0,
	valorRecebido: 0,
	parcelas: [],
};

const INITIAL_VALUES = {
	id: null,
	numero: null,
	serie: null,
	chave: null,
	protocolo: null,
	situacao: CTE_SITUACAO.NAO_ENVIADO,
	tipo: CTE_TIPO.NORMAL,
	finalidade: CTE_FINALIDADE.NORMAL,
	emissao: formatISO(new Date()),
	rntrc: '',
	cteGlobalizado: false,
	cfop: null,
	tomador: CTE_TOMADOR.REMETENTE,
	localEmissao: null,
	localInicioPrestacao: null,
	localFimPrestacao: null,
	remetente: null,
	destinatario: null,
	expedidor: null,
	recebedor: null,
	outros: null,
	documento: {
		tipoDocumento: CTE_TIPO_DOCUMENTOS.NFE,
		nfes: [],
		notasFiscais: [],
		outros: [],
	},
	documentoAnterior: {
		emissor: null,
		tipoDocumento: TIPO_DOCUMENTO_ANTERIOR.CTE,
		ctes: [],
		documentosPapel: [],
	},
	cteComplemento: {
		chaveAcesso: '',
	},
	cteSubstituicao: {
		chaveAcesso: '',
		tomadorInformadoDiferente: false,
	},
	servicos: [],
	informacaoCarga: {
		produtoPredominante: '',
		valorCarga: 0,
		outraCaracteristicaCarga: '',
		quantidades: [],
	},
	impostos: {
		cstCsosn: null,
		percentualBaseCalculoIcms: 100,
		valorBaseCalculoIcms: 0,
		aliquotaIcms: 0,
		valorIcms: 0,
	},
	totalizadores: {
		totalCarga: 0,
		totalFrete: 0,
		totalDesconto: 0,
		totalLiquido: 0,
	},
	pagamentos: [INITIAL_VALUE_PAGAMENTO],
	informacoesComplementares: {
		usuario: null,
		setor: null,
		informacaoComplementar: '',
		recebedorRetira: false,
		recebedorRetiraDetalhes: '',
		observacaoInterna: '',
	},
	errors: [],
	correcoes: [],
};

const INITIAL_VALUE_PESSOA_MODAL = {
	id: null,
	nome: null,
	tipoPessoa: TIPO_PESSOA.JURIDICA,
	cnpj: null,
	cpf: null,
	indicadorInscricaoEstadual: null,
	inscricaoEstadual: null,
	email: null,
	telefone: null,
	cep: null,
	logradouro: null,
	bairro: null,
	numero: null,
	complemento: null,
	municipio: null,
};

const INITIAL_VALUE_EMISSOR_DOCUMENTO_ANTERIOR_MODAL = {
	id: null,
	nome: null,
	tipoPessoa: TIPO_PESSOA.JURIDICA,
	cnpj: null,
	cpf: null,
	inscricaoEstadual: null,
	municipio: null,
	pessoa: null,
};

const INITIAL_VALUE_ENVIAR_POR_EMAIL = {
	assunto: null,
	conteudo: null,
	destinatarios: null,
	enviarCopia: false,
};

const ClassNameTabViewColor = {
	NAO_ENVIADO: 'tab-view-cte-pendente',
	AGUARDANDO_AUTORIZACAO: 'tab-view-cte-aguardando',
	TRANSMITIDO: 'tab-view-cte-transmitido',
	REJEITADO: 'tab-view-cte-rejeitado',
	CANCELADO: 'tab-view-cte-cancelado',
};

const SECAO_STYLE = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 1.5rem 0 0',
};

const NUMERO_STYLE = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

const SITUACAO_STYLE = {
	height: '38px',
	borderRadius: '25px',
	fontWeight: 'bold',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
};

const CHAVE_STYLE = {
	textAlign: 'center',
	cursor: 'pointer',
	flex: '1 1',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: '2px 0px',
	fontSize: '12px',
};

const NAME_STYLE = {
	textAlign: 'center',
	fontWeight: 'bold',
	padding: '2px 0px',
};

const ClassNameInputValorTotalColor = {
	NAO_ENVIADO: 'input-double-valor-total-pendente',
	AGUARDANDO: 'input-double-valor-total-aguardando',
	TRANSMITIDO: 'input-double-valor-total-transmitido',
	REJEITADO: 'input-double-valor-total-rejeitado',
	CANCELADO: 'input-double-valor-total-cancelado',
};

const colorsStatus = {
	NAO_ENVIADO: {
		lightColor: '#b3e5fc',
		strongColor: '#01579B',
	},
	TRANSMITIDO: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CANCELADO: {
		lightColor: '#ffcdd2',
		strongColor: '#b71c1c',
	},
	REJEITADO: {
		lightColor: '#FFECB3',
		strongColor: '#765100',
	},
	AGUARDANDO_AUTORIZACAO: {
		lightColor: '#E7ECF0',
		strongColor: '#333333',
	},
};

export {
	CTE_TIPO,
	CTE_TIPO_OPTIONS,
	CTE_FINALIDADE,
	CTE_FINALIDADE_OPTIONS,
	CTE_GLOBALIZADO_OPTIONS,
	CTE_TOMADOR,
	CTE_TOMADOR_OPTIONS,
	INITIAL_VALUES,
	INITIAL_VALUE_PESSOA_MODAL,
	INITIAL_VALUE_EMISSOR_DOCUMENTO_ANTERIOR_MODAL,
	INITIAL_VALUE_ENVIAR_POR_EMAIL,
	INITIAL_VALUE_DOCUMENTO_NFES,
	INITIAL_VALUE_DOCUMENTO_CTES,
	INITIAL_VALUE_NOTAS_FISCAIS,
	INITIAL_VALUE_OUTROS_DOCUMENTOS,
	INITIAL_VALUE_TRANSPORTE_PAPEL,
	INITIAL_VALUE_DADOS_CTE,
	INITIAL_VALUE_QUANTIDADE_CARGA,
	INITIAL_VALUE_COMPONENTE_VALOR_PRESTACAO,
	INITIAL_VALUE_PAGAMENTO,
	ClassNameTabViewColor,
	SECAO_STYLE,
	NUMERO_STYLE,
	SITUACAO_STYLE,
	CHAVE_STYLE,
	NAME_STYLE,
	CTE_TIPO_DOCUMENTOS,
	CTE_TIPO_DOCUMENTOS_OPTIONS,
	ClassNameInputValorTotalColor,
	colorsStatus,
	TIPO_DOCUMENTO_ANTERIOR_OPTIONS,
	TIPO_DOCUMENTO_ANTERIOR,
	TIPO_DOCUMENTO_ANTERIOR_PAPEL,
	TIPO_DOCUMENTO_ANTERIOR_PAPEL_OPTIONS,
	CTE_TIPO_DOCUMENTOS_NOTA_FISCAL,
	CTE_TIPO_DOCUMENTOS_NOTA_FISCAL_OPTIONS,
	CTE_UNIDADE_MEDIDA,
	CTE_UNIDADE_MEDIDA_OPTIONS,
	TIPO_DOCUMENTO_OUTROS,
	TIPO_DOCUMENTO_OUTROS_OPTIONS,
};
