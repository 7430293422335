import { Checkbox, Col, Grid, If, InputDate, InputField } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import CertificadoUpload from '../../../CertificadoUpload';
import { TabsConfiguracoesFiscais } from '../../../TabsConfiguracoesFiscais';
import { helpConfiguracoesForm } from '../../Help';

function TabConfiguracoesFiscais() {
	const {
		podeEditarEmpresa,
		informacoesPermissoes,
		credencial,
		setAlterarNumeroInicialESerieNfce,
		setAlterarNumeroInicialESerieNfe,
		setAlterarNumeroInicialESerieNfse,
		setAlterarNumeroInicialESerieMdfe,
		setAlterarNumeroInicialESerieCte,
		marcarCheckboxEmiteNfe,
		marcarCheckboxEmiteNfce,
		marcarCheckboxEmiteNfse,
		marcarCheckboxEmiteMdfe,
		marcarCheckboxEmiteCte,
		setMarcarCheckboxEmiteNfe,
		setMarcarCheckboxEmiteNfce,
		setMarcarCheckboxEmiteNfse,
		setMarcarCheckboxEmiteMdfe,
		setMarcarCheckboxEmiteCte,
	} = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	function existeValorFiscalNfceSerieEnumero() {
		if (values.emiteNfce) {
			if (!credencial?.filialConectada?.parametrosFiscalNFCe.serieNfce) {
				setAlterarNumeroInicialESerieNfce(true);
				setFieldValue('alterarNumeroInicialESerieNfce', true);
			}
		} else {
			setFieldValue('alterarNumeroInicialESerieNfce', false);
		}
	}

	function existeValorFiscalNfeSerieEnumero() {
		if (values.emiteNfe) {
			if (!credencial?.filialConectada?.parametrosFiscalNFe.serieNfe) {
				setAlterarNumeroInicialESerieNfe(true);
				setFieldValue('alterarNumeroInicialESerieNfe', true);
			}
		} else {
			setFieldValue('alterarNumeroInicialESerieNfe', false);
		}
	}

	function existeValorFiscalNfseSerieEnumero() {
		if (values.emiteNfse) {
			if (!credencial?.filialConectada?.parametrosFiscalNFSe.serieNfse) {
				setAlterarNumeroInicialESerieNfse(true);
				setFieldValue('alterarNumeroInicialESerieNfse', true);
			}
		} else {
			setFieldValue('alterarNumeroInicialESerieNfse', false);
		}
	}

	function existeValorFiscalMdfeSerieEnumero() {
		if (values.emiteMdfe) {
			if (!credencial?.filialConectada?.parametrosFiscalMDFe.serieMdfe) {
				setAlterarNumeroInicialESerieMdfe(true);
				setFieldValue('alterarNumeroInicialESerieMdfe', true);
			}
		} else {
			setFieldValue('alterarNumeroInicialESerieMdfe', false);
		}
	}

	function existeValorFiscalCteSerieEnumero() {
		if (values.emiteCte) {
			if (!credencial?.filialConectada?.parametrosFiscalCTe.serieCte) {
				setAlterarNumeroInicialESerieCte(true);
				setFieldValue('alterarNumeroInicialESerieCte', true);
			}
		} else {
			setFieldValue('alterarNumeroInicialESerieCte', false);
		}
	}

	function buscarSenhaFake() {
		const { certificado, certificadoAlterado } = values;
		if (certificado != null && !certificadoAlterado) {
			return '••••••••••';
		}
		return '';
	}

	return (
		<Grid>
			<Col
				sm="6"
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<CertificadoUpload
					onChange={(event) => {
						setFieldValue('certificado', event);
						setFieldValue('certificadoAlterado', true);
					}}
					value={values.certificado}
					podeEditarEmpresa={podeEditarEmpresa}
				/>
			</Col>
			<Col sm="6">
				<Grid>
					<Field
						sm="12"
						label="Senha do certificado"
						name="certificadoSenha"
						size={128}
						component={InputField}
						type="password"
						helpMessage={helpConfiguracoesForm.certificadoSenha}
						disabled={!values.certificadoAlterado}
						title={!values.certificadoAlterado ? 'Para alterar a senha é necessário inserir um novo certificado' : ''}
						placeholder={buscarSenhaFake()}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						label="Confirme a senha do certificado"
						name="certificadoConfirmacaoSenha"
						size={128}
						component={InputField}
						type="password"
						title={!values.certificadoAlterado ? 'Para alterar a senha é necessário inserir um novo certificado' : ''}
						placeholder={buscarSenhaFake()}
						helpMessage={helpConfiguracoesForm.certificadoSenha}
						disabled={!values.certificadoAlterado}
						{...informacoesPermissoes}
					/>
					<If test={values.certificado?.vencimento}>
						<Field
							sm="12"
							label="Vencimento do certificado"
							name="vencimento"
							component={InputDate}
							value={values.certificado?.vencimento}
							disabled
							{...informacoesPermissoes}
						/>
					</If>
				</Grid>
			</Col>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Configurar informações fiscais para emissão de NF-e"
					name="emiteNfe"
					value={marcarCheckboxEmiteNfe}
					onChange={(e) => {
						setMarcarCheckboxEmiteNfe(e.checked);
						setFieldValue('emiteNfe', e.checked);
						existeValorFiscalNfeSerieEnumero();
					}}
					checked={values.emiteNfe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Configurar informações fiscais para emissão de NFC-e"
					name="emiteNfce"
					value={marcarCheckboxEmiteNfce}
					onChange={(e) => {
						setMarcarCheckboxEmiteNfce(e.checked);
						setFieldValue('emiteNfce', e.checked);
						existeValorFiscalNfceSerieEnumero();
					}}
					checked={values.emiteNfce}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Configurar informações fiscais para emissão de NFS-e"
					name="emiteNfse"
					value={marcarCheckboxEmiteNfse}
					onChange={async (e) => {
						setMarcarCheckboxEmiteNfse(e.checked);
						setFieldValue('emiteNfse', e.checked);
						existeValorFiscalNfseSerieEnumero();
					}}
					checked={values.emiteNfse}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Configurar informações fiscais para emissão de MDF-e"
					name="emiteMdfe"
					value={marcarCheckboxEmiteMdfe}
					onChange={async (e) => {
						setMarcarCheckboxEmiteMdfe(e.checked);
						setFieldValue('emiteMdfe', e.checked);
						existeValorFiscalMdfeSerieEnumero();
					}}
					checked={values.emiteMdfe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Configurar informações fiscais para emissão de CT-e"
					name="emiteCte"
					value={marcarCheckboxEmiteCte}
					onChange={async (e) => {
						setMarcarCheckboxEmiteCte(e.checked);
						setFieldValue('emiteCte', e.checked);
						existeValorFiscalCteSerieEnumero();
					}}
					checked={values.emiteCte}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Col>
				<TabsConfiguracoesFiscais />
			</Col>
		</Grid>
	);
}

export { TabConfiguracoesFiscais };
