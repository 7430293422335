import { get, services } from 'Common';
import { formatISO, isDate, isValid } from 'date-fns';

function formatDate(date) {
	return isDate(date) && isValid(date)
		? formatISO(date, { representation: 'date' })
		: formatISO(new Date(1900, 1, 1), { representation: 'date' });
}

export function readResultadoPeriodo(dataInicial, dataFinal, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/cte/resultado_periodo_vs_periodo_anterior?dataInicial=${formatDate(
			dataInicial
		)}&dataFinal=${formatDate(dataFinal)}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingTomadores(dataInicial, dataFinal, page, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/cte/ranking_tomadores?dataInicial=${formatDate(
			dataInicial
		)}&dataFinal=${formatDate(dataFinal)}&page=${page}&sort=quantidadenotas,desc&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingComponentesValorPrestacao(dataInicial, dataFinal, page, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/cte/ranking_componentes_valor_prestacao?dataInicial=${formatDate(
			dataInicial
		)}&dataFinal=${formatDate(dataFinal)}&page=${page}&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readRankingProdutosPredominantes(dataInicial, dataFinal, page, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/cte/ranking_produtos_predominantes?dataInicial=${formatDate(
			dataInicial
		)}&dataFinal=${formatDate(dataFinal)}&page=${page}&sort=quantidadeprodutos,desc&size=10`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readDocumentosPorDia(dataInicial, dataFinal, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/dashboards/transporte/cte/documentos_por_dia?dataInicial=${formatDate(
			dataInicial
		)}&dataFinal=${formatDate(dataFinal)}`,
		null,
		onSuccess,
		onError,
		false
	);
}

export function readDocumentosUltimosDozeMeses(onSuccess, onError) {
	return get(`${services.GESTOR}/v1/dashboards/transporte/cte/ultimos_doze_meses`, null, onSuccess, onError, false);
}
