import { copiarObjeto, estadosCadastro, formatarMonetario, gerarUUID, removerElemento } from 'Common';
import { Button, ButtonNovo, Divider, Fieldset, If } from 'components';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { asyncGetImprimirRecibo } from 'views/financas/ContasReceber/Requests';
import ModalRecebimento from '../ModalRecebimento';
import TabelaRecebimentos from '../TabelaRecebimentos';

function FormNovoRecebimento({
	// registroSelecionado,
	// setRegistroSelecionado,
	informacoesPermissoes,
	podeEditar,
	setModalDetalhesPixVisible,
	isReparcelada,
}) {
	const { values, errors, dirty, setFieldValue } = useFormikContext();
	const [recebimentoSelecionado, setRecebimentoSelecionado] = useState(null);
	const [exibirModalRecebimento, setExibirModalRecebimento] = useState(false);

	const desabilitarCampos = informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !podeEditar;

	const valorAReceber = calcularValorAReceber();
	const tituloRecebimentos = buscarTituloRecebimentos(valorAReceber, values.valor);

	function novoRecebimento() {
		setRecebimentoSelecionado(null);
		setExibirModalRecebimento(true);
	}

	function onHideRecebimento() {
		setExibirModalRecebimento(false);
	}

	function inserirRegistroNaLista(dadosFormulario) {
		const recebimentos = copiarObjeto(values.recebimentos);
		recebimentos && recebimentos.push(dadosFormulario);

		setFieldValue('recebimentos', recebimentos);
	}

	function atualizarRegistroNaLista(registro) {
		const { recebimentos } = values;
		const result =
			recebimentos &&
			recebimentos.map((item) => {
				if (item.idTemporario && item.idTemporario === registro.idTemporario) {
					return registro;
				} else if (item.id && item.id === registro.id) {
					return registro;
				}
				return item;
			});
		setFieldValue('recebimentos', result);
	}

	function editarRecebimento(dadosFormulario) {
		setRecebimentoSelecionado(dadosFormulario);
		setExibirModalRecebimento(true);
	}

	function salvarRecebimento(recebimento) {
		const novoRecebimento = { ...recebimento };
		novoRecebimento.recebimentoNovoOuAlterado = true;

		if (!recebimento.id && !recebimento.idTemporario) {
			novoRecebimento.idTemporario = gerarUUID();

			inserirRegistroNaLista(novoRecebimento);
		} else {
			atualizarRegistroNaLista(novoRecebimento);
		}

		onHideRecebimento();
	}

	function excluirRecebimento(dadosFormulario) {
		setFieldValue('recebimentos', removerElemento(values.recebimentos, dadosFormulario));
		setRecebimentoSelecionado(null);
	}

	async function imprimirRecibo(recebimentoId) {
		await asyncGetImprimirRecibo(recebimentoId, async ({ data: arquivo }) => {
			const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const danfce = window.open(arquivoURL);
			if (danfce) {
				danfce.onload = () => {
					setTimeout(() => {
						danfce.document.title = 'Recibo de pagamento';
					}, 250);
				};
			}
		});
	}

	function calcularValorAReceber() {
		let valorRecebido = 0;

		values.recebimentos &&
			values.recebimentos.forEach((recebimento) => {
				valorRecebido += recebimento.valor;
			});

		return values.valor - valorRecebido;
	}

	function buscarTituloRecebimentos(valorAReceber, valorTotal) {
		const totalRecebimentos = valorTotal - valorAReceber;
		if (!totalRecebimentos) {
			return (
				<Divider
					styleContainer={{ margin: '0.5rem 0.5rem 0.5rem 0rem' }}
					styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
				/>
			);
		} else {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						paddingBottom: '5px',
					}}
				>
					<Divider
						styleContainer={{ margin: '0.5rem 0.5rem 0.5rem 0rem' }}
						styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
					/>
					<span style={{ fontWeight: 'bold' }} title="Este valor não considera juros e multa">
						Recebido: <b>{formatarMonetario(totalRecebimentos)}</b> de {formatarMonetario(valorTotal || 0)}{' '}
					</span>
					<If test={errors.recebimentos}>
						<span style={{ fontSize: '12px', fontWeight: 'lighter', color: 'red' }}>{errors.recebimentos}</span>
					</If>
				</div>
			);
		}
	}

	return (
		<Fieldset style={{ width: '100%', marginRight: '4px' }} className="fieldset">
			<>
				<div style={{ margin: '-20px 5px 0 5px' }}>{tituloRecebimentos}</div>
				<ButtonNovo
					label="Novo recebimento"
					podeInserir={!desabilitarCampos}
					onClick={() => novoRecebimento()}
					disabled={
						(!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)) ||
						!!values.pix ||
						isReparcelada
					}
				/>
				<If test={!!values.pix}>
					<Button
						style={{ margin: '5px' }}
						icon="pi pi-qrcode"
						className="p-button-success"
						label="Pix cobrança"
						title="Detalhes do pix cobrança"
						onClick={() => setModalDetalhesPixVisible(true)}
					/>
				</If>
				<If test={exibirModalRecebimento}>
					<ModalRecebimento
						isModal
						visible
						onSalvar={salvarRecebimento}
						onHide={onHideRecebimento}
						valorAReceber={valorAReceber}
						formaPagamento={values.formaPagamento}
						conta={values.conta}
						informacoesPermissoes={informacoesPermissoes}
						recebimentoSelecionado={recebimentoSelecionado}
						setRecebimentoSelecionado={setRecebimentoSelecionado}
					/>
				</If>
				<TabelaRecebimentos
					podeEditar={!desabilitarCampos}
					podeExcluir={!desabilitarCampos}
					registros={values.recebimentos}
					dadosPix={values.pix}
					onEditar={editarRecebimento}
					onExcluir={excluirRecebimento}
					onImprimirRecibo={imprimirRecibo}
					hasBoleto={!!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao)}
					hasPix={values.pix && ['RECEBIDO'].includes(values.pix?.status)}
					dirty={dirty}
					isReparcelada={isReparcelada}
				/>
			</>
		</Fieldset>
	);
}

export { FormNovoRecebimento };
