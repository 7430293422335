import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Message } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function ErrorsValidacoes() {
	const { errors, submitCount } = useFormikContext();
	const { setActiveTabDocumentos, setActiveTabCargas, setActiveTabComplementares, setActiveIndexOutrosParcipantes } =
		useContextCTe();

	useEffect(() => {
		if (errors && Object.keys(errors)?.length > 0) {
			if (errors.documento && Object.keys(errors.documento)?.length > 0) {
				setActiveTabDocumentos(0);
			} else if (errors.documentoAnterior && Object.keys(errors.documentoAnterior)?.length > 0) {
				setActiveTabDocumentos(1);
			} else if (errors.cteComplemento && Object.keys(errors.cteComplemento)?.length > 0) {
				setActiveTabDocumentos(2);
			} else if (errors.cteSubstituicao && Object.keys(errors.cteSubstituicao)?.length > 0) {
				setActiveTabDocumentos(3);
			}

			if (errors.servicos && Object.keys(errors.servicos)?.length > 0) {
				setActiveTabCargas(0);
			} else if (errors.informacaoCarga && Object.keys(errors.informacaoCarga)?.length > 0) {
				setActiveTabCargas(1);
			}

			if (errors.totalizadores && Object.keys(errors.totalizadores)?.length > 0) {
				setActiveTabComplementares(0);
			} else if (errors.pagamentos && Object.keys(errors.pagamentos)?.length > 0) {
				setActiveTabComplementares(1);
			}
		}

		if (errors.errorsParaMessage && Object.keys(errors.errorsParaMessage)?.length > 0) {
			const { errorsParaMessage } = errors;

			if (errorsParaMessage?.camposPrincipais?.expedidor || errorsParaMessage?.camposPrincipais?.recebedor) {
				setActiveIndexOutrosParcipantes(0);
			}

			if (errorsParaMessage?.documento && Object.keys(errorsParaMessage?.documento)?.length > 0) {
				setActiveTabDocumentos(0);
			} else if (
				errorsParaMessage?.documentoAnterior &&
				Object.keys(errorsParaMessage?.documentoAnterior)?.length > 0
			) {
				setActiveTabDocumentos(1);
			} else if (errorsParaMessage?.cteComplemento && Object.keys(errorsParaMessage?.cteComplemento)?.length > 0) {
				setActiveTabDocumentos(2);
			} else if (errorsParaMessage?.cteSubstituicao && Object.keys(errorsParaMessage?.cteSubstituicao)?.length > 0) {
				setActiveTabDocumentos(3);
			}

			if (errorsParaMessage?.servicos && Object.keys(errorsParaMessage?.servicos)?.length > 0) {
				setActiveTabCargas(0);
			} else if (errorsParaMessage?.informacaoCarga && Object.keys(errorsParaMessage?.informacaoCarga)?.length > 0) {
				setActiveTabCargas(1);
			} else if (errorsParaMessage?.impostos && Object.keys(errorsParaMessage?.impostos)?.length > 0) {
				setActiveTabCargas(2);
			}

			if (errorsParaMessage?.totalizadores && Object.keys(errorsParaMessage?.totalizadores)?.length > 0) {
				setActiveTabComplementares(0);
			} else if (errorsParaMessage?.pagamentos && Object.keys(errorsParaMessage?.pagamentos)?.length > 0) {
				setActiveTabComplementares(1);
			}
		}
	}, [errors, submitCount]);

	function montarMensagemValidacoes() {
		if (errors?.errorsParaMessage && Object.keys(errors.errorsParaMessage)?.length > 0) {
			const { errorsParaMessage } = errors;

			return (
				<div style={{ width: '100%' }}>
					{validarErrorsCamposPrincipais(errorsParaMessage)}

					{errorsParaMessage?.documento?.documentos
						? montarMensagem('Aba documentos', errorsParaMessage.documento?.documentos)
						: null}
					{errorsParaMessage?.documentoAnterior?.documentosAnterior
						? montarMensagem('Aba documentos anteriores', errorsParaMessage.documentoAnterior?.documentosAnterior)
						: null}
					{errorsParaMessage?.servicos?.componentesValorPrestacao
						? montarMensagem('Aba serviço', errorsParaMessage.servicos?.componentesValorPrestacao)
						: null}
					{errorsParaMessage?.impostos?.cstCsosn
						? montarMensagem('Aba impostos', errorsParaMessage.impostos?.cstCsosn)
						: null}
					{errorsParaMessage?.informacaoCarga?.quantidades
						? montarMensagem('Aba informação de carga', errorsParaMessage.informacaoCarga?.quantidades)
						: null}
					{errorsParaMessage?.informacaoCarga?.valorCarga
						? montarMensagem('Aba informação de carga', errorsParaMessage.informacaoCarga?.valorCarga)
						: null}
					{errorsParaMessage?.cteComplemento?.chaveAcesso
						? montarMensagem('Aba Complemento de valores', errorsParaMessage.cteComplemento?.chaveAcesso)
						: null}
					{errorsParaMessage?.cteSubstituicao?.chaveAcesso
						? montarMensagem('Aba Substituição', errorsParaMessage.cteSubstituicao?.chaveAcesso)
						: null}
				</div>
			);
		}

		return null;
	}

	function montarMensagem(title, erro) {
		return (
			<div style={{ width: '100%' }}>
				<span style={{ fontWeight: 'bold' }}>{`${title}: `}</span>
				<span>{erro}</span>
			</div>
		);
	}

	function validarErrorsCamposPrincipais(errorsParaMessage) {
		return (
			<>
				{errorsParaMessage?.camposPrincipais?.finalidade
					? montarMensagem('Campos principais', errorsParaMessage.camposPrincipais?.finalidade)
					: null}
				{errorsParaMessage?.camposPrincipais?.remetente
					? montarMensagem('Campos principais', errorsParaMessage.camposPrincipais?.remetente)
					: null}
				{errorsParaMessage?.camposPrincipais?.destinatario
					? montarMensagem('Campos principais', errorsParaMessage.camposPrincipais?.destinatario)
					: null}
				{errorsParaMessage?.camposPrincipais?.expedidor
					? montarMensagem('Campos principais', errorsParaMessage.camposPrincipais?.expedidor)
					: null}
				{errorsParaMessage?.camposPrincipais?.recebedor
					? montarMensagem('Campos principais', errorsParaMessage.camposPrincipais?.recebedor)
					: null}
			</>
		);
	}

	return <Message severity="warn" text={montarMensagemValidacoes()} />;
}

export { ErrorsValidacoes };
