import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { TabView } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { ClassNameTabViewColor } from '../../Util/constantes';
import { TabTotalizadores } from './components/TabTotalizadores';
import { TabPagamentos } from './components/TabPagamentos';
import { TabInformacoesComplementares } from './components/TabInformacoesComplementares';

function TabsComplementares({ favoritosPagamento, totalizadores }) {
	const { activeTabComplementares, setActiveTabComplementares, informacoesPermissoes, podeEditar } = useContextCTe();
	const { values } = useFormikContext();

	return (
		<TabView
			className={`tab-view ${ClassNameTabViewColor[values.situacao]}`}
			activeIndex={activeTabComplementares}
			onTabChange={({ index }) => setActiveTabComplementares(index)}
			renderActiveOnly={false}
		>
			<TabPanel header="Totalizadores">
				<TabTotalizadores totalizadores={totalizadores} />
			</TabPanel>
			<TabPanel header="Pagamentos">
				<TabPagamentos
					informacoesPermissoes={informacoesPermissoes}
					favoritos={favoritosPagamento}
					totalizadores={totalizadores}
					classNameTabView={ClassNameTabViewColor}
					podeEditar={podeEditar}
				/>
			</TabPanel>
			<TabPanel header="Informações complementares">
				<TabInformacoesComplementares />
			</TabPanel>
		</TabView>
	);
}

export { TabsComplementares };
