import { useState } from 'react';
import propTypes from 'prop-types';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import MultipleSelect from '../MultipleSelect';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import ModalProduto from '../../../views/cadastros/produtos/Produtos/Modal';
import { copiarObjeto } from '../../../Common';

export default function MultiSelectProdutos(props) {
	const { url, parametros, noOptionsMessage, service } = props;
	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		return `${url}?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisa}*",codigo=contains="*${pesquisa}*",sku=contains="*${pesquisa}*");situacao=="ATIVO"${parametros}&page=${page}&size=50&sort=nome`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	async function setarNovoRegistroNoSelect(novoRegistro) {
		const produtos = await copiarObjeto([...props.value]);
		if (novoRegistro) {
			produtos.push({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: {
					id: novoRegistro.id,
					codigo: novoRegistro.codigo,
					nome: novoRegistro.nome,
				},
			});

			await props.onChange(produtos);
		}
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.nome}`;
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	return (
		<>
			<ModalProduto service={service} visible={visible} onHide={onHide} />
			<MultipleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				noOptionsMessage={noOptionsMessage}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				esconderBotao={false}
				{...props}
			/>
		</>
	);
}

MultiSelectProdutos.defaultProps = {
	label: 'Produto',
};

MultiSelectProdutos.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
	/** texto a ser exibido quando não tem nenhum item na lista */
	noOptionsMessage: propTypes.func,
};
