import { permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import { GestaoFechamento } from 'views/controladoria/GestaoFechamento';
import SpedForm from '../../controladoria/sped/Form';

export const pathControladoriaRoutes = ['/sped', '/gestao_fechamento'];

function ControladoriaRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/sped']}
				render={props => <SpedForm {...props} />}
				recurso={recursos.CONTROLADORIA_SPED}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/gestao_fechamento']}
				render={props => <GestaoFechamento {...props} />}
				recurso={recursos.CONTROLADORIA_GESTAO_FECHAMENTO}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(ControladoriaRoutes);
