import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const SITUACAO = {
	ABERTO: 'ABERTO',
	FECHADO: 'FECHADO',
}

export const TIPO = {
	GERAL: 'GERAL'
}

export const SITUACAO_OPTIONS = [
	{ label: 'Aberto', value: SITUACAO.ABERTO },
	{ label: 'Fechado', value: SITUACAO.FECHADO },
];

export const TIPO_ALTERACAO_OPTIONS = [
	{ label: 'Abrir', value: SITUACAO.ABERTO },
	{ label: 'Fechar', value: SITUACAO.FECHADO },
];

export const TIPO_OPTIONS = [
	{ label: 'Geral', value: TIPO.GERAL },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Data início', name: 'dataInicial', type: tipoCampos.DATE },
	{ label: 'Data fim', name: 'dataFinal', type: tipoCampos.DATE },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_OPTIONS,
	},
];

export const COLORS = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const STYLE_BUTTON_OPTIONS = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

export const INITIAL_VALUES = {
	id: null,
	dataInicial: null,
	dataFinal: null,
	tipo: 'GERAL',
	situacao: 'FECHADO'
};

export const INITIAL_VALUES_LOTE = {
	dataInicial: null,
	dataFinal: null,
	acao: 'FECHADO'
};

export const PESQUISA_URL = '/controladoria/gestao_fechamento';
