import { createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';

import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';

const ContextCTe = createContext();

function CTeProvider({ children, history, match, isMobile, isTablet }) {
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [errorsTransmissao, setErrorsTransmissao] = useState({});
	const [isSituacaoFinalCTe, setIsSituacaoFinalCTe] = useState(false);
	const [atualizarTotalizadores, setAtualizarTotalizadores] = useState(false);

	const [exibirLoadingTransmissao, setExibirLoadingTransmissao] = useState(false);
	const [messageLoading, setMessageLoading] = useState('');
	const [showMessageTransmissao, setShowMessageTransmissao] = useState(false);
	const [showMessageValidacoes, setShowMessageValidacoes] = useState(false);
	const [showMessageParametrosFiscais, setShowMessageParametrosFiscais] = useState(false);
	const [visibleModalImportacaoNFe, setVisibleModalImportacaoNFe] = useState(false);
	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);
	const [visibleModalCancelamento, setVisibleModalCancelamento] = useState(false);
	const [visibleModalCorrecao, setVisibleModalCorrecao] = useState(false);
	const [visibleModalHistoricoCorrecoes, setVisibleModalHistoricoCorrecoes] = useState(false);
	const [visibleModalComprovanteEntrega, setVisibleModalComprovanteEntrega] = useState(false);
	const [visibleModalHistoricoComprovantesEntrega, setVisibleModalHistoricoComprovantesEntrega] = useState(false);

	const [registroSelecionadoCartaCorrecao, setRegistroSelecionadoCartaCorrecao] = useState(null);
	const [registroSelecionadoComprovanteEntrega, setRegistroSelecionadoComprovanteEntrega] = useState(null);

	const [visibleModalDadosPessoa, setVisibleModalDadosPessoa] = useState(false);
	const [visibleModalDadosEmissorDocumentoAnterior, setVisibleModalDadosEmissorDocumentoAnterior] = useState(false);
	const [visibleModalDadosNFe, setVisibleModalDadosNFe] = useState(false);
	const [visibleModalDadosNotaFiscal, setVisibleModalDadosNotaFiscal] = useState(false);
	const [visibleModalDadosOutrosDocumentos, setVisibleModalDadosOutrosDocumentos] = useState(false);
	const [visibleModalDadosCTe, setVisibleModalDadosCTe] = useState(false);
	const [visibleModalDadosDocumentoPapel, setVisibleModalDadosDocumentoPapel] = useState(false);
	const [visibleModalComponenteValorPrestacao, setVisibleModalComponenteValorPrestacao] = useState(false);
	const [visibleModalQuantidadeCarga, setVisibleModalQuantidadeCarga] = useState(false);

	const [activeIndexOutrosParcipantes, setActiveIndexOutrosParcipantes] = useState(null);
	const [activeTabDocumentos, setActiveTabDocumentos] = useState(0);
	const [activeTabCargas, setActiveTabCargas] = useState(0);
	const [activeTabComplementares, setActiveTabComplementares] = useState(0);

	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir,
		podeEditar,
		podeExcluir,
	};

	return (
		<ContextCTe.Provider
			value={{
				history,
				match,
				isMobile,
				isTablet,
				podeInserir,
				podeEditar,
				podeExcluir,
				informacoesPermissoes,
				isFirstRender,
				setIsFirstRender,
				exibirLoadingTransmissao,
				setExibirLoadingTransmissao,
				messageLoading,
				setMessageLoading,
				showMessageTransmissao,
				setShowMessageTransmissao,
				showMessageValidacoes,
				setShowMessageValidacoes,
				showMessageParametrosFiscais,
				setShowMessageParametrosFiscais,
				errorsTransmissao,
				setErrorsTransmissao,
				isSituacaoFinalCTe,
				setIsSituacaoFinalCTe,
				atualizarTotalizadores,
				setAtualizarTotalizadores,
				activeTabDocumentos,
				setActiveTabDocumentos,
				activeTabCargas,
				setActiveTabCargas,
				activeTabComplementares,
				setActiveTabComplementares,
				visibleModalImportacaoNFe,
				setVisibleModalImportacaoNFe,
				visibleModalHistorico,
				setVisibleModalHistorico,
				visibleModalCancelamento,
				setVisibleModalCancelamento,
				visibleModalCorrecao,
				setVisibleModalCorrecao,
				visibleModalHistoricoCorrecoes,
				setVisibleModalHistoricoCorrecoes,
				visibleModalComprovanteEntrega,
				setVisibleModalComprovanteEntrega,
				visibleModalHistoricoComprovantesEntrega,
				setVisibleModalHistoricoComprovantesEntrega,
				visibleModalDadosPessoa,
				setVisibleModalDadosPessoa,
				visibleModalDadosEmissorDocumentoAnterior,
				setVisibleModalDadosEmissorDocumentoAnterior,
				visibleModalDadosNFe,
				setVisibleModalDadosNFe,
				visibleModalDadosNotaFiscal,
				setVisibleModalDadosNotaFiscal,
				visibleModalDadosOutrosDocumentos,
				setVisibleModalDadosOutrosDocumentos,
				visibleModalDadosCTe,
				setVisibleModalDadosCTe,
				visibleModalDadosDocumentoPapel,
				setVisibleModalDadosDocumentoPapel,
				visibleModalComponenteValorPrestacao,
				setVisibleModalComponenteValorPrestacao,
				visibleModalQuantidadeCarga,
				setVisibleModalQuantidadeCarga,
				registroSelecionadoCartaCorrecao,
				setRegistroSelecionadoCartaCorrecao,
				registroSelecionadoComprovanteEntrega,
				setRegistroSelecionadoComprovanteEntrega,
				activeIndexOutrosParcipantes,
				setActiveIndexOutrosParcipantes,
			}}
		>
			{children}
		</ContextCTe.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(CTeProvider);
export const useContextCTe = () => useContext(ContextCTe);
