import { useFormikContext } from 'formik';

import { If, ModalHistorico } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

import { ModalComprovanteEntrega } from './components/ModalComprovanteEntrega';
import { ModalCancelamento } from './components/ModalCancelamento';
import { ModalImportacaoNFe } from './components/ModalImportacaoNFe';
import { ModalCorrecao } from './components/ModalCorrecao';
import { ModalHistoricoComprovantesEntrega } from './components/ModalHistoricoComprovantesEntrega';
import { ModalHistoricoCorrecoes } from './components/ModalHistoricoCorrecoes';

function EventosPosteriores({ fetchRegistro, importarDadosNFeParaCTe }) {
	const { values, resetForm } = useFormikContext();
	const {
		visibleModalImportacaoNFe,
		setVisibleModalImportacaoNFe,
		visibleModalHistorico,
		setVisibleModalHistorico,
		visibleModalCancelamento,
		setVisibleModalCancelamento,
		visibleModalCorrecao,
		setVisibleModalCorrecao,
		visibleModalHistoricoCorrecoes,
		setVisibleModalHistoricoCorrecoes,
		visibleModalComprovanteEntrega,
		setVisibleModalComprovanteEntrega,
		visibleModalHistoricoComprovantesEntrega,
		setVisibleModalHistoricoComprovantesEntrega,
		registroSelecionadoCartaCorrecao,
		setRegistroSelecionadoCartaCorrecao,
		registroSelecionadoComprovanteEntrega,
		setRegistroSelecionadoComprovanteEntrega,
	} = useContextCTe();

	return (
		<>
			<If test={visibleModalImportacaoNFe}>
				<ModalImportacaoNFe
					cte={values}
					visible={visibleModalImportacaoNFe}
					onHide={() => {
						setVisibleModalImportacaoNFe(false);
					}}
					importarDadosNFeParaCTe={importarDadosNFeParaCTe}
				/>
			</If>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do conhecimento de transporte eletrônico"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="ctes"
					id={values.id}
				/>
			</If>
			<If test={visibleModalCancelamento}>
				<ModalCancelamento
					visible={visibleModalCancelamento}
					onHide={() => setVisibleModalCancelamento(false)}
					resetFormPrincipal={resetForm}
					cte={values}
				/>
			</If>
			<If test={visibleModalCorrecao}>
				<ModalCorrecao
					cte={values}
					visible={visibleModalCorrecao}
					onHide={() => {
						setVisibleModalCorrecao(false);
						setRegistroSelecionadoCartaCorrecao(null);
					}}
					registroSelecionado={registroSelecionadoCartaCorrecao}
					fetchRegistro={fetchRegistro}
				/>
			</If>
			<If test={visibleModalHistoricoCorrecoes}>
				<ModalHistoricoCorrecoes
					visible={visibleModalHistoricoCorrecoes}
					onHide={() => setVisibleModalHistoricoCorrecoes(false)}
				/>
			</If>
			<If test={visibleModalComprovanteEntrega}>
				<ModalComprovanteEntrega
					visible={visibleModalComprovanteEntrega}
					onHide={() => setVisibleModalComprovanteEntrega(false)}
				/>
			</If>
			<If test={visibleModalHistoricoComprovantesEntrega}>
				<ModalHistoricoComprovantesEntrega
					visible={visibleModalHistoricoComprovantesEntrega}
					onHide={() => {
						setVisibleModalHistoricoComprovantesEntrega(false);
						setRegistroSelecionadoComprovanteEntrega(null);
					}}
					registroSelecionado={registroSelecionadoComprovanteEntrega}
				/>
			</If>
		</>
	);
}

export { EventosPosteriores };
