import { Field, useFormikContext } from 'formik';

import { FormContent, Grid, InputField } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function TabDocumentosComplementarValor() {
	const { setFieldValue } = useFormikContext();
	const { isSituacaoFinalCTe } = useContextCTe();

	return (
		<FormContent>
			<Grid>
				<Field
					sm="12"
					md="8"
					lg="8"
					xl="8"
					label="Chave de acesso do CT-e original"
					name="cteComplemento.chaveAcesso"
					component={InputField}
					size={44}
					useOnBlurPerformatico
					onBlur={(value) => {
						setFieldValue('cteComplemento.chaveAcesso', value || null);
					}}
					disabled={isSituacaoFinalCTe}
				/>
			</Grid>
		</FormContent>
	);
}

export { TabDocumentosComplementarValor };
