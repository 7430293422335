import { Field, useFormikContext } from 'formik';

import { InputField } from 'components';

function EletronicoFields({ informacoesPermissoes }) {
	const { setFieldValue, values } = useFormikContext();
	return (
		<Field
			sm="9"
			md="9"
			lg="9"
			xl="9"
			component={InputField}
			label="Serie"
			name="eletronico.serie"
			value={values.eletronico.serie}
			onChange={(e) => setFieldValue('eletronico.serie', e.target?.value)}
			{...informacoesPermissoes}
		/>
	);
}

export default EletronicoFields;
