import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';

import { buscarDadosLoginLocalStorage, gerarUUID } from 'Common';
import converteXmlParaFormulario from 'Common/dfe/NFe/Utils/xmlConverter';
import { MODULOS_IMPORTACAO } from 'Common/dfe/NFe/Utils/constantes';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	NormalButton,
	notify,
	ToastTypes,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { createCTe, deleteCTe, transmitirCTe, updateCTe } from 'views/transporte/CTe/Requests';
import { CADASTRO_URL, CTE_SITUACAO, PESQUISA_URL } from 'views/transporte/CTe/Util/constantes';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { converterCTeParaApi } from '../../Util/CTeConverter';

function ActionButtons({ fetchRegistro, newRegistro, setDesabilitarRecalcularPagamento, importarDadosNFeParaCTe }) {
	const { dirty, values, handleSubmit, resetForm, validateForm, handleReset } = useFormikContext();
	const {
		history,
		informacoesPermissoes,
		isSituacaoFinalCTe,
		podeEditar,
		setVisibleModalImportacaoNFe,
		setVisibleModalHistorico,
		setVisibleModalCancelamento,
		setVisibleModalCorrecao,
		setVisibleModalHistoricoCorrecoes,
		setVisibleModalComprovanteEntrega,
		setVisibleModalHistoricoComprovantesEntrega,
		setExibirLoadingTransmissao,
		setMessageLoading,
		setErrorsTransmissao,
	} = useContextCTe();

	const menuOpcoes = useRef(null);

	const dadosLocais = buscarDadosLoginLocalStorage();
	const isSituacaoFinal = Boolean([CTE_SITUACAO.CANCELADO, CTE_SITUACAO.TRANSMITIDO].includes(values.situacao));
	const desabilitarBotaoTransmitir =
		dirty || isSituacaoFinalCTe || !podeEditar || !organizacaoPodeTransmitirCTe() || !values.id;

	const itensOpcoes = getItensOpcoes();

	function organizacaoPodeTransmitirCTe() {
		if (dadosLocais?.filialConectada?.parametrosFiscalCTe) {
			if (
				!dadosLocais?.filialConectada?.parametrosFiscalCertificado ||
				!dadosLocais.filialConectada.parametrosFiscalCTe.serie ||
				!dadosLocais.filialConectada.parametrosFiscalCTe.numeroInicial
			) {
				return false;
			}
		}
		return true;
	}

	function getTitleButtonTransmitir() {
		if (dadosLocais?.filialConectada?.parametrosFiscalCTe) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de CT-e';
			} else if (!dadosLocais?.filialConectada?.parametrosFiscalCTe?.serie) {
				return 'Série do CT-e não configurada';
			}
		}
		return '';
	}

	function getItensOpcoes() {
		const itens = [];
		const isCancelado = values.situacao === CTE_SITUACAO.CANCELADO;
		const isTransmitido = values.situacao === CTE_SITUACAO.TRANSMITIDO;
		const isCorrigido = values.situacao === CTE_SITUACAO.TRANSMITIDO && values.correcoes?.length > 0;
		const isComprovanteEntregue = values.situacao === CTE_SITUACAO.TRANSMITIDO && values.comprovantes?.length > 0;

		itens.push({
			label: 'Importar NF-es do sistema',
			icon: 'fa fa-file-text-o',
			command: () => setVisibleModalImportacaoNFe(true),
			visible: !isCancelado && !isTransmitido,
		});

		itens.push({
			label: 'Importar XML NF-e',
			icon: 'fa fa-file-text-o',
			command: () => onImportarNFeXml(),
			visible: !isCancelado && !isTransmitido,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: !dirty && values.id,
		});

		if (isCancelado || isCorrigido || isComprovanteEntregue) {
			itens.push({
				separator: true,
				visible: true,
			});
		}

		itens.push({
			label: isCancelado ? 'Motivo do cancelamento' : 'Cancelar CT-e',
			icon: isCancelado ? 'pi pi-file-excel' : 'fa fa-times',
			command: () => setVisibleModalCancelamento(true),
			visible: isCancelado || isTransmitido,
		});

		itens.push({
			label: isCorrigido ? 'Emitir nova carta de correção' : 'Emitir carta de correção',
			icon: 'fa fa-edit',
			command: () => setVisibleModalCorrecao(true),
			visible: isTransmitido,
		});

		if (isCorrigido) {
			itens.push({
				label: 'Ver correções',
				icon: 'fa fa-eye',
				command: () => setVisibleModalHistoricoCorrecoes(true),
				visible: isCorrigido,
			});
		}

		itens.push({
			label: isComprovanteEntregue ? 'Emitir novo comprovante de entrega' : 'Emitir comprovante de entrega',
			icon: 'fa fa-list-ul',
			command: () => setVisibleModalComprovanteEntrega(true),
			visible: isComprovanteEntregue,
		});

		if (isComprovanteEntregue) {
			itens.push({
				label: 'Ver comprovantes de entrega',
				icon: 'fa fa-eye',
				command: () => setVisibleModalHistoricoComprovantesEntrega(true),
				visible: isComprovanteEntregue,
			});
		}

		return itens.filter((element) => element.visible);
	}

	async function createRegistro(formulario, onSuccess) {
		await createCTe(converterCTeParaApi(formulario), ({ data: response }) => {
			if (typeof onSuccess === 'function') {
				onSuccess();
			}

			atualizarUrl(history, CADASTRO_URL, response.id, metodosAtualizarUrl.POP);
			resetForm({ values: { ...formulario, id: response.id } });
		});
	}

	async function updateRegistro(formulario, onSuccess) {
		await updateCTe(converterCTeParaApi(formulario), ({ data: response }) => {
			if (typeof onSuccess === 'function') {
				onSuccess();
			}
			atualizarUrl(history, CADASTRO_URL, response.id, metodosAtualizarUrl.POP);
			resetForm({ values: formulario });
		});
	}

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function handleClickSalvar(novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				updateRegistro(values, novoOnSuccess);
			} else {
				createRegistro(values, novoOnSuccess);
			}
		}
	}

	function handleClickNovo() {
		if (dirty) {
			handleClickSalvar(newRegistro);
		} else {
			newRegistro();
		}
	}

	function handleClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	async function deleteRegistro() {
		await deleteCTe(values.id, () => {
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function handleClickTransmitir() {
		setExibirLoadingTransmissao(true);
		setMessageLoading('Transmitindo CT-e...');
		await transmitirCTe(
			values.id,
			async () => {
				setErrorsTransmissao({});
				setExibirLoadingTransmissao(false);
				setMessageLoading('');
				await fetchRegistro(values.id);
			},
			async (error) => {
				let errorData = [];
				if (error.response?.data?.rejeicao?.length > 0) {
					errorData = error.response.data?.rejeicao;
				} else if (error.response?.data?.details?.length > 0) {
					errorData = [
						{
							id: gerarUUID(),
							mensagem: `Validação interna - ${error.response?.data?.details?.map((detail) => `${detail} `)}`,
						},
					];
				}
				console.warn('rejeição:', error.response.data);

				setExibirLoadingTransmissao(false);
				setMessageLoading('');
				await fetchRegistro(values.id);

				if (errorData?.length > 0) {
					errorData?.forEach((error) => {
						setErrorsTransmissao({ id: error.id, mensagem: error.mensagem });
					});
				}
			}
		);
	}

	function onImportarNFeXml() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'text/xml';
		input.onchange = (e) => {
			const file = e.target.files[0];

			if (file.type === 'text/xml') {
				const reader = new FileReader();
				reader.readAsText(file, 'UTF-8');

				reader.onload = async (readerEvent) => {
					setDesabilitarRecalcularPagamento(true);
					const dadosConvertidos = await converteXmlParaFormulario(readerEvent.target.result, MODULOS_IMPORTACAO.CTES);

					if (dadosConvertidos) {
						await importarDadosNFeParaCTe(values, dadosConvertidos);
					} else {
						notify('O modelo do XML importado é diferente de 55 (NF-e).', ToastTypes.ERROR);
					}
					setDesabilitarRecalcularPagamento(false);
				};
			} else {
				notify('O arquivo selecionado não é um arquivo XML válido.', ToastTypes.ERROR);
			}
		};
		input.click();
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || isSituacaoFinal} onClick={handleClickSalvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={handleClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id}
				disabled={dirty || isSituacaoFinal}
				onClick={handleClickExcluir}
				{...informacoesPermissoes}
			/>
			<Button
				className="p-button-success"
				label="Transmitir CT-e"
				icon="fa fa-send"
				title={getTitleButtonTransmitir()}
				onClick={handleClickTransmitir}
				style={{ margin: '5px' }}
				hidden={isSituacaoFinal}
				disabled={desabilitarBotaoTransmitir}
				{...informacoesPermissoes}
			/>
			<NormalButton
				type="button"
				label="Opções"
				icon={<MdFormatListBulleted size={20} />}
				style={{ margin: '5px', gap: '5px' }}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
