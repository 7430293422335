import { useState, useEffect } from 'react';
import { buscarContas } from './Requests';
import ModalConta from './components/ModalConta';
import CardConta from './components/CardConta';
import { asyncDeleteRegistro, asyncUpdateRegistro } from './components/ModalConta/Requests';
import { Checkbox } from 'primereact/checkbox';
import { removerElementoPorId, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { ButtonNovo, Grid, confirm, If, Form, FormActions, FormContent, NenhumRegistroEncontrado } from 'components';

function Contas(props) {
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.INSERIR)
	);
	const [podeVisualizarLancamentos, setPodeVisualizarLancamentos] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR)
	);
	const [exibirModalConta, setExibirModalConta] = useState(false);
	const [exibirContasInativas, setExibirContasInativas] = useState(false);
	const [contaSelecionada, setContaSelecionada] = useState(null);
	const [contas, setContas] = useState([]);

	useEffect(() => {
		buscarTodasAsContas();
	}, []);

	function buscarTodasAsContas() {
		buscarContas(({ data }) => {
			setContas(data.content);
		});
	}

	function onClickExibirContasInativas() {
		setExibirContasInativas(!exibirContasInativas);
	}

	function ativarConta(conta) {
		confirm('Confirmação', 'Tem certeza que deseja reativar a conta?', async () => {
			await asyncUpdateRegistro({ ...conta, situacao: 'ATIVA' }, () => {
				buscarTodasAsContas({ ...conta, situacao: 'ATIVA' });
			});
		});
	}

	function editarConta(conta) {
		setContaSelecionada(conta.id);
		setExibirModalConta(true);
	}

	function excluirConta(conta) {
		confirm('Confirmação', 'Tem certeza que deseja excluir a conta?', async () => {
			await asyncDeleteRegistro(conta.id, () => {
				setContas(removerElementoPorId(contas, conta, 'id'));
			});
		});
	}

	function desativarConta(conta) {
		confirm('Confirmação', 'Tem certeza que deseja inativar a conta?', async () => {
			const dados = { ...conta, situacao: 'INATIVA', favoritaDespesa: false, favoritaReceita: false };
			await asyncUpdateRegistro(dados, () => {
				buscarTodasAsContas(dados);
			});
		});
	}

	function onVerDetalhesClick(conta) {
		props.history.push({
			pathname: '/movimentacoes',
			state: { contaSelecionadaId: conta.id },
		});
	}

	function renderizarContas() {
		let contaFiltrada = contas;
		if (exibirContasInativas === false) {
			contaFiltrada = contas.filter((conta) => conta.situacao === 'ATIVA');
		}

		if (contaFiltrada.length === 0) {
			return <NenhumRegistroEncontrado message="Nenhuma conta encontrada" />;
		}

		return contaFiltrada.map((conta, key) => {
			return (
				<CardConta
					key={key}
					conta={conta}
					editarConta={editarConta}
					excluirConta={excluirConta}
					desativarConta={desativarConta}
					ativarConta={ativarConta}
					verDetalhesClick={onVerDetalhesClick}
					habilitarVerDetalhes={podeVisualizarLancamentos && conta.situacao === 'ATIVA'}
				/>
			);
		});
	}

	function onHideModalConta() {
		buscarTodasAsContas();
		setExibirModalConta(false);
		setContaSelecionada(null);
	}

	return (
		<>
			<Form header="Contas">
				<FormActions>
					<ButtonNovo label="Nova conta" onClick={() => setExibirModalConta(true)} podeInserir={podeInserir} />
				</FormActions>
				<FormContent>
					<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0px' }}>
						<Checkbox
							inputId="exibirContasInativas"
							checked={exibirContasInativas}
							name="exibirContasInativas"
							onChange={() => onClickExibirContasInativas()}
						/>
						<label htmlFor="exibirContasInativas" className="p-checkbox-label">
							Exibir contas inativas
						</label>
					</div>
					<Grid>{renderizarContas()}</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalConta}>
				<ModalConta visible={exibirModalConta} onHide={onHideModalConta} registroSelecionado={contaSelecionada} />
			</If>
		</>
	);
}

export default Contas;
