import parse from 'date-fns/parse';
import { buscarDadosLoginLocalStorage, usuarioPossuiModulo } from 'Common/Autenticacao';
import { modulos } from 'Common/Constantes/autorizacao';
import { formatarNcm } from 'Common/Mascara';

function converterProdutoParaFormulario(produto) {
	return {
		//IDENTIFICACAO
		id: produto.id,

		//CABECALHO
		situacao: produto.situacao,

		codigo: produto.codigo,
		sku: produto.sku,
		nome: produto.nome,
		tipo: produto.tipo,
		preco: produto.preco,

		codigoBarras: produto.codigoBarras ?? null,
		precoAtacado: produto.precoAtacado,
		codigoBarras: produto.codigoBarras ?? null,
		unidadeMedida: converterUnidadMedidaParaFormulario(produto.unidadeMedida),
		unidadeMedidaEntrada: converterUnidadMedidaParaFormulario(produto.unidadeMedidaEntrada),
		grupo: converterGrupoParaFormulario(produto),

		//ESTOQUE
		controlarEstoque: produto.controlarEstoque,
		estoqueMinimo: produto.estoqueMinimo ?? 0,
		ultimoCusto: produto.ultimoCusto ?? 0,
		estoqueSaldo: produto.estoqueSaldo ?? 0,

		//NOTA FISCAL
		origem: produto.origem ?? null,
		ncm: converterNcmParaFormulario(produto),
		cest: converterCestParaFormulario(produto),
		pesoBruto: produto.pesoBruto ?? 0,
		pesoLiquido: produto.pesoLiquido ?? 0,
		codigoAnp: produto.codigoAnp ?? null,

		//CONFIGURACOES
		utilizarDocumentosDigitais: produto.utilizarDocumentosDigitais,
		utilizaBalanca: produto.utilizaBalanca,
		exportaArquivoBalanca: produto.exportaArquivoBalanca,

		//INFORMAÇÕES COMPLEMENTARES
		observacao: produto.observacao,

		cadastro: parse(produto.cadastro, 'yyyy-MM-dd', new Date()),

		imagem: produto.imagem,
		imagemUrl: produto.imagemUrl,

		possuiModulos: {
			VENDAS: usuarioPossuiModulo(modulos.VENDAS),
		},

		fatorConversao: produto.fatorConversao,
		operacaoFatorConversao: produto.operacaoFatorConversao,

		marca: converterMarcaParaFormulario(produto),

		//FISCAL
		codigoServico: converterCodigoServicoParaFormulario(produto.codigoServico),

		//FILIAIS
		filiais: converterFiliaisParaFormulario(produto.filiais),
	};
}

function converterFiliaisParaFormulario(filiais) {
	const filialId = buscarDadosLoginLocalStorage().filialConectada.id;
	const filiaisFormulario = filiais?.map((filial) => {
		return {
			id: filial.id,
			filialId: filial.filialId,
			filialCodigo: filial.filialCodigo,
			filialNome: filial.filialNome,
			filialNomeApresentacao: filial.filialNomeApresentacao,
			precoCusto: filial.precoCusto,
			custoMedio: filial.custoMedio,
			ultimoCusto: filial.ultimoCusto,
			fatorConversao: filial.fatorConversao,
			operacaoFatorConversao: filial.operacaoFatorConversao,
			estoqueMinimo: filial.estoqueMinimo,
			estoqueReservado: filial.estoqueReservado,
			estoqueSaldo: filial.estoqueSaldo,
			precoVenda: filial.precoVenda,
			precoAtacado: filial.precoAtacado,
			filialConectada: filialId === filial.filialId ? true : false,
		};
	});
	return filiaisFormulario;
}

function converterProdutoParaApi(produto) {
	const tipoIsProduto = produto.tipo !== 'SERVICO';
	const controlarEstoque = produto.controlarEstoque;

	return {
		//IDENTIFICACAO
		id: produto.id,

		//CABECALHO
		situacao: produto.situacao,
		sku: produto.sku && produto.sku != '' ? produto.sku : null,
		nome: produto.nome,
		tipo: produto.tipo,
		preco: produto.preco,
		precoAtacado: produto.precoAtacado,
		codigoBarras: tipoIsProduto ? produto.codigoBarras : null,
		unidadeMedida: produto.unidadeMedida ? { id: produto.unidadeMedida.value } : null,
		unidadeMedidaEntrada:
			tipoIsProduto && produto.unidadeMedidaEntrada ? { id: produto.unidadeMedidaEntrada.value } : null,
		grupo: produto.grupo ? { id: produto.grupo.value } : null,

		//ESTOQUE
		controlarEstoque: tipoIsProduto ? produto.controlarEstoque : false,
		estoqueMinimo: tipoIsProduto && controlarEstoque ? produto.estoqueMinimo : null,
		estoqueSaldo: tipoIsProduto && controlarEstoque && !produto.id ? produto.estoqueSaldo ?? null : null,
		ultimoCusto: tipoIsProduto && controlarEstoque ? produto.ultimoCusto : null,

		//NOTA FISCAL
		origem: tipoIsProduto ? produto.origem : null,
		ncm: tipoIsProduto ? (produto.ncm ? { id: produto.ncm.value } : null) : null,
		cest: tipoIsProduto ? (produto.cest ? { id: produto.cest.value } : null) : null,
		pesoBruto: tipoIsProduto ? produto.pesoBruto ?? 0 : null,
		pesoLiquido: tipoIsProduto ? produto.pesoLiquido ?? 0 : null,
		codigoAnp: tipoIsProduto ? produto.codigoAnp ?? null : null,

		//CONFIGURACOES
		utilizarDocumentosDigitais: produto.utilizarDocumentosDigitais ?? false,
		utilizaBalanca: produto.utilizaBalanca ?? false,
		exportaArquivoBalanca: produto.exportaArquivoBalanca ?? false,

		//INFORMAÇÕES COMPLEMENTARES
		observacao: produto.observacao,
		imagem: produto.imagem,

		cadastro: produto.cadastro,

		fatorConversao: produto.fatorConversao,
		operacaoFatorConversao: produto.operacaoFatorConversao,

		codigoServico: produto.codigoServico ? { id: produto.codigoServico.value } : null,

		marca: produto.marca ? { id: produto.marca.value } : null,

		//FILIAIS
		filiais: converterFiliaisParaApi(produto.filiais),
	};
}

function converterFiliaisParaApi(filiais) {
	const filiaisApi = filiais?.map((filial) => {
		return {
			id: filial.id,
			filialId: filial.filialId,
			filialCodigo: filial.filialCodigo,
			filialNome: filial.filialNome,
			filialNomeApresentacao: filial.filialNomeApresentacao,
			precoCusto: filial.precoCusto,
			custoMedio: filial.custoMedio,
			ultimoCusto: filial.ultimoCusto,
			fatorConversao: filial.fatorConversao,
			operacaoFatorConversao: filial.operacaoFatorConversao,
			estoqueMinimo: filial.estoqueMinimo,
			estoqueReservado: filial.estoqueReservado,
			estoqueSaldo: filial.estoqueSaldo,
			precoVenda: filial.precoVenda,
			precoAtacado: filial.precoAtacado,
		};
	});
	return filiaisApi;
}

function converterMarcaParaFormulario(produto) {
	if (produto && produto.marca) {
		return {
			label: produto.marca.nome,
			value: produto.marca.id,
		};
	}
	return null;
}

function converterUnidadMedidaParaFormulario(produto) {
	if (produto && produto.unidade) {
		return {
			label: produto.unidade + ' - ' + produto.descricao,
			value: produto.id,
		};
	}
	return null;
}

function converterCestParaFormulario(produto) {
	if (produto && produto.cest) {
		return {
			label: produto.cest.codigo + ' - ' + produto.cest.descricao,
			value: produto.cest.id,
		};
	}
	return null;
}

function converterNcmParaFormulario(produto) {
	if (produto && produto.ncm) {
		if (produto.ncm.extensao) {
			if (produto.ncm.cadastrado) {
				return {
					label: '✓ - ' + formatarNcm(produto.ncm.codigo) + '-' + produto.ncm.extensao + ' - ' + produto.ncm.descricao,
					value: produto.ncm.id,
					registro: produto.ncm,
				};
			} else {
				return {
					label: '✕ - ' + formatarNcm(produto.ncm.codigo) + '-' + produto.ncm.extensao + ' - ' + produto.ncm.descricao,
					value: produto.ncm.id,
					registro: produto.ncm,
				};
			}
		} else {
			if (produto.ncm.cadastrado) {
				return {
					label: '✓ - ' + formatarNcm(produto.ncm.codigo) + ' - ' + produto.ncm.descricao,
					value: produto.ncm.id,
					registro: produto.ncm,
				};
			} else {
				return {
					label: '✕ - ' + formatarNcm(produto.ncm.codigo) + ' - ' + produto.ncm.descricao,
					value: produto.ncm.id,
					registro: produto.ncm,
				};
			}
		}
	}
	return null;
}

function converterGrupoParaFormulario(produto) {
	if (produto && produto.grupo) {
		return {
			label: produto.grupo.nivel + ' - ' + produto.grupo.descricao,
			value: produto.grupo.id,
		};
	}
	return null;
}

function converterCodigoServicoParaFormulario(codigoServico) {
	if (codigoServico) {
		if (codigoServico.cadastrado) {
			return {
				label: '✓ - ' + codigoServico.codigo + ' - ' + codigoServico.descricao,
				value: codigoServico.id,
				registro: codigoServico,
			};
		} else {
			return {
				label: '✕ - ' + codigoServico.codigo + ' - ' + codigoServico.descricao,
				value: codigoServico.id,
				registro: codigoServico,
			};
		}
	}
	return null;
}

function converterProdutoParaDuplicar(produto) {
	return {
		id: null,
		codigo: null,

		situacao: produto.situacao,
		sku: produto.sku,
		nome: produto.nome,
		tipo: produto.tipo,
		preco: produto.preco,

		codigoBarras: produto.codigoBarras ?? null,
		unidadeMedida: produto.unidadeMedida,
		precoAtacado: produto.precoAtacado,
		codigoBarras: produto.codigoBarras ?? null,
		unidadeMedida: converterUnidadMedidaParaFormulario(produto.unidadeMedida),
		unidadeMedidaEntrada: converterUnidadMedidaParaFormulario(produto.unidadeMedidaEntrada),
		grupo: converterGrupoParaFormulario(produto),

		controlarEstoque: produto.controlarEstoque,
		estoqueMinimo: produto.estoqueMinimo ?? 0,
		estoqueSaldo: 0,
		ultimoCusto: 0,

		origem: produto.origem ?? null,
		ncm: converterNcmParaFormulario(produto),
		cest: converterCestParaFormulario(produto),
		pesoBruto: produto.pesoBruto ?? 0,
		pesoLiquido: produto.pesoLiquido ?? 0,
		codigoAnp: produto.codigoAnp ?? null,

		utilizarDocumentosDigitais: produto.utilizarDocumentosDigitais,
		utilizaBalanca: produto.utilizaBalanca,
		exportaArquivoBalanca: produto.exportaArquivoBalanca,

		observacao: produto.observacao,
		cadastro: parse(produto.cadastro, 'yyyy-MM-dd', new Date()),

		imagem: null,
		imagemUrl: null,

		possuiModulos: {
			VENDAS: usuarioPossuiModulo(modulos.VENDAS),
		},

		fatorConversao: produto.fatorConversao,
		operacaoFatorConversao: produto.operacaoFatorConversao,

		codigoServico: converterCodigoServicoParaFormulario(produto.codigoServico),
		filiais: converterProdutoFilialParaDuplicar(produto.filiais),
	};
}

function converterProdutoFilialParaDuplicar(filiais) {
	filiais.map((e) => {
		e.estoqueSaldo = 0;
		e.estoqueReservado = 0;
		e.ultimoCusto = 0;
		e.custoMedio = 0;
	});
	return filiais;
}

export { converterProdutoParaFormulario, converterProdutoParaApi, converterProdutoParaDuplicar };
