export const TRANSPORTES_MODULOS = {
	MDFE: 'MDFE',
	CTE: 'CTE',
};

export const OPTIONS_SELECT = [
	{
		label: 'MDF-e',
		value: TRANSPORTES_MODULOS.MDFE,
	},
	{
		label: 'CT-e',
		value: TRANSPORTES_MODULOS.CTE,
	},
];

export const TRANSPORTES_CORES = {
	vermelho: '#b71c1c',
	vermelhoClaro: '#ffcdd2',
	verde: '#1b5e20',
	verdeClaro: '#dcedc8',
	azul: '#006095',
	amarelo: '#765100',
	preto: '#fff0',
	cinza: '#888888',
	branco: '#F2F2F2',
};
