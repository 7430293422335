function converterComponenteValorPrestacaoParaFormulario(value) {
	return {
		id: value.id,
		descricao: value.descricao,
		valor: value.valor,
		favorito: value.favorito,
		situacao: value.situacao,
	};
}

function converterComponenteValorPrestacaoParaApi(value) {
	return {
		id: value.id ? value.id : null,
		descricao: value.descricao,
		valor: value.valor || 0,
		favorito: value.favorito,
		situacao: value.situacao,
	};
}

export { converterComponenteValorPrestacaoParaFormulario, converterComponenteValorPrestacaoParaApi };
