import { buscarDadosLoginLocalStorage, del, exibirToast, get, post, put, services } from 'Common';
import { formatISO } from 'date-fns';

export async function readMDFes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readMDFe(idMDFe, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/mdfes/${idMDFe}`, null, onSuccess, onError);
}

export async function createMDFe(mdfe, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/mdfes`,
		mdfe,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export async function updateMDFe(mdfe, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/mdfes/${mdfe.id}`,
		mdfe,
		null,
		exibirToast(onSuccess, 'Registro atualizado com sucesso'),
		onError
	);
}

export async function deleteMDFe(idMDFe, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/mdfes/${idMDFe}`, null, exibirToast(onSuccess, 'Registro excluído'), onError);
}

export async function transmitirMDFe(idMDFe, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/mdfes/${idMDFe}/transmitir`, null, null, onSuccess, onError, false, false);
}

export async function cancelarMDFe(idMDFe, motivoCancelamento, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/mdfes/${idMDFe}/cancelar`, motivoCancelamento, null, onSuccess, onError);
}

export async function encerrarMDFe(idMDFe, municipioEncerramento, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/mdfes/${idMDFe}/encerrar`, municipioEncerramento, null, onSuccess, onError);
}

export async function createDFeMDFe(idMDFe, dfe, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/mdfes/${idMDFe}/incluir_dfe`, dfe, null, onSuccess, onError);
}

export async function createCondutorIndicadoMDFe(idMDFe, condutorIndicado, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/mdfes/${idMDFe}/indicar_condutor`,
		condutorIndicado,
		null,
		onSuccess,
		onError
	);
}

export async function createPagamentoMDFe(idMDFe, pagamento, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/mdfes/${idMDFe}/incluir_pagamento`, pagamento, null, onSuccess, onError);
}

export async function readVeiculos(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/relacoes/veiculos?size=500&sort=placa,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readSetores(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/relacoes/setores?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readUsuarioFavorito(onSuccess) {
	const { email } = buscarDadosLoginLocalStorage();
	await get(`${services.GESTOR}/v1/mdfes/relacoes/usuarios?query=credencial.email==${email}`, null, onSuccess);
}

export async function readUsuarios(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/relacoes/usuarios?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readCondutores(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/relacoes/condutores?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readSeguradoras(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/relacoes/seguradoras?size=500&sort=nome,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readEstados(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/estados/resumo?query=(sigla!="EX")&size=500&sort=sigla,asc`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function readCondutoresIndicadosMDFe(id, query, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/${id}/listar_condutores_indicados${query}`,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function readPagamentosMDFe(id, query, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/mdfes/${id}/listar_pagamentos${query}`, null, onSuccess, onError, true);
}

export async function readDFesMDFe(id, query, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/mdfes/${id}/listar_dfes${query}`, null, onSuccess, onError, true);
}

export async function readImprimirMDFe(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/mdfes/${id}/imprimir`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function createMDFePorEmail(id, body, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/mdfes/${id}/enviar_por_email`,
		body,
		null,
		exibirToast(onSuccess, 'E-mail(s) enviado(s)'),
		onError
	);
}

export async function readXmlMDFe(id, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/mdfes/${id}/xml`, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function readMDFeNaoEncerrados(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/mdfes/consultar_nao_encerrados`, null, onSuccess, onError, true);
}

export async function readMDFeImportarNFes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}
export async function asyncBaixarRelatorioMdfePorPeriodo(
	dataInicial,
	dataFinal,
	situacao,
	filiais,
	setores,
	onSuccess,
	onError
) {
	return await get(
		`${services.GESTOR}/v1/mdfes/imprimir/mdfe_por_periodo?dataInicial=${dataInicial}&dataFinal=${dataFinal}&situacao=${situacao}&filiais=${filiais}&setores=${setores}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncBaixarRelatorioMdfeNaoEncerrados(setores, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/imprimir/mdfe_nao_encerrados?setores=${setores}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncGetDownloadXmls(filtro, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/mdfes/xmls/download${filtro}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function readTalizadoresCard(interval, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/mdfes/totais?dataInicial=${formatISO(interval.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(interval.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError
	);
}
