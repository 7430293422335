import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';
import { copiarObjeto } from 'Common';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { CADASTRO_URL, INITIAL_VALUES, PESQUISA_URL } from '../../../Util/constantes';
import { createMercadoriaTransporte, deleteMercadoriaTransporte, updateMercadoriaTransporte } from '../../../Request';
import { converterMercadoriaTransporteParaApi } from '../../../Util/MercadoriaTransporteConverter';

function ActionButtons({ informacoesPermissoes, isModal, history, hideModal }) {
	const { values, dirty, resetForm, handleSubmit, validateForm, handleReset } = useFormikContext();

	const estadoBotaoSalvar = !dirty && isModal && values.id ? estadosBotaoSalvar.CONFIRMAR : estadosBotaoSalvar.SALVAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			closeModal();
		} else {
			voltarParaAPesquisa(history, `/${PESQUISA_URL}`);
		}
	}

	async function handleClickSalvar(novoOnSuccess = null) {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				updateRegistro(values, novoOnSuccess);
			} else {
				createRegistro(values, novoOnSuccess);
			}
		}
	}

	function handleClickNovo() {
		if (dirty) {
			handleClickSalvar(onNovo);
		} else {
			onNovo();
		}
	}

	function handleClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	function closeModal(dados = null) {
		if (dados) {
			hideModal(dados);
		} else {
			hideModal();
		}
	}

	async function updateRegistro(dadosFormulario, novoOnSuccess) {
		await updateMercadoriaTransporte(converterMercadoriaTransporteParaApi(dadosFormulario), () => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function createRegistro(dadosFormulario, novoOnSuccess) {
		await createMercadoriaTransporte(converterMercadoriaTransporteParaApi(dadosFormulario), ({ data: mercadoria }) => {
			if (typeof novoOnSuccess === 'function') {
				novoOnSuccess();
			} else {
				resetForm({ values: { ...values, id: mercadoria.id } });
				if (!isModal) {
					atualizarUrl(history, `/${CADASTRO_URL}`, mercadoria.id, metodosAtualizarUrl.POP);
				} else {
					closeModal({ ...values, id: mercadoria.id, codigo: mercadoria.codigo });
				}
			}
		});
	}

	async function onNovo() {
		atualizarUrl(history, `/${CADASTRO_URL}`, null, metodosAtualizarUrl.POP);
		resetForm({ values: copiarObjeto(INITIAL_VALUES) });
	}

	async function deleteRegistro() {
		await deleteMercadoriaTransporte(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, `/${PESQUISA_URL}`);
		});
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadoBotaoSalvar}
				disabled={!dirty && estadoBotaoSalvar === estadosBotaoSalvar.SALVAR}
				onClick={handleClickSalvar}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				estadoBotao={estadoBotaoNovo}
				hidden={(!dirty && !values.id) || isModal}
				onClick={handleClickNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id || isModal} onClick={handleClickExcluir} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
