import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Dropdown, Grid, InputDouble } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { copiarObjeto, formatarMonetario, getSafeValue } from 'Common';
import {
	converterComponentesValorPrestacaoParaCalculadoraFrete,
	converterComponenteValorPrestacaoInicial,
} from '../../../../Utils/TabComplementaresConverter';
import {
	TIPO_CARGA_CALCULADORA_FRETE,
	TIPO_CARGA_CALCULADORA_FRETE_OPTIONS,
} from '../../../../Utils/TabComplementaresConstantes';

function CalculadoraFrete({ panelCalculadoraFrete }) {
	const { values, setFieldValue } = useFormikContext();
	const { isMobile, isSituacaoFinalCTe, setAtualizarTotalizadores } = useContextCTe();

	const [overlayPanelOpen, setOverlayPanelOpen] = useState(false);
	const [componenteValorPrestacaoSelecionado, setComponenteValorPrestacaoSelecionado] = useState(null);
	const [tipoCarga, setTipoCarga] = useState(TIPO_CARGA_CALCULADORA_FRETE.KG);
	const [quantidade, setQuantidade] = useState(1);
	const [valor, setValor] = useState(0);

	useEffect(() => {
		if (overlayPanelOpen && !componenteValorPrestacaoSelecionado) {
			const componente =
				values.servicos?.length > 0
					? values.servicos?.find((item) => item.componente.registro.favorito) || values.servicos[0]
					: null;

			if (componente) {
				setComponenteValorPrestacaoSelecionado(converterComponenteValorPrestacaoInicial(componente));
				setValor(componente.valor);
			}
		}
	}, [overlayPanelOpen]);

	function onHideOverlayPanel() {
		const servicos = copiarObjeto(values.servicos) || [];
		const registroId = componenteValorPrestacaoSelecionado?.value;
		const totalFrete = parseFloat((getSafeValue(quantidade, 0) * getSafeValue(valor, 0)).toFixed(2));

		if (registroId) {
			const newServicos = servicos.map((servico) => {
				const servicoId = servico.id || servico.tempId;
				if (servicoId === registroId) {
					return {
						...servico,
						valor: totalFrete,
					};
				}
				return servico;
			});

			setFieldValue('servicos', newServicos);
			setAtualizarTotalizadores(true);
		}

		setValor(0);
		setQuantidade(1);
		setComponenteValorPrestacaoSelecionado(null);
		setOverlayPanelOpen(false);
	}

	return (
		<OverlayPanel
			id="id-cte-calculadora-frete"
			ref={panelCalculadoraFrete}
			style={{ right: `${isMobile ? '12px' : null}`, width: '100%', maxWidth: '400px' }}
			showCloseIcon
			onShow={() => setOverlayPanelOpen(true)}
			onHide={() => onHideOverlayPanel()}
		>
			<h1 style={{ fontSize: '18px', fontWeight: 'bold' }}>Calculadora de frete</h1>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					label="Componente valor prestação"
					name="componenteValorPrestacaoCalculadoraFrete"
					component={Dropdown}
					options={converterComponentesValorPrestacaoParaCalculadoraFrete(values.servicos)}
					value={componenteValorPrestacaoSelecionado}
					showClear={false}
					onChange={(e) => setComponenteValorPrestacaoSelecionado(e.value)}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					label="Tipo de carga"
					name="tipoCargaCalculadoraFrete"
					component={Dropdown}
					options={TIPO_CARGA_CALCULADORA_FRETE_OPTIONS}
					value={tipoCarga}
					onChange={(e) => setTipoCarga(e.value)}
					showClear={false}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="Quantidade"
					name="quantidadeCalculadoraFrete"
					component={InputDouble}
					suffix={` ${tipoCarga}`}
					decimalScale={2}
					size={15}
					value={quantidade}
					onChange={(e) => setQuantidade(e.target.value)}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label={`Valor por ${tipoCarga}`}
					name="valorCalculadoraFrete"
					component={InputDouble}
					decimalScale={2}
					size={15}
					prefix="R$ "
					value={valor}
					onChange={(e) => setValor(e.target.value)}
					disabled={isSituacaoFinalCTe}
				/>
				<Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<div
						style={{ color: '#006095', fontSize: '18px', fontWeight: 'bold', marginTop: '16px' }}
					>{`Total do frete: R$ ${formatarMonetario(getSafeValue(quantidade, 0) * getSafeValue(valor, 0))}`}</div>
				</Grid>
			</Grid>
		</OverlayPanel>
	);
}

export { CalculadoraFrete };
