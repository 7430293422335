import { useFormikContext } from 'formik';

import { ButtonAdicionarItem, Card, Col, Grid, NenhumRegistroEncontrado } from 'components';
import { copiarObjeto, gerarUUID } from 'Common';

import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';
import { TABELA_PRECO_ALTERACAO_CONDICAO } from 'views/cadastros/vendas/TabelaPreco/Util/constantes';

import { AlteracaoPercentualPorQuantidade } from '../../../../../AlteracaoPercentualPorQuantidade';

function AlteracaoPercentualCondicaoAPrazo({ tipoAlteracaoPorCondicao }) {
	const { values, setFieldValue, errors } = useFormikContext();
	const { isMobile, desabilitarCampos } = useContextTabelaPreco();

	function addItem() {
		const arrayTemp = copiarObjeto(values.condicaoItens);

		arrayTemp?.push({
			id: null,
			item: values.condicaoItens.length + 1,
			idTemporario: gerarUUID(),
			valorInicial: null,
			valorFinal: null,
			percentualAplicado: null,
		});

		setFieldValue('condicaoItens', arrayTemp);
	}

	function deleteItem(index) {
		const arrayTemp = copiarObjeto(values.condicaoItens);
		arrayTemp?.splice(index, 1);

		arrayTemp.forEach((_, index) => {
			arrayTemp[index].item = index + 1;
		});

		setFieldValue('condicaoItens', arrayTemp);
	}

	return (
		<Card style={{ width: '100%' }}>
			<Grid
				style={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					padding: '8px',
					maxHeight: isMobile ? '320px' : '240px',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{values.condicaoItens?.length > 0 ? (
					values.condicaoItens?.map((item, index) => (
						<AlteracaoPercentualPorQuantidade
							key={`${item?.idTemporario ?? item?.id}`}
							isInput={false}
							quantidadeMinima={values.condicao?.registro?.minimoParcelas}
							quantidadeMaxima={values.condicao?.registro?.maximoParcelas}
							isAcrescimo={tipoAlteracaoPorCondicao === TABELA_PRECO_ALTERACAO_CONDICAO.ACRESCIMO}
							registro={item}
							onChangeValorInicial={(e) => setFieldValue(`condicaoItens[${index}].valorInicial`, e)}
							onChangeValorFinal={(e) => setFieldValue(`condicaoItens[${index}].valorFinal`, e)}
							onChangePercentualAplicado={(e) => setFieldValue(`condicaoItens[${index}].percentualAplicado`, e)}
							excluirItem={() => deleteItem(index)}
							errors={errors.condicaoItens && errors.condicaoItens[index]}
						/>
					))
				) : (
					<NenhumRegistroEncontrado />
				)}
			</Grid>
			<Grid>
				<Col sm="4" md="4" lg="4" xl="4">
					<ButtonAdicionarItem onClick={() => addItem()} disabled={desabilitarCampos} />
				</Col>
			</Grid>
		</Card>
	);
}

export { AlteracaoPercentualCondicaoAPrazo };
