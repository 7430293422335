import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ButtonEditarTable from 'components/Button/ButtonEditarTable';
import ButtonExcluirTable from 'components/Button/ButtonExcluirTable';
import NenhumRegistroEncontrado from 'components/NenhumRegistroEncontrado';

export default function ListagemTributacaoUf(props) {
	const { values, setTributacaoUf, setVisibleModal, excluirTributacao, validacaoNovaTributacao } = props;

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => editarTributacao(row)} />
				<ButtonExcluirTable onClick={() => excluirTributacao(row)} disabled={validacaoNovaTributacao} />
			</div>
		);
	}

	function editarTributacao(tributacao) {
		if (tributacao.id || tributacao.idTemporario) {
			setTributacaoUf(tributacao);
			setVisibleModal(true);
		}
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={values}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				className="step-listagem-order"
				field="estado.registro.nome"
				header="UF destino"
				sortable
				body={(row) => row.estado?.label}
				style={{ width: '15rem' }}
			/>
			<Column
				field="cfop.registro.codigo"
				header="CFOP"
				body={(row) => row.cfop?.registro.codigo}
				sortable
				style={{ width: '15rem' }}
			/>
			<Column
				field="cstCsosn.registro.codigo"
				header="CST/CSOSN"
				body={(row) => row.cstCsosn?.registro.codigo}
				sortable
				style={{ width: '15rem' }}
			/>
			<Column
				field="icmsPercentual"
				header="% ICMS"
				body={(row) => (row.icmsPercentual ? `${row.icmsPercentual} %` : `${0} %`)}
				sortable
				style={{ width: '15rem' }}
			/>
			<Column
				field="icmsSTPercentual"
				header="% ICMS ST"
				body={(row) => (row.icmsStPercentual ? `${row.icmsStPercentual} %` : `${0} %`)}
				sortable
				style={{ width: '15rem' }}
			/>
			<Column
				className="step-listagem-acoes"
				body={(row) => renderOpcoes(row)}
				header="Ações"
				style={{ width: '7em' }}
			/>
		</DataTable>
	);
}
