import { useState } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Field, withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TabPanel } from 'primereact/tabview';
import * as Yup from 'yup';

import { asyncGetProduto } from '../Requests';
import { origem } from '../Util/constantes';
import { converterModalProdutoParaForm } from '../../../../Util/notaConverter';
import { validarFormulario } from '../../../../../../Util';

import {
	Col,
	ButtonCancelar,
	ButtonSalvar,
	Form,
	FormActions,
	FormContent,
	Grid,
	Dropdown,
	InputField,
	InputMoney,
	InputPercentage,
	InputSelectMultiplicacaoOrDivisao,
	typesCst,
	SingleSelectCfop,
	SingleSelectCst,
	SingleSelectUnidadeMedida,
	SingleSelectProduto,
	estadosBotaoCancelar,
	TabView,
	If,
	Message,
	InputDouble,
} from '../../../../../../../components';

import {
	mensagensDeValidacao,
	services,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	DADOSLOCALSTORAGE,
	parseFloatNumber,
} from '../../../../../../../Common';
import { calcularTotal } from 'Common/dfe/NFe/Utils/functions';

import '../Styles/index.css';

const initialValue = {
	baseCalculoIcms: 0,
	icmsPorcentagem: 0,
	valorIcms: 0,
	baseCalculoIcmsSt: 0,
	icmsStPorcentagem: 0,
	valorIcmsSt: 0,
	enquadramentoIpi: {
		codigo: '999',
		descricao: 'Tributação normal IPI; Outros;',
		id: '15c1cd1b-29ba-4769-83bf-e399e9e3cb18',
		label: '999 - Tributação normal IPI; Outros;',
	},
	cstIpi: {
		codigo: '03',
		descricao: 'Entrada Não-Tributada',
		id: 'dd73980f-d0d1-4a4b-9bde-270e0f846b1b',
		label: '03 - Entrada Não-Tributada',
	},
	baseCalculoIpi: 0,
	ipiPorcentagem: 0,
	valorIpi: 0,
	cstPis: {
		codigo: '07',
		descricao: 'Operação Isenta da Contribuição',
		id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
		label: '07 - Operação Isenta da Contribuição',
	},
	baseCalculoPis: 0,
	pisPorcentagem: 0,
	valorPis: 0,
	baseCalculoPisSt: 0,
	pisStPorcentagem: 0,
	valorPisSt: 0,
	cstCofins: {
		codigo: '07',
		descricao: 'Operação Isenta da Contribuição',
		id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
		label: '07 - Operação Isenta da Contribuição',
	},
	baseCalculoCofins: 0,
	cofinsPorcentagem: 0,
	valorCofins: 0,
	baseCalculoCofinsSt: 0,
	cofinsStPorcentagem: 0,
	valorCofinsSt: 0,
	quantidadeConvertida: 1,

	id: '',
	item: 0,
	produto: null,
	unidadeCompra: null,
	unidadeEstoque: null,
	codigoProduto: '',
	codigoEan: '',
	descricaoProduto: '',
	ncm: null,
	cfop: {
		codigo: '1102',
		descricao: 'Compra p/ comercialização',
		id: '1fbe083c-affe-f816-23d3-8611ff75f03c',
		label: '1102 - Compra p/ comercialização',
	},
	cst:
		buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao?.regimeTributario === 'SIMPLES_NACIONAL_EXCESSO' ||
		buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao?.regimeTributario === 'NORMAL'
			? {
					codigo: '40',
					descricao: 'Isenta',
					id: '93c072ca-ab25-4f63-a607-d33ee62cb8b6',
					label: '40 - Isenta',
				}
			: {
					codigo: '400',
					descricao: 'Isenta',
					id: 'bfca6e70-6184-4a45-a28c-c320c39382af',
					label: '400 - Não tributada pelo Simples Nacional',
				},

	origem: 'NACIONAL',
	fatorConversao: 1,
	operacaoFatorConversao: 'MULTIPLICACAO',
	cest: null,
	quantidade: 1,
	valorUnitario: 0,
	valorFrete: 0,
	valorSeguro: 0,
	valorDesconto: 0,
	valorAcessorias: 0,
	subTotal: 0,
	pedidoCompra: '',
	itemPedidoCompra: '',
	margemLucro: 0,
	margemLucroAtacado: 0,
	icms: {
		cst: null,
		baseCalculo: 0,
		aliquota: 0,
		valor: 0,
		modalidadeBcIcms: 'PAUTA',
		valorBaseCalculo: 0,
		valorFcp: 0,
		aliquotaFcp: 0,
		valorBcFcp: 0,
		modalidadeBcIcmsSt: 'PRECO_MAXIMO_OU_MAX_SUGERIDO',
		percentualReducaoBcSt: 0,
		valorBcSt: 0,
		aliquotaSt: 0,
		valorSt: 0,
		valorBcFcpSt: 0,
		aliquotaFcpSt: 0,
		valorFcpSt: 0,
	},
	ipi: {
		cnpjProdutor: '',
		codigoSeloControle: '',
		quantidadeSeloControle: 0,
		enquadramento: null,
		cst: null,
		baseCalculo: 0,
		aliquota: 0,
		quantidadeUnidadeMedida: 0,
		valorUnidadeMedida: 0,
		valor: 0,
	},
	pis: {
		cst: null,
		baseCalculoValor: 0,
		baseCalculoQuantidade: 0,
		aliquota: 0,
		valor: 0,
		quantidadeVendida: 0,
		aliquotaReais: 0,
		baseCalculoSt: 0,
		aliquotaSt: 0,
		quantidadeVendidaSt: 0,
		aliquotaReaisSt: 0,
		valorSt: 0,
	},
	cofins: {
		cst: null,
		aliquota: 0,
		valor: 0,
		baseCalculoValor: 0,
		quantidadeVendida: 0,
		aliquota: 0,
		aliquotaReais: 0,
		baseCalculoSt: 0,
		aliquotaSt: 0,
		quantidadeSt: 0,
		aliquotaReaisSt: 0,
		valorSt: 0,
	},
};

function FormModalProdutosView(props) {
	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	const {
		values,
		setFieldValue,
		onSave,
		dirty,
		resetForm,
		onHide,
		handleSubmit,
		errors,
		isFinalizado,
		isMobile,
		xmlImportado,
		isNfse,
	} = props;

	const [submitCount, setSubmitCount] = useState(0);

	useEffectOnce(() => {
		setTimeout(() => {
			let target = document.getElementById('Produto');
			target.focus();
		}, 500);
	});

	function setFiltroModeloServico() {
		if (isNfse) {
			return `;tipo=="SERVICO"`;
		} else {
			return `;tipo!="SERVICO"`;
		}
	}

	useUpdateEffect(() => {
		const quantidadeConvertida = calcularConversao(
			values.quantidade,
			values.fatorConversao,
			values.operacaoFatorConversao
		);
		setFieldValue('quantidadeConvertida', quantidadeConvertida);
	}, [values.quantidade, values.fatorConversao, values.operacaoFatorConversao]);

	useUpdateEffect(() => {
		const subTotal = calcularTotal(
			values.operacaoFatorConversao,
			values.valorUnitario,
			values.quantidade,
			values.valorIcmsSt,
			values.valorIpi,
			values.valorFrete,
			values.valorSeguro,
			values.valorAcessorias,
			values.valorDesconto
		);
		setFieldValue('subTotal', subTotal);
	}, [
		values.valorUnitario,
		values.quantidadeConvertida,
		values.valorIcmsSt,
		values.valorIpi,
		values.valorFrete,
		values.valorSeguro,
		values.valorAcessorias,
		values.valorDesconto,
	]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoIcms, values.icmsPorcentagem);
		setFieldValue('valorIcms', valorImposto);
	}, [values.baseCalculoIcms, values.icmsPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoIcmsSt, values.icmsStPorcentagem);
		setFieldValue('valorIcmsSt', valorImposto);
	}, [values.baseCalculoIcmsSt, values.icmsStPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoIpi, values.ipiPorcentagem);
		setFieldValue('valorIpi', valorImposto);
	}, [values.baseCalculoIpi, values.ipiPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoPis, values.pisPorcentagem);
		setFieldValue('valorPis', valorImposto);
	}, [values.baseCalculoPis, values.pisPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoPisSt, values.pisStPorcentagem);
		setFieldValue('valorPisSt', valorImposto);
	}, [values.baseCalculoPisSt, values.pisStPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoCofins, values.cofinsPorcentagem);
		setFieldValue('valorCofins', valorImposto);
	}, [values.baseCalculoCofins, values.cofinsPorcentagem]);

	useUpdateEffect(() => {
		const valorImposto = calcularValorImposto(values.baseCalculoCofinsSt, values.cofinsStPorcentagem);
		setFieldValue('valorCofinsSt', valorImposto);
	}, [values.baseCalculoCofinsSt, values.cofinsStPorcentagem]);

	function cancelar() {
		if (dirty) {
			resetForm('');
		} else {
			onHide();
		}
	}

	async function handleSubmitForm() {
		handleSubmit();
		setSubmitCount(submitCount + 1);
		if (dirty && (await validarFormulario(props))) {
			onSave(values);
		}
	}

	function calcularConversao(quantidade, fator, operacao) {
		let total = 0;
		if (operacao === 'MULTIPLICACAO') {
			total = quantidade * fator;
		} else if (operacao === 'DIVISAO') {
			total = quantidade / fator;
		}
		return parseFloatNumber(total, decimaisQtd);
	}

	function calcularValorImposto(value, percentage) {
		return parseFloatNumber(value * (percentage / 100));
	}

	function concatenate(value) {
		let str1 = value.codigo ? value.codigo : value.unidade;
		let str2 = value.descricao;
		let concatLabel = str1 + ' - ' + str2;
		return { ...value, label: concatLabel };
	}

	function onChangeSelect(field, value) {
		if (!value?.registro && value !== undefined) {
			setFieldValue(`${field}`, {
				label: value.descricao + ' - ' + value.unidade,
				registro: value,
				value: value.id,
			});
		} else if (value?.registro && value !== undefined) {
			if (field === 'unidadeCompra' || field === 'unidadeEstoque') {
				setFieldValue(`${field}`, {
					...value,
					id: value.registro.id,
					descricao: value.registro.descricao,
					unidade: value.registro.unidade,
				});
			} else if (
				field === 'cst' ||
				field === 'cfop' ||
				field === 'cstPis' ||
				field === 'cstCofins' ||
				field === 'cstIpi' ||
				field === 'enquadramentoIpi'
			) {
				setFieldValue(`${field}`, {
					...value,
					id: value.registro.id,
					descricao: value.registro.descricao,
					codigo: value.registro.codigo,
				});
			} else {
				setFieldValue(`${field}`, value);
			}
		} else {
			setFieldValue(`${field}`, null);
		}
	}

	async function onChangeProduto(value) {
		let produtoConvertido = {};
		produtoConvertido = {
			...value.registro,
			label: value.label,
			value: value.value,
			codigoBarras: value.codigoBarras,
		};
		await asyncGetProduto(produtoConvertido.id, ({ data: produto }) => {
			let produtoSelecionado = produto;
			onChangeSelect('unidadeCompra', produtoSelecionado.unidadeMedida);
			onChangeSelect('unidadeEstoque', produtoSelecionado.unidadeMedidaEntrada);
		});

		setFieldValue('produto', produtoConvertido);
	}

	function mensagensDeErro() {
		return (
			<span>
				{Object.entries(errors).map(([field, error]) => {
					switch (field) {
						case 'cst':
							return formatField('CST ICMS');
						case 'baseCalculoIcms':
							return formatField('Base cálculo ICMS');
						case 'enquadramentoIpi':
							return formatField('Enquadramento IPI');
						case 'cstIpi':
							return formatField('CST IPI');
						case 'cstPis':
							return formatField('CST PIS');
						case 'cstCofins':
							return formatField('CST COFINS');
					}
				})}
			</span>
		);
	}

	function formatField(field) {
		return (
			<span key={field}>
				{field} - Obrigatório
				<br />
			</span>
		);
	}

	function checkWichErrors() {
		let errorsNotToCheck = ['cst', 'baseCalculoIcms', 'enquadramentoIpi', 'cstIpi', 'cstPis', 'cstCofins'];
		return Object.keys(errors).some((key) => errorsNotToCheck.indexOf(key) >= 0);
	}

	return (
		<div
			style={{
				width: '100%',
				overflowY: isMobile ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: '40rem',
			}}
		>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						style={{
							boxShadow: 'none',
						}}
					/>
					<ButtonSalvar onClick={() => handleSubmitForm()} disabled={!dirty || isFinalizado} autofocus />
				</FormActions>
				<FormContent>
					<If test={errors && submitCount > 0 && checkWichErrors()}>
						<Message
							severity="error"
							text={errors ? mensagensDeErro() : null}
							colStyle={{ padding: '5px 0px', marginBottom: '8px' }}
							className="message-nota-compra-effect"
						/>
					</If>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							id="Produto"
							label={isNfse ? 'Serviço' : 'Produto'}
							autofocus
							component={SingleSelectProduto}
							name={'produto'}
							value={values.produto}
							onChange={(event) => onChangeProduto(event)}
							url={`${services.GESTOR}/v1/produtos/resumo`}
							isClearable={false}
							tipoPredefinido="PRODUTO"
							obrigatorio
							filtroAdicionalUrl={setFiltroModeloServico()}
							disabled={isFinalizado || xmlImportado}
						/>
						<Field
							sm="12"
							md="12"
							lg="3"
							xl="3"
							component={InputDouble}
							type="number"
							label="Qtde."
							name="quantidade"
							value={values.quantidade}
							decimalScale={decimaisQtd}
							onChange={(e) => {
								setFieldValue('quantidade', e.target?.value);
							}}
							disabled={isFinalizado || xmlImportado}
						/>
						<Field
							sm="12"
							md="12"
							lg="3"
							xl="3"
							component={InputSelectMultiplicacaoOrDivisao}
							label="Fator conversão"
							name="fatorConversao"
							value={values.fatorConversao}
							valueOperacao={values.operacaoFatorConversao}
							onChange={(e) => {
								setFieldValue('fatorConversao', e, ['─']);
							}}
							onChangeOperacao={(e) => {
								setFieldValue('operacaoFatorConversao', e);
							}}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="12"
							lg="2"
							xl="2"
							disabled={true}
							component={InputDouble}
							type="number"
							label="Qtde. convertida"
							name="quantidadeConvertida"
							value={values.quantidadeConvertida}
							decimalScale={decimaisQtd}
							onChange={(e) => {
								setFieldValue('quantidadeConvertida', e.target?.value);
							}}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={SingleSelectUnidadeMedida}
							label="Und. origem"
							name="unidadeCompra"
							value={values?.unidadeCompra}
							onChange={(e) => onChangeSelect('unidadeCompra', e)}
							isClearable={false}
							showClear={false}
							placeholder="Pesquisar"
							obrigatorio
							disabled={isFinalizado || xmlImportado}
						/>
						<Field
							sm="12"
							md="1"
							lg="2"
							xl="2"
							component={SingleSelectUnidadeMedida}
							label="Und. entrada"
							name="unidadeEstoque"
							value={values?.unidadeEstoque}
							onChange={(e) => onChangeSelect('unidadeEstoque', e)}
							isClearable={false}
							showClear={false}
							placeholder="Pesquisar"
							obrigatorio
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Unitário"
							name="valorUnitario"
							value={values.valorUnitario}
							decimalScale={decimaisPreco}
							onChange={(e) => {
								setFieldValue('valorUnitario', e.target?.value);
							}}
							allowNegative={false}
							disabled={isFinalizado || xmlImportado}
						/>
						<Field
							sm="12"
							md="12"
							lg="3"
							xl="3"
							component={SingleSelectCfop}
							label="CFOP"
							name="cfop"
							value={values.cfop}
							onChange={(e) => onChangeSelect('cfop', e)}
							isClearable={false}
							obrigatorio
							disabled={isFinalizado}
						/>

						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Desconto"
							name="valorDesconto"
							value={values.valorDesconto}
							onChange={(e) => {
								setFieldValue('valorDesconto', e.target?.value);
							}}
							allowNegative={false}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Frete"
							name="valorFrete"
							value={values.valorFrete}
							onChange={(e) => {
								setFieldValue('valorFrete', e.target?.value);
							}}
							allowNegative={false}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Seguro"
							name="valorSeguro"
							value={values.valorSeguro}
							onChange={(e) => {
								setFieldValue('valorSeguro', e.target?.value);
							}}
							allowNegative={false}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Acessórias"
							name="valorAcessorias"
							value={values.valorAcessorias}
							onChange={(e) => {
								setFieldValue('valorAcessorias', e.target?.value);
							}}
							allowNegative={false}
							disabled={isFinalizado}
						/>
						<Field
							sm="12"
							md="4"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Valor total"
							name="subTotal"
							value={values.subTotal}
							onChange={(e) => setFieldValue('subTotal', e.target?.value)}
							allowNegative={false}
							disabled={true}
						/>
						<Field
							sm="12"
							md="4"
							lg="2"
							xl="2"
							component={InputField}
							label="Cód. fornecedor"
							name="codigoProduto"
							value={values.codigoProduto}
							onChange={(e) => setFieldValue('codigoProduto', e.target?.value, ['─'])}
							disabled={isFinalizado || xmlImportado}
						/>
					</Grid>
					<TabView renderActiveOnly className="tab-view">
						<TabPanel header="ICMS">
							<Grid>
								<Field
									sm="12"
									md="12"
									lg="6"
									xl="6"
									component={SingleSelectCst}
									label="CST/CSOSN"
									name="cst"
									type={typesCst.CST_CSOSN}
									value={values.cst}
									onChange={(e) => onChangeSelect('cst', e)}
									isClearable={false}
									showClear={false}
									obrigatorio
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="6"
									lg="6"
									xl="6"
									component={Dropdown}
									label="Origem do produto"
									name="origem"
									onChange={(e) => setFieldValue('origem', e.value)}
									options={origem}
									obrigatorio
									id="ProdutoDropdownOrigemProduto"
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC ICMS"
									name="baseCalculoIcms"
									value={values.baseCalculoIcms}
									onChange={(e) => {
										setFieldValue('baseCalculoIcms', e.target?.value);
									}}
									allowNegative={false}
									obrigatorio
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="ICMS %"
									name="icmsPorcentagem"
									value={values.icmsPorcentagem}
									onChange={(e) => {
										setFieldValue('icmsPorcentagem', e.target?.value);
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor ICMS"
									name="valorIcms"
									value={values.valorIcms}
									onChange={(e) => setFieldValue('valorIcms', e.target?.value)}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC ICMS ST"
									name="baseCalculoIcmsSt"
									value={values.baseCalculoIcmsSt}
									onChange={(e) => {
										setFieldValue('baseCalculoIcmsSt', e.target?.value),
											setFieldValue('valorIcmsSt', calcularValorImposto(e.target?.value, values.icmsStPorcentagem));
									}}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="ICMS ST %"
									name="icmsStPorcentagem"
									value={values.icmsStPorcentagem}
									onChange={(e) => {
										setFieldValue('icmsStPorcentagem', e.target?.value),
											setFieldValue('valorIcmsSt', calcularValorImposto(values.baseCalculoIcmsSt, e.target?.value));
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor ICMS ST"
									name="valorIcmsSt"
									value={values.valorIcmsSt}
									onChange={(e) => {
										setFieldValue('valorIcmsSt', e.target?.value);
									}}
									disabled={isFinalizado}
								/>
							</Grid>
						</TabPanel>
						<TabPanel header="IPI">
							<Grid>
								<Field
									sm="12"
									md="2"
									lg="12"
									xl="12"
									component={SingleSelectCst}
									type={typesCst.ENQUADRAMENTO_IPI}
									label="Enquadramento IPI"
									name="enquadramentoIpi"
									value={values.enquadramentoIpi}
									onChange={(e) => onChangeSelect('enquadramentoIpi', e)}
									isClearable={false}
									showClear={false}
									allowNegative={false}
									obrigatorio
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="6"
									xl="6"
									component={SingleSelectCst}
									type={typesCst.CST_IPI}
									label="CST IPI"
									name="cstIpi"
									value={values.cstIpi}
									onChange={(e) => onChangeSelect('cstIpi', e)}
									isClearable={false}
									showClear={false}
									allowNegative={false}
									obrigatorio
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC IPI"
									name="baseCalculoIpi"
									value={values.baseCalculoIpi}
									onChange={(e) => {
										setFieldValue('baseCalculoIpi', e.target?.value),
											setFieldValue('valorIpi', calcularValorImposto(e.target?.value, values.ipiPorcentagem));
									}}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="IPI %"
									name="ipiPorcentagem"
									value={values.ipiPorcentagem}
									onChange={(e) => {
										setFieldValue('ipiPorcentagem', e.target?.value),
											setFieldValue('valorIpi', calcularValorImposto(values.baseCalculoIpi, e.target?.value));
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor IPI"
									name="valorIpi"
									value={values.valorIpi}
									onChange={(e) => {
										setFieldValue('valorIpi', e.target?.value);
									}}
									disabled={isFinalizado}
								/>
							</Grid>
						</TabPanel>
						<TabPanel header="PIS">
							<Grid>
								<Col sm="12" md="12" lg="12" xl="12" style={{ margin: 0, padding: 0 }}>
									<Field
										sm="12"
										md="12"
										lg="6"
										xl="6"
										component={SingleSelectCst}
										type={typesCst.CST_PIS_COFINS}
										label="CST PIS"
										name="cstPis"
										value={values.cstPis}
										onChange={(e) => onChangeSelect('cstPis', e)}
										isClearable={false}
										showClear={false}
										obrigatorio
										disabled={isFinalizado}
									/>
								</Col>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC PIS"
									name="baseCalculoPis"
									value={values.baseCalculoPis}
									onChange={(e) => {
										setFieldValue('baseCalculoPis', e.target?.value);
									}}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="PIS %"
									name="pisPorcentagem"
									value={values.pisPorcentagem}
									onChange={(e) => {
										setFieldValue('pisPorcentagem', e.target?.value);
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor PIS"
									name="valorPis"
									value={values.valorPis}
									onChange={(e) => setFieldValue('valorPis', e.target?.value)}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC PIS ST"
									name="baseCalculoPisSt"
									value={values.baseCalculoPisSt}
									onChange={(e) => {
										setFieldValue('baseCalculoPisSt', e.target?.value);
									}}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="PIS ST %"
									name="pisStPorcentagem"
									value={values.pisStPorcentagem}
									onChange={(e) => {
										setFieldValue('pisStPorcentagem', e.target?.value);
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor PIS ST"
									name="valorPisSt"
									value={values.valorPisSt}
									onChange={(e) => setFieldValue('valorPisSt', e.target?.value)}
									allowNegative={false}
									disabled={isFinalizado}
								/>
							</Grid>
						</TabPanel>
						<TabPanel header="COFINS">
							<Grid>
								<Col sm="12" md="12" lg="12" xl="12" style={{ margin: 0, padding: 0 }}>
									<Field
										sm="12"
										md="12"
										lg="6"
										xl="6"
										component={SingleSelectCst}
										type={typesCst.CST_PIS_COFINS}
										label="CST COFINS"
										name="cstCofins"
										value={values.cstCofins}
										onChange={(e) => onChangeSelect('cstCofins', e)}
										isClearable={false}
										showClear={false}
										obrigatorio
										disabled={isFinalizado}
									/>
								</Col>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC COFINS"
									name="baseCalculoCofins"
									value={values.baseCalculoCofins}
									onChange={(e) => {
										setFieldValue('baseCalculoCofins', e.target?.value);
									}}
									disabled={isFinalizado}
								/>

								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="COFINS %"
									name="cofinsPorcentagem"
									value={values.cofinsPorcentagem}
									onChange={(e) => {
										setFieldValue('cofinsPorcentagem', e.target?.value);
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor COFINS"
									name="valorCofins"
									value={values.valorCofins}
									onChange={(e) => setFieldValue('valorCofins', e.target?.value)}
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputMoney}
									label="BC COFINS ST"
									name="baseCalculoCofinsSt"
									value={values.baseCalculoCofinsSt}
									onChange={(e) => {
										setFieldValue('baseCalculoCofinsSt', e.target?.value);
									}}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="2"
									lg="2"
									xl="2"
									component={InputPercentage}
									label="COFINS ST %"
									name="cofinsStPorcentagem"
									value={values.cofinsStPorcentagem}
									onChange={(e) => {
										setFieldValue('cofinsStPorcentagem', e.target?.value);
									}}
									suffix=" %"
									placeholder="0 %"
									allowNegative={false}
									disabled={isFinalizado}
								/>
								<Field
									sm="12"
									md="4"
									lg="2"
									xl="2"
									component={InputMoney}
									label="Valor COFINS ST"
									name="valorCofinsSt"
									value={values.valorCofinsSt}
									onChange={(e) => setFieldValue('valorCofinsSt', e.target?.value)}
									disabled={isFinalizado}
								/>
							</Grid>
						</TabPanel>
					</TabView>
				</FormContent>
			</Form>
		</div>
	);
}

const FormModalProdutosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: true,

	mapPropsToValues(props) {
		if (props.valuesProduto) {
			return converterModalProdutoParaForm(props.valuesProduto, props.modeloNota);
		} else {
			return { ...initialValue, modeloNota: props.modeloNota };
		}
	},

	validationSchema: Yup.object().shape({
		produto: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		quantidade: Yup.number()
			.nullable()
			.when('modeloNota', (modeloNota) => {
				//validação quando for modelo 55
				if (modeloNota?.value.includes('a4067392-505f-47e4-81c0-2f89c55484c9')) {
					return Yup.number()
						.nullable()
						.required(mensagensDeValidacao.OBRIGATORIO)
						.moreThan(0, mensagensDeValidacao.VALOR_INVALIDO);
				}
			}),
		cfop: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cst: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		unidadeCompra: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		unidadeEstoque: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		valorUnitario: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO).min(0.01, 'Valor mínimo R$ 0,01'),
		baseCalculoIcms: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		enquadramentoIpi: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cstIpi: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cstPis: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		icmsPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		icmsStPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		ipiPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		pisPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		pisStPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		cofinsPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		cofinsStPorcentagem: Yup.string()
			.trim()
			.nullable()
			.matches(/^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$|^(100)$/, 'Valor máximo 100%'),
		cstCofins: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(FormModalProdutosView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default withRouter(connect(mapStateToProps)(FormModalProdutosForm));
