import { formatarMonetario } from 'Common';
import { Chart } from 'chart.js';
import { If } from 'components';
import TituloCard from 'components/TituloCard';
import { ProgressBar } from 'primereact/progressbar';
import { useEffect, useRef } from 'react';
import { TRANSPORTES_CORES } from '../../../../Util/constantes';

const CARD_CONTENT = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

let chartDocumentos;

function ChartDocumentosUltimosDozeMeses({ data, title, helpMessage, isMobile, loading }) {
	const graficoDocumentosUltimosDozeMeses = useRef();

	const emptyData = !data.meses?.length;

	const cardStyle = {
		borderRadius: '5px',
		backgroundColor: 'white',
		boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
		minHeight: '100%',
		padding: isMobile ? '7px 0 0 0' : '7px',
	};

	useEffect(
		() => () => {
			if (typeof chartDocumentos !== 'undefined') {
				chartDocumentos.destroy();
			}
		},
		[]
	);

	setTimeout(() => {
		buildChart();
	}, 300);

	function buildChart() {
		if (!graficoDocumentosUltimosDozeMeses.current || emptyData) {
			return;
		}

		const myChartRef = graficoDocumentosUltimosDozeMeses.current?.getContext('2d');

		if (typeof chartDocumentos !== 'undefined') {
			chartDocumentos.destroy();
		}

		chartDocumentos = new Chart(myChartRef, {
			type: 'bar',
			data: {
				labels: data.meses,
				datasets: [
					{
						label: 'Pendentes',
						data: data.pendentes,
						backgroundColor: TRANSPORTES_CORES.azul,
						hidden: true,
					},
					{
						label: 'Transmitidos',
						data: data.transmitidos,
						backgroundColor: TRANSPORTES_CORES.verde,
					},
					{
						label: 'Rejeitados',
						data: data.rejeitados,
						backgroundColor: TRANSPORTES_CORES.amarelo,
					},
					{
						label: 'Cancelados',
						data: data.cancelados,
						backgroundColor: TRANSPORTES_CORES.vermelho,
						hidden: true,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 1,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					datalabels: {
						color: TRANSPORTES_CORES.preto,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							usePointStyle: true,
						},
						onHover: (e) => {
							e.native.target.style.cursor = 'pointer';
						},
						onLeave: (e) => {
							e.native.target.style.cursor = 'default';
						},
					},
					tooltip: {
						callbacks: {
							title: (tooltipItem) => `Competência: ${tooltipItem[0].label}`,
							label: (tooltipItem) => {
								const { label } = tooltipItem.dataset;
								const valor = formatarMonetario(tooltipItem.raw);
								return `${label}: ${valor}`;
							},
						},
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							callback: (value) => formatarMonetario(value),
						},
					},
				},
			},
		});
	}

	return (
		<div style={cardStyle}>
			<TituloCard title={title} helpMessage={helpMessage} style={{ padding: isMobile ? '7px 0 0 7px' : '7px' }} />
			<If test={loading}>
				<div style={CARD_CONTENT}>
					<ProgressBar mode="indeterminate" style={{ height: '3px', width: '100%', margin: '10px 0px' }} />
				</div>
			</If>

			<If test={!loading && !emptyData}>
				<div
					style={{
						marginTop: '20px',
						padding: isMobile ? '0px' : '7px 0 0 7px',
					}}
				>
					<canvas
						id="graficoDocumentosUltimosDozeMeses"
						ref={graficoDocumentosUltimosDozeMeses}
						style={{ maxWidth: '100%' }}
					/>
				</div>
			</If>
		</div>
	);
}

export { ChartDocumentosUltimosDozeMeses };
