const TIPO_CARGA_CALCULADORA_FRETE = {
	KG: 'KG',
	UN: 'UN',
	M3: 'M3',
};

const TIPO_CARGA_CALCULADORA_FRETE_OPTIONS = [
	{
		label: 'Peso (KG)',
		value: TIPO_CARGA_CALCULADORA_FRETE.KG,
	},
	{
		label: 'Volume (M3)',
		value: TIPO_CARGA_CALCULADORA_FRETE.M3,
	},
	{
		label: 'Quantidade (UN)',
		value: TIPO_CARGA_CALCULADORA_FRETE.UN,
	},
];

export { TIPO_CARGA_CALCULADORA_FRETE, TIPO_CARGA_CALCULADORA_FRETE_OPTIONS };
