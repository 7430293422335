import { useEffect } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';

import { Dropdown, FormActions, FormContent, Grid, InputDate, InputDouble, InputField, Modal } from 'components';
import { mensagensDeValidacao } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import {
	INITIAL_VALUE_OUTROS_DOCUMENTOS,
	TIPO_DOCUMENTO_OUTROS_OPTIONS,
} from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { converterDadosOutrosDocumentosParaForm } from '../../../../Utils/converterDocumentosFiscais';
import { ActionButtons } from './ActionButtons';

function ModalDadosOutrosDocumentosView({ onHide, onSave }) {
	const { visibleModalDadosOutrosDocumentos, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('id-cte-documentos-outros-numero')?.focus();
		}, 200);
	}, []);

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);
		}
	}

	return (
		<Modal visible={visibleModalDadosOutrosDocumentos} header="Dados outros documentos" onHide={onHide}>
			<FormActions>
				<ActionButtons {...{ onHide, onHandleSave }} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Tipo de documento"
						name="tipoDocumento"
						component={Dropdown}
						options={TIPO_DOCUMENTO_OUTROS_OPTIONS}
						value={values.tipoDocumento}
						onChange={(e) => setFieldValue('tipoDocumento', e)}
						showClear={false}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						id="id-cte-documentos-outros-numero"
						label="Número"
						name="numero"
						component={InputField}
						value={values.numero}
						size={20}
						onChange={(e) => setFieldValue('numero', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Valor"
						name="valor"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valor}
						onChange={(e) => setFieldValue('valor', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Emissão"
						name="emissao"
						component={InputDate}
						value={values.emissao}
						obrigatorio
						onChange={(e) => setFieldValue('emissao', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						label="Descrição do documento"
						name="descricao"
						component={InputField}
						value={values.descricao}
						size={100}
						onChange={(e) => setFieldValue('descricao', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalDadosOutrosDocumentos = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterDadosOutrosDocumentosParaForm(props.registroSelecionado);
		} else {
			return INITIAL_VALUE_OUTROS_DOCUMENTOS;
		}
	},

	validate(values) {
		const errors = {};

		if (!values.tipoDocumento) {
			errors.tipoDocumento = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.emissao) {
			errors.emissao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosOutrosDocumentosView);

export { ModalDadosOutrosDocumentos };
