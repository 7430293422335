import { useRef } from 'react';
import { Field, useFormikContext } from 'formik';

import { Grid, InputDouble, InputDoubleCalculateButton } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { ClassNameInputValorTotalColor } from 'views/transporte/CTe/Form/Util/constantes';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';
import { CalculadoraFrete } from './components/CalculadoraFrete';

function TabTotalizadores({ totalizadores }) {
	const { informacoesPermissoes } = useContextCTe();
	const { values } = useFormikContext();

	const panelCalculadoraFrete = useRef(null);

	function handleClickCalculadoraFrete(e) {
		if (values.servicos?.length > 0) {
			panelCalculadoraFrete.current.toggle(e);
		}
	}

	return (
		<>
			<Grid style={{ justifyContent: 'flex-end' }}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Valor da carga"
					name="totalizadores.totalCarga"
					value={totalizadores.totalCarga}
					decimalScale={2}
					prefix="R$ "
					allowNegative={false}
					size={11}
					disabled
					touched
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDoubleCalculateButton}
					styleLabel={{ fontWeight: 'bold', color: defineColorStatus(values?.situacao) }}
					className={`${ClassNameInputValorTotalColor[values?.situacao]}`}
					label="Valor do frete"
					name="totalizadores.totalFrete"
					value={totalizadores.totalFrete}
					onClick={(e) => handleClickCalculadoraFrete(e)}
					obrigatorio
					decimalScale={2}
					size={15}
					allowNegative={false}
					touched
					titleButton="Calculadora de frete"
					disabled
					{...informacoesPermissoes}
				/>
			</Grid>

			<CalculadoraFrete panelCalculadoraFrete={panelCalculadoraFrete} />
		</>
	);
}

export { TabTotalizadores };
