import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Card, Checkbox, Dropdown, Grid, InputDouble, Subtitle } from 'components';
import { Field, useFormikContext } from 'formik';
import { CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { FORMA_PAGAMENTO_FISCAL } from 'views/cadastros/financas/FormaPagamento/Util/constantes';

function Compensacao({ match }) {
	const { values, setFieldValue } = useFormikContext();
	
	const informacoesPermissoes = {
		estadoCadastro: match?.params?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir : usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.INSERIR),
	    podeEditar : usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.EDITAR),
	    podeExcluir : usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.EXCLUIR),
	};

	const isBoleto = values.formaPagamento?.registro?.formaPagamentoFiscal === FORMA_PAGAMENTO_FISCAL.BOLETO_BANCARIO;

	return (
		<>
			<Grid>
				<Subtitle subtitle="Compensação automática" style={{ margin: '1em 8px 0 0.5em' }} />
			</Grid>

			<Card style={{ paddingBottom: '8px' }}>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="4"
						xl="4"
						component={Checkbox}
						colStyle={{
							display: 'flex',
							alignItems: 'flex-end',
							marginBottom: '7px',
							paddingBottom: '8px',
						}}
						label="Permite compensação automática"
						name="compensacaoAutomatica"
						checked={values.compensacaoAutomatica}
						onChange={() => setFieldValue('compensacaoAutomatica', !values.compensacaoAutomatica)}
						showClear={false}
						disabled={isBoleto}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de compensação"
						name="compensacaoTipo"
						value={values.compensacaoTipo}
						onChange={(event) => setFieldValue('compensacaoTipo', event?.value)}
						showClear={false}
						options={CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS}
						disabled={!values.compensacaoAutomatica || isBoleto}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Número dias compensação"
						name="compensacaoNumeroDias"
						component={InputDouble}
						decimalScale={0}
						size={13}
						allowNegative={false}
						value={values?.compensacaoNumeroDias}
						onChange={(event) => setFieldValue('compensacaoNumeroDias', event.target?.value || 0)}
						disabled={!values.compensacaoAutomatica || isBoleto}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Card>
		</>
	);
}

export { Compensacao };
