import { useFormikContext } from 'formik';

import { ButtonAdicionarItem, Card, Col, Grid, NenhumRegistroEncontrado } from 'components';

import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';
import { TABELA_PRECO_ALTERACAO_CONDICAO } from 'views/cadastros/vendas/TabelaPreco/Util/constantes';

import { AlteracaoPercentualPorQuantidade } from '../../../../../AlteracaoPercentualPorQuantidade';

function AlteracaoPercentualCondicaoAVista({ tipoAlteracaoPorCondicao }) {
	const { values, setFieldValue, errors } = useFormikContext();
	const { isMobile } = useContextTabelaPreco();

	return (
		<Card style={{ width: '100%' }}>
			<Grid
				style={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					padding: '8px',
					maxHeight: isMobile ? '320px' : '240px',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{values.condicaoItens?.length > 0 ? (
					values.condicaoItens?.map((item, index) => (
						<AlteracaoPercentualPorQuantidade
							key={`${item?.idTemporario ?? item?.id}`}
							isAVista
							isInput={false}
							isAcrescimo={tipoAlteracaoPorCondicao === TABELA_PRECO_ALTERACAO_CONDICAO.ACRESCIMO}
							registro={item}
							onChangeValorInicial={() => {}}
							onChangeValorFinal={() => {}}
							onChangePercentualAplicado={(e) => setFieldValue(`condicaoItens[${index}].percentualAplicado`, e)}
							errors={errors.condicaoItens && errors.condicaoItens[index]}
						/>
					))
				) : (
					<NenhumRegistroEncontrado />
				)}
			</Grid>
			<Grid>
				<Col sm="4" md="4" lg="4" xl="4">
					<ButtonAdicionarItem disabled />
				</Col>
			</Grid>
		</Card>
	);
}

export { AlteracaoPercentualCondicaoAVista };
