import { useCallback, useEffect, useRef, useState } from 'react';
import { format, formatISO, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';

import {
	baixarArquivo,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarMonetario,
	permissoes,
	recursos,
	removerElemento,
	replaceCaracteresEspeciais,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	ModalLoadingTransmissao,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	tipoCampos,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { CADASTRO_URL, CTE_INFORMACOES_SITUACAO, CTE_SITUACAO, CTE_OPTIONS_FILTRO_AVANCADO } from './Util/constantes';
import {
	asyncGetDownloadXmls,
	deleteCTe,
	getCTes,
	getImprimirCTe,
	getSetores,
	getUsuarios,
	getXmlCTe,
	readTotalizadoresCard,
} from './Requests';
import { currentMonth } from './Util/functions';
import { CTeCardsListagem } from './components/CTeCardsListagem';
import { CTE_TIPO } from './Form/Util/constantes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '0 5px',
};

function CTe({ history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroData,
		setFiltroData,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(1);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(CTE_OPTIONS_FILTRO_AVANCADO);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [mostrarLoading, setMostrarLoading] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [valorCard, setValorCard] = useState('');
	const [cards, setCards] = useState({
		ctesRejeitados: {
			valor: 0,
			quantidade: 0,
		},
		ctesCancelados: {
			valor: 0,
			quantidade: 0,
		},
		ctesAguardandoAutorizacao: {
			valor: 0,
			quantidade: 0,
		},
		ctesNaoEnviados: {
			valor: 0,
			quantidade: 0,
		},
		ctesTransmitidos: {
			valor: 0,
			quantidade: 0,
		},
	});

	const menu = useRef(null);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.EXCLUIR);
	const itensMenu = montarItensMenu();
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'numero';

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			fetch();
		}
	});

	useEffect(() => {
		fetch();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('input-search-ctes')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [interval, filtroAvancado, rows, page, sortOrder, sortFieldLocal, selectedCard]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		const filtro = getFilter();
		const url = construirUrl(
			`${services.GESTOR}/v1/ctes/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readTotalizadoresCard(interval, ({ data: totais }) => {
			const newCards = cards;

			totais &&
				totais.forEach((element) => {
					if (element.status === CTE_SITUACAO.NAO_ENVIADO) {
						newCards.ctesNaoEnviados = {
							quantidade: element.quantidade || 0,
							valor: element.valor || 0,
						};
					} else if (element.status === CTE_SITUACAO.AGUARDANDO_AUTORIZACAO) {
						newCards.ctesAguardandoAutorizacao = {
							quantidade: element.quantidade || 0,
							valor: element.valor || 0,
						};
					} else if (element.status === CTE_SITUACAO.TRANSMITIDO) {
						newCards.ctesTransmitidos = {
							quantidade: element.quantidade || 0,
							valor: element.valor || 0,
						};
					} else if (element.status === CTE_SITUACAO.REJEITADO) {
						newCards.ctesRejeitados = {
							quantidade: element.quantidade || 0,
							valor: element.valor || 0,
						};
					} else if (element.status === CTE_SITUACAO.CANCELADO) {
						newCards.ctesCancelados = {
							quantidade: element.quantidade || 0,
							valor: element.valor || 0,
						};
					}
				});
			setCards(newCards);
		});

		await getCTes(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.totalElements);
			setFirstRender(false);
		});
	}

	function getFilter() {
		const replacedValorPesquisa = replaceCaracteresEspeciais(valorPesquisa);
		let filter = String('?query=(')
			.concat(`numero=contains="*${replacedValorPesquisa}*",`)
			.concat(`serie=contains="*${replacedValorPesquisa}*",`)
			.concat(`emissao=contains="*${replacedValorPesquisa}*",`)
			.concat(`situacao=contains="*${replacedValorPesquisa}*",`)
			.concat(`tipo=contains="*${replacedValorPesquisa}*",`)
			.concat(`nomeTomador=contains="*${replacedValorPesquisa}*")`);

		if (filtroData) {
			filter = filter.concat(`;(${filtroData})`);
		} else {
			filter = filter.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		if (valorCard) {
			filter = filter.concat(`;(${valorCard})`);
		}

		return filter;
	}

	function baixarArquivosXml() {
		setMostrarLoading(true);
		asyncGetDownloadXmls(
			buscarFiltroParaDownloadXmls(),
			({ data }) => {
				baixarArquivo(
					data,
					`ArquivosXmlCTe_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(interval.dataFinal, 'dd-MM-yyyy')}.zip`
				);
				setMostrarLoading(false);
			},
			() => {
				setMostrarLoading(false);
			}
		);
	}

	function buscarFiltroParaDownloadXmls() {
		let filtro = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`serie=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`chave=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`protocolo=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtro = filtro.concat(`;(${filtroData})`);
		} else {
			filtro = filtro.concat(`;(${currentMonth()})`);
		}
		return filtro;
	}

	function handleChangeInterval(interval) {
		setPage(0);
		setInterval(interval);
		setFiltroData(
			`criadoEm>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};criadoEm<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	async function fetchFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			getSetores(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Setor',
						name: 'setorId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
			getUsuarios(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Usuário',
						name: 'usuarioId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function renderEmissao(row) {
		const label = format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderTipo(row) {
		if (row.tipo === CTE_TIPO.NORMAL) {
			return <span>Normal</span>;
		} else if (row.tipo === CTE_TIPO.COMPLEMENTO_VALORES) {
			return <span>Complemento</span>;
		} else if (row.tipo === CTE_TIPO.SUBSTITUICAO) {
			return <span>Substituição</span>;
		}
		return <span>{row.tipo}</span>;
	}

	function renderTomador(row) {
		return <span>{row.nomeTomador}</span>;
	}

	function renderValor(row) {
		return <span>{formatarMonetario(row.valor)}</span>;
	}

	function renderFieldSituacao(row) {
		const styleBadge = {
			borderRadius: '20px',
			fontWeight: 'bold',
			fontSize: '13px',
			display: 'flex',
			height: '1.5rem',
			width: '7rem',
			alignItems: 'center',
			justifyContent: 'center',
		};
		const situacaoMDFe = CTE_INFORMACOES_SITUACAO[row.situacao];

		return Badge(situacaoMDFe?.strongColor, situacaoMDFe?.lightColor, situacaoMDFe?.name, styleBadge);
	}

	function renderOptions(row) {
		let disableBtnOpcoes = false;
		let titleBtnOpcoes = 'Opções';

		if (row.status === CTE_SITUACAO.AGUARDANDO_AUTORIZACAO) {
			titleBtnOpcoes = 'Aguarde até que o documento fiscal seja transmitido';
			disableBtnOpcoes = false;
		}

		if (row.status === CTE_SITUACAO.REJEITADO) {
			titleBtnOpcoes = 'Documento fiscal rejeitado';
			disableBtnOpcoes = false;
		}
		if (row.status === CTE_SITUACAO.NAO_ENVIADO) {
			titleBtnOpcoes = 'Documento fiscal não transmitido';
			disableBtnOpcoes = false;
		}

		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable
					onClick={() => handleClickExcluir(row)}
					podeExcluir={podeExcluir}
					disabled={isSituacaoFinalCTe(row)}
				/>
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					title={titleBtnOpcoes}
					disabled={disableBtnOpcoes}
					aria-controls="popup_menu"
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.current.toggle(event);
					}}
				/>
			</div>
		);
	}

	function isSituacaoFinalCTe(row) {
		return [CTE_SITUACAO.AGUARDANDO_AUTORIZACAO, CTE_SITUACAO.CANCELADO, CTE_SITUACAO.TRANSMITIDO].includes(
			row.situacao
		);
	}

	function montarItensMenu() {
		const itens = [];

		if (registroSelecionado) {
			itens.push({
				label: 'Imprimir DACT-e',
				icon: 'fa fa-print',
				command: () => handleClickImprimir(),
				disabled: ![CTE_SITUACAO.CANCELADO, CTE_SITUACAO.TRANSMITIDO].includes(registroSelecionado.situacao),
			});
			itens.push({
				label: 'Baixar XML do CT-e',
				icon: 'fa fa-download',
				command: () => handleClickBaixarXml(),
				disabled: ![CTE_SITUACAO.CANCELADO, CTE_SITUACAO.TRANSMITIDO].includes(registroSelecionado.situacao),
			});
		}

		return itens;
	}

	function handleClickBaixarXml() {
		getXmlCTe(registroSelecionado.id, ({ data: file }) => {
			baixarArquivo(file, registroSelecionado.chave, 'application/xml');
		});
	}

	async function handleClickImprimir() {
		await getImprimirCTe(registroSelecionado.id, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `CT-e Nº ${registroSelecionado.numero}`;
					}, 250);
				};
			}
		});
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteCTe(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Conhecimento de transporte eletrônico (CT-e)">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo CT-e"
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
						style={{ height: '32px' }}
					/>
					<Button
						label="Download XMLs"
						className="step-listagem-novo p-button-primary"
						icon="fa fa-file-archive-o"
						title="Download dos arquivos XMLs dos CT-es filtrados"
						style={{ margin: '5px' }}
						onClick={() => baixarArquivosXml()}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} />
						</span>
						<InputSearch
							id="input-search-ctes"
							className="step-listagem-input-search"
							onPesquisar={fetchCallback}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={filtroAvancadoOptions}
								onPesquisarClick={fetchFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</span>
					</Grid>
					<CTeCardsListagem
						cards={cards}
						selectedCard={selectedCard}
						setSelectedCard={setSelectedCard}
						setValorCard={setValorCard}
					/>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Menu model={itensMenu} popup style={{ width: '200px' }} ref={(elemento) => (menu.current = elemento)} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="numero"
							header="Série/N°"
							body={(row) => `${row.serie ?? '-'} / ${row.numero ?? '-'}`}
							style={{ width: '120px' }}
							sortable
						/>
						<Column field="emissao" header="Emissão" body={renderEmissao} style={{ width: '120px' }} sortable />
						<Column field="tipo" header="Tipo" body={renderTipo} style={{ width: '140px' }} sortable />
						<Column field="nomeTomador" header="Tomador" body={renderTomador} sortable />
						<Column
							header="Situação"
							field="situacao"
							sortable
							body={renderFieldSituacao}
							style={{ color: '#333333', width: '160px' }}
						/>
						<Column field="valor" header="Valor" body={renderValor} style={{ width: '200px' }} sortable />
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
		</>
	);
}

export { CTe };
