import { useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Dropdown, Grid, If } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { CTE_TIPO_DOCUMENTOS, CTE_TIPO_DOCUMENTOS_OPTIONS } from 'views/transporte/CTe/Form/Util/constantes';
import {
	converterDadosDocumentoFiscalEletronicoParaForm,
	converterDadosNotaFiscalParaForm,
	converterDadosOutrosDocumentosParaForm,
} from '../../Utils/converterDocumentosFiscais';
import { ModalDadosNFe } from './components/ModalDadosNFe';
import { ModalDadosNotaFiscal } from './components/ModalDadosNotaFiscal';
import { ModalDadosOutrosDocumentos } from './components/ModalDadosOutrosDocumentos';
import { TabelaNfe } from './components/TabelaNfe';
import { TabelaNotaFiscal } from './components/TabelaNotaFiscal';
import { TabelaOutrosDocumentos } from './components/TabelaOutrosDocumentos';

function TabDocumentosFiscais() {
	const {
		visibleModalDadosNFe,
		setVisibleModalDadosNFe,
		visibleModalDadosNotaFiscal,
		setVisibleModalDadosNotaFiscal,
		visibleModalDadosOutrosDocumentos,
		setVisibleModalDadosOutrosDocumentos,
		isSituacaoFinalCTe,
	} = useContextCTe();
	const { values, setFieldValue, setValues } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	function handleSalvarDadosNfe(event) {
		const nfes = values.documento?.nfes || [];
		const newItem = converterDadosDocumentoFiscalEletronicoParaForm(event);

		let updatedNfes;
		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;

			updatedNfes = nfes.map((nfe) => {
				const nfeId = nfe.id || nfe.tempId;
				if (nfeId === registroId) {
					return newItem;
				}
				return nfe;
			});
		} else {
			const chaveAcessoJaExiste = nfes.some((nfe) => nfe.chaveAcesso === newItem.chaveAcesso);

			if (chaveAcessoJaExiste) {
				return;
			}

			updatedNfes = [...nfes, newItem];
		}

		setFieldValue('documento.nfes', updatedNfes);
		if (registroSelecionado) {
			setRegistroSelecionado(null);
		}
	}

	function handleCloseDadosNfe() {
		setRegistroSelecionado(null);
		setVisibleModalDadosNFe(false);
	}

	function handleSalvarDadosNotaFiscal(event) {
		const notasFiscais = values.documento?.notasFiscais || [];
		const newItem = converterDadosNotaFiscalParaForm(event);
		let updatedNotasFiscais;

		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedNotasFiscais = notasFiscais.map((notaFiscal) => {
				const nfId = notaFiscal.id || notaFiscal.tempId;
				if (nfId === registroId) {
					return newItem;
				}
				return notaFiscal;
			});
		} else {
			updatedNotasFiscais = [...notasFiscais, newItem];
		}

		setFieldValue('documento.notasFiscais', updatedNotasFiscais);
		setRegistroSelecionado(null);
		setVisibleModalDadosNotaFiscal(false);
	}

	function handleCloseDadosNotaFiscal() {
		setRegistroSelecionado(null);
		setVisibleModalDadosNotaFiscal(false);
	}

	function handleSalvarDadosOutrosDocumentos(event) {
		const outrosDocumentos = values.documento?.outros || [];
		const newItem = converterDadosOutrosDocumentosParaForm(event);
		let updatedOutrosDocumentos;

		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedOutrosDocumentos = outrosDocumentos.map((documento) => {
				const docsId = documento.id || documento.tempId;
				if (docsId === registroId) {
					return newItem;
				}
				return documento;
			});
		} else {
			updatedOutrosDocumentos = [...outrosDocumentos, newItem];
		}

		setFieldValue('documento.outros', updatedOutrosDocumentos);
		setRegistroSelecionado(null);
		setVisibleModalDadosOutrosDocumentos(false);
	}

	function handleCloseDadosOutrosDocumentos() {
		setRegistroSelecionado(null);
		setVisibleModalDadosOutrosDocumentos(false);
	}

	function handleChangeTipoDocumento(value) {
		switch (value) {
			case CTE_TIPO_DOCUMENTOS.NFE: {
				setValues({
					...values,
					documento: {
						...values.documento,
						tipoDocumento: value,
						notasFiscais: [],
						outros: [],
					},
				});
				break;
			}
			case CTE_TIPO_DOCUMENTOS.NOTA_FISCAL: {
				setValues({
					...values,
					documento: {
						...values.documento,
						tipoDocumento: value,
						nfes: [],
						outros: [],
					},
				});
				break;
			}
			case CTE_TIPO_DOCUMENTOS.OUTROS: {
				setValues({
					...values,
					documento: {
						...values.documento,
						tipoDocumento: value,
						nfes: [],
						notasFiscais: [],
					},
				});
				break;
			}
			default:
				break;
		}
	}

	return (
		<>
			<Grid style={{ padding: '0px' }}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					name="tipoDocumento"
					label="Tipo do documento"
					component={Dropdown}
					showClear={false}
					options={CTE_TIPO_DOCUMENTOS_OPTIONS}
					value={values.documento.tipoDocumento}
					onChange={({ value }) => handleChangeTipoDocumento(value)}
					disabled={isSituacaoFinalCTe}
				/>
			</Grid>
			<If test={values.documento.tipoDocumento === CTE_TIPO_DOCUMENTOS.NFE}>
				<TabelaNfe {...{ setRegistroSelecionado }} />
			</If>
			<If test={values.documento.tipoDocumento === CTE_TIPO_DOCUMENTOS.NOTA_FISCAL}>
				<TabelaNotaFiscal {...{ setRegistroSelecionado }} />
			</If>
			<If test={values.documento.tipoDocumento === CTE_TIPO_DOCUMENTOS.OUTROS}>
				<TabelaOutrosDocumentos {...{ setRegistroSelecionado }} />
			</If>

			<If test={visibleModalDadosNFe}>
				<ModalDadosNFe onHide={handleCloseDadosNfe} onSave={handleSalvarDadosNfe} {...{ registroSelecionado }} />
			</If>
			<If test={visibleModalDadosNotaFiscal}>
				<ModalDadosNotaFiscal
					onHide={handleCloseDadosNotaFiscal}
					onSave={handleSalvarDadosNotaFiscal}
					{...{ registroSelecionado }}
				/>
			</If>
			<If test={visibleModalDadosOutrosDocumentos}>
				<ModalDadosOutrosDocumentos
					onHide={handleCloseDadosOutrosDocumentos}
					onSave={handleSalvarDadosOutrosDocumentos}
					{...{ registroSelecionado }}
				/>
			</If>
		</>
	);
}

export { TabDocumentosFiscais };
