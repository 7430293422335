import { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	Modal,
	NenhumRegistroEncontrado,
	Paginacao,
} from 'components';
import {
	buscarDadosLoginLocalStorage,
	construirUrl,
	formatarMonetario,
	formatos,
	inserirMascara,
	services,
} from 'Common';

import { converterCTeNFesParaForm } from 'views/transporte/CTe/Form/Util/CTeConverter';
import { getCTeImportarNFes } from 'views/transporte/CTe/Requests';
import { ActionButtons } from './components/ActionButtons';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function ModalImportacaoNFe({ cte, visible, onHide, importarDadosNFeParaCTe }) {
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [totalElements, setTotalElements] = useState(0);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [registros, setRegistros] = useState(null);
	const [registrosSelecionados, setRegistrosSelecionados] = useState([]);
	const [valorPesquisa, setValorPesquisa] = useState('');

	const { filialConectada } = buscarDadosLoginLocalStorage();

	const pesquisarCallback = useCallback(async () => {
		const filtro = buscarFiltro();
		const url = construirUrl(
			`${services.GESTOR}/v1/ctes/listar_nfes_importacao`,
			filtro || '?',
			rows,
			page,
			`numero,desc`
		);

		getCTeImportarNFes(url, async ({ data: notas }) => {
			const registros = await converterCTeNFesParaForm(notas?.content);
			const registrosFiltrados = await registros.filter((registro) => {
				const nfes = cte?.documento?.nfes || [];

				return !nfes?.some((nfe) => nfe.chaveAcesso === registro.chave);
			});

			setRegistros(registrosFiltrados);
			setTotalElements(notas.totalElements);
		});
	});

	useEffect(() => {
		pesquisarCallback();
		setIsFirstRender(false);

		setTimeout(() => {
			document.getElementById('input-search-cte-importar-nfe')?.focus();
		}, 200);
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			pesquisarCallback();
		}
	}, [rows, page]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	function pesquisar() {
		pesquisarCallback();
	}

	function buscarFiltro() {
		return `?filtro=${valorPesquisa}`;
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderNumeroField(row) {
		return (
			<div>
				<span>{row.serie}</span>
				<span>{' / '}</span>
				<span style={{ fontWeight: 'bolder' }}>{row.numero || '-'}</span>
			</div>
		);
	}

	function renderCpfCnpj(emitente) {
		if (emitente?.cnpj) {
			return inserirMascara(emitente?.cnpj, formatos.CNPJ);
		} else if (emitente?.cpf) {
			return inserirMascara(emitente?.cpf, formatos.CPF);
		}

		return null;
	}

	function renderEmissaoField(row) {
		return <span>{row ? format(parseISO(row.emissao), 'dd/MM/yyyy') : ' - '}</span>;
	}

	return (
		<Modal
			header="Importar notas para o CT-e"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons
							cte={cte}
							onHide={onHide}
							registrosSelecionados={registrosSelecionados}
							setRegistrosSelecionados={setRegistrosSelecionados}
							importarDadosNFeParaCTe={importarDadosNFeParaCTe}
						/>
					</Col>
				</FormActions>
				<FormContent>
					<Grid style={{ justifyContent: 'center', marginTop: '16px' }}>
						<InputSearch
							sm="12"
							md="12"
							lg="6"
							xl="6"
							id="input-search-cte-importar-nfe"
							name="pesquisa"
							onPesquisar={() => pesquisar()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
					</Grid>
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						sortField="numero"
						sortOrder={-1}
						value={registros}
						style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', maxHeight: '382px' }}
						emptyMessage={<NenhumRegistroEncontrado message="Informe um estado para buscar as notas" />}
						selection={registrosSelecionados}
						onSelectionChange={(e) => setRegistrosSelecionados(e.value)}
						dataKey="id"
					>
						<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
						<Column
							header="Série/Número"
							field="numero"
							body={renderNumeroField}
							style={{ color: '#333333', width: '8%' }}
						/>
						<Column
							field="emissao"
							header="Emissão"
							body={(row) => renderEmissaoField(row)}
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
						<Column
							field="emitenteCnpj"
							header="CPF/CNPJ da filial"
							body={(row) => renderCpfCnpj(row.emitente)}
							hidden={!filialConectada?.parametrosTransportes?.cteImportarNfeTodasFiliais}
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
						<Column
							field="destinatario"
							header="Cliente"
							body={(row) => `${row.destinatario?.pessoa?.codigo} - ${row.destinatario?.pessoa?.nome}`}
							style={{
								...styleColumn,
							}}
						/>
						<Column
							field="municipioDestino"
							header="Município destino"
							body={(row) => `${row.destinatario?.municipio?.nome} - ${row.destinatario?.municipio?.estado?.sigla}`}
							style={{
								...styleColumn,
							}}
						/>
						<Column
							field="valorNfe"
							header="Valor"
							body={(row) => formatarMonetario(row.totais?.valorNfe)}
							style={{
								...styleColumn,
								width: '8%',
							}}
						/>
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalImportacaoNFe };
