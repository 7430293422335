import { buscarDadosLoginLocalStorage } from 'Common';
import { If } from 'components';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useContextCTe } from 'views/transporte/CTe/Context';
import { ErrorsTransmissao } from './components/ErrorsTransmissao';
import { ErrorsParametrosFiscais } from './components/ErrorsParametrosFiscais';
import { ErrorsValidacoes } from './components/ErrorsValidacoes';

function ErrorsMessage() {
	const {
		setShowMessageParametrosFiscais,
		showMessageParametrosFiscais,
		errorsTransmissao,
		showMessageTransmissao,
		setShowMessageTransmissao,
		showMessageValidacoes,
		setShowMessageValidacoes,
	} = useContextCTe();
	const { values, errors } = useFormikContext();

	const [mensagemParametroFiscal, setMensagemParametroFiscal] = useState(null);

	const dadosLocais = buscarDadosLoginLocalStorage();

	useEffect(() => {
		handleParametrosFiscais();
		handleErrorsTransmissao();
		handleErrorsValidacoes();
	}, []);

	useEffect(() => {
		handleParametrosFiscais();
		handleErrorsTransmissao();
	}, [errorsTransmissao, values.errors]);

	useEffect(() => {
		handleErrorsValidacoes();
	}, [errors]);

	function handleParametrosFiscais() {
		if (dadosLocais?.filialConectada?.parametrosFiscalCTe) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				setMensagemParametroFiscal('Filial sem certificado digital configurado para transmissão de CT-e.');
				setShowMessageParametrosFiscais(true);
			} else if (
				!dadosLocais.filialConectada.parametrosFiscalCTe?.serie ||
				!dadosLocais.filialConectada.parametrosFiscalCTe?.numeroInicial
			) {
				setMensagemParametroFiscal('Número inicial ou série inválidos para transmissão de CT-e.');
				setShowMessageParametrosFiscais(true);
			}
		}
	}

	function handleErrorsTransmissao() {
		if (Object.keys(errorsTransmissao)?.length > 0) {
			setShowMessageTransmissao(true);
		}

		if (values?.errors && Object.keys(values?.errors)?.length > 0) {
			setShowMessageTransmissao(true);
		}

		if (!Object.keys(errorsTransmissao)?.length > 0 && !Object.keys(values?.errors)?.length > 0) {
			setShowMessageTransmissao(false);
		}
	}

	function handleErrorsValidacoes() {
		if (errors?.errorsParaMessage && Object.keys(errors.errorsParaMessage)?.length > 0) {
			setShowMessageValidacoes(true);
		} else {
			setShowMessageValidacoes(false);
		}
	}

	return (
		<>
			<If test={showMessageTransmissao}>
				<ErrorsTransmissao />
			</If>
			<If test={showMessageParametrosFiscais}>
				<ErrorsParametrosFiscais mensagemParametroFiscal={mensagemParametroFiscal} />
			</If>
			<If test={showMessageValidacoes}>
				<ErrorsValidacoes />
			</If>
		</>
	);
}

export { ErrorsMessage };
