import {
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	gerarUUID,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import { copiarObjeto, setNullForIdProperties } from 'Common/Array';
import {
	confirm,
	Form,
	FormActions,
	FormContent,
	Grid,
	INITIAL_VALUE_PAGAMENTO,
	Prompt,
	Tutorial,
	tutorialStepsOrdemDeServico,
	useGenerateParcelas,
	useRecalculatePagamentos,
	validatePagamentos,
} from 'components';
import { isBefore, isValid, parseISO } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { NOVO_SERVICO } from 'components/Servicos/Utils';
import { moduloUtilizado, NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { validateServicos } from 'components/Servicos/Utils/validations';
import { validateProdutos } from 'components/Produtos/Util/validations';
import { useContextOrdemServico } from '../Context';
import {
	asyncBuscarTabelaPreco,
	asyncGetCategoria,
	asyncGetCondicaoPagamentoFavorita,
	asyncGetConta,
	asyncGetFormaPagamentoDinheiro,
	asyncGetOperacoesFiscais,
	asyncGetOrdemServicoById,
	asyncGetVendedor,
} from '../Requests';
import { COLORS, INITIAL_VALUE, ORDEM_SERVICO_ORCAMENTO_SITUACAO, SITUACAO_ORDEM_SERVICO } from '../Util/constantes';
import { montarCalcularImpostosServicoOS, recalcularTotais } from '../Util/funtions';
import { converterOrdemServicoParaFormulario, converterValueParaSelect } from '../Util/ordemServicoConverterFormulario';
import './Styles/index.css';
import { ActionButtons } from './components/ActionButtons';
import { CabecalhoOrdemServico } from './components/CabecalhoOrdemServico';
import { HeaderForm } from './components/HeaderForm';
import { NumeroAndSituacao } from './components/NumeroAndSituacao';
import { OrdemServicoMessages } from './components/OrdemServicoMessages';
import { TabItens } from './components/TabItens';
import { TabViewAdicionais } from './components/TabViewAdicionais';
import { TotalizadoresAdicionais } from './components/TotalizadoresAdicionais';

function OrdemServicoFormImpl(props) {
	const { isMobile, isTablet, isLessHd, history, match, location } = props;
	const { values, dirty, errors, submitCount, resetForm, setFieldValue, setValues } = useFormikContext();

	const {
		setSituacaoOS,
		setColor,
		setDisabledFields,
		setClienteUsaPrecoAtacado,
		totais,
		setTotais,
		setMunicipioId,
		setFunctionsForm,
		setUfId,
		setHasRejeicaoNFSe,
		setHasRejeicaoNFe,
		atualizarTotais,
		setAtualizarTotais,
		setTabParcelaSelecionada,
		deveExibirTutorial,
		setDeveExibirTutorial,
		setTutorialVisible,
		tutorialVisible,
		podeEditar,
		setHasNFSeGerada,
		setHasNFeGerada,
		setHasNFSeConfigurada,
		setHasNFeConfigurada,
		setTabAdicionaisIndex,
		setFavoritos,
		favoritos,
	} = useContextOrdemServico();

	const [isFirstRender, setIsFirstRender] = useState(true);
	const credencial = buscarDadosLoginLocalStorage();
	const utilizaTabelaPreco = credencial?.filialConectada?.parametrosVendas?.utilizaTabelaPreco;
	const utilizaTabelaPrecoServico = credencial?.filialConectada?.parametrosVendas?.utilizaTabelaPrecoParaServico;

	const idUrl = match.params?.id;

	useEffect(async () => {
		const ordemServicoId = location.state?.ordemServicoId;

		if (validarUUID(ordemServicoId)) {
			await duplicateOrdemServico(ordemServicoId);
		} else if (validarUUID(idUrl)) {
			await fetchOrdemServico(idUrl);
		} else {
			await resetFormWithFavoritos();
		}
		setIsFirstRender(false);
		setHasNFSeConfigurada(filialHasNFSeConfigurada());
		setHasNFeConfigurada(filialHasNFeConfigurada());

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_ORDEM_SERVICO, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('OrdemServicoSingleSelectCliente')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (!isFirstRender) {
			setMunicipioAndUfId(values);
		}
	}, [values.informacoesComplementares.servico.municipio]);

	useEffect(() => {
		if (atualizarTotais) {
			const newTotais = recalcularTotais(values, totais);
			setTotais(newTotais);
			setAtualizarTotais(false);
		}
	}, [atualizarTotais]);

	useEffect(() => {
		montarERecalcularTodosOsServicos();
	}, [values?.informacoesComplementares?.servico?.municipio]);

	useEffect(() => {
		if (Object.keys(errors)?.length > 0) {
			if (
				errors?.informacoesComplementares?.vendedor ||
				errors?.informacoesComplementares?.setor ||
				errors?.informacoesComplementares?.municipio
			) {
				setTabAdicionaisIndex(2);
			}
			if (errors?.pagamentos?.length > 0) {
				setTabAdicionaisIndex(1);
			}
		}
	}, [errors, submitCount]);

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: values.valorTotal,
		dataBaseParcela: values.dataAbertura,
		favoritos,
	});

	useRecalculatePagamentos({ valorTotal: totais.valorTotal, dataBaseParcela: values.dataAbertura, favoritos }, [
		totais.valorTotal,
	]);

	async function montarERecalcularTodosOsServicos() {
		if (dirty) {
			const { servicos } = values;
			const municipioId = values?.informacoesComplementares?.servico?.municipio?.value;
			await values.servicos.forEach(async (_, index) => {
				if (servicos[index].servico) {
					servicos[index] = await montarCalcularImpostosServicoOS(municipioId, servicos[index]);
				}
				if (index === servicos.length - 1) {
					setFieldValue('servicos', servicos);

					setTimeout(() => {
						setAtualizarTotais(true);
					}, 250);
				}
			});
		}
	}

	async function fetchOrdemServico(id) {
		await asyncGetOrdemServicoById(id, async ({ data: ordemServico }) => {
			const convertedData = converterOrdemServicoParaFormulario(ordemServico);

			addEmptyServicoProduto(convertedData);

			resetForm({ values: convertedData });
			updateStatesContext(convertedData);
			setDisabledFields(!podeEditar || convertedData.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE);
			fetchFavoritosPagamentos();
		});
	}

	function addEmptyServicoProduto(convertedData) {
		function addEmptyServico() {
			convertedData.servicos.push({
				...copiarObjeto(NOVO_SERVICO),
				item: convertedData.servicos.length + 1,
				operacaoFiscal: convertedData.operacaoFiscal ?? null,
			});
		}

		function addEmptyProduto() {
			convertedData.produtos.push({
				...copiarObjeto(NOVO_PRODUTO_VENDA),
				item: convertedData.produtos.length + 1,
				operacaoFiscal: convertedData.operacaoFiscal ?? null,
			});
		}

		if (convertedData.servicos.length === 0) {
			addEmptyServico();
		}
		if (convertedData.produtos.length === 0) {
			addEmptyProduto();
		}
	}

	function updateStatesContext(values) {
		setFunctionsForm(startFunctionsForm());
		setColor(
			COLORS[values.situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO ? 'EXPIRADO' : values.situacao]
		);
		setSituacaoOS(SITUACAO_ORDEM_SERVICO[values.situacao]);
		setDisabledFields(values.situacao !== SITUACAO_ORDEM_SERVICO.PENDENTE);
		setClienteUsaPrecoAtacado(values.cliente?.registro?.configPrecoPraticado === 'PRECO_ATACADO');
		setTotais({
			valorTotal: values.valorTotal,
			totalServicos: values.totalServicos,
			totalProdutos: values.totalProdutos,
		});
		setMunicipioAndUfId(values);
		setHasRejeicaoNFSe(Boolean(values.nfse?.rejeicao));
		setHasRejeicaoNFe(Boolean(values.nfe?.rejeicao));
		setHasNFSeGerada(Boolean(values?.nfse?.id));
		setHasNFeGerada(Boolean(values?.nfe?.id));
		setTabParcelaSelecionada(values.situacao === SITUACAO_ORDEM_SERVICO.FINALIZADO ? 1 : 0);
	}

	function startFunctionsForm() {
		return {
			handleChangeInput: (event) => {
				setFieldValue(event.target?.name, event.target?.value);
			},
			handleChangeSelect: (field, value) => {
				setFieldValue(field, {
					value: value?.value,
					label: value?.label,
					registro: value?.registro,
				});
			},
			updateStatesContext: (values) => updateStatesContext(values),
		};
	}

	function setMunicipioAndUfId(values) {
		const { municipio } = values.informacoesComplementares.servico;
		setMunicipioId(municipio?.value);
		setUfId(municipio?.registro?.estado?.id ?? municipio?.registro?.ufId);
	}

	async function resetFormWithFavoritos(data = values) {
		const favoritos = {
			operacaoFiscal: null,
			informacoesComplementares: {
				...data.informacoesComplementares,
				vendedor: null,
				setor: converterValueParaSelect(getSetor()),
			},
			pagamentos: [],
		};

		await asyncGetVendedor(({ data: response }) => {
			const indexFavorito =
				Math.max(
					response.content?.findIndex((vendedor) => vendedor.favorito === true),
					0
				) ?? 0;
			const vendedor = response.content[indexFavorito];

			const labelVendedor = vendedor ? `${vendedor.codigo} - ${vendedor.nome}` : null;
			favoritos.informacoesComplementares.vendedor = converterValueParaSelect(vendedor, labelVendedor);
		});

		const promises = [
			asyncGetOperacoesFiscais({ favoritaSaida: true }, ({ data: response }) => {
				const operacaoFiscal = response.content[0];
				favoritos.operacaoFiscal = converterValueParaSelect(
					operacaoFiscal,
					`${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`
				);
			}),
			buscarTabelaPreco(
				{ vendedorId: favoritos.informacoesComplementares?.vendedor?.value },
				({ data: tabelaPrecoApi }) => {
					const tabelaPrecoGet = {
						label: `${tabelaPrecoApi.codigo} - ${tabelaPrecoApi.nome}`,
						registro: tabelaPrecoApi,
						value: tabelaPrecoApi.id,
					};
					favoritos.informacoesComplementares = {
						...favoritos.informacoesComplementares,
						tabelaPreco: tabelaPrecoGet,
					};
				}
			),
			fetchFavoritosPagamentos((favoritosPagamentos) => {
				favoritos.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			resetForm({ values: { ...data, ...favoritos } });
			updateStatesContext(data);
		});
	}

	async function fetchFavoritosPagamentos(callback) {
		const pagamentos = [
			{
				...copiarObjeto(INITIAL_VALUE_PAGAMENTO),
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categoria: null,
				condicaoPagamento: null,
				formaPagamento: null,
			},
		];
		const favoritosToContextState = {};

		const promises = [
			asyncGetConta(({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritosToContextState.conta = contaFavorita;
			}),
			asyncGetCategoria(({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritosToContextState.categoria = categoria;
			}),
			asyncGetCondicaoPagamentoFavorita(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritosToContextState.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritosToContextState.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritosToContextState.conta = contaConvertida;
					}

					if (formaPagamento.categoriaReceita) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoriaReceita);
						pagamentos[0].categoria = categoriaConvertida;
						favoritosToContextState.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritosToContextState,
						dataBaseParcela: values.dataAbertura,
						valorPagamento: 0,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritos(favoritosToContextState);

			if (typeof callback === 'function') {
				callback(pagamentos);
			}
		});
	}

	function getSetor() {
		return credencial.setores[0];
	}

	async function duplicateOrdemServico(id) {
		await asyncGetOrdemServicoById(id, ({ data: ordemServico }) => {
			atualizarUrl(history, '/ordem_servico/cadastro', null, metodosAtualizarUrl.POP);

			let convertedData = converterOrdemServicoParaFormulario(ordemServico);
			addEmptyServicoProduto(convertedData);
			convertedData = clearOrdemServico(convertedData);

			setValues(convertedData);
			updateStatesContext(convertedData);
			fetchFavoritosPagamentos();
		});
	}

	function clearOrdemServico(values) {
		setNullForIdProperties(values, ['registro', 'cliente']);
		return {
			...values,
			numero: null,
			situacao: SITUACAO_ORDEM_SERVICO.PENDENTE,
			dataFechamento: null,
			horaFechamento: null,
		};
	}

	function filialHasNFSeConfigurada() {
		if (credencial?.filialConectada?.parametrosFiscalNFSe) {
			if (
				!credencial?.filialConectada?.parametrosFiscalCertificado ||
				!credencial.filialConectada.parametrosFiscalNFSe.serie
			) {
				return false;
			}
		}
		return true;
	}

	function filialHasNFeConfigurada() {
		if (credencial?.filialConectada?.parametrosFiscalNFe) {
			if (
				!credencial?.filialConectada?.parametrosFiscalCertificado ||
				!credencial.filialConectada.parametrosFiscalNFe.serieNfe
			) {
				return false;
			}
		}
		return true;
	}

	function atualizarSituacaoOrcamentoOrdemServico(situacaoOrcamento) {
		setColor(COLORS[situacaoOrcamento === ORDEM_SERVICO_ORCAMENTO_SITUACAO.REJEITADO ? 'EXPIRADO' : values.situacao]);
		resetForm({ values: { ...values, situacaoOrcamento: situacaoOrcamento } });
	}

	async function buscarTabelaPreco(dados, onSuccess) {
		function onSuccessWrapper(request) {
			const arrayServicos = values.servicos.filter((item) => item.servico);
			const arrayProdutos = values.produtos.filter((item) => item.produto);
			if (
				!isFirstRender &&
				values.informacoesComplementares?.tabelaPreco &&
				(arrayServicos?.length > 0 || arrayProdutos?.length > 0) &&
				values.informacoesComplementares?.tabelaPreco?.value !== request?.data?.id
			) {
				let texto = '';
				if (arrayProdutos?.length > 0 && arrayServicos?.length > 0) {
					texto = 'produtos e serviços';
				} else if (arrayProdutos?.length > 0) {
					texto = 'produtos';
				} else if (arrayServicos?.length > 0) {
					texto = 'serviços';
				}

				confirm(
					'Confirmação',
					`Nova tabela de preço reconhecida. Ao atualizá-la os ${texto} serão removidos. Deseja atualizar a tabela de preço?`,
					() => {
						setFieldValue('servicos', [{ ...copiarObjeto(NOVO_SERVICO), id: gerarUUID() }]);
						setFieldValue('produtos', [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }]);
						onSuccess(request);
					}
				);
			} else if (typeof onSuccess === 'function') {
				onSuccess(request);
			}
		}

		if (utilizaTabelaPreco || utilizaTabelaPrecoServico) {
			await asyncBuscarTabelaPreco(dados, onSuccessWrapper);
		}
	}

	function buscarMunicipioIdPessoa(pessoa) {
		let municipioId = null;
		if (pessoa) {
			const indexEnderecoFavorito = pessoa.registro?.enderecos?.findIndex((endereco) => endereco.favorito);
			if (indexEnderecoFavorito >= 0) {
				municipioId = pessoa.registro?.enderecos[indexEnderecoFavorito]?.municipio?.id;
			} else if (indexEnderecoFavorito === -1 && pessoa.registro?.enderecos?.length > 0) {
				municipioId = pessoa.registro?.enderecos[0]?.municipio?.id;
			}
		}

		return municipioId;
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsOrdemDeServico}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header={<HeaderForm isMobile={isMobile} />} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						history={history}
						resetFormWithFavoritos={resetFormWithFavoritos}
						duplicateOrdemServico={duplicateOrdemServico}
						setTabParcelaSelecionada={setTabParcelaSelecionada}
						isMobile={isMobile}
						isTablet={isTablet}
						isLessHd={isLessHd}
						atualizarSituacaoOrcamentoOrdemServico={atualizarSituacaoOrcamentoOrdemServico}
						situacaoOrdemServico={values.situacao}
						situacaoOrcamentoOrdemServico={values.situacaoOrcamento}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyBetween verticalAlignCenter style={{ margin: '1rem 0px 0px 0px' }}>
						<OrdemServicoMessages history={history} isFirstRender={isFirstRender} />
						<NumeroAndSituacao isMobile={isMobile} />
						<Grid style={{ width: '100%', marginTop: '0.5rem', flex: 1 }}>
							<CabecalhoOrdemServico
								isMobile={isMobile}
								buscarTabelaPreco={buscarTabelaPreco}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
							<TabItens
								isFirstRender={isFirstRender}
								tutorialVisible={tutorialVisible}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
							<TotalizadoresAdicionais isMobile={isMobile} isTablet={isTablet} />
							<TabViewAdicionais
								isMobile={isMobile}
								isTablet={isTablet}
								utilizaTabelaPreco={utilizaTabelaPreco}
								utilizaTabelaPrecoServico={utilizaTabelaPrecoServico}
								buscarTabelaPreco={buscarTabelaPreco}
								buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
							/>
						</Grid>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const OrdemServicoFormWithFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		if (!INITIAL_VALUE.servicos.length) {
			INITIAL_VALUE.servicos = [copiarObjeto(NOVO_SERVICO)];
		}
		if (!INITIAL_VALUE.produtos.length) {
			INITIAL_VALUE.produtos = [copiarObjeto(NOVO_PRODUTO_VENDA)];
		}
		return INITIAL_VALUE;
	},

	validate(values) {
		const errors = {};
		const errorsServicos = validateServicos(values.servicos, values.informacoesComplementares?.tabelaPreco);
		const errorsProdutos = validateProdutos(
			values.produtos,
			moduloUtilizado.ORDEM_SERVICO,
			values.informacoesComplementares?.tabelaPreco
		);
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.valorTotal);

		if (!values.cliente?.registro) {
			errors.cliente = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.operacaoFiscal?.registro) {
			errors.operacaoFiscal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataAbertura || !isValid(parseISO(values.dataAbertura))) {
			errors.dataAbertura = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataAbertura !== null && !isValid(parseISO(values.dataAbertura))) {
			errors.dataAbertura = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFechamento !== null && !isValid(parseISO(values.dataFechamento))) {
			errors.dataFechamento = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (isBefore(parseISO(values.dataFechamento), parseISO(values.dataAbertura))) {
			errors.dataFechamento = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (!values.informacoesComplementares.setor?.registro) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				setor: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (!values.informacoesComplementares.vendedor?.registro) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				vendedor: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (!values.informacoesComplementares.servico.municipio?.registro) {
			errors.informacoesComplementares = {
				...errors.informacoesComplementares,
				municipio: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (!!values.objetoServico?.registro && values.cliente?.value !== values.objetoServico?.registro?.cliente?.id) {
			errors.objetoServico = 'Cliente informado é diferente do proprietário do objeto.';
		}

		if (errorsServicos.length > 0) {
			errors.servicos = errorsServicos;
		}

		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(OrdemServicoFormImpl);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export const OrdemServicoForm = connect(mapStateToProps)(OrdemServicoFormWithFormik);
