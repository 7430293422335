import { exibirToast, get, post, del, put, services } from 'Common';

export async function readFechamentos(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError, true);
}

export async function readHistorico(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError, true);
}

export async function getFechamento(id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/controladoria/gestao_fechamentos/${id}`, null, onSuccess, onError);
}

export async function createFechamento(registro, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/controladoria/gestao_fechamento`,
		registro,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export async function updateFechamentosEmLote(registros, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/controladoria/gestao_fechamento/alterar_registros_em_lote`,
		registros,
		null,
		exibirToast(onSuccess, 'Registros alterados com sucesso'),
		onError
	);
}

export async function updateFechamentosPorPeriodo(registro, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/controladoria/gestao_fechamento/alterar_registros_por_periodo`,
		registro,
		null,
		exibirToast(onSuccess, 'Registros alterados com sucesso'),
		onError
	);
}

export async function updateFechamento(registro, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/controladoria/gestao_fechamento/${registro.id}`,
		registro,
		null,
		exibirToast(onSuccess, 'Registro atualizado com sucesso'),
		onError
	);
}

export async function deleteFechamento(id, onSuccess, onError) {
    await del(
		`${services.GESTOR}/v1/controladoria/gestao_fechamento/${id}`,
		null,
		exibirToast(onSuccess, 'Registro excluído com sucesso'),
		onError
	);
}
