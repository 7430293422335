import { recursos, permissoes } from '../../Common/Constantes/autorizacao';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../Common/Autenticacao';

export const itensBreadCrumb = [
	{
		valor: 'pessoas',
		descricao: 'Pessoas',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'pessoas/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'produtos',
		descricao:
			buscarDadosLoginLocalStorage().organizacao?.aplicacao === 'DOCS_DIGITAIS' ? 'Produtos' : 'Produtos / serviços',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'produtos/grupos',
		descricao: 'Grupos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'produtos/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'produtos/etiquetas',
		descricao: 'Etiquetas',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'produtos/etiquetas/configuracao',
		descricao: 'Configuração',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'produtos/etiquetas/configuracao/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ETIQUETAS, permissoes.VISUALIZAR),
	},

	{
		valor: 'tributacoes/ncm',
		descricao: 'NCM',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/ncm/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'tributacoes/codigo_servico',
		descricao: 'Código Serviço',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/codigo_servico/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'tributacoes/municipal',
		descricao: 'Tributação municipal ',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/municipal/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'tributacoes/estadual',
		descricao: 'Tributação estadual ',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/estadual/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'tributacoes/federal',
		descricao: 'Tributação federal',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/federal/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'categorias',
		descricao: 'Categorias',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'contas',
		descricao: 'Contas',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'formas_pagamento',
		descricao: 'Formas de pagamentos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.VISUALIZAR),
	},
	{
		valor: 'formas_pagamento/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'condicoes_pagamento',
		descricao: 'Condições de pagamento',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CONDICOES_PAGAMENTO, permissoes.VISUALIZAR),
	},
	{
		valor: 'condicoes_pagamento/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'papeis',
		descricao: 'Grupos de usuários e permissões',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PAPEIS, permissoes.VISUALIZAR),
	},
	{
		valor: 'papeis/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'usuarios',
		descricao: 'Usuários',
		podeAcessar: () => usuarioPossuiPermissao(recursos.USUARIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'usuarios/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'configuracoes_gerais',
		descricao: 'Configurações gerais',
		podeAcessar: () => true,
	},
	{
		valor: 'manifestacao_destinatario',
		descricao: 'Manifestação do destinatário ',
		podeAcessar: () => usuarioPossuiPermissao(recursos.COMPRAS_MANIFESTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'nota_entrada',
		descricao: 'Notas de entrada ',
		podeAcessar: () => usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR),
	},
	{
		valor: 'nota_entrada/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'nota_entrada/importar_xml',
		descricao: 'Cadastro',
	},
	{
		valor: 'nota_entrada/importar_xml_mde',
		descricao: 'Cadastro',
	},
	{
		valor: 'setores',
		descricao: 'Setores',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SETORES, permissoes.VISUALIZAR),
	},
	{
		valor: 'setores/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'tecnicos',
		descricao: 'Técnicos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TECNICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'tecnicos/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'organizacoes',
		descricao: 'Organizações',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ORGANIZACOES, permissoes.VISUALIZAR),
	},
	{
		valor: 'organizacoes/cadastro',
		descricao: 'Cadastro',
	},

	{
		valor: 'minhaconta',
		descricao: 'Minha conta',
		podeAcessar: () => true,
	},
	{
		valor: 'notas_de_versao',
		descricao: 'Notas de versão',
		podeAcessar: () => true,
	},
	{
		valor: 'painel_helpdesk',
		descricao: 'Painel helpdesk',
		podeAcessar: () => ['ADMIN', 'HELPDESK'].includes(buscarDadosLoginLocalStorage().organizacao?.autoridade),
	},
	{
		valor: 'documentos/assinatura',
		descricao: 'Assinatura de documentos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.VISUALIZAR),
	},
	{
		valor: 'documentos/assinatura/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'documentos/assinatura/importar_pedido',
		descricao: 'Cadastro',
	},
	{
		valor: 'documentos/gestao_documentos',
		descricao: 'Gestão de documentos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'documentos/gestao_documentos/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'relatorios_documentos',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.DOCUMENTOS_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'planos',
		descricao: 'Planos e preços',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PLANOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'movimentacoes',
		descricao: 'Movimentações financeiras',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_LANCAMENTOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'contas_receber',
		descricao: 'Contas a receber',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
	},
	{
		valor: 'contas_pagar',
		descricao: 'Contas a pagar',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR),
	},
	{
		valor: 'relatorios_financas',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'relatorios_vendas',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'relatorios_servicos',
		descricao: 'Relatórios',
		podeAcessar: () =>
			usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR) ||
			usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR),
	},
	{
		valor: 'relatorios_estoque',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ESTOQUE_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'boletos',
		descricao: 'Boletos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
	},
	{
		valor: 'pix_cobranca',
		descricao: 'Pix cobrança',
		podeAcessar: () => usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR),
	},
	{
		valor: 'pedido_venda',
		descricao: 'Pedidos de venda',
		podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'orcamento',
		descricao: 'Orçamentos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'pedido_venda/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'pedido_venda/importar_orcamento',
		descricao: 'Cadastro',
	},
	{
		valor: 'orcamento/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'movimentacoes_estoque',
		descricao: 'Movimentações de estoque',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.VISUALIZAR),
	},
	{
		valor: 'agentes_autorizados',
		descricao: 'Agentes autorizados',
		podeAcessar: () => usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'agentes_autorizados/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'nfes',
		descricao: 'Notas fiscais (NF-e)',
		podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR),
	},
	{
		valor: 'nfes/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'nfes/importar_pedido',
		descricao: 'Cadastro',
	},
	{
		valor: 'nfces/cadastro',
		descricao: 'Notas fiscais de consumidor (NFC-e)',
		podeAcessar: () => usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR),
	},
	{
		valor: 'nfces/importar_pedido',
		descricao: 'Cadastro',
	},
	{
		valor: 'administracao_planos',
		descricao: 'Planos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'administracao_planos/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'relatorios_administracao',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.RELATORIOS_ADMINISTRACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'estoque_relatorios',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.ESTOQUE_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/operacoes_fiscais',
		descricao: 'Operações fiscais',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.VISUALIZAR),
	},
	{
		valor: 'tributacoes/operacoes_fiscais/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'sped',
		descricao: 'SPED',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CONTROLADORIA_SPED, permissoes.VISUALIZAR),
	},
	{
		valor: 'gestao_fechamento',
		descricao: 'Gestão de fechamentos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CONTROLADORIA_GESTAO_FECHAMENTO, permissoes.VISUALIZAR),
	},
	{
		valor: 'ordem_servico',
		descricao: 'Ordem de serviço',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR),
	},
	{
		valor: 'ordem_servico/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR),
	},
	{
		valor: 'nfses',
		descricao: 'Nota fiscal de serviço',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR),
	},
	{
		valor: 'nfses/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR),
	},
	{
		valor: 'servicos/objeto',
		descricao: 'Objeto de serviço',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'servicos/objeto/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'marca',
		descricao: 'Marca',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'marca/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'servicos/modelo',
		descricao: 'Modelo',
		podeAcessar: () => usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'servicos/modelo/cadastro',
		descricao: 'Cadastro',
	},
	{
		valor: 'ctes',
		descricao: 'CT-e',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'ctes/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'mdfes',
		descricao: 'MDF-e',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR),
	},
	{
		valor: 'mdfes/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.VISUALIZAR),
	},
	{
		valor: 'relatorios_transporte',
		descricao: 'Relatórios',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE_RELATORIOS, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/componentes_valores_prestacao',
		descricao: 'Componentes de valor de prestação',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/componentes_valores_prestacao/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/mercadoria',
		descricao: 'Mercadoria de transporte',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/mercadoria/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/condutores',
		descricao: 'Condutores',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/condutores/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/veiculos',
		descricao: 'Veículos',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/veiculos/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/seguradora',
		descricao: 'Seguradora',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'transporte/seguradora/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/regioes',
		descricao: 'Regiões',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/regioes/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/tabelas_preco',
		descricao: 'Tabelas de preço',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/tabelas_preco/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/vendedores',
		descricao: 'Vendedores',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.VISUALIZAR),
	},
	{
		valor: 'vendas/vendedores/cadastro',
		descricao: 'Cadastro',
		podeAcessar: () => usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.VISUALIZAR),
	},
];
