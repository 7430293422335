import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import Col from '../../../../Col';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
	buscarTitleCampoDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import {
	renderizarValidacao,
	buscarClassNamePelosErros,
} from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import { usuarioPossuiModulos } from '../../../../../Common/Autenticacao';
import If from '../../../../If';

const shortcutStyle = {
	marginLeft: '4px',
	fontSize: 10,
};

export function InternalInputField(props) {
	const {
		id,
		type,
		name,
		title,
		placeholder,
		className,
		onKeyDown,
		errors,
		touched,
		sm,
		md,
		lg,
		xl,
		keyfilter,
		podeVisualizar,
		podeInserir,
		podeEditar,
		estadoCadastro,
		disabled,
		colId,
		col,
		onFocus,
		step,
		min,
		autoFocus,
		floatLabel,
		colClassName,
		warning,
		size,
		obrigatorio,
		labelSize,
		style,
		pattern,
		shortcut,
		onClick,
		useOnBlurPerformatico = false,
		value,
	} = props;

	const [validouModulo, setValidouModulo] = useState(true);
	const [inputValue, setInputValue] = useState(null);

	useEffect(() => {
		if (useOnBlurPerformatico) {
			setInputValue(value);
		}
	}, [value]);

	useEffect(() => {
		if (props.modulosEspecificos) {
			setValidouModulo(usuarioPossuiModulos(props.modulosEspecificos));
		}
	});

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden)) {
			return { display: 'none', ...props.colStyle };
		}
		return { ...props.colStyle, textOverflow: 'ellipsis', overflow: 'hidden' };
	}

	function modificarOnChangeParaNull(e) {
		return {
			target: {
				value: null,
				name: e.target.name,
			},
		};
	}

	function onChange(e) {
		if (useOnBlurPerformatico) {
			if (size && e.target.value.length > size) {
				const values = String(e.target.value).substr(0, size);
				setInputValue(values || '');
			} else {
				setInputValue(e.target.value || '');
			}
		} else {
			if (e.target.value === '') {
				props.onChange(modificarOnChangeParaNull(e));
			} else if (e.target.value.length > size) {
				const values = String(e.target.value).substr(0, size);
				props.onChange({
					target: {
						value: values,
						name: e.target.name,
					},
				});
			} else {
				props.onChange(e);
			}
		}
	}

	function getValue() {
		if (useOnBlurPerformatico) {
			return inputValue !== undefined ? inputValue || '' : value || '';
		} else {
			return value || value === 0 ? value : '';
		}
	}

	function onBlur(e) {
		if (useOnBlurPerformatico) {
			props.onBlur(inputValue);
		} else {
			typeof props.onBlur === 'function' ? props.onBlur(e) : null;
		}
	}

	function montarLabel() {
		const label = props.floatLabel || props.label;
		if (obrigatorio) {
			return (
				<span style={{ display: 'flex', alignItems: 'center' }}>
					<label style={{ fontSize: labelSize }} title={props.helpMessage}>
						{label}
						<b
							style={{
								fontSize: labelSize ? labelSize : '18px',
								lineHeight: '5px',
							}}
						>
							*
						</b>
					</label>
					{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
				</span>
			);
		}
		return (
			<label style={{ display: 'flex', alignItems: 'center', fontSize: labelSize }} title={props.helpMessage}>
				{label}
				{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
			</label>
		);
	}

	function getStyle() {
		return {
			borderRadius: '3px',
			height: style?.height ? style.height : '32px',
			...style,
		};
	}

	if (!validouModulo) {
		return null;
	}

	return (
		<Col className={colClassName} id={colId} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<If test={!floatLabel}>{montarLabel()}</If>

			<span
				title={buscarTitleCampoDeAcordoComAsPermissoes(podeVisualizar, podeInserir, podeEditar, estadoCadastro, title)}
				className="p-fluid"
			>
				<div className={'p-inputgroup' + floatLabel ? ' p-float-label ' : ''}>
					<InputText
						id={id}
						keyfilter={keyfilter}
						type={type}
						name={name}
						step={step}
						min={min}
						placeholder={placeholder}
						value={getValue()}
						onClick={onClick}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						className={buscarClassNamePelosErros(className, errors, touched)}
						disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
						style={getStyle()}
						onKeyDown={onKeyDown}
						autoFocus={autoFocus}
						autoComplete="off"
						pattern={pattern}
					/>
					<If test={floatLabel}>
						<label htmlFor={id}>{montarLabel()}</label>
					</If>
				</div>
			</span>
			{renderizarValidacao(errors, touched, warning)}
		</Col>
	);
}

InternalInputField.defaultProps = {
	value: '',
	onChange: () => {},
	onClick: () => {},
	size: 99999,
	obrigatorio: false,
};

InternalInputField.propTypes = {
	/** Evento disparado ao modificar o componente do componente */
	onChange: propTypes.func,
	/** Evento disparado ao clicar no componente */
	onClick: propTypes.func,
	/** Especifica o tipo do campo */
	type: propTypes.string,
	/** Label do componente */
	label: propTypes.string,
	/** Identificador do componente */
	id: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente */
	value: propTypes.any,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Especifica os erros de validação que o componente possui (geralmente vindos do Yup)*/
	errors: propTypes.oneOfType([propTypes.string, propTypes.bool]),
	/** Evento executado ao sair do campo*/
	onBlur: propTypes.any,
	/** Evento executado ao pressionar uma tecla do campo*/
	onKeyDown: propTypes.any,
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: propTypes.string,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Estado em que o cadastro se encontra*/
	estadoCadastro: propTypes.string,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Title do componente*/
	title: propTypes.string,
	/** Id da coluna*/
	colId: propTypes.string,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	/** Filtra os caracteres aceitos pelo campo, pode aceitar expressões regulares também além de:
	 * pint: Positive integers
	 * int: Integers
	 * pnum: Positive numbers
	 * num: Numbers
	 * hex: Hexadecimal
	 * email: Email
	 * alpha: Alphabetic
	 * alphanum: Alphanumeric
	 */
	keyfilter: propTypes.any,
	/** Tamanho máximo do texto do componente*/
	size: propTypes.number,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
	/** Evento disparado ao componente receber foco*/
	onFocus: propTypes.func,
	/** Define se o campo será brigatório*/
	obrigatorio: propTypes.bool,
	/** Define o label flututante. Caso essa propriedade for passada, o label padrão é ignorado */
	floatLabel: propTypes.string,
};
