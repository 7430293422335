import { Field, useFormikContext } from 'formik';

import { generateOptionsQtdParcelas } from 'Common';
import { ButtonExcluirTable, Col, Divider, Dropdown, Grid, If, InputDouble } from 'components';

import { useContextCondicaoPagamento } from 'views/cadastros/financas/CondicaoPagamento/Context';

function AlteracaoPercentualOuValorPorQuantidade({
	taxa,
	quantidadeRegistros,
	indexTaxa,
	isAVista,
	isValor,
	onChangeValorInicial,
	onChangeValorFinal,
	onChangePercentualAplicado,
	onChangeValorAplicado,
	quantidadeMinima,
	quantidadeMaxima,
	excluirItem,
	desabilitarCampos,
}) {
	const { errors } = useFormikContext();
	const { informacoesPermissoes, isMobile, isTablet } = useContextCondicaoPagamento();

	function onClickExcluir() {
		if (typeof excluirItem === 'function') {
			excluirItem();
		}
	}

	return (
		<Grid style={{ width: '100%' }}>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				obrigatorio={!isAVista}
				label="Valor inicial"
				name="valorInicial"
				component={Dropdown}
				value={taxa?.valorInicial}
				onChange={(e) => onChangeValorInicial(e.value)}
				options={generateOptionsQtdParcelas(quantidadeMinima, quantidadeMaxima)}
				showClear
				touched
				useFormErrors={false}
				errors={errors?.taxas ? errors?.taxas[indexTaxa]?.valorInicial : null}
				disabled={desabilitarCampos || isAVista}
				{...informacoesPermissoes}
			/>
			<Field
				sm="6"
				md="3"
				lg="3"
				xl="3"
				obrigatorio={!isAVista}
				label="Valor final"
				name="valorFinal"
				component={Dropdown}
				value={taxa?.valorFinal}
				onChange={(e) => onChangeValorFinal(e.value)}
				options={generateOptionsQtdParcelas(quantidadeMinima, quantidadeMaxima)}
				showClear
				touched
				useFormErrors={false}
				errors={errors?.taxas ? errors?.taxas[indexTaxa]?.valorFinal : null}
				disabled={desabilitarCampos || isAVista}
				{...informacoesPermissoes}
			/>

			<If test={!isValor}>
				<Field
					sm="6"
					md="4"
					lg="3"
					xl="3"
					obrigatorio
					label="Percentual aplicado"
					name="percentualAplicado"
					component={InputDouble}
					decimalScale={2}
					size={6}
					allowNegative={false}
					value={taxa?.percentualAplicado}
					onChange={(e) => onChangePercentualAplicado(e.target.value)}
					touched
					useFormErrors={false}
					errors={errors?.taxas ? errors?.taxas[indexTaxa]?.percentualAplicado : null}
					disabled={desabilitarCampos}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={isValor}>
				<Field
					sm="6"
					md="4"
					lg="3"
					xl="3"
					obrigatorio
					label="Valor aplicado"
					name="valorAplicado"
					component={InputDouble}
					decimalScale={2}
					size={13}
					allowNegative={false}
					value={taxa?.valorAplicado}
					onChange={(e) => onChangeValorAplicado(e.target.value)}
					touched
					useFormErrors={false}
					errors={errors?.taxas ? errors?.taxas[indexTaxa]?.valorAplicado : null}
					disabled={desabilitarCampos}
					{...informacoesPermissoes}
				/>
			</If>

			<Col
				sm="2"
				md="1"
				lg="1"
				xl="1"
				style={
					isMobile || isTablet
						? { display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }
						: { height: '70px', display: 'flex' }
				}
			>
				<ButtonExcluirTable
					onClick={() => (informacoesPermissoes.podeEditar ? onClickExcluir() : null)}
					title="Excluir item"
					podeExcluir={informacoesPermissoes.podeEditar}
					disabled={desabilitarCampos}
					styleContentButton={{ marginTop: `${isMobile ? '0px' : '20px'}` }}
				/>
			</Col>
			{(isMobile || isTablet) && quantidadeRegistros > 1 ? (
				<Divider
					styleContainer={{ marginTop: '8px', marginBottom: '16px' }}
					styleLine={{ borderBottom: '0.5px solid' }}
				/>
			) : null}
		</Grid>
	);
}

export { AlteracaoPercentualOuValorPorQuantidade };
