import { useEffect, useState } from 'react';

import { copiarObjeto, permissoes, recursos, removerCaracteres, usuarioPossuiPermissao } from 'Common';
import { ModalMercadoriaTransporteForm } from 'views/cadastros/transporte/MercadoriaTransporte/Modal';
import SingleSelect from '../SingleSelect';

function converterMercadoriaTransporteNovoParaListagem(mercadoria) {
	if (mercadoria) {
		return {
			id: mercadoria.id,
			codigo: mercadoria.codigo,
			codigoBarra: mercadoria.codigoBarra,
			nome: mercadoria.nome,
			favorito: mercadoria.favorito,
			situacao: mercadoria.situacao,
			mercadoriaNova: true,
		};
	} else {
		return null;
	}
}

function SingleSelectMercadoriaTransporte(props) {
	const { value, sortBy = 'codigo' } = props;
	const [valueSingle, setValueSingle] = useState(null);
	const [visible, setVisible] = useState(false);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeVisualizar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.VISUALIZAR);

	const [resetPesquisa, setResetPesquisa] = useState(false);

	useEffect(() => {
		let mercadoriaSelecionada = value;
		if (mercadoriaSelecionada) {
			mercadoriaSelecionada = copiarObjeto(value);
			mercadoriaSelecionada.label = mercadoriaSelecionada.label
				? mercadoriaSelecionada.label
				: `${mercadoriaSelecionada.registro?.codigo} - ${mercadoriaSelecionada.registro?.nome}`;
		}
		setValueSingle(mercadoriaSelecionada);
	}, [value]);

	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		return `${props.url}?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisa}*",codigoBarra=contains="*${pesquisa}*");situacao=="ATIVO"&page=${page}&size=20&sort=${sortBy}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		const registroNovoConvertido = converterMercadoriaTransporteNovoParaListagem(novoRegistro);
		if (novoRegistro) {
			props.onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: registroNovoConvertido,
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.nome}`;
	}

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	return (
		<>
			<ModalMercadoriaTransporteForm visible={visible} onHide={onHide} service={props.service} />
			<SingleSelect
				titleBotaoNovo="Adicionar novo produto"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				value={valueSingle}
				esconderBotao={props.esconderBotao}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				{...props}
			/>
		</>
	);
}

export { SingleSelectMercadoriaTransporte };
