import { format, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	NenhumRegistroEncontrado,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';

function TableDadosDocumentoPapel({ setRegistroSelecionado }) {
	const { setVisibleModalDadosDocumentoPapel, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue } = useFormikContext();

	function renderSerie(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.serie}</div>;
	}
	function renderNumeroDocumento(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.numeroDocumento}</div>;
	}
	function renderEmissao(row) {
		const label = row.emissao ? format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-';
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{label}</div>;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalDadosDocumentoPapel(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const documentosPapel = values.documentoAnterior?.documentosPapel || [];
		const updatedDocumentosPapel = documentosPapel.filter((documentoPapel) =>
			documentoPapel.tempId ? documentoPapel.tempId !== row.tempId : documentoPapel.id !== row.id
		);
		setFieldValue('documentoAnterior.documentosPapel', updatedDocumentosPapel);
	}

	return (
		<div>
			<DataTable
				className="table"
				responsive
				value={values.documentoAnterior?.documentosPapel || []}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Série"
					field="serie"
					body={(e) => renderSerie(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					header="Número documento"
					field="numeroDocumento"
					body={(e) => renderNumeroDocumento(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					header="Emissão"
					field="emissao"
					body={(e) => renderEmissao(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					className="step-listagem-acoes"
					body={(e, options) => renderOpcoes(e, options)}
					header="Ações"
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar documento papel"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setVisibleModalDadosDocumentoPapel(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>
		</div>
	);
}

export default TableDadosDocumentoPapel;
