import { formatISO } from 'date-fns';
import { useState } from 'react';

import {
	Col,
	DateInterval,
	DescricaoFiltroAvancado,
	Grid,
	InputSearch,
	PesquisaAvancada,
	tipoCampos,
} from 'components';

import { readFormasPagamento } from 'views/cadastros/financas/CondicaoPagamento/Requests';
import { optionsFiltroAvancadoPix } from '../../Util/constantes';

function PesquisaFields(props) {
	const {
		setFieldValue,
		pesquisar,
		valorPesquisa,
		setFiltroData,
		setPage,
		setInterval,
		setValorPesquisa,
		setFiltroAvancado,
	} = props;

	const [descricaoFiltroAvancado, setDescricaoFiltroAvancado] = useState('');
	const [optionsFiltroAvancado, setOptionsFiltroAvancado] = useState(optionsFiltroAvancadoPix);

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...optionsFiltroAvancado];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readFormasPagamento(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Forma pagamento',
						name: 'formaPagamento',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.descricao, value: option.id });
					});

					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setOptionsFiltroAvancado(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function handleChangeInterval(interval) {
		setPage(0);
		setInterval(interval);
		setFieldValue('selectedCobrancas', []);
		setFiltroData(
			`vencimento>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};vencimento<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onPesquisar() {
		setPage(0);
		pesquisar();
	}

	return (
		<>
			<Grid justifyCenter>
				<DateInterval onChange={(e) => handleChangeInterval(e)} />
				<InputSearch
					value={valorPesquisa}
					onPesquisar={() => onPesquisar()}
					onChange={(value) => setValorPesquisa(value)}
					removerEComercial={false}
					id="CobrancaInputSearch"
				/>
				<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado" style={{ width: 'fit-content' }}>
					<PesquisaAvancada
						className="step-listagem-filtro-avancado"
						optionsFiltros={optionsFiltroAvancado}
						onPesquisarClick={onPesquisarFiltroAvancado}
						onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
						onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
						onOpen={fetchOptionsFiltroAvancado}
					/>
				</Col>
			</Grid>
			<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
		</>
	);
}

export default PesquisaFields;
