import { Field, useFormikContext, withFormik } from 'formik';

import { Dropdown, Grid, InputDouble, InputField, Modal } from 'components';

import { CTE_UNIDADE_MEDIDA_OPTIONS, INITIAL_VALUE_QUANTIDADE_CARGA } from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { useContextCTe } from 'views/transporte/CTe/Context';
import { ActionButtons } from './components/ActionButtons';
import { converterDadosQuantidadeCargaParaForm } from '../../../../Utils/converterCargas';

function ModalQuantidadeCargaImpl({ onHide, onSave }) {
	const { values, validateForm, handleSubmit, setFieldValue } = useFormikContext();
	const { visibleModalQuantidadeCarga, isSituacaoFinalCTe } = useContextCTe();

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);
		}
	}

	function onChangeUnidadeMedida(event) {
		setFieldValue('unidadeMedida', event);
		setFieldValue('tipoMedida', event.value);
	}

	return (
		<Modal visible={visibleModalQuantidadeCarga} header="Dados de quantidade de carga" onHide={onHide}>
			<ActionButtons {...{ onHide, onHandleSave }} />
			<Grid style={{ marginTop: '4px' }}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Unidade de medida"
					name="unidadeMedida"
					obrigatorio
					touched
					options={CTE_UNIDADE_MEDIDA_OPTIONS}
					allowNegative={false}
					value={values?.unidadeMedida}
					onChange={(event) => onChangeUnidadeMedida(event)}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="5"
					lg="5"
					xl="5"
					component={InputField}
					label="Tipo da medida"
					name="tipoMedida"
					obrigatorio
					touched
					allowNegative={false}
					value={values?.tipoMedida}
					onChange={(event) => setFieldValue('tipoMedida', event.target.value)}
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputDouble}
					label="Quantidade"
					name="quantidade"
					obrigatorio
					decimalScale={3}
					onChange={(event) => setFieldValue('quantidade', event.target.value)}
					value={values.quantidade}
					allowNegative={false}
					touched
					disabled={isSituacaoFinalCTe}
				/>
			</Grid>
		</Modal>
	);
}

const ModalQuantidadeCarga = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.quantidadeCarga) {
			return converterDadosQuantidadeCargaParaForm(props.quantidadeCarga);
		} else {
			return { ...INITIAL_VALUE_QUANTIDADE_CARGA };
		}
	},

	validate(values) {
		const errors = {};

		if (!values.unidadeMedida) {
			errors.unidadeMedida = 'Campo obrigatório';
		}

		if (!values.tipoMedida) {
			errors.tipoMedida = 'Campo obrigatório';
		}

		if (!values.quantidade || values.quantidade === 0) {
			errors.quantidade = 'Campo obrigatório';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalQuantidadeCargaImpl);

export { ModalQuantidadeCarga };
