import { Field, useFormikContext, withFormik } from 'formik';

import { copiarObjeto, services } from 'Common';
import { Grid, InputDouble, Modal, SingleSelectComponenteValorPrestacao } from 'components';

import { INITIAL_VALUE_COMPONENTE_VALOR_PRESTACAO } from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { useContextCTe } from 'views/transporte/CTe/Context';
import { ActionButtons } from './components/ActionButtons';
import { converterDadosComponenteValorPrestacaoParaForm } from '../../../../Utils/converterCargas';

function ModalComponenteValorPrestacaoImpl({ onHide, onSave }) {
	const { validateForm, handleSubmit, values, setFieldValue } = useFormikContext();
	const { visibleModalComponenteValorPrestacao, isSituacaoFinalCTe } = useContextCTe();

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);
		}
	}

	function onChageComponenteValorPrestacao(event) {
		setFieldValue('componente', event);
		setFieldValue('valor', event.registro.valor);
	}

	return (
		<Modal
			visible={visibleModalComponenteValorPrestacao}
			header="Dados componente do valor da prestação"
			onHide={onHide}
		>
			<ActionButtons {...{ onHide, onHandleSave }} />
			<Grid style={{ marginTop: '4px' }}>
				<Field
					sm="12"
					md="9"
					lg="9"
					xl="9"
					component={SingleSelectComponenteValorPrestacao}
					label="Componente do valor da prestação"
					name="componente"
					obrigatorio
					isClearable={false}
					value={values.componente}
					url={`${services.GESTOR}/v1/ctes/relacoes/componente_valor_prestacao`}
					onChange={onChageComponenteValorPrestacao}
					touched
					disabled={isSituacaoFinalCTe}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputDouble}
					label="Valor"
					name="valor"
					obrigatorio
					prefix="R$ "
					decimalScale={2}
					value={values.valor}
					onChange={(event) => setFieldValue('valor', event.target.value)}
					allowNegative={false}
					touched
					disabled={isSituacaoFinalCTe}
				/>
			</Grid>
		</Modal>
	);
}

const ModalComponenteValorPrestacao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.componente) {
			return converterDadosComponenteValorPrestacaoParaForm(props.componente);
		} else {
			return { ...copiarObjeto(INITIAL_VALUE_COMPONENTE_VALOR_PRESTACAO) };
		}
	},

	validate(values) {
		const errors = {};

		if (!values.componente) {
			errors.componente = 'Campo obrigatório';
		}

		if (!values.valor || values.valor === 0) {
			errors.valor = 'Campo obrigatório';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalComponenteValorPrestacaoImpl);

export { ModalComponenteValorPrestacao };
