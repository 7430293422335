import { services } from 'Common/Constantes/api';
import { exibirToast, get, post } from 'Common/Requisicoes';

export async function asyncGetNcmDestino(urlParametros, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/ncm/lista_destino/${urlParametros}`, null, onSuccess, onError);
}

export async function asyncPostReplicarNcms(values, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/ncm/replicar`,
		values,
		null,
		exibirToast(onSuccess, 'Replicação realizada com sucesso!'),
		onError
	);
}
