import { useEffect, useRef, useState } from 'react';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { helpPlanosForm } from './Help';
import { connect } from 'react-redux';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../Util';
import { asyncCreateAdmPlano, asyncDeleteAdmPlano, asyncGetAdmPlano, asyncUpdateAdmPlano } from './Requests';
import { CADASTROURL, PESQUISAURL } from '../Util/constantes';

import {
	mensagensDeValidacao,
	recursos,
	permissoes,
	estadosCadastro,
	buscarDescricaoTamanhoArquivo,
	validarUUID,
	usuarioPossuiPermissao,
	buscarDadosLoginLocalStorage,
} from 'Common';

import {
	ButtonNovo,
	InputField,
	Grid,
	Dropdown,
	Prompt,
	confirm,
	FormActions,
	Checkbox,
	FormContent,
	Form,
	InputMoney,
	Col,
	If,
	estadosBotaoNovo,
	ButtonCancelar,
	estadosBotaoCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	ButtonExcluir,
	TabView,
	Tree,
	Button,
	NormalButton,
} from 'components';
import { TabPanel } from 'primereact/tabview';
import {
	converterEstruturaParaTree,
	converterPlanoParaApi,
	converterPlanoParaFormulario,
	converterRecursosParaFormulario,
} from '../Util/converter';
import { asyncBuscarEstruturaRecursos } from '../Requests';
import { Menu } from 'primereact/menu';

const initialValues = {
	id: null,
	descricao: '',
	ilimitado: false,
	tipo: 'EXCLUSIVO',
	aplicacao: 'MSYS_GESTOR',
	usuarios: 0,
	armazenamento: 0,
	cadastrosPessoas: 0,
	cadastrosProdutos: 0,
	documentosMensais: 0,
	contasFinanceiras: 0,
	orcamentosMensais: 0,
	pedidosMensais: 0,
	nfesMensais: 0,
	nfcesMensais: 0,
	comprasMensais: 0,
	ordensMensais: 0,
	nfsesMensais: 0,
	mdfesMensais: 0,
	ctesMensais: 0,
	boletosMensais: 0,
	suporteChat: false,
	valor: 0,
	recursos: [],
};

const styleWarnings = {
	fontSize: '12px',
	color: '#ff9800',
	height: '16px',
};

function AdministracaoPlanosForm(props) {
	const { values, dirty, isModal, setFieldValue, resetForm } = props;

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EDITAR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EXCLUIR)
	);
	const [tabViewIndex, setTabViewIndex] = useState(0);
	const [estruturaTree, setEstruturaTree] = useState([]);
	const menuOpcoes = useRef(null);
	const itensOpcoes = buscaritensOpcoes();
	const [fieldCheckboxes, setFieldCheckboxes] = useState({
		usuarios: false,
		armazenamento: false,
		cadastrosPessoas: false,
		cadastrosProdutos: false,
		documentosMensais: false,
		contasFinanceiras: false,
		orcamentosMensais: false,
		pedidosMensais: false,
		nfesMensais: false,
		nfcesMensais: false,
		comprasMensais: false,
		ordensMensais: false,
		nfsesMensais: false,
		mdfesMensais: false,
		ctesMensais: false,
		boletosMensais: false,
	});

	useEffect(() => {
		carregarEstruturaRecurso();
		setTimeout(() => {
			document.getElementById('PlanosInputFieldDescricao')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		loadCheckboxes();
	}, [values]);

	useEffect(() => {
		fetchData();
	}, [estruturaTree]);

	function fetchData() {
		const id = props.match && props.match.params.id;
		const fetchData = async () => {
			if (validarUUID(id)) {
				await asyncSelectRegistro(id);
			}
		};
		fetchData();
	}

	async function carregarEstruturaRecurso() {
		await asyncBuscarEstruturaRecursos(({ data: recursos }) => {
			const estrutura = converterEstruturaParaTree(recursos);
			setEstruturaTree(estrutura);
		});
	}

	function novo() {
		atualizarUrl(props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		props.resetForm({ values: initialValues });
		handleIlimitado(false);
	}

	function handleIlimitado(current) {
		const campos = {
			usuarios: false,
			armazenamento: false,
			cadastrosPessoas: false,
			cadastrosProdutos: false,
			documentosMensais: false,
			contasFinanceiras: false,
			orcamentosMensais: false,
			pedidosMensais: false,
			nfesMensais: false,
			nfcesMensais: false,
			comprasMensais: false,
			ordensMensais: false,
			nfsesMensais: false,
			mdfesMensais: false,
			ctesMensais: false,
			boletosMensais: false,
		};

		Object.keys(campos).forEach(function (key, index) {
			if (current === true) {
				if (key === 'armazenamento') {
					props.setFieldValue([key], Number.parseInt('99999999999999999'));
					changeCheckboxesFunction(key, true);
				} else {
					props.setFieldValue([key], 999999999);
					changeCheckboxesFunction(key, true);
				}
			} else {
				props.setFieldValue([key], 0);
				changeCheckboxesFunction(key, false);
			}
		});
	}

	function excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir o registro?', async () => {
			await asyncDeleteRegistro();
		});
	}

	function handleClickDuplicar() {
		atualizarUrl(props.history, CADASTROURL, null, metodosAtualizarUrl.POP);
		props.resetForm({ values: { ...props.values, id: null } });
		setFieldValue('descricao', '');
		setTimeout(() => {
			document.getElementById('PlanosInputFieldDescricao')?.focus();
		}, 500);
	}

	function buscaritensOpcoes() {
		const itens = [];
		if (values.id) {
			itens.push({
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => {
					handleClickDuplicar();
				},
			});
		}
		return itens;
	}

	function fecharModal() {
		if (props.values.id) {
			props.hideModal(props.values);
		} else {
			props.hideModal();
		}
	}

	async function salvar(e, novoOnSuccess) {
		props.handleSubmit();

		if (await validarFormulario(props)) {
			if (props.values.id) {
				await asyncUpdateRegistro(converterPlanoParaApi(validarValor(props.values), estruturaTree), novoOnSuccess);
			} else {
				await asyncCreateRegistro(converterPlanoParaApi(validarValor(props.values), estruturaTree), novoOnSuccess);
			}
			if (props.hideModal) {
				fecharModal();
			}
		}
	}

	function validarValor(values) {
		if (values.valor === null || values.valor < 0) {
			return {
				...values,
				valor: 0,
			};
		}
		return values;
	}

	function cancelar() {
		if (props.dirty) {
			handleIlimitado(false);
			props.resetForm({ values: props.initialValues });
		} else if (props.isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(props.history, PESQUISAURL);
		}
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteAdmPlano(props.values.id, () => {
			props.resetForm();
			voltarParaAPesquisa(props.history, PESQUISAURL);
		});
	}

	async function asyncUpdateRegistro(values, novoOnSuccess) {
		await asyncUpdateAdmPlano(values, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				props.resetForm({ values: props.values });
			}
		});
	}

	async function asyncCreateRegistro(values, novoOnSuccess) {
		if (values) {
			await asyncCreateAdmPlano(values, async ({ data: plano }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					props.resetForm({ values: { ...props.values, id: plano.id } });
					if (!props.isModal) {
						atualizarUrl(props.history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
					}
				}
			});
		}
	}

	async function asyncSelectRegistro(idPlano) {
		await asyncGetAdmPlano(idPlano, ({ data: plano }) => {
			resetForm({ values: converterPlanoParaFormulario(plano, estruturaTree) });
			if (!props.isModal) {
				atualizarUrl(props.history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function changeCheckboxesFunction(field, value) {
		setFieldCheckboxes((prevState) => {
			return {
				...prevState,
				[field]: value,
			};
		});
	}

	function onChangeCampoIlimitado(field, value) {
		if (value === true) {
			if (field === 'armazenamento') {
				props.setFieldValue([field], Number.parseInt('99999999999999999'));
				changeCheckboxesFunction(field, value);
			} else {
				props.setFieldValue([field], 999999999);
				changeCheckboxesFunction(field, value);
			}
		} else {
			props.setFieldValue([field], props.initialValues[field]);
			changeCheckboxesFunction(field, value);
		}
	}

	function loadCheckboxes() {
		if (values.usuarios >= 999999999) {
			changeCheckboxesFunction('usuarios', true);
		}
		if (values.cadastrosPessoas >= 999999999) {
			changeCheckboxesFunction('cadastrosPessoas', true);
		}
		if (values.cadastrosProdutos >= 999999999) {
			changeCheckboxesFunction('cadastrosProdutos', true);
		}
		if (values.documentosMensais >= 999999999) {
			changeCheckboxesFunction('documentosMensais', true);
		}
		if (values.contasFinanceiras >= 999999999) {
			changeCheckboxesFunction('contasFinanceiras', true);
		}
		if (values.orcamentosMensais >= 999999999) {
			changeCheckboxesFunction('orcamentosMensais', true);
		}
		if (values.pedidosMensais >= 999999999) {
			changeCheckboxesFunction('pedidosMensais', true);
		}
		if (values.nfesMensais >= 999999999) {
			changeCheckboxesFunction('nfesMensais', true);
		}
		if (values.nfcesMensais >= 999999999) {
			changeCheckboxesFunction('nfcesMensais', true);
		}
		if (values.comprasMensais >= 999999999) {
			changeCheckboxesFunction('comprasMensais', true);
		}

		if (values.ordensMensais >= 999999999) {
			changeCheckboxesFunction('ordensMensais', true);
		}
		if (values.nfsesMensais >= 999999999) {
			changeCheckboxesFunction('nfsesMensais', true);
		}
		if (values.mdfesMensais >= 999999999) {
			changeCheckboxesFunction('mdfesMensais', true);
		}
		if (values.ctesMensais >= 999999999) {
			changeCheckboxesFunction('ctesMensais', true);
		}
		if (values.boletosMensais >= 999999999) {
			changeCheckboxesFunction('boletosMensais', true);
		}
		if (values.armazenamento >= Number.parseInt('99999999999999999')) {
			changeCheckboxesFunction('armazenamento', true);
		}
	}

	const planoIlimitado = values.ilimitado === true;

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const dadosLogin = buscarDadosLoginLocalStorage();

	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	function marcarTodosOsRecursos() {
		let resultado = {};
		for (let i = 0; i < estruturaTree.length; i++) {
			const recurso = estruturaTree[i];

			resultado[recurso.key] = { ...recurso, checked: true, partialChecked: false };
		}
		setFieldValue('recursos', resultado);
	}

	function desmarcarTodosOsRecursos() {
		setFieldValue('recursos', []);
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Form header="Cadastro de plano" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={() => salvar()}
						{...informacoesPermissoes}
					/>
					<ButtonNovo
						onClick={onClickNovo}
						hidden={(!dirty && !values.id) || isModal}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir hidden={!values.id} onClick={() => excluir()} {...informacoesPermissoes} />
					<NormalButton
						className="p-button-primary"
						type="button"
						label="Opções"
						icon="fa fa-list"
						style={{ margin: '5px' }}
						hidden={itensOpcoes.length === 0}
						onClick={(event) => menuOpcoes.current?.toggle(event)}
					/>
					<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							label="O plano é ilimitado"
							name="ilimitado"
							helpMessage={helpPlanosForm.ilimitado}
							onChange={(e) => {
								const { descricao, tipo, valor } = props.values;
								setFieldValue('ilimitado', e.checked);
								setFieldValue('tipo', tipo);
								setFieldValue('descricao', descricao);
								setFieldValue('valor', valor);
								handleIlimitado(e.checked);
							}}
							checked={values.ilimitado === true}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							label="Suporte via chat"
							name="suporteChat"
							disabled={planoIlimitado}
							helpMessage={helpPlanosForm.suporteChat}
							onChange={(e) => setFieldValue('suporteChat', e.checked)}
							checked={values.suporteChat === true}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							component={InputField}
							label="Descrição"
							name="descricao"
							size={128}
							obrigatorio
							helpMessage={helpPlanosForm.descricao}
							id="PlanosInputFieldDescricao"
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Tipo"
							name="tipo"
							showClear={false}
							onChange={(e) => setFieldValue('tipo', e.value)}
							options={[
								{ label: 'Gratuito', value: 'GRATUITO' },
								{ label: 'Normal', value: 'NORMAL', isDisabled: dadosLogin.autoridade !== 'ADMIN' },
								{ label: 'Exclusivo', value: 'EXCLUSIVO' },
							]}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Aplicação"
							name="aplicacao"
							showClear={false}
							onChange={(e) => setFieldValue('aplicacao', e.value)}
							options={[
								{ label: 'DOCs Digitais', value: 'DOCS_DIGITAIS' },
								{ label: 'Msys Gestor', value: 'MSYS_GESTOR' },
								{ label: 'Gestor MEI', value: 'GESTOR_MEI' },
							]}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={InputMoney}
							label="Valor"
							name="valor"
							obrigatorio
							size={15}
							min={0}
							helpMessage={helpPlanosForm.valor}
							onChange={(e) => setFieldValue('valor', e.target.value)}
							value={values.valor}
							{...informacoesPermissoes}
						/>
					</Grid>
					<TabView
						className={'tab-view-plano'}
						activeIndex={tabViewIndex}
						onTabChange={(event) => {
							setTabViewIndex(event.index);
						}}
						{...informacoesPermissoes}
					>
						<TabPanel header="Limites">
							<Grid>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Usuários ilimitados?"
										disabled={planoIlimitado}
										name="usuarios"
										helpMessage={helpPlanosForm.usuarios}
										onChange={(e) => onChangeCampoIlimitado('usuarios', e.checked)}
										checked={fieldCheckboxes.usuarios}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="usuarios"
										disabled={planoIlimitado || fieldCheckboxes.usuarios}
										type="number"
										size={9}
										min={0}
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Armazenamento ilimitado?"
										disabled={planoIlimitado}
										name="armazenamento"
										helpMessage={helpPlanosForm.armazenamento}
										onChange={(e) => onChangeCampoIlimitado('armazenamento', e.checked)}
										checked={fieldCheckboxes.armazenamento}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="armazenamento"
										disabled={planoIlimitado || fieldCheckboxes.armazenamento}
										type="number"
										size={18}
										min={0}
										{...informacoesPermissoes}
									/>
									<If test={values.armazenamento && values.armazenamento >= 0 && !fieldCheckboxes.armazenamento}>
										<div style={styleWarnings}> {buscarDescricaoTamanhoArquivo(values.armazenamento)} </div>
									</If>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Pessoas ilimitadas?"
										disabled={planoIlimitado}
										name="cadastrosPessoas"
										helpMessage={helpPlanosForm.cadastrosPessoas}
										onChange={(e) => onChangeCampoIlimitado('cadastrosPessoas', e.checked)}
										checked={fieldCheckboxes.cadastrosPessoas}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="cadastrosPessoas"
										disabled={planoIlimitado || fieldCheckboxes.cadastrosPessoas}
										type="number"
										size={9}
										min={0}
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Produtos ilimitados?"
										disabled={planoIlimitado}
										name="cadastrosProdutos"
										helpMessage={helpPlanosForm.cadastrosProdutos}
										onChange={(e) => onChangeCampoIlimitado('cadastrosProdutos', e.checked)}
										checked={fieldCheckboxes.cadastrosProdutos}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="cadastrosProdutos"
										disabled={planoIlimitado || fieldCheckboxes.cadastrosProdutos}
										type="number"
										size={9}
										min={0}
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Documentos ilimitados?"
										name="documentosMensais"
										disabled={planoIlimitado}
										helpMessage={helpPlanosForm.documentosMensais}
										onChange={(e) => onChangeCampoIlimitado('documentosMensais', e.checked)}
										checked={fieldCheckboxes.documentosMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="documentosMensais"
										disabled={planoIlimitado || fieldCheckboxes.documentosMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Compras ilimitadas?"
										disabled={planoIlimitado}
										name="comprasMensais"
										helpMessage={helpPlanosForm.comprasMensais}
										onChange={(e) => onChangeCampoIlimitado('comprasMensais', e.checked)}
										checked={fieldCheckboxes.comprasMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="comprasMensais"
										disabled={planoIlimitado || fieldCheckboxes.comprasMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Orçamentos ilimitados?"
										disabled={planoIlimitado}
										name="orcamentosMensais"
										helpMessage={helpPlanosForm.orcamentosMensais}
										onChange={(e) => onChangeCampoIlimitado('orcamentosMensais', e.checked)}
										checked={fieldCheckboxes.orcamentosMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="orcamentosMensais"
										disabled={planoIlimitado || fieldCheckboxes.orcamentosMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Pedidos ilimitados?"
										disabled={planoIlimitado}
										name="pedidosMensais"
										helpMessage={helpPlanosForm.pedidosMensais}
										onChange={(e) => onChangeCampoIlimitado('pedidosMensais', e.checked)}
										checked={fieldCheckboxes.pedidosMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="pedidosMensais"
										disabled={planoIlimitado || fieldCheckboxes.pedidosMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Notas fiscais ilimitadas?"
										disabled={planoIlimitado}
										name="nfesMensais"
										helpMessage={helpPlanosForm.nfesMensais}
										onChange={(e) => onChangeCampoIlimitado('nfesMensais', e.checked)}
										checked={fieldCheckboxes.nfesMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="nfesMensais"
										disabled={planoIlimitado || fieldCheckboxes.nfesMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Notas fiscais consumidor ilimitadas?"
										disabled={planoIlimitado}
										name="nfcesMensais"
										helpMessage={helpPlanosForm.nfcesMensais}
										onChange={(e) => onChangeCampoIlimitado('nfcesMensais', e.checked)}
										checked={fieldCheckboxes.nfcesMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="nfcesMensais"
										disabled={planoIlimitado || fieldCheckboxes.nfcesMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>

								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Ordens de serviço ilimitadas?"
										disabled={planoIlimitado}
										name="ordensMensais"
										helpMessage={helpPlanosForm.ordensMensais}
										onChange={(e) => onChangeCampoIlimitado('ordensMensais', e.checked)}
										checked={fieldCheckboxes.ordensMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="ordensMensais"
										disabled={planoIlimitado || fieldCheckboxes.ordensMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>

								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Notas fiscais de serviço ilimitadas?"
										disabled={planoIlimitado}
										name="nfsesMensais"
										helpMessage={helpPlanosForm.nfsesMensais}
										onChange={(e) => onChangeCampoIlimitado('nfsesMensais', e.checked)}
										checked={fieldCheckboxes.nfsesMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="nfsesMensais"
										disabled={planoIlimitado || fieldCheckboxes.nfsesMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>

								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Manifestos de documentos fiscais eletrônicos ilimitados?"
										disabled={planoIlimitado}
										name="mdfesMensais"
										helpMessage={helpPlanosForm.mdfesMensais}
										onChange={(e) => onChangeCampoIlimitado('mdfesMensais', e.checked)}
										checked={fieldCheckboxes.mdfesMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="mdfesMensais"
										disabled={planoIlimitado || fieldCheckboxes.mdfesMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>

								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Conhecimentos de transporte eletrônicos ilimitados?"
										disabled={planoIlimitado}
										name="ctesMensais"
										helpMessage={helpPlanosForm.ctesMensais}
										onChange={(e) => onChangeCampoIlimitado('ctesMensais', e.checked)}
										checked={fieldCheckboxes.ctesMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="ctesMensais"
										disabled={planoIlimitado || fieldCheckboxes.ctesMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>

								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Contas financeiras ilimitadas?"
										disabled={planoIlimitado}
										name="contasFinanceiras"
										helpMessage={helpPlanosForm.contasFinanceiras}
										onChange={(e) => onChangeCampoIlimitado('contasFinanceiras', e.checked)}
										checked={fieldCheckboxes.contasFinanceiras}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="contasFinanceiras"
										disabled={planoIlimitado || fieldCheckboxes.contasFinanceiras}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
								<Col sm="6" md="6" lg="6" xl="6">
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={Checkbox}
										colStyle={{ padding: '0px 0px 3px 1px' }}
										label="Boletos ilimitados?"
										disabled={planoIlimitado}
										name="boletosMensais"
										helpMessage={helpPlanosForm.boletosMensais}
										onChange={(e) => onChangeCampoIlimitado('boletosMensais', e.checked)}
										checked={fieldCheckboxes.boletosMensais}
										{...informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="12"
										lg="12"
										xl="12"
										component={InputField}
										colStyle={{ padding: '0px' }}
										name="boletosMensais"
										disabled={planoIlimitado || fieldCheckboxes.boletosMensais}
										size={9}
										min={0}
										type="number"
										{...informacoesPermissoes}
									/>
								</Col>
							</Grid>
						</TabPanel>
						<TabPanel header="Recursos">
							<Button
								label="Marcar todos"
								type="button"
								color="success"
								icon="fa fa-check"
								style={{ margin: '5px' }}
								title="Marca todos os recursos"
								onClick={() => marcarTodosOsRecursos()}
							/>
							<Button
								label="Desmarcar todos"
								type="button"
								color="danger"
								icon="fa fa-close"
								title="Desmarca todos os recursos"
								style={{ margin: '5px' }}
								onClick={() => desmarcarTodosOsRecursos()}
							/>
							<Tree
								className="step-papeis-three"
								style={{ border: 0, padding: 0 }}
								value={estruturaTree}
								selectionKeys={values.recursos}
								onSelectionChange={async (e) => {
									await setFieldValue('recursos', e.value);
									props.validateForm();
								}}
								selection={values.recursos}
								sortMode="single"
								selectionMode="checkbox"
								{...informacoesPermissoes}
							/>
						</TabPanel>
					</TabView>
				</FormContent>
			</Form>
		</>
	);
}

AdministracaoPlanosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValues;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		armazenamento: Yup.number().nullable('abc').required(mensagensDeValidacao.OBRIGATORIO),
		usuarios: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cadastrosPessoas: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cadastrosProdutos: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		documentosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		contasFinanceiras: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		orcamentosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		pedidosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nfesMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nfcesMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		comprasMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		boletosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		aplicacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(AdministracaoPlanosForm);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(AdministracaoPlanosForm);
