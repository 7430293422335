import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	If,
	ModalHistorico,
	NormalButton,
	ToastTypes,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../../Util';
import { confirmarExclusao } from '../../../../../../Util/ExclusaoDeRegistros';
import { converterProdutoParaApi, converterProdutoParaDuplicar } from '../../../Util/produtoConverter';

import {
	asyncCreateProduto,
	asyncDeleteImagemProduto,
	asyncDeleteProduto,
	asyncGetProduto,
	asyncGetProdutoMesmoCodigoBarras,
	asyncGetProdutoMesmoSku,
	asyncUpdateProduto,
	asyncUploadImagemProduto,
} from '../../../Requests';

export default function ActionButtons(props) {
	const {
		values,
		isModal,
		dirty,
		resetForm,
		history,
		handleSubmit,
		setFieldError,
		hideModal,
		isGestor,
		informacoesPermissoes,
		ajustarSaldoEmEstoque,
		CADASTROURL,
		initialValue,
		setEstoqueSaldoAnterior,
		setAjustarSaldoEmEstoque,
		setEstoqueSaldo,
		PESQUISAURL,
		dadosBase,
		setDadosBase,
		setIsNewProduto,
		setActiveIndex,
		setValues,
	} = props;

	const { id, codigoBarras, sku } = values;

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	const menuOpcoes = useRef(null);

	const itensOpcoes = getItensOpcoes();

	function getItensOpcoes() {
		const itens = [];

		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicarProduto(values.id),
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: true,
		});

		return itens.filter((element) => element.visible);
	}

	async function duplicarProduto(id) {
		await asyncGetProduto(id, async ({ data: produto }) => {
			atualizarUrl(history, '/produtos/cadastro', null, metodosAtualizarUrl.POP);
			const produtoConvertido = converterProdutoParaDuplicar(produto);
			if (isGestor) {
				setActiveIndex(0);
			} else {
				setActiveIndex(5);
			}
			setEstoqueSaldoAnterior(0);
			setEstoqueSaldo(0);
			setDadosBase(produtoConvertido);
			setValues(produtoConvertido);
		});
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
		setEstoqueSaldoAnterior(0);
		setEstoqueSaldo(0);
	}

	async function uploadImagem(idProduto) {
		let idImagem = values.imagem ?? null;
		if (
			values.imagemFile !== initialValue.imagemFile &&
			values?.imagemFile !== dadosBase?.imagemFile &&
			values.imagemFile !== undefined
		) {
			await asyncUploadImagemProduto(
				values.imagemFile,
				idProduto,
				({ data }) => {
					idImagem = data.id;
				},
				() => {
					notify('Não foi possivel fazer o upload da imagem', ToastTypes.ERROR);
				},
				true,
				false
			);
		}
		return idImagem;
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario(props)) {
			if ((await validarSku()) && (await validarCodigoBarras())) {
				const dadosFormulario = converterProdutoParaApi(values);
				if (id) {
					asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
					setAjustarSaldoEmEstoque(false);
					const idImagem = await uploadImagem(id);
					let dados = { ...values, imagem: idImagem };

					if (values.imagemFile === null && values?.imagemFile !== dadosBase?.imagemFile) {
						await asyncDeleteImagemProduto(
							id,
							() => {
								dados = { ...values, imagem: null };
							},
							() => {
								notify('Não foi possivel excluir a imagem', ToastTypes.ERROR);
							},
							true,
							false
						);
					}
					setDadosBase(dados);
					resetForm({ values: dados });
				} else {
					const dados = { ...values };
					dados.filiais[0].estoqueSaldo = dados.estoqueSaldo;
					dados.filiais[0].ultimoCusto = dados.ultimoCusto;
					dados.filiais[0].custoMedio = dados.ultimoCusto;
					setDadosBase(dados);
					await asyncCreateRegistro(dadosFormulario, novoOnSuccess);
				}
			}
		}
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		if (isModal && !dirty && id) {
			fecharModal();
		} else {
			await asyncUpdateProduto(dadosFormulario, () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: values });
				}
			});
		}
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateProduto(dadosFormulario, async ({ data: produto }) => {
			setIsNewProduto(true);
			const idImagem = await uploadImagem(produto.id);
			const dados = {
				...values,
				id: produto.id,
				codigo: produto.codigo,
				imagem: idImagem,
				produtoFilialConectada: produto?.produtoFilialConectada,
				preco: produto?.produtoFilialConectada?.precoVenda,
				precoAtacado: produto?.produtoFilialConectada?.precoAtacado,
				filiais: produto?.filiais,
			};
			setDadosBase(dados);
			resetForm({ values: dados });

			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (isModal) {
				fecharModal({ ...dadosFormulario, ...dados });
			} else {
				atualizarUrl(history, CADASTROURL, produto.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function fecharModal(produto = null) {
		if (produto?.id) {
			hideModal(produto);
		} else {
			hideModal();
		}
	}

	async function validarCodigoBarras() {
		let codigoBarrasJaExiste = false;

		codigoBarras === '' || codigoBarras === null || codigoBarras === undefined
			? (codigoBarrasJaExiste = false)
			: await asyncGetProdutoMesmoCodigoBarras(codigoBarras, (e) => {
					if (e.data.content.length > 0 && e.data.content[0].id !== id) {
						codigoBarrasJaExiste = true;
						setFieldError('codigoBarras', 'O código de barras informado já existe');
					}
				});

		return !codigoBarrasJaExiste;
	}

	async function validarSku() {
		let skuJaExiste = false;
		if (sku && sku.trim() !== '') {
			await asyncGetProdutoMesmoSku(sku, (e) => {
				if (e.data.content.length > 0 && e.data.content[0].id !== id) {
					skuJaExiste = true;
					setFieldError('sku', 'O código informado já existe');
				}
			});
		}
		return !skuJaExiste;
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteProduto(id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	function cancelar() {
		if (dirty) {
			resetForm({
				values: dadosBase?.values,
			});
		} else if (isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}
	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={Boolean(isModal && values.id && !dirty)}
				estadoBotao={estadoBotaoCancelar}
				onClick={cancelar}
			/>
			<ButtonSalvar
				{...informacoesPermissoes}
				disabled={!dirty || ajustarSaldoEmEstoque}
				onClick={() => salvar()}
				titlePersonalizado={ajustarSaldoEmEstoque ? 'Finalize a movimentação de estoque para salvar o produto ' : null}
			/>
			<ButtonNovo
				onClick={onClickNovo}
				disabled={ajustarSaldoEmEstoque}
				titlePersonalizado={
					ajustarSaldoEmEstoque ? 'Finalize a movimentação de estoque para inserir um novo produto ' : null
				}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal}
				disabled={ajustarSaldoEmEstoque}
				titlePersonalizado={ajustarSaldoEmEstoque ? 'Finalize a movimentação de estoque para excluir o produto ' : null}
				{...informacoesPermissoes}
				onClick={excluir}
			/>
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>

			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do cadastro de produto"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="produtos"
					id={values.id}
				/>
			</If>
		</>
	);
}
