import { useState } from "react";
import { baixarArquivo, keyFilterConsultaRsql, mensagensDeValidacao } from "Common";
import { Button, Dropdown, Form, FormActions, FormContent, Grid, InputField, Modal, ModalLoadingTransmissao } from "components";
import { Field, withFormik } from "formik";
import { FiDownload } from "react-icons/fi";
import { validarFormulario } from "views/Util";
import { optionsLayoutArquivoBalanca } from "../../Util/constantes";
import { asyncGetArquivoBalanca } from "../../Requests";

const INITIAL_VALUE = {   
    layoutArquivoBalanca: 'TOLEDO_MGV7',
    departamento: 1,
};

function ModalExportarArquivoBalancaImpl({ visible, onHide, values, setFieldValue, handleSubmit, ...props }){

    const [exibirLoading, setExibirLoading] = useState(false);

    function getNomeArquivo(layout){
        if (layout === 'TOLEDO_MGV5'){
            return 'TXITENS.TXT';
        } else if (layout === 'TOLEDO_MGV6'){
            return 'ITENSMGV.TXT';
        } else if (layout === 'TOLEDO_MGV7'){
            return 'ITENSMGV.TXT';
        }    
        return 'FILIZOLA.TXT';
    }

    async function handleGerarArquivo(){
        handleSubmit();

		if (await validarFormulario(props)) {
			setExibirLoading(true);

			await asyncGetArquivoBalanca(
				values.layoutArquivoBalanca,
                values.departamento,
				({ data: arquivo }) => {
					baixarArquivo(
						new Blob([arquivo]),
						getNomeArquivo(values.layoutArquivoBalanca),
						'text/plain;charset=ISO-8859-1'
					);

					setExibirLoading(false);
                    onHide();
				},
				() => setExibirLoading(false)
			);
		}
    }

    return (
        <>
            <Modal
                header="Exportar arquivo para balança"
                visible={visible}
                onHide={onHide}
            >                
                <Form>
                    <FormActions>
                    <Button
                        label="Gerar arquivo"
                        icon={<FiDownload size={20} style={{ marginRight: '6px', marginBootom : '3px' }} />}
                        onClick={handleGerarArquivo}
                    />
                    </FormActions>
                    <FormContent>
                        <Grid>
                            <Field
                                sm="12"
                                md="6"
                                lg="4"
                                xl="4"
                                component={Dropdown}
                                label="Layout arquivo"
                                name="layoutArquivoBalanca"
                                onChange={e => setFieldValue('layoutArquivoBalanca', e.value)}
                                options={optionsLayoutArquivoBalanca}
                                value={values.layoutArquivoBalanca}
                                showClear={false}
                                obrigatorio
                            />
                            <Field
                                sm="12"
                                md="4"
                                lg="3"
                                xl="3"
                                component={InputField}
                                type="number"
                                label="Departamento"
                                keyfilter={keyFilterConsultaRsql}
                                name="departamento"
                                obrigatorio
                                size={8}                            
                            />
                        </Grid>                  
                    </FormContent>
                </Form>
            </Modal>
            <ModalLoadingTransmissao visible={exibirLoading} message="Gerando arquivo para balança..." />
        </>    
    );

}

const ModalExportarArquivoBalanca = withFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  
    mapPropsToValues() {
      return INITIAL_VALUE;
    },
  
    validate(values) {
      const errors = {};    
      
      if (!values.layoutArquivoBalanca) {
        errors.layoutArquivoBalanca = mensagensDeValidacao.OBRIGATORIO;
      }
      
      if (!values.departamento) {
        errors.departamento = mensagensDeValidacao.OBRIGATORIO;
      }
      
      return errors;
    },
  
   
    handleSubmit: () => {},
    
  })(ModalExportarArquivoBalancaImpl);

export { ModalExportarArquivoBalanca }