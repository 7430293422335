import { del, exibirToast, get, post, put, services } from 'Common';

export async function getMercadoriasTransporte(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function getMercadoriaTransporte(idMercadoria, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/transporte/mercadoria/${idMercadoria}`, null, onSuccess, onError);
}

export async function createMercadoriaTransporte(mercadoria, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/transporte/mercadoria`,
		mercadoria,
		null,
		exibirToast(onSuccess, 'Mercadoria de transporte criado com sucesso'),
		onError
	);
}

export async function updateMercadoriaTransporte(mercadoria, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transporte/mercadoria/${mercadoria.id}`,
		mercadoria,
		null,
		exibirToast(onSuccess, 'Mercadoria de transporte atualizado com sucesso'),
		onError
	);
}

export async function deleteMercadoriaTransporte(idMercadoria, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/transporte/mercadoria/${idMercadoria}`,
		null,
		exibirToast(onSuccess, 'Mercadoria de transporte excluído'),
		onError
	);
}
