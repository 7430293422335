import { Field, useFormikContext } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { Card, Col, Grid, If, LinkVerDados, SingleSelectPessoa } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { converterPessoaParaForm } from 'views/transporte/CTe/Form/Util/CTeConverter';
import { CTE_TOMADOR } from 'views/transporte/CTe/Form/Util/constantes';

function OutrosParticipantes({ setPessoaSelecionada, activeIndexOutrosParcipantes, setActiveIndexOutrosParcipantes }) {
	const { values, errors, setFieldValue } = useFormikContext();
	const { isSituacaoFinalCTe, informacoesPermissoes, setVisibleModalDadosPessoa } = useContextCTe();

	async function handleChangeExpedidor(event) {
		if (event) {
			const pessoaConvertida = await converterPessoaParaForm('expedidor', event.registro);
			setFieldValue('expedidor', pessoaConvertida);
		} else {
			setFieldValue('expedidor', null);
		}
	}

	async function handleChangeRecebedor(event) {
		if (event) {
			const pessoaConvertida = await converterPessoaParaForm('recebedor', event.registro);
			setFieldValue('recebedor', pessoaConvertida);
		} else {
			setFieldValue('recebedor', null);
		}
	}

	async function handleChangeOutros(event) {
		if (event) {
			const pessoaConvertida = await converterPessoaParaForm('outros', event.registro);
			setFieldValue('outros', pessoaConvertida);
		} else {
			setFieldValue('outros', null);
		}
	}

	return (
		<Card style={{ width: '100%', paddingBottom: '8px' }}>
			<Accordion
				className="tab-accordion-lite"
				activeIndex={activeIndexOutrosParcipantes}
				onTabChange={(event) => setActiveIndexOutrosParcipantes(event.index)}
			>
				<AccordionTab header="Outros participantes">
					<Grid>
						<Col sm="12" md="12" lg="4" xl="4" style={{ padding: '0px' }}>
							<Field
								label="Expedidor"
								name="expedidor"
								component={SingleSelectPessoa}
								value={values?.expedidor}
								onChange={(value) => handleChangeExpedidor(value)}
								errors={errors.expedidor}
								disabled={isSituacaoFinalCTe}
								touched
								{...informacoesPermissoes}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: `${errors?.expedidor ? 'space-between' : 'flex-end'}`,
								}}
							>
								<If test={values.expedidor}>
									<LinkVerDados
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											position: 'relative',
											margin: '-8px 30px -15px 0px',
										}}
										onClick={() => {
											setPessoaSelecionada(values.expedidor);
											setVisibleModalDadosPessoa(true);
										}}
										label="Ver dados do expedidor"
									/>
								</If>
							</div>
						</Col>
						<Col sm="12" md="12" lg="4" xl="4" style={{ padding: '0px' }}>
							<Field
								label="Recebedor"
								name="recebedor"
								component={SingleSelectPessoa}
								value={values?.recebedor}
								onChange={(value) => handleChangeRecebedor(value)}
								errors={errors.recebedor}
								disabled={isSituacaoFinalCTe}
								touched
								{...informacoesPermissoes}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: `${errors?.recebedor ? 'space-between' : 'flex-end'}`,
								}}
							>
								<If test={values.recebedor}>
									<LinkVerDados
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											position: 'relative',
											margin: '-8px 30px -15px 0px',
										}}
										onClick={() => {
											setPessoaSelecionada(values.recebedor);
											setVisibleModalDadosPessoa(true);
										}}
										label="Ver dados do recebedor"
									/>
								</If>
							</div>
						</Col>
						<Col sm="12" md="12" lg="4" xl="4" style={{ padding: '0px' }}>
							<Field
								label="Outros"
								name="outros"
								component={SingleSelectPessoa}
								value={values?.outros}
								onChange={(value) => handleChangeOutros(value)}
								errors={errors.outros}
								disabled={isSituacaoFinalCTe || values?.tomador !== CTE_TOMADOR.OUTROS}
								touched
								{...informacoesPermissoes}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: `${errors?.outros ? 'space-between' : 'flex-end'}`,
								}}
							>
								<If test={values.outros}>
									<LinkVerDados
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											position: 'relative',
											margin: '-8px 30px -15px 0px',
										}}
										onClick={() => {
											setPessoaSelecionada(values.outros);
											setVisibleModalDadosPessoa(true);
										}}
										label="Ver dados outros"
									/>
								</If>
							</div>
						</Col>
					</Grid>
				</AccordionTab>
			</Accordion>
		</Card>
	);
}

export { OutrosParticipantes };
