import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Column } from 'primereact/column';

import {
	Tutorial,
	Grid,
	InputField,
	Dropdown,
	Divider,
	Prompt,
	Form,
	FormActions,
	FormContent,
	NenhumRegistroEncontrado,
	Badge,
	ButtonExcluirTable,
	ButtonNovo,
	Button,
	ButtonEditar,
	tutorialStepsNcm,
	SingleSelectNcm,
	If,
} from 'components';
import {
	removerCaracteres,
	validarUUID,
	estadosCadastro,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	salvarConfiguracaoUsuario,
	mensagensDeValidacao,
	formatarNcm,
} from 'Common';

import { asyncGetNcm } from '../Requests';
import { converterNcmParaFormulario } from '../Util/ncmConverter';
import { helpNcmForm } from './Help';

import ActionButton from './Components/ActionButtons';
import ModalOperacoesTributacoes from './Components/ModalOperacoesTributacoes';
import ModalNovoModeloNcm from './Components/ModalNovoModeloNcm';
import ModalImportarModeloNcm from './Components/ModalImportarModeloNcm';

const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

const Colors = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

const PESQUISAURL = '/tributacoes/ncm';
const CADASTROURL = '/tributacoes/ncm/cadastro';

const initialValue = {
	id: null,
	codigo: null,
	extensao: null,
	descricao: null,
	situacao: 'ATIVO',
	tributacoes: [],
};

function NcmFormView(props) {
	const {
		values,
		dirty,
		setFieldValue,
		resetForm,
		isMobile,
		isTablet,
		isDesktop,
		isModal,
		hideModal,
		setHideBackground,
		ncm,
	} = props;

	const { id, codigoNcm, extensao, descricao, situacao, tributacoes } = values;

	const [dadosBase, setDadosBase] = useState(null);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isNewNcm, setIsNewNcm] = useState(false);
	const [visibleModalOperacoesTributacoes, setVisibleModalOperacoesTributacoes] = useState(false);
	const [visibleModalNovoModeloNcm, setVisibleModalNovoModeloNcm] = useState(false);
	const [visibleModalImportartModeloNcm, setVisibleModalImportarModeloNcm] = useState(false);
	const [isTouched, setIsTouched] = useState(true);
	const menu = useRef(null);

	const idURL = props.match?.params.id;
	const podeInserir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const itensOpcoes = montarItensMenu();

	useEffect(() => {
		if (ncm) {
			setCodigoNcm(ncm);
		}
	}, []);

	useEffect(() => {
		if (setHideBackground) {
			setHideBackground(
				visibleModalOperacoesTributacoes || visibleModalNovoModeloNcm || visibleModalImportartModeloNcm
			);
		}
	}, [visibleModalOperacoesTributacoes, visibleModalNovoModeloNcm, visibleModalImportartModeloNcm]);

	useEffect(() => {
		const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NCM);

		if (!!idURL) {
			setTimeout(() => {
				document.getElementById('InputDescricaoNcm')?.focus();
			}, 500);
		}

		if (validarUUID(idURL) && !isNewNcm && !isModal) {
			getNcmSelected();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NCM, false, null, false);
		}
	}, [idURL]);

	function getNcmSelected() {
		asyncGetNcm(idURL, async ({ data: ncm }) => {
			let ncmConvertida = converterNcmParaFormulario(ncm);
			resetForm({ values: ncmConvertida });
			setDadosBase(ncmConvertida);
		});
	}

	function buscarEstadoCadastro() {
		return id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function renderSituacaoTributacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 0',
		};

		const styleDescription = {
			opacity: row.situacaoTributacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacaoTributacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacaoTributacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacaoTributacao;
		}
	}
	function montarItensMenu() {
		let itens = [];
		let existeTributacoes = tributacoes.length === 0;

		itens.push({
			label: 'Salvar tributação da NCM em novo modelo',
			icon: 'pi pi-plus',
			disabled: existeTributacoes,
			command: () => {
				setVisibleModalNovoModeloNcm(true);
			},
		});
		itens.push({
			label: 'Importar modelo de tributação para NCM',
			icon: 'pi pi-download',
			command: () => {
				setVisibleModalImportarModeloNcm(true);
			},
		});

		return itens;
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ButtonExcluirTable onClick={() => onExcluirTributacao(row)} disabled={!podeEditar} />
			</div>
		);
	}

	function onExcluirTributacao(row) {
		let tributacoesNcm = tributacoes.filter((item) => {
			return item !== row;
		});
		setFieldValue('tributacoes', tributacoesNcm);
	}

	function setCodigoNcm(event) {
		if (event !== null) {
			setFieldValue('codigoNcm', {
				label: formatarNcm(event.registro.codigo),
				value: event.registro.id,
			});
			setFieldValue(
				'descricao',
				event.registro.descricao?.length > 120 ? event.registro.descricao.substring(0, 120) : event.registro.descricao
			);
			setIsTouched(false);
		} else {
			setFieldValue('codigoNcm', null);
			setFieldValue('descricao', null);
			setIsTouched(true);
		}
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsNcm}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Menu model={itensOpcoes} style={{ minWidth: '320px' }} popup={true} ref={menu} id="popup_menu" />
			<Form header="Cadastro de NCM" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButton
						{...props}
						informacoesPermissoes={informacoesPermissoes}
						initialValue={initialValue}
						dadosBase={dadosBase}
						setDadosBase={setDadosBase}
						CADASTROURL={CADASTROURL}
						PESQUISAURL={PESQUISAURL}
						setIsNewNcm={setIsNewNcm}
						isModal={isModal}
						hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid className="step-ncm-principais">
						<Field
							sm="12"
							md={isDesktop ? '3' : '4'}
							lg="3"
							xl="3"
							label="NCM"
							name="codigoNcm"
							obrigatorio
							key={codigoNcm?.value}
							component={SingleSelectNcm}
							value={codigoNcm}
							onChange={(event) => setCodigoNcm(event)}
							disabled={!!id}
							placeholder={'Selecione a NCM'}
							helpMessage={helpNcmForm.codigo}
							useFormErrors={false}
							useFormTouched={false}
							esconderBotao
							touched={isTouched}
							isClearable={false}
							errors={props.errors && props.errors.codigoNcm ? props.errors.codigoNcm : null}
							styles={{
								menuPortal: (base) => ({
									...base,
									width: isMobile ? '90%' : '50%',
								}),
								placeholder: (defaultStyles) => {
									return {
										...defaultStyles,
										whiteSpace: 'nowrap',
									};
								},
							}}
							menuPortalTarget={document.body}
							listarTributacaoNcm={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="1"
							xl="1"
							component={InputField}
							label="Extensão"
							name="extensao"
							value={extensao}
							disabled
							onChange={(e) => setFieldValue('extensao', removerCaracteres(e.target?.value, ['─']))}
							size={2}
							helpMessage={helpNcmForm.extensao}
							{...informacoesPermissoes}
						/>
						<If test={isDesktop || isMobile}>
							<Field
								sm="12"
								md="5"
								lg="6"
								xl="6"
								id={'InputDescricaoNcm'}
								component={InputField}
								label="Descrição"
								name="descricao"
								value={descricao}
								obrigatorio
								onChange={(e) => setFieldValue('descricao', removerCaracteres(e.target?.value, ['─']))}
								size={120}
								helpMessage={helpNcmForm.descricao}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="2"
								lg="2"
								xl="2"
								component={Dropdown}
								label="Situação"
								name="situacao"
								value={situacao}
								obrigatorio
								options={situacaoOptions}
								onChange={(event) => setFieldValue('situacao', event?.value)}
								showClear={false}
								helpMessage={helpNcmForm.situacao}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={isTablet}>
							<Field
								sm="12"
								md="3"
								lg="2"
								xl="2"
								component={Dropdown}
								label="Situação"
								name="situacao"
								value={situacao}
								obrigatorio
								options={situacaoOptions}
								onChange={(event) => setFieldValue('situacao', event?.value)}
								showClear={false}
								helpMessage={helpNcmForm.situacao}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								id={'InputDescricaoNcm'}
								component={InputField}
								label="Descrição"
								name="descricao"
								value={descricao}
								obrigatorio
								onChange={(e) => setFieldValue('descricao', removerCaracteres(e.target?.value, ['─']))}
								size={120}
								helpMessage={helpNcmForm.descricao}
								{...informacoesPermissoes}
							/>
						</If>
						<Divider label="Tributações" styleLabel={{ fontSize: '20px', fontWeight: '500' }} />
					</Grid>
					<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
						{!!tributacoes?.length ? (
							<ButtonEditar
								className="step-editar-tributacoes"
								label="Editar tributações"
								color="primary"
								title="Editar os registros"
								onClick={() => setVisibleModalOperacoesTributacoes(true)}
							/>
						) : (
							<ButtonNovo
								className="step-editar-tributacoes"
								label="Novas tributações"
								title="Adicionar novo registro"
								onClick={() => setVisibleModalOperacoesTributacoes(true)}
							/>
						)}
						<Button
							className="p-button-success"
							type="button"
							label="Modelo de tributação"
							icon="fa fa-list"
							style={{ margin: '5px' }}
							hidden={itensOpcoes.length === 0}
							onClick={(event) => menu.current.toggle(event)}
							aria-controls="popup_menu"
							aria-haspopup={true}
						/>
					</div>
					<DataTable
						className="table step-listagem-tributacoes"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={tributacoes}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							className="step-listagem-order"
							field="operacaoFiscal.label"
							header="Operação"
							body={(row) => row.operacaoFiscal?.label}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: 'calc(100% - 66%)',
								wordBreak: 'break-all',
							}}
						/>
						<Column
							field="tributacaoEstadual.label"
							header="Tributação"
							body={(row) => row.tributacaoEstadual?.label}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: 'calc(100% - 53%)',
								wordBreak: 'break-all',
							}}
						/>
						<Column
							field="situacaoTributacao"
							header="Status tributação"
							body={renderSituacaoTributacao}
							sortable
							style={{ width: '17rem' }}
						/>
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
				</FormContent>
				<If test={visibleModalOperacoesTributacoes}>
					<ModalOperacoesTributacoes
						visible={visibleModalOperacoesTributacoes}
						onHide={() => setVisibleModalOperacoesTributacoes(false)}
						tributacoesNcm={tributacoes}
						setFieldValueForm={setFieldValue}
						permissoes={informacoesPermissoes}
						idURL={idURL}
					/>
				</If>
				<If test={visibleModalNovoModeloNcm}>
					<ModalNovoModeloNcm
						tributacoes={tributacoes}
						visible={visibleModalNovoModeloNcm}
						onHide={() => setVisibleModalNovoModeloNcm(false)}
						showCloseIcon={false}
						permissoes={informacoesPermissoes}
					/>
				</If>
				<If test={visibleModalImportartModeloNcm}>
					<ModalImportarModeloNcm
						visible={visibleModalImportartModeloNcm}
						onHide={() => setVisibleModalImportarModeloNcm(false)}
						setFieldValue={setFieldValue}
						tributacoes={tributacoes}
						atualizarListaNcm={!visibleModalNovoModeloNcm}
					/>
				</If>
			</Form>
		</>
	);
}

const NcmForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.codigoNcm) {
			errors.codigoNcm = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(NcmFormView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isDesktop: state.dispositivo.isDesktop,
});

export default withRouter(connect(mapStateToProps)(NcmForm));
