import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	formatarTelefone,
	formatos,
	inserirMascara,
	manterApenasNumeros,
	permissoes,
	recursos,
	removerCaracteres,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import { APLICACAO, atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import { asyncDeletePessoa, asyncGetPesquisaPessoas } from './Requests';
import { optionsFiltroAvancado } from './Util/constantes';
import { ModalAniversariantes } from './components/ModalAniversariantes';
import { OverlayPanelInformacoesComplementares } from './components/OverlayPanelInformacoesComplementares';

function Pessoas(props) {
	const { history } = props;

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EXCLUIR));
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));
	const [currentPessoaIndex, setCurrentPessoaIndex] = useState(null);

	const panelInformacoesComplementares = useRef(null);

	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [ModalAniversariantesVisible, setModalAniversariantesVisible] = useState(false);
	const tipoAplicacao = buscarDadosLoginLocalStorage()?.organizacao?.aplicacao;

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('PessoasInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado]);

	async function pesquisar() {
		const filtro = buscarFiltro();
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('nome');
			setSortOrder(1);
			campoOrdenacao = 'nome';
			sentidoOrdenacao = 1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/pessoas/resumo`,
			filtro,
			rows,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		await asyncGetPesquisaPessoas(url, ({ data: pessoas }) => {
			setRegistros(pessoas.content);
			setTotalElements(pessoas.totalElements);
			setFirstRender(false);
		});
	}

	function buscarFiltro() {
		let localFiltroAvancado = filtroAvancado || '';

		if (localFiltroAvancado.substr(0, 10) === 'telefone==') {
			localFiltroAvancado = `telefone==${manterApenasNumeros(localFiltroAvancado)}`;
		} else if (localFiltroAvancado.substr(0, 10) === 'telefone=c') {
			localFiltroAvancado = `telefone=contains="*${manterApenasNumeros(localFiltroAvancado)}*"`;
		} else if (localFiltroAvancado.substr(0, 10) === 'telefone!=') {
			localFiltroAvancado = `telefone!=${manterApenasNumeros(localFiltroAvancado)}`;
		}

		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(removerCaracteres(valorPesquisa, ['&']));
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '/', '-', '&']);

		let result = `?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${valorPesquisa.replaceAll(
			'&',
			'%26'
		)}*",tipo=contains="*${valorTiposEnumerados}*",situacao=contains="*${valorTiposEnumerados}*",cpf=contains="*${pesquisaCodigo}*",cnpj=contains="*${pesquisaCodigo}*",email=contains="*${valorPesquisa.replaceAll(
			'&',
			'%26'
		)}*",telefone=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	function onPesquisar() {
		pesquisar();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function onEditar(row) {
		atualizarUrl(history, '/pessoas/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeletePessoa(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function handleShowOverlay(e, rowIndex) {
		document
			.getElementById('overlayPanel-informacoes-complementares')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();

		setCurrentPessoaIndex(rowIndex);
		panelInformacoesComplementares.current?.toggle(e);
	}

	function renderOpcoes(row, options) {
		return (
			<div style={{ display: 'flex' }}>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						marginRight: '3px',
					}}
					className="p-button p-button-primary"
					title="Informações complementares"
					icon="fa fa-info"
					onClick={(e) => handleShowOverlay(e, options.rowIndex)}
					hidden={tipoAplicacao === APLICACAO.DOCS_DIGITAIS}
				/>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function renderCpfCnpj(row) {
		if (row?.tipo === 'JURIDICA') {
			return inserirMascara(row?.cnpj, formatos.CNPJ);
		} else if (row?.tipo === 'FISICA') {
			return inserirMascara(row?.cpf, formatos.CPF);
		} else if (row?.tipo === 'ESTRANGEIRO') {
			return row?.identificacao;
		}

		return null;
	}

	function renderSituacao(row) {
		const label = row?.situacao === 'ATIVA' ? 'Ativa' : 'Inativa';
		const strongColor = row?.situacao === 'ATIVA' ? 'green' : '#da1f1f';
		const lightColor = row?.situacao === 'ATIVA' ? '#dcedc8' : '#ffcdd2';
		const styleBackground = row?.situacao === 'INATIVA' ? '0.2rem 1.2rem' : '0.2rem 1.5rem';

		return (
			<span
				style={{
					backgroundColor: lightColor,
					color: strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					padding: styleBackground,
				}}
			>
				<span style={{ width: '100%', textAlign: 'center' }}>{label}</span>
			</span>
		);
	}

	function renderTipoOuTelefoneQuandoDocs(row) {
		if (tipoAplicacao === APLICACAO.DOCS_DIGITAIS) {
			return formatarTelefone(row.telefone);
		} else {
			let tipo = '';

			if (row.cliente) {
				tipo = 'Cliente';
			}
			if (row.fornecedor) {
				tipo = tipo ? `${tipo} / Fornecedor` : 'Fornecedor';
			}
			if (row.transportador) {
				tipo = tipo ? `${tipo} / Transportador` : 'Transportador';
			}

			return tipo;
		}
	}

	function renderCodigo(row) {
		return <span title={row?.codigo}>{row?.codigo}</span>;
	}

	function renderNome(row) {
		return (
			<span
				title={row?.nome}
				style={{
					overflow: 'hidden',
					display: 'inline-flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{row?.nome}
			</span>
		);
	}

	function renderContato(row) {
		if (tipoAplicacao === APLICACAO.DOCS_DIGITAIS) {
			return (
				<span
					title={row?.email}
					style={{
						overflow: 'hidden',
						wordBreak: 'break-word',
						maxWidth: props.isMobile ? '70%' : '100%',
						textAlign: props.isMobile ? 'end' : 'start',
					}}
				>
					{row?.email}
				</span>
			);
		} else {
			return formatarTelefone(row.telefone);
		}
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form header="Pessoas">
				<FormActions>
					<ButtonNovo
						label="Nova pessoa"
						className="step-listagem-novo"
						onClick={() => {
							history.push('/pessoas/cadastro');
						}}
						podeInserir={podeInserir}
					/>
					<Button
						label="Aniversariantes"
						onClick={() => {
							setModalAniversariantesVisible(true);
						}}
						color="success"
						icon="fa fa-birthday-cake"
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<InputSearch
							className="step-listagem-input-search"
							onPesquisar={() => onPesquisar()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="PessoasInputSearch"
						/>
						<Col sm="12" md="4" lg="4" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={(e) => onPesquisarFiltroAvancado(e)}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							className="step-listagem-order"
							field="codigo"
							header="Código"
							sortable
							body={(row) => renderCodigo(row)}
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '6rem',
							}}
						/>
						<Column
							className="step-listagem-order"
							field="nome"
							header="Nome"
							sortable
							body={(row) => renderNome(row)}
							style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '30rem' }}
						/>
						<Column field="cpfcnpj" header="CPF/CNPJ" body={(row) => renderCpfCnpj(row)} style={{ width: '10rem' }} />
						<Column
							field={tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? 'email' : 'telefone'}
							header={tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? 'E-mail' : 'Telefone'}
							sortable
							body={(row) => renderContato(row)}
							style={{ width: '14rem' }}
						/>
						<Column
							field={tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? 'telefone' : 'tipo'}
							header={tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? 'Telefone' : 'Tipo'}
							sortable
							body={(row) => renderTipoOuTelefoneQuandoDocs(row)}
							style={{ width: tipoAplicacao === APLICACAO.DOCS_DIGITAIS ? '8rem' : '14rem' }}
						/>
						<Column
							field="situacao"
							header="Situação"
							body={(row) => renderSituacao(row)}
							sortable
							style={{ width: '8rem' }}
						/>
						<Column
							className="step-listagem-acoes"
							body={(row, options) => renderOpcoes(row, options)}
							header="Ações"
							style={{ width: '7em' }}
						/>
					</DataTable>
				</FormContent>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
			</Form>
			<If test={ModalAniversariantesVisible}>
				<ModalAniversariantes
					visible={ModalAniversariantesVisible}
					onHide={() => setModalAniversariantesVisible(false)}
					isMobile={props.isMobile}
				/>
			</If>
			<OverlayPanelInformacoesComplementares
				pessoa={registros[currentPessoaIndex]}
				panelInformacoesComplementares={panelInformacoesComplementares}
				isMobile={props.isMobile}
				setCurrentPessoaIndex={setCurrentPessoaIndex}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Pessoas);
