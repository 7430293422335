import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, useFormikContext, withFormik } from 'formik';

import { buscarDadosLoginLocalStorage, mensagensDeValidacao } from 'Common';
import {
	AutoProgressBar,
	Button,
	ButtonCancelar,
	Checkbox,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';

import { enviarCTePorEmail } from 'views/transporte/CTe/Requests';
import { CTE_SITUACAO } from 'views/transporte/CTe/Util/constantes';
import { INITIAL_VALUE_ENVIAR_POR_EMAIL } from 'views/transporte/CTe/Form/Util/constantes';

function ModalCompartilharPorEmailImpl({ visible, onHide, isMobile }) {
	const { values, isValid, setFieldValue, handleSubmit, validateForm } = useFormikContext();
	const { numero, situacao, id } = values.valuesForm;

	const [emailUsuario, setEmailUsuario] = useState(null);

	useEffect(() => {
		const dadosLogin = buscarDadosLoginLocalStorage();

		let assunto = `CT-e Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
		let conteudo = `Olá, segue em anexo as informações do CT-e Nº ${numero}`;

		if (situacao === CTE_SITUACAO.CANCELADO) {
			assunto = `CT-e cancelado Nº ${numero} - ${dadosLogin.filialConectada.nome}`;
			conteudo = `Olá, segue em anexo as informações do CT-e cancelado Nº ${numero}`;
		}

		setFieldValue('assunto', assunto);
		setFieldValue('conteudo', conteudo);

		setEmailUsuario(dadosLogin.email);
	}, []);

	async function handleClickEnviarPorEmail() {
		handleSubmit();
		await validateForm();

		if (isValid && values.destinatarios) {
			const destinatarios = values.destinatarios
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.trim() !== '');
			if (values.enviarCopia) {
				destinatarios.push(emailUsuario);
			}

			await enviarCTePorEmail(id, { ...values, destinatarios }, () => onHide());
		}
	}

	return (
		<Modal header="Enviar CT-e por e-mail" visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions style={{ marginBottom: '10px' }}>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
					<Button
						label={isMobile ? 'Enviar' : 'Enviar por e-mail'}
						icon="fa fa-send"
						onClick={handleClickEnviarPorEmail}
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Destinatários"
							name="destinatarios"
							helpMessage="Pessoas que irão receber o e-mail, podem ser informados vários e-mails separados por vírgula"
							obrigatorio
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Assunto"
							obrigatorio
							name="assunto"
							helpMessage="Assunto (título) do e-mail que será encaminhado para os destinatários"
							size={255}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={TextArea}
							label="Texto do e-mail"
							obrigatorio
							placeholder="Escreva o texto do e-mail aqui..."
							name="conteudo"
							helpMessage="Texto do e-mail que será encaminhado para os destinatários"
							rows={5}
							onChange={(e) => setFieldValue('conteudo', e.target.value)}
						/>
						<If test={emailUsuario}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								label={`Enviar cópia do e-mail para mim (${emailUsuario})`}
								name="enviarCopia"
								helpMessage="Inclui o usuário do sistema na lista de destinatários ao enviar o e-mail"
								onChange={(e) => setFieldValue('enviarCopia', e.checked)}
								checked={values.enviarCopia}
							/>
						</If>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCompartilharPorEmail = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues(props) {
		return { ...INITIAL_VALUE_ENVIAR_POR_EMAIL, valuesForm: props.valuesForm };
	},

	async validate(values) {
		const errors = {};

		if (values.destinatarios) {
			const destinatarios = values.destinatarios.split(',').map((item) => item.trim());
			const schemaEmail = Yup.string().email();

			for (const item of destinatarios) {
				if (item === '') {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				} else if (!schemaEmail.isValid(item)) {
					errors.destinatarios = `O e-mail "${item}" não é um e-mail válido`;
					break;
				}
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		assunto: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		destinatarios: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conteudo: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalCompartilharPorEmailImpl);

export { ModalCompartilharPorEmail };
