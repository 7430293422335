import { copiarObjeto, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	If,
	NenhumRegistroEncontrado,
} from 'components';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import {
	CONTA_PAGAR_STATUS,
	INITIAL_VALUES_PAGAR,
} from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import {
	asyncCreateContaPagar,
	asyncDeleteContaPagar,
	asyncUpdateContaPagar,
	buscarTitulosNovosOuAntigosApi,
} from 'views/financas/ContasPagar/Requests';
import montarMensagemExclusaoTitulos from 'views/financas/Util/functions';
import { atualizarUrl } from 'views/Util';
import { converterContaPagarParaApi } from '../../Util/contaPagarConverter';
import {
	exibirConfirmacaoMesmoNumeroDocumento,
	jaExisteContaPagarComMesmoNumeroDocumentoOrigem,
	saoIguais,
} from '../../Util/util';

function ActionButtons({
	informacoesPermissoes,
	isModal,
	isMobile,
	onHide,
	setExibirModalExclusao,
	filialConectadaId,
	onNovoClick,
	history,
	setRegistroSelecionado,
	atribuirValoresIniciais,
	setExibirModalConfirmacaoEdicao,
	origem,
}) {
	const { values, dirty, handleSubmit, validateForm, isValid, initialValues, resetForm } = useFormikContext();

	const [podeVisualizarNotaEntrada] = useState(
		usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.VISUALIZAR)
	);
	const [titulos, setTitulos] = useState([]);

	const isFromFilialConectada = values.filialId === filialConectadaId;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;
	const panelDetalhes = useRef(null);

	function novo() {
		onNovoClick && onNovoClick();
		resetForm({ values: copiarObjeto(INITIAL_VALUES_PAGAR) });
		atribuirValoresIniciais();
	}

	async function salvar(e, novoOnSuccess) {
		const currentErrors = await validateForm();

		if (Object.keys(currentErrors).length === 0) {
			handleSubmit();
			if (isValid) {
				if (values) {
					if (
						values.numeroDocumento &&
						values.numeroDocumento !== initialValues.numeroDocumento &&
						(await jaExisteContaPagarComMesmoNumeroDocumentoOrigem(values.id, values.numeroDocumento))
					) {
						exibirConfirmacaoMesmoNumeroDocumento(() => {
							salvarOuAtualizar(values, novoOnSuccess);
						});
					} else {
						salvarOuAtualizar(values, novoOnSuccess);
					}
				}
			}
		}
	}

	async function salvarOuAtualizar(values, novoOnSuccess) {
		if (values.id) {
			if (values.itemRepeticao && !alterouApenasRepeticoes()) {
				setExibirModalConfirmacaoEdicao(true);
			} else {
				await asyncUpdateContaPagar(converterContaPagarParaApi(values), () => {
					if (novoOnSuccess) {
						novoOnSuccess();
					} else {
						onHide(false);
					}
				});
			}
		} else {
			await asyncCreateContaPagar(converterContaPagarParaApi(values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					onHide(false);
				}
			});
		}
	}

	function excluir() {
		if (values.itemRepeticao) {
			setExibirModalExclusao(true);
		} else {
			confirm('Confirmação', montarMensagemExclusaoTitulos(values.status, values.origem), async () => {
				await asyncDeleteContaPagar(values.id, () => onHide());
			});
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function alterouApenasRepeticoes() {
		const valuesLocal = { ...values, pagamentos: [] };
		const initialValuesLocal = { ...initialValues, pagamentos: [] };

		return saoIguais(valuesLocal, initialValuesLocal);
	}

	function renderIdentificador(value) {
		return <p>{value.identificador}</p>;
	}

	function renderEmissao(value) {
		return <p>{value.emissao}</p>;
	}

	function renderValor(value) {
		return <p>{value.valor}</p>;
	}

	function handleSelectTitulo(e) {
		setRegistroSelecionado(e.value);
		setTitulos([]);

		setTimeout(() => {
			panelDetalhes.current.toggle(e);
		}, 100);
	}

	async function buscarTitulosNovosOuAntigos(idReparcelamento, tipo) {
		buscarTitulosNovosOuAntigosApi(idReparcelamento, tipo, (response) => {
			setTitulos(response.data);
		});
	}

	function handleOverlayPanel(e, tipo) {
		setTimeout(() => {
			panelDetalhes.current.toggle(e);
		}, 100);

		if (titulos.length <= 0 || (values.reparcelamentoOrigem && values.reparcelamentoDestino)) {
			if (tipo === 'DESTINO') {
				buscarTitulosNovosOuAntigos(values.reparcelamentoOrigem.id, 'ORIGEM');
			} else if (tipo === 'ORIGEM') {
				buscarTitulosNovosOuAntigos(values.reparcelamentoDestino.id, 'DESTINO');
			}
		}
	}

	function desabilitarExcluir() {
		const isOrigemNotaCompra = values.origem === 'NOTA_COMPRA';
		const isTituloReparcelado = values.status === CONTA_PAGAR_STATUS.REPARCELADA;

		if (isOrigemNotaCompra || isTituloReparcelado) {
			return true;
		}
		return false;
	}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={(e) => cancelar(e)}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={(e) => salvar(e)} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal}
				disabled={desabilitarExcluir()}
				{...informacoesPermissoes}
				onClick={(e) => excluir(e)}
			/>
			<If test={values.compraId && podeVisualizarNotaEntrada && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar compra' : 'Compra'}
					icon="fa fa-handshake-o"
					onClick={() => atualizarUrl(history, '/nota_entrada/cadastro', values.compraId)}
				/>
			</If>
			<If test={values.reparcelamentoOrigem}>
				<Button
					className="p-button-info"
					style={{ marginTop: '5px' }}
					label="Acessar títulos novos"
					icon={<IoDocumentTextOutline size={20} style={{ marginRight: '5px' }} />}
					onClick={(e) => handleOverlayPanel(e, 'DESTINO')}
				/>
			</If>
			<If test={values.reparcelamentoDestino}>
				<Button
					className="p-button-info"
					style={{ marginTop: '5px' }}
					label="Acessar títulos antigos"
					icon={<IoDocumentTextOutline size={20} style={{ marginRight: '5px' }} />}
					onClick={(e) => handleOverlayPanel(e, 'ORIGEM')}
				/>
			</If>
			<OverlayPanel style={isMobile ? { right: '12px' } : null} ref={panelDetalhes} showCloseIcon>
				<DataTable
					className="table"
					responsive
					value={titulos}
					emptyMessage={<NenhumRegistroEncontrado />}
					selectionMode="single"
					onSelectionChange={(e) => handleSelectTitulo(e)}
				>
					{origem === 'CONTAS_PAGAR' ? (
						<Column header="Identificador" field="identificador" body={renderIdentificador} />
					) : null}
					<Column header="Emissão" field="emissao" body={renderEmissao} />
					<Column header="Valor" field="valor" body={renderValor} />
				</DataTable>
			</OverlayPanel>
		</>
	);
}

export { ActionButtons };
