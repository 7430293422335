import { useEffect } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';

import { mensagensDeValidacao } from 'Common';
import {
	Dropdown,
	FormActions,
	FormContent,
	Grid,
	InputDate,
	InputDouble,
	InputField,
	Modal,
	SingleSelectCfop,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import {
	CTE_TIPO_DOCUMENTOS_NOTA_FISCAL_OPTIONS,
	INITIAL_VALUE_NOTAS_FISCAIS,
} from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { ActionButtons } from './ActionsButtons';
import { converterDadosNotaFiscalParaForm } from '../../../../Utils/converterDocumentosFiscais';

function ModalDadosNotaFiscalView({ onHide, onSave }) {
	const { visibleModalDadosNotaFiscal, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('id-cte-documentos-nota-fiscal-serie')?.focus();
		}, 200);
	}, []);

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);
		}
	}

	return (
		<Modal visible={visibleModalDadosNotaFiscal} header="Dados da Nota Fiscal" onHide={onHide}>
			<FormActions>
				<ActionButtons {...{ onHide, onHandleSave }} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Modelo da nota fiscal"
						name="modeloDocumento"
						component={Dropdown}
						options={CTE_TIPO_DOCUMENTOS_NOTA_FISCAL_OPTIONS}
						value={values.modeloDocumento}
						onChange={(e) => setFieldValue('modeloDocumento', e)}
						showClear={false}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="2"
						lg="2"
						xl="2"
						id="id-cte-documentos-nota-fiscal-serie"
						label="Série"
						name="serie"
						component={InputField}
						value={values.serie}
						size={3}
						onChange={(e) => setFieldValue('serie', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Número"
						name="numero"
						component={InputField}
						value={values.numero}
						size={20}
						onChange={(e) => setFieldValue('numero', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Emissão"
						name="emissao"
						component={InputDate}
						value={values.emissao}
						onChange={(e) => setFieldValue('emissao', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						label="CFOP"
						name="cfop"
						component={SingleSelectCfop}
						value={values.cfop}
						onChange={(e) => setFieldValue('cfop', e)}
						isClearable={false}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Número romaneio"
						name="numeroRomaneio"
						component={InputField}
						value={values.numeroRomaneio}
						size={20}
						onChange={(e) => setFieldValue('numeroRomaneio', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Número pedido"
						name="numeroPedido"
						component={InputField}
						value={values.numeroPedido}
						size={20}
						onChange={(e) => setFieldValue('numeroPedido', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="BC ICMS"
						name="valorBaseCalculoIcms"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorBaseCalculoIcms}
						onChange={(e) => setFieldValue('valorBaseCalculoIcms', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Valor ICMS"
						name="valorIcms"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorIcms}
						onChange={(e) => setFieldValue('valorIcms', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>

					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="BC ICMS ST"
						name="valorBaseCalculoIcmsSt"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorBaseCalculoIcmsSt}
						onChange={(e) => setFieldValue('valorBaseCalculoIcmsSt', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Valor ICMS ST"
						name="valorIcmsSt"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorIcmsSt}
						onChange={(e) => setFieldValue('valorIcmsSt', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Peso"
						name="peso"
						component={InputDouble}
						decimalScale={3}
						size={15}
						value={values.peso}
						onChange={(e) => setFieldValue('peso', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Valor total dos produto"
						name="valorTotalProdutos"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorTotalProdutos}
						onChange={(e) => setFieldValue('valorTotalProdutos', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Valor total da nota fiscal"
						name="valorTotalNotaFiscal"
						component={InputDouble}
						decimalScale={2}
						size={15}
						value={values.valorTotalNotaFiscal}
						onChange={(e) => setFieldValue('valorTotalNotaFiscal', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalDadosNotaFiscal = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterDadosNotaFiscalParaForm(props.registroSelecionado);
		} else {
			return INITIAL_VALUE_NOTAS_FISCAIS;
		}
	},

	validate(values) {
		const errors = {};

		if (!values.emissao) {
			errors.emissao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.cfop) {
			errors.cfop = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.serie) {
			errors.serie = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.numero) {
			errors.numero = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorBaseCalculoIcms === null || values.valorBaseCalculoIcms === undefined) {
			errors.valorBaseCalculoIcms = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorIcms === null || values.valorIcms === undefined) {
			errors.valorIcms = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorBaseCalculoIcmsSt === null || values.valorBaseCalculoIcmsSt === undefined) {
			errors.valorBaseCalculoIcmsSt = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.valorIcmsSt === null || values.valorIcmsSt === undefined) {
			errors.valorIcmsSt = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.valorTotalProdutos) {
			errors.valorTotalProdutos = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.valorTotalNotaFiscal) {
			errors.valorTotalNotaFiscal = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosNotaFiscalView);

export { ModalDadosNotaFiscal };
