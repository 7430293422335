import { Field } from 'formik';

import { Grid, TextArea } from 'components';

import { helpProdutosForm } from '../../../Help';

export default function FieldsInformacoesComplementares(props) {
	const { setFieldValue, informacoesPermissoes } = props;

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				placeholder="Escreva sua observação aqui"
				name="observacao"
				helpMessage={helpProdutosForm.observacoes}
				onChange={(e) => setFieldValue('observacao', e.target.value)}
				id="ProdutoTextAreaObservacao"
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}
