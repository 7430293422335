import { useState } from 'react';
import ListagemRelatorios from '../../../components/ListagemRelatorios';
import { modulos } from '../../../Common/Constantes/autorizacao';
import { If } from '../../../components';
import { ModalMdfePeriodo } from './components/ModalMdfePeriodo';
import { ModalMdfeNaoEncerrado } from './components/ModalMdfeNaoEncerrado';
import { ModalCtePeriodo } from './components/ModalCTePeriodo';

function RelatoriosTransporte() {
	const [exibirModalMDFEPorPeriodo, setExibirModalMDFEPorPeriodo] = useState(false);
	const [exibirModalMDFENaoEncerrado, setExibirModalMDFENaoEncerrado] = useState(false);
	const [exibirModalCTEPorPeriodo, setExibirModalCTEPorPeriodo] = useState(false);

	const metodosImprimir = [
		{
			nome: 'MDFE_POR_PERIODO',
			onImprimir: () => {
				setExibirModalMDFEPorPeriodo(true);
			},
		},
		{
			nome: 'MDFE_NAO_ENCERRADA',
			onImprimir: () => {
				setExibirModalMDFENaoEncerrado(true);
			},
		},
		{
			nome: 'CTE_POR_PERIODO',
			onImprimir: () => {
				setExibirModalCTEPorPeriodo(true);
			},
		},
	];

	return (
		<>
			<ListagemRelatorios
				header="Relatórios de transporte"
				modulo={modulos.TRANSPORTE}
				metodosImprimir={metodosImprimir}
			/>
			<If test={exibirModalMDFEPorPeriodo}>
				<ModalMdfePeriodo 
					onHide={() => setExibirModalMDFEPorPeriodo(false)} 
					visible={exibirModalMDFEPorPeriodo} />
			</If>
			<If test={exibirModalMDFENaoEncerrado}>
				<ModalMdfeNaoEncerrado
					onHide={() => setExibirModalMDFENaoEncerrado(false)}
					visible={exibirModalMDFENaoEncerrado}
				/>
			</If>
			<If test={exibirModalCTEPorPeriodo}>
				<ModalCtePeriodo
					onHide={() => setExibirModalCTEPorPeriodo(false)}
					visible={exibirModalCTEPorPeriodo}
				/>
			</If>
		</>
	);
}

export { RelatoriosTransporte };
