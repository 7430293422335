import TecnicoForm from '../Form';
import { Modal, If } from 'components';

function ModalTecnico(props) {
	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de técnico"
			visible={props.visible}
			onHide={props.onHide}
		>
			<If test={props.visible}>
				<TecnicoForm
					service={props.service}
					hideModal={props.onHide}
					isModal={true}
					papelObrigatorio={props.papelObrigatorio}
					atualizarRegistrosSelect={props.atualizarRegistrosSelect}
				/>
			</If>
		</Modal>
	);
}

export default ModalTecnico;
