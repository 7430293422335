import { services } from '../../../Common/Constantes/api';
import { DADOSLOCALSTORAGE } from '../../../Common/Constantes/localStorage';
import { buscarItemLocalStorage } from '../../../Common/LocalStorage';
import SingleSelect from '../SingleSelect';

export const typesCst = {
	ENQUADRAMENTO_IPI: 'ENQUADRAMENTO_IPI',
	CST_IPI: 'CST_IPI',
	CST_PIS_COFINS: 'CST_PIS_COFINS',
	CST_CSOSN: 'CST_CSOSN',
	CST_CTE: 'CST_CTE',
};

export default function SingleSelectCst(props) {
	const { label, type, isClearable } = props;
	const regimeTributario = buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).filialConectada?.regimeTributario;

	function buscarUrlPesquisa(pesquisa, page) {
		let query = '';
		if (type === typesCst.CST_CSOSN) {
			query = `?query=${pesquisa}&page=${page}&size=50`;
		} else if (type === typesCst.CST_CTE) {
			query = `?query=${pesquisa}&page=${page}&size=50`;
		} else {
			query = `?query=descricao=contains="*${pesquisa}*",codigo=contains="*${pesquisa}*"&page=${page}&size=50`;
		}

		switch (type) {
			case typesCst.ENQUADRAMENTO_IPI: {
				return `${services.GESTOR}/v1/tributacoes/federal/ipi_enquadramento${query}`;
			}
			case typesCst.CST_IPI: {
				return `${services.GESTOR}/v1/tributacoes/federal/ipi_cst${query}`;
			}
			case typesCst.CST_PIS_COFINS: {
				return `${services.GESTOR}/v1/tributacoes/federal/pis_cofins_cst${query}`;
			}
			case typesCst.CST_CSOSN: {
				return regimeTributario === 'SIMPLES_NACIONAL_EXCESSO' || regimeTributario === 'NORMAL'
					? `${services.GESTOR}/v1/cst_csosn/cst${query}`
					: regimeTributario === 'SIMPLES_NACIONAL' || regimeTributario === 'MEI'
						? `${services.GESTOR}/v1/cst_csosn/csosn${query}`
						: `${services.GESTOR}/v1/cst_csosn${query}`;
			}
			case typesCst.CST_CTE: {
				return `${services.GESTOR}/v1/cst_csosn/cte${query}`;
			}
			default: {
				return null;
			}
		}
	}

	function montarLabel(row) {
		return row.codigo + ' - ' + row.descricao;
	}

	return (
		<>
			<SingleSelect
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={props.onChange}
				isClearable={isClearable}
				esconderBotao
				{...props}
			/>
		</>
	);
}

SingleSelectCst.defaultProps = {
	label: 'CST',
};
