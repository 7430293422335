import { buscarDadosLoginLocalStorage, estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { createContext, useContext, useState } from 'react';
import { connect } from 'react-redux';

const contextConfiguracoesGerais = createContext();

function ConfiguracoesGeraisProvider({ children, isMobile, isTablet, location }) {
	const [podeExcluirEmpresa] = useState(
		usuarioPossuiPermissao(recursos.EMPRESA_CONFIGURACOES, permissoes.EXCLUIR_EMPRESA)
	);
	const [podeEditarEmpresa] = useState(
		usuarioPossuiPermissao(recursos.EMPRESA_CONFIGURACOES, permissoes.EDITAR_EMPRESA)
	);
	const [podeVisualizarVendas, setPodeVisualizarVendas] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR)
	);
	const [credencial] = useState(buscarDadosLoginLocalStorage());
	const [novoLogotipo, setNovoLogotipo] = useState(null);
	const [tabConfiguracoesGerais, setTabConfiguracoesGerais] = useState(0);
	const [tabConfiguracoesFiscais, setTabConfiguracoesFiscais] = useState(0);
	const [tabsParametrizacao, setTabsParametrizacao] = useState(0);
	const [modalExcluirEmpresaVisible, setModalExcluirEmpresaVisible] = useState(false);
	const [modalConfigurarAliquotaSimplesVisible, setModalConfigurarAliquotaSimplesVisible] = useState(false);
	const [marcarCheckboxEmiteNfe, setMarcarCheckboxEmiteNfe] = useState(false);
	const [marcarCheckboxEmiteNfce, setMarcarCheckboxEmiteNfce] = useState(false);
	const [marcarCheckboxEmiteNfse, setMarcarCheckboxEmiteNfse] = useState(false);
	const [marcarCheckboxEmiteMdfe, setMarcarCheckboxEmiteMdfe] = useState(false);
	const [marcarCheckboxEmiteCte, setMarcarCheckboxEmiteCte] = useState(false);
	const [marcarCheckBoxUsaGestaoFechamentoControladoria, setMarcarCheckBoxUsaGestaoFechamentoControladoria] = useState(false);
	const [alterarNumeroInicialESerieNfe, setAlterarNumeroInicialESerieNfe] = useState(false);
	const [alterarNumeroInicialESerieNfce, setAlterarNumeroInicialESerieNfce] = useState(false);
	const [alterarNumeroInicialESerieNfse, setAlterarNumeroInicialESerieNfse] = useState(false);
	const [alterarNumeroInicialESerieMdfe, setAlterarNumeroInicialESerieMdfe] = useState(false);
	const [alterarNumeroInicialESerieCte, setAlterarNumeroInicialESerieCte] = useState(false);
	const [visibleModalContador, setVisibleModalContador] = useState(false);
	const [alterarUltimoNsu, setAlterarUltimoNsu] = useState(false);

	const informacoesPermissoes = {
		estadoCadastro: estadosCadastro.EDICAO,
		podeEditar: podeEditarEmpresa,
	};
	const isHelpDesk = buscarDadosLoginLocalStorage()?.autoridade === 'HELPDESK';

	return (
		<contextConfiguracoesGerais.Provider
			value={{
				isMobile,
				isTablet,
				podeExcluirEmpresa,
				podeEditarEmpresa,
				podeVisualizarVendas,
				setPodeVisualizarVendas,
				credencial,
				novoLogotipo,
				setNovoLogotipo,
				tabConfiguracoesGerais,
				setTabConfiguracoesGerais,
				tabConfiguracoesFiscais,
				setTabConfiguracoesFiscais,
				tabsParametrizacao,
				setTabsParametrizacao,
				modalExcluirEmpresaVisible,
				setModalExcluirEmpresaVisible,
				modalConfigurarAliquotaSimplesVisible,
				setModalConfigurarAliquotaSimplesVisible,
				marcarCheckboxEmiteNfe,
				setMarcarCheckboxEmiteNfe,
				marcarCheckboxEmiteNfce,
				setMarcarCheckboxEmiteNfce,
				marcarCheckboxEmiteNfse,
				setMarcarCheckboxEmiteNfse,
				marcarCheckboxEmiteMdfe,
				setMarcarCheckboxEmiteMdfe,
				marcarCheckboxEmiteCte,
				setMarcarCheckboxEmiteCte,
				marcarCheckBoxUsaGestaoFechamentoControladoria,
				setMarcarCheckBoxUsaGestaoFechamentoControladoria,
				alterarNumeroInicialESerieNfe,
				setAlterarNumeroInicialESerieNfe,
				alterarNumeroInicialESerieNfce,
				setAlterarNumeroInicialESerieNfce,
				alterarNumeroInicialESerieNfse,
				setAlterarNumeroInicialESerieNfse,
				alterarNumeroInicialESerieMdfe,
				setAlterarNumeroInicialESerieMdfe,
				alterarNumeroInicialESerieCte,
				setAlterarNumeroInicialESerieCte,
				visibleModalContador,
				setVisibleModalContador,
				alterarUltimoNsu,
				setAlterarUltimoNsu,
				informacoesPermissoes,
				isHelpDesk,
				location,
			}}
		>
			{children}
		</contextConfiguracoesGerais.Provider>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(ConfiguracoesGeraisProvider);
export const useContextConfiguracoesGerais = () => useContext(contextConfiguracoesGerais);
