import { useCallback } from 'react';
import { copiarObjeto } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';
import { validarFormulario } from '../../../../../../../../../../Util';

function ActionButtons(props) {
	const {
		values,
		convenios,
		initialValue,
		isModal,
		dirty,
		informacoesPermissoes,
		indexConvenio,
		onHide,
		setFieldValueForm,
		resetForm,
		onExcluirRegistro,
		setExibirModalConvenioCertificado,
		propsForm,
	} = props;

	const salvar = useCallback(async () => {
		props.handleSubmit();

		if (await validarFormulario(propsForm)) {
			if (!(await validarNumeroConvenioExistente())) {
				let convenioArray = await copiarObjeto(convenios);
				let existeConvenioCadastrado = false;
				let indexConvenioCadastrado = null;

				await convenioArray?.forEach((convenio, index) => {
					if (convenio.id === values.id) {
						existeConvenioCadastrado = true;
						indexConvenioCadastrado = index;
					}
				});

				if (existeConvenioCadastrado) {
					convenioArray[indexConvenioCadastrado] = values;
				} else {
					convenioArray.push(values);
				}

				await setFieldValueForm(`convenios`, convenioArray);

				onHide();
			} else {
				await props.setFieldError('numero', 'Convênio já cadastrado');
			}
		}
	});

	async function validarNumeroConvenioExistente() {
		const numeroConvenio = await convenios.find(
			(convenio) => convenio.numero === values.numero && convenio.id !== values.id
		);
		if (numeroConvenio) {
			return true;
		}
		return false;
	}

	const cancelar = useCallback(() => {
		if (dirty) {
			values.id
				? resetForm({
						values: {
							...copiarObjeto(props.registroSelecionado),
							bancoSelecionado: values.bancoSelecionado,
						},
					})
				: resetForm({ values: { ...copiarObjeto(initialValue), bancoSelecionado: values.bancoSelecionado } });
		} else {
			onHide();
		}
	}, [dirty]);

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar
				{...informacoesPermissoes}
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={salvar}
			/>
			<ButtonExcluir
				hidden={!indexConvenio}
				{...informacoesPermissoes}
				onClick={() => {
					onExcluirRegistro(values), onHide();
				}}
			/>
			<Button
				label="Sincronizar certificado"
				icon="fa fa-repeat"
				hidden={dirty || !values.id}
				onClick={() => setExibirModalConvenioCertificado(true)}
			/>
		</>
	);
}
export default ActionButtons;
