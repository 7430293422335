import { format } from 'date-fns';
import { colorsStatus } from '../Form/Util/constantes';

export function currentMonth() {
	let date = new Date();
	let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	let formatedDate = `criadoEm>=${firstDay};criadoEm<=${lastDay}`;

	return formatedDate;
}

export function defineColorStatus(situacao) {
	return colorsStatus[situacao] ? colorsStatus[situacao]?.strongColor : colorsStatus['NAO_ENVIADO']?.strongColor;
}
