import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';
import { useFormikContext } from 'formik';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { validarFormulario } from 'views/Util';

function ActionButtons({ onHide, setFieldValueCte }) {
	const { dirty, handleReset, values, validateForm } = useFormikContext();
	const { informacoesPermissoes } = useContextCTe();
	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function salvar() {
		if (await validarFormulario({ values, validateForm })) {
			setFieldValueCte(`documentoAnterior.emissor.registro`, values);

			onHide();
		}
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} {...informacoesPermissoes} />
			<ButtonSalvar onClick={salvar} disabled={!dirty} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
