import { parseFloatNumber } from '../../../../Common';

export function validarValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

export function calcularCusto(valor, quantidadeConvertida) {
	return parseFloatNumber(valor / quantidadeConvertida);
}

export function ordenarItens(itens) {
	return Array.from(itens).sort((a, b) => {
		if (a.item > b.item) {
			return 1;
		}
		if (a.item < b.item) {
			return -1;
		}
		return 0;
	});
}

export function addZero(item) {
	let convertido = '';

	if (item.toString().length === 1) {
		return convertido.concat('0', item);
	} else {
		return item;
	}
}
