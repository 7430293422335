import { useEffect } from 'react';
import { useFormikContext, withFormik } from 'formik';

import { InputField, Modal } from 'components';
import { mensagensDeValidacao } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { INITIAL_VALUE_DOCUMENTO_NFES } from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { converterDadosDocumentoFiscalEletronicoParaForm } from '../../../../Utils/converterDocumentosFiscais';
import { ActionButtons } from './ActionButtons';

function ModalDadosNFeView({ onHide, onSave }) {
	const { values, setFieldValue, validateForm, handleSubmit, handleReset, errors } = useFormikContext();
	const { visibleModalDadosNFe, setVisibleModalDadosNFe, isSituacaoFinalCTe } = useContextCTe();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('id-cte-documentos-nfe-chave-acesso')?.focus();
		}, 200);
	}, []);

	function handleChangeChaveAcesso(e) {
		setFieldValue('chaveAcesso', e.target.value);
	}

	async function onHandleSave(hideModal = false) {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);

			if (!hideModal){
				if (!values.id) {
					handleReset();
					setTimeout(() => {
						document.getElementById('id-cte-documentos-nfe-chave-acesso')?.focus();
					}, 200);
				}
			}else{				
				onHide();
			}			
		}
	}

	function onKeyDownInputField(event){
		if (event.key === "Enter"){
			onHandleSave();
		}		
	}

	return (
		<Modal visible={visibleModalDadosNFe} setVisible={setVisibleModalDadosNFe} header="Dados da NF-e" onHide={onHide} >
			<ActionButtons {...{ onHide, onHandleSave }} />
			<InputField
				id="id-cte-documentos-nfe-chave-acesso"
				name="chaveAcesso"
				type="number"
				label="Chave da NF-e"
				value={values.chaveAcesso}
				onChange={handleChangeChaveAcesso}
				colStyle={{ width: '100%', margin: '8px 0 0 0', padding: 0 }}
				errors={errors.chaveAcesso}
				size={44}
				touched
				obrigatorio
				disabled={isSituacaoFinalCTe}
				onKeyDown={onKeyDownInputField}
			/>
		</Modal>
	);
}

const ModalDadosNFe = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterDadosDocumentoFiscalEletronicoParaForm(props.registroSelecionado);
		} else {
			return INITIAL_VALUE_DOCUMENTO_NFES;
		}
	},

	validate(values) {
		const errors = {};
		if (!values.chaveAcesso) {
			errors.chaveAcesso = mensagensDeValidacao.OBRIGATORIO;			
		}else if (values.chaveAcesso.length < 44){
			errors.chaveAcesso = "A chave deve ter 44 caracteres";
		}	
		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosNFeView);

export { ModalDadosNFe };
