import { mensagensDeValidacao, services } from 'Common';
import {
	DropdownMultiSelect,	
	Grid,
	InputDate,	
	ModalRelatorio,
	MultipleSelectSetores,
	SingleSelectPessoa,
} from 'components';
import { format, formatISO, isValid as isValidDate, parseISO, startOfMonth } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect } from 'react';
import { asyncBaixarRelatorioCtePorPeriodo } from 'views/transporte/CTe/Requests';
import { CTE_SITUACAO_OPTIONS } from 'views/transporte/CTe/Util/constantes';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	tomador: null,
	situacao: [],
	filiaisSelecionadas: [],
	setores: [],
};

function ModalCtePeriodoView({ visible, onHide }) {
	const { values, setFieldValue, validateForm, handleSubmit, isValid, errors } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			try {
				const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
				const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
				let setores = '';
				let filiais = '';
				let situacao = '';
				const {tomador} = values;

				if (values.setores) {
					setores = values.setores.map((item) => `'${item.value}'`).join(',');
				}

				if (values.filiaisSelecionadas) {
					filiais = values.filiaisSelecionadas.map((item) => `'${item.value}'`).join(',');
				}

				if (values.situacao) {
					situacao = values.situacao.map((item) => `'${item.value}'`).join(',');
				}

				await asyncBaixarRelatorioCtePorPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					tomador,
					situacao,
					filiais,
					setores,
					({ data: pdf }) => {
						const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						const arquivoURL = URL.createObjectURL(arquivoPDF);
						const relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = "Relatório CT-es por período";
								}, 250);
							};
						}
						onHide();
					}
				);
			} catch (e) {
				console.error(e);
			}
		}
	}

	const parametros = (					
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				value={values.dataInicial}
				touched
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				value={values.dataFinal}
				touched
			/>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				component={DropdownMultiSelect}
				label="Situação"
				name="situacao"
				onChange={(e) => setFieldValue('situacao', e)}
				options={CTE_SITUACAO_OPTIONS}
				value={values.situacao}
				showClear={false}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				label="Tomador"
				name="tomador"
				component={SingleSelectPessoa}
				esconderBotao
				value={values?.tomador}
				onChange={e => setFieldValue('tomador', e)}
				showClear
			/>
			<Field
				sm="12"
				md="12"
				lg="6"
				xl="6"
				component={MultipleSelectSetores}
				label="Setor(es)"
				name="setores"
				obrigatorio
				url={`${services.GESTOR}/v1/mdfes/relacoes/setores`}
				onChange={(e) => setFieldValue('setores', e)}
				value={values.setores}
				showClear={false}
				utilizaFavorito
			/>
		</Grid>
	);

	return (
		<ModalRelatorio
		  header="Relatório de CT-es por período"
		  visible={visible}
		  onHide={onHide}
		  onImprimir={imprimir}
		  parametros={parametros}
		  isValid={Object.keys(errors).length === 0}
		/>
	);

}

const ModalCtePeriodo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValidDate(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValidDate(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.setores.length === 0) {
			errors.setores = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalCtePeriodoView);

export { ModalCtePeriodo };
