import { Field, useFormikContext, withFormik } from 'formik';

import { keyFilterConsultaRsql, mensagensDeValidacao, services } from 'Common';
import {
	ButtonCancelar,
	ButtonSalvar,
	Form,
	FormActions,
	FormContent,
	Grid,
	Modal,
	SingleSelectTabelaPreco,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	notify,
} from 'components';

import { validarFormulario } from 'views/Util';
import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';
import { importarProdutosDeOutraTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Requests';

function ModalImportarProdutosOutraTabelaImpl({ visible, onCloseModal, fetch, idTabelaPreco }) {
	const { values, dirty, handleReset, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const { informacoesPermissoes, desabilitarCampos } = useContextTabelaPreco();

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else {
			onCloseModal();
		}
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			confirm(
				'Atenção',
				'A importação de produtos de outra tabela vai substituir todos os produtos presentes nesta tabela, deseja continuar?',
				async () => {
					await importarProdutosDeOutraTabela();

					onCloseModal();
				},
				() => {},
				'Sim',
				'Não'
			);
		}
	}

	async function importarProdutosDeOutraTabela() {
		importarProdutosDeOutraTabelaPreco(
			idTabelaPreco,
			values.tabelaOrigem?.value,
			() => {
				fetch();
			},
			() => {
				notify('Erro desconhecido ao importar produtos de outra tabela', ToastTypes.ERROR);
			}
		);
	}

	function onChangeTabelaOrigem(event) {
		setFieldValue('tabelaOrigem', event);
	}

	return (
		<Modal header="Importar produtos de outra tabela" visible={visible} onHide={onCloseModal}>
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={handleClickCancelar}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						label="Importar"
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty || desabilitarCampos}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={SingleSelectTabelaPreco}
							label="Importar produtos da tabela:"
							name="tabelaOrigem"
							url={`${services.GESTOR}/v1/vendas/tabelas_preco/${idTabelaPreco}/buscar_tabelas_importar_replicar_outra_tabela`}
							obrigatorio
							value={values.tabelaOrigem}
							onChange={onChangeTabelaOrigem}
							keyfilter={keyFilterConsultaRsql}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalImportarProdutosOutraTabela = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { tabelaOrigem: null };
	},

	validate(values) {
		const errors = {};

		if (!values.tabelaOrigem) {
			errors.tabelaOrigem = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalImportarProdutosOutraTabelaImpl);

export { ModalImportarProdutosOutraTabela };
