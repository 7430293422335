import { useState } from 'react';

import SingleSelect from '../SingleSelect';
import ModalTecnico from 'views/cadastros/outros/Tecnicos/Modal';

export default function SingleSelectTecnico(props) {
	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${props.url}?query=(nome=contains="*${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50&sort=nome`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return row.nome;
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id) {
			props.onChange({
				label: novoRegistro.nome,
				registro: novoRegistro,
				value: novoRegistro.id,
			});
			setResetPesquisa(true);
		}
	}

	return (
		<>
			<ModalTecnico visible={visible} onHide={onHide} />
			<SingleSelect
				titleBotaoNovo={'Adicionar novo técnico'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				onChange={props.onChange}
				{...props}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectTecnico.defaultProps = {
	label: 'Técnico',
};
