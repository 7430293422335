import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Checkbox,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	InputMoney,
	Prompt,
	Tutorial,
	tutorialStepsComponenteValorPrestacao,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { ActionButtons } from './components/ActionButtons';
import { CADASTRO_URL, INITIAL_VALUES, SITUACAO_OPTIONS } from '../Util/constantes';
import { converterComponenteValorPrestacaoParaFormulario } from '../Util/ComponenteValorPrestacaoConverter';
import { getComponenteValorPrestacao } from '../Request';

function ComponenteValorPrestacaoFormImpl({ isMobile, history, isModal, match, hideModal }) {
	const { values, dirty, resetForm, errors, setFieldValue } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_COMPONENTE_VALOR_PRESTACAO);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	useEffect(() => {
		let id = null;
		if (match?.params){
			id = match.params.id;
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_COMPONENTE_VALOR_PRESTACAO, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('componente-valor-prestacao-input-descricao')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idcomponente) {
		await getComponenteValorPrestacao(idcomponente, ({ data: componente }) => {
			resetForm({ values: converterComponenteValorPrestacaoParaFormulario(componente) });

			if (!isModal) {
				atualizarUrl(history, `/${CADASTRO_URL}`, componente.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsComponenteValorPrestacao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form
				className="card-default screen-max-width"
				header={isMobile ? 'Cadastro de componente' : 'Cadastro de componente de valor de prestação'}
				isModal={isModal}
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						isModal={isModal}
						history={history}
						hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							label="Componente de valor de prestação favorito"
							title="Marque esta opção caso este componente de valor de prestação seja o favorito"
							name="favorito"
							onChange={() => setFieldValue('favorito', !values.favorito)}
							errors={errors.favorito}
							touched
							checked={values.favorito}
							{...informacoesPermissoes}
						/>
						<Field
							id="componente-valor-prestacao-input-descricao"
							sm="12"
							md="5"
							lg="7"
							xl="7"
							component={InputField}
							label="Descrição"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="descricao"
							size={15}
							onChange={(e) => setFieldValue('descricao', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputMoney}
							label="Valor"
							name="valor"
							size={12}
							onChange={(e) => setFieldValue('valor', e.target.value ?? 0)}
							value={values.valor}
							decimalScale={decimaisPreco}
							allowNegative={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							onChange={(event) => setFieldValue('situacao', event.value)}
							options={SITUACAO_OPTIONS}
							showClear={false}
							disabled={isModal}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}
const ComponenteValorPrestacaoFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.descricao) {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.situacao === 'INATIVO' && values.favorito) {
			errors.favorito = 'Componente inativo não pode ser favorito';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ComponenteValorPrestacaoFormImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const ComponenteValorPrestacaoForm = connect(mapStateToProps)(ComponenteValorPrestacaoFormFormik);
