import { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import ModalProduto from '../../../views/cadastros/produtos/Produtos/Modal';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import { copiarObjeto } from '../../../Common/Array';

function converterProdutoNovoParaListagem(produto) {
	if (produto) {
		return {
			...produto,
			ncm: produto.ncm ? { ...produto.ncm.registro } : '',
			cest: produto.cest ? { ...produto.cest.registro } : '',
			unidadeMedida: produto.unidadeMedida ? { ...produto.unidadeMedida } : '',
			valorUnitario: produto.produtoFilialConectada?.preco,
			produtoNovo: true,
		};
	} else {
		return null;
	}
}

function SingleSelectProduto(props) {
	const { value, sortBy = 'codigo' } = props;
	const [valueSingle, setValueSingle] = useState(null);
	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	useEffect(() => {
		let produtoSelecionado = value;
		if (produtoSelecionado) {
			produtoSelecionado = copiarObjeto(value);
			produtoSelecionado.label = produtoSelecionado.label
				? produtoSelecionado.label
				: produtoSelecionado.registro?.codigo + ' - ' + produtoSelecionado.registro?.nome;
		}
		setValueSingle(produtoSelecionado);
	}, [value]);

	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		return `${props.url}?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${pesquisa}*",codigo=contains="*${pesquisa}*",sku=contains="*${pesquisa}*",codigoBarras=contains="${pesquisa}");situacao=="ATIVO"${props.filtroAdicionalUrl}&page=${page}&size=20&sort=${sortBy}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		const registroNovoConvertido = converterProdutoNovoParaListagem(novoRegistro);
		if (novoRegistro) {
			props.onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: registroNovoConvertido,
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return row.codigo + ' - ' + row.nome + (row.sku && row.sku.trim() != '' ? ' - [Ref: ' + row.sku + ']' : '');
	}

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	return (
		<>
			<ModalProduto visible={visible} onHide={onHide} tipoPredefinido={props.tipoPredefinido} service={props.service} />
			<SingleSelect
				titleBotaoNovo="Adicionar novo produto"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				{...props}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				value={valueSingle}
				esconderBotao={props.esconderBotao}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectProduto.defaultProps = {
	label: '',
	filtroAdicionalUrl: '',
};

SingleSelectProduto.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
	/** Filtor adicional a ser passado para o RSQL ex: ";tipo=="PRODUTO";controlarEstoque==true"*/
	filtroAdicionalUrl: propTypes.string,
};

export default SingleSelectProduto;
