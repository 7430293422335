import { services } from 'Common/Constantes/api';
import { get, post, put, del, exibirToast } from 'Common/Requisicoes';

export async function asyncGetGruposNivelRaiz(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/produtos/grupos/nivel_raiz`, null, onSuccess, onError);
}

export async function asyncGetGruposNivelFilho(id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/produtos/grupos/${id}/filhos`, null, onSuccess, onError);
}

export async function asyncPostGrupos(grupo, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/produtos/grupos`,
		grupo,
		null,
		exibirToast(onSuccess, 'Grupo criado com sucesso'),
		onError
	);
}

export async function asyncPutGrupos(id, grupo, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/produtos/grupos/${id}`,
		grupo,
		null,
		exibirToast(onSuccess, 'Grupo atualizado com sucesso'),
		onError
	);
}

export async function asyncDelGrupos(id, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/produtos/grupos/${id}`,
		null,
		exibirToast(onSuccess, 'Grupo excluído com sucesso'),
		onError
	);
}

export async function asyncPutDesvincularProdutos(id, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/produtos/grupos/${id}/desvincular`,
		{},
		null,
		exibirToast(onSuccess, 'Produto(s) atualizado(s) com sucesso'),
		onError
	);
}
