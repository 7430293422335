import { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ButtonNovo from 'components/Button/ButtonNovo';
import Col from 'components/Col';
import DescricaoFiltroAvancado from 'components/DescricaoFiltroAvancado';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Grid from 'components/Grid';
import InputSearch from 'components/input/InputSearch';
import NenhumRegistroEncontrado from 'components/NenhumRegistroEncontrado';
import PesquisaAvancada from 'components/PesquisaAvancada';
import Tutorial from 'components/Tutorial';
import { Checkbox } from 'primereact/checkbox';
import { usuarioPossuiPermissao } from 'Common/Autenticacao';
import { permissoes, recursos } from 'Common/Constantes/autorizacao';
import { removerCaracteres } from 'Common/ManipulacaoDeString';
import { optionsFiltroAvancado } from './Util/constantes';
import { construirUrl } from 'Common/Rsql';
import { services } from 'Common/Constantes/api';
import { atualizarUrl } from '../../../Util';
import { asyncDeleteTributacaoFederal, asyncGetTributacoesFederais } from './Requests';
import ButtonEditarTable from 'components/Button/ButtonEditarTable';
import ButtonExcluirTable from 'components/Button/ButtonExcluirTable';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';
import { Badge } from 'components/Badge';
import { removerElemento } from 'Common/Array';
import './Styles/index.css';
import { buscarConfiguracaoUsuario, configuracoesUsuario, salvarConfiguracaoUsuario } from 'Common/ConfiguracaoUsuario';
import { tutorialStepsListagens } from 'components/Tutorial/steps/Listagens';
import { connect } from 'react-redux';
import { Paginacao } from 'components';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

const Colors = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

function TributacaoFederal(props) {
	const [registros, setRegistros] = useState([]);

	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR));

	const pesquisarCallback = useCallback((e) => {
		pesquisar();
	});

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('InputSearchTributacaoFederal')) {
				document.getElementById('InputSearchTributacaoFederal').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

		let result = `?query=(codigo=contains="*${pesquisaCodigo}*",descricao=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);

		const url = construirUrl(
			`${services.GESTOR}/v1/tributacoes/federal/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await asyncGetTributacoesFederais(url, ({ data: tributacao }) => {
			setRegistros(tributacao.content);
			setTotalElements(tributacao.totalElements);

			if (!exibirBloqueadas && (filtroAvancado === 'situacao=="INATIVO"' || filtroAvancado === 'situacao!="ATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	function onPesquisar() {
		pesquisar();
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{field}
			</span>
		);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/tributacoes/federal/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteTributacaoFederal(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Tributação federal">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova tributação"
						onClick={() => {
							props.history.push('/tributacoes/federal/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchTributacaoFederal"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir tributações inativas
								</label>
							</span>
						}
					>
						<Column
							className="step-listagem-order"
							field="codigo"
							header="Código"
							body={(row) => aplicarEstiloInativa(row, row.codigo)}
							sortable
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '130px',
							}}
						/>
						<Column
							field="descricao"
							header="Descrição"
							body={(row) => aplicarEstiloInativa(row, row.descricao)}
							sortable
						/>
						<Column field="situacao" header="Status" body={renderSituacao} sortable style={{ width: '15rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(TributacaoFederal);
