import If from 'components/If';
import Modal from 'components/Modal';
import TributacaoFederalForm from '../Form';

export default function ModalTributacaoFederal(props) {
	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de tributação federal"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={props.visible}>
				<TributacaoFederalForm hideModal={props.onHide} isModal={true} history={props.history} />
			</If>
		</Modal>
	);
}
