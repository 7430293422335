import {
	buscarDadosLoginLocalStorage,
	copiarObjeto,
	estadosCadastro,
	mensagensDeValidacao,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
} from 'Common';
import { confirm, Modal } from 'components';
import { addMonths, formatISO, isBefore, isValid, parseISO, startOfDay } from 'date-fns';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FormPagar from 'views/financas/components/FormContasPagarReceber/FormPagar';
import { INITIAL_VALUES_PAGAR } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import * as Yup from 'yup';
import {
	asyncDeleteContaPagar,
	asyncDeleteContaPagarProximasEmAberto,
	asyncDeleteContaPagarTodasEmAberto,
	asyncEditarContaPagarProximasEmAberto,
	asyncEditarContaPagarTodasEmAberto,
	asyncUpdateContaPagar,
	buscarCategoriaFavoritaDespesa,
	buscarContaFavoritaDespesa,
	buscarFormaPagamentoDinheiro,
} from '../../Requests';
import { TIPO_CONTA_PAGAR } from '../../Util/constantes';
import { tiposEdicoesContasPagar } from '../ModalEdicaoContasPagar';
import { TIPOS_EXCLUSOES_CONTAS_PAGAR } from '../ModalExclusaoContasPagar';
import { converterContaPagarParaApi, converterContaPagarParaFormulario } from './Util/contaPagarConverter';

function ModalContaPagarView({ valorPadraoDataVencimento, isMobile, isModal, onHide, history, visible, onNovoClick }) {
	const { values, dirty, resetForm } = useFormikContext();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR));

	const [exibirModalPagamento, setExibirModalPagamento] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [exibirModalExclusao, setExibirModalExclusao] = useState(false);
	const [exibirModalConfirmacaoEdicao, setExibirModalConfirmacaoEdicao] = useState(false);
	const [activeIndex, setActiveIndex] = useState([]);
	const { filialConectada } = buscarDadosLoginLocalStorage();

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const isEdicao = values.id !== null;

	useEffect(() => {
		if (!values.id) {
			atribuirValoresIniciais();
		}

		setTimeout(() => {
			document.getElementById('ContasPagarInputMoneyValor')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (registroSelecionado) {
			resetForm({
				values: converterContaPagarParaFormulario({
					...registroSelecionado,
					vendaId: values.vendaId,
					nfeId: values.nfeId,
				}),
			});
		}
	}, [registroSelecionado]);

	async function atribuirValoresIniciais() {
		let vencimento = null;
		let categoria = null;
		let formaPagamento = null;
		let conta = null;
		let contaPago = null;
		let taxaCategoria = null;

		if (valorPadraoDataVencimento) {
			vencimento = formatISO(valorPadraoDataVencimento);
		} else {
			vencimento = formatISO(addMonths(parseISO(values.emissao) || new Date(), 1));
		}

		try {
			const [categoriaData, formaPagamentoData, contaData] = await Promise.all([
				new Promise((resolve) => {
					buscarCategoriaFavoritaDespesa(resolve);
				}),
				new Promise((resolve) => {
					buscarFormaPagamentoDinheiro(resolve);
				}),
				new Promise((resolve) => {
					buscarContaFavoritaDespesa(resolve);
				}),
			]);

			if (categoriaData.data?.totalElements > 0) {
				categoria = {
					label: categoriaData.data.content[0].nome,
					value: categoriaData.data.content[0].id,
					registro: categoriaData.data.content[0],
				};
			}
			if (formaPagamentoData.data?.totalElements > 0) {
				formaPagamento = {
					label: formaPagamentoData.data.content[0].descricao,
					value: formaPagamentoData.data.content[0].id,
					registro: formaPagamentoData.data.content[0],
				};
			}
			if (contaData.data?.totalElements > 0) {
				contaPago = {
					label: contaData.data.content[0].nome,
					value: contaData.data.content[0].id,
					registro: contaData.data.content[0],
				};
				conta = copiarObjeto(contaPago);
			}

			if (filialConectada?.parametrosFinancas?.taxaCategoria) {
				const categoriaTaxa = filialConectada?.parametrosFinancas?.taxaCategoria;
				taxaCategoria = {
					label: categoriaTaxa.nome,
					value: categoriaTaxa.id,
					registro: categoriaTaxa,
				};
			}

			resetForm({
				values: {
					...copiarObjeto(INITIAL_VALUES_PAGAR),
					vencimento,
					categoria,
					formaPagamento,
					contaPago,
					conta,
					taxaCategoria,
				},
			});
		} catch (error) {
			console.error(error);
		}
	}

	function onHideModalExclusao(tipoExclusao) {
		if (tipoExclusao) {
			if (tipoExclusao === TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA) {
				asyncDeleteContaPagar(values.id, () => {
					onHide();
				});
			} else if (tipoExclusao === TIPOS_EXCLUSOES_CONTAS_PAGAR.PROXIMAS_EM_ABERTO) {
				asyncDeleteContaPagarProximasEmAberto(values.id, () => {
					onHide();
				});
			} else {
				asyncDeleteContaPagarTodasEmAberto(values.id, () => {
					onHide();
				});
			}
		} else {
			setExibirModalExclusao(false);
		}
	}

	function onHideModalEdicao(tipoEdicao) {
		if (tipoEdicao) {
			if (tipoEdicao === tiposEdicoesContasPagar.APENAS_SELECIONADA) {
				asyncUpdateContaPagar(converterContaPagarParaApi(values), () => {
					onHide();
				});
			} else if (tipoEdicao === tiposEdicoesContasPagar.PROXIMAS_EM_ABERTO) {
				asyncEditarContaPagarProximasEmAberto(converterContaPagarParaApi(values), () => {
					onHide();
				});
			} else {
				asyncEditarContaPagarTodasEmAberto(converterContaPagarParaApi(values), () => {
					onHide();
				});
			}
		} else {
			setExibirModalConfirmacaoEdicao(false);
		}
	}

	function renderModalHeader(values) {
		if (values.itemRepeticao && values.quantidadeRepeticoes) {
			const tituloRepeticao = `${values.itemRepeticao}/${values.quantidadeRepeticoes}`;
			return values.id ? `Editar conta a pagar ${tituloRepeticao}` : `Nova conta a pagar ${tituloRepeticao}`;
		}

		return values.id ? `Editar conta a pagar` : `Nova conta a pagar`;
	}

	function onHideModal(solicitarConfirmacao = true) {
		if (dirty && solicitarConfirmacao) {
			confirm(
				'Confirmação',
				'A conta a pagar possui informações não salvas, tem certeza que deseja sair sem salvar?',
				() => {
					onHide(true);
				}
			);
		} else {
			onHide(true);
		}
	}

	function buscarValor() {
		if (values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO) {
			return Math.trunc((values.valor / values.quantidadeRepeticoes) * 100) / 100;
		} else {
			return values.valor;
		}
	}

	return (
		<Modal
			header={renderModalHeader(values)}
			visible={visible}
			onHide={onHideModal}
			styleModal={{ padding: isMobile ? '5px' : '20px' }}
			styleCloseButton={{
				marginTop: isMobile ? '-0.5rem' : '0',
			}}
		>
			<FormPagar
				{...{
					isMobile,
					isModal,
					onHideModal,
					history,
					onNovoClick,
					onHideModalExclusao,
					onHideModalEdicao,
					atribuirValoresIniciais,
					buscarValor,
					podeEditar,
					registroSelecionado,
					setRegistroSelecionado,
					exibirModalExclusao,
					setExibirModalExclusao,
					exibirModalConfirmacaoEdicao,
					setExibirModalConfirmacaoEdicao,
					exibirModalPagamento,
					setExibirModalPagamento,
					activeIndex,
					setActiveIndex,
					filialConectada,
					informacoesPermissoes,
					isEdicao,
				}}
			/>
		</Modal>
	);
}

const ModalContaPagar = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterContaPagarParaFormulario(props.registroSelecionado);
		} else {
			return INITIAL_VALUES_PAGAR;
		}
	},

	validate(values) {
		const errors = {};

		if (values.pagamentos.length > 0) {
			let valorTotalPagamentos = 0;

			values.pagamentos.forEach((pagamento) => {
				valorTotalPagamentos += pagamento.valor;
			});

			if (valorTotalPagamentos > values.valor) {
				errors.pagamentos = 'O valor a pagar não pode ser maior que o valor total da conta';
			}
		}

		if (values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (isBefore(startOfDay(parseISO(values.vencimento)), startOfDay(parseISO(values.emissao)))) {
			errors.vencimento = 'Venc. menor que emissão';
		}

		if (values.emissao && !isValid(parseISO(values.emissao))) {
			errors.emissao = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.vencimento && !isValid(parseISO(values.vencimento))) {
			errors.vencimento = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.competencia && !isValid(parseISO(values.competencia))) {
			errors.competencia = mensagensDeValidacao.DATA_INVALIDA;
			errors.activeIndex = [0];
		}

		if (values.dataDocumento && !isValid(parseISO(values.dataDocumento))) {
			errors.dataDocumento = mensagensDeValidacao.DATA_INVALIDA;
			errors.activeIndex = [0];
		}

		if (values.tipo !== TIPO_CONTA_PAGAR.UNICA) {
			if (values.quantidadeRepeticoes < 2) {
				errors.quantidadeRepeticoes = 'Não pode ser inferior a 2';
			}
		}

		if (values.observacao && values.observacao.length > 4096) {
			errors.activeIndex = [0];
		}

		if (values.pago) {
			if (values.valorPago <= 0) {
				errors.valorPago = 'O valor deve ser maior que zero';
			}
			if (!isValid(parseISO(values.dataPago))) {
				errors.dataPago = mensagensDeValidacao.DATA_INVALIDA;
			}

			if (!values.contaPago) {
				errors.contaPago = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (!values.conta) {
			errors.conta = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.formaPagamento) {
			errors.formaPagamento = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.compensacaoAutomatica) {
			if (!values.dataCompensacao) {
				errors.dataCompensacao = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.tipoCompensacao) {
				errors.tipoCompensacao = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.taxaValor > 0 || values.taxaAliquota > 0) {
			if (!values.taxaCategoria) {
				errors.taxaCategoria = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		vencimento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		emissao: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		competencia: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataDocumento: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		valor: Yup.string().required(mensagensDeValidacao.OBRIGATORIO),
		numeroDocumento: Yup.string().max(50, 'O campo observação não pode ter mais que 50 caracteres.').nullable(),
		observacao: Yup.string().max(4096, 'O campo observação não pode ter mais que 4096 caracteres.').nullable(),
	}),

	handleSubmit: () => {},
})(ModalContaPagarView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalContaPagar));
