export const DATA_ACTION_TYPES = {
	SET_DATA: 'SET_DATA',
	ADD_RANKING_TOMADORES: 'ADD_RANKING_TOMADORES',
	ADD_RANKING_COMPONENTES_VALOR_PRESTACAO: 'ADD_RANKING_COMPONENTES_VALOR_PRESTACAO',
	ADD_RANKING_PRODUTOS_PREDOMINANTES: 'ADD_RANKING_PRODUTOS_PREDOMINANTES',
};

export function setData(data) {
	return {
		type: DATA_ACTION_TYPES.SET_DATA,
		payload: {
			...data,
		},
	};
}

export function addDataRankingTomadores(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_TOMADORES,
		payload: {
			...data,
		},
	};
}

export function addDataRankingComponentesValorPrestacao(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_COMPONENTES_VALOR_PRESTACAO,
		payload: {
			...data,
		},
	};
}

export function addDataRankingProdutosPredominantes(data) {
	return {
		type: DATA_ACTION_TYPES.ADD_RANKING_PRODUTOS_PREDOMINANTES,
		payload: {
			...data,
		},
	};
}
