import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usePrevious,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	PesquisaAvancada,
	ToastTypes,
	Tutorial,
	confirm,
	notify,
	tutorialStepsListagens,
} from 'components';
import { format, formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from '../../../Util';
import {
	asyncCancelarPedidoOrcamento,
	asyncDeleteVenda,
	asyncGetTotalizadores,
	asyncGetVenda,
	asyncGetVendasOrcamentos,
	asyncImprimirVenda,
	asyncUpdateVenda,
} from './Requests';
import { Operacao, OptionsFiltroAvancadoOrcamento, OptionsFiltroAvancadoPedido, Status, Tipo } from './Util/constantes';
import { montarMensagemToast } from './Util/funcoes';
import CardsOrcamentoVendas from './components/Cards';
import TabelaVendasOrcamentos from './components/TabelaVendasOrcamentos';

import { TypeErrors } from './Form/Util/constantes';

function currentMonth() {
	const date = new Date();
	const firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	const lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	const formatedDate = `emissao>=${firstDay};emissao<=${lastDay}`;

	return formatedDate;
}

function VendasOrcamentos(props) {
	const { location, history, isMobile, match } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		valorCard,
		setValorCard,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [isPedidoVenda, setIsPedidoVenda] = useState(true);
	const [podeInserir] = useState(isVenda() ? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.INSERIR) : usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.INSERIR));
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);
	const [filtroData, setFiltroData] = useState(
		`emissao>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};emissao<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [cards, setCards] = useState({
		pendente: {
			quantidade: 0,
			valor: 0,
		},
		finalizado: {
			quantidade: 0,
			valor: 0,
		},
		cancelada: {
			quantidade: 0,
			valor: 0,
		},
	});
	const prevProps = usePrevious(props);
	const urlVendaOuOrcamento = isVenda() ? 'pedidos_venda' : 'orcamentos';
	const [firstRender, setFirstRender] = useState(true);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar(false);
		}
	});

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}
		pesquisar(false);
		setTimeout(() => {
			document.getElementById('VendasInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setIsPedidoVenda(location.pathname === '/pedido_venda');
		if (prevProps?.location.pathname && location.pathname !== prevProps?.location.pathname) {
			setFiltroData(currentMonth());
		}
	}, [location.pathname, prevProps]);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, valorCard]);

	function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function buscarFiltro(isNovaPagina) {
		const isPedidoVenda = isVenda() ? `tipo==${Tipo.PEDIDO_VENDA}` : `tipo==${Tipo.ORCAMENTO}`;

		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

		let filtroRSQL = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`status=contains="*${valorTiposEnumerados.replaceAll('&', '%26')}*",`)
			.concat(`clienteNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`totalLiquido=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		filtroRSQL = filtroRSQL.concat(`;(${isPedidoVenda})`);

		if (filtroData && !isNovaPagina) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	async function pesquisar(isNovaPagina) {
		if (interval.dataInicial != null && interval.dataFinal != null) {
			const filtro = buscarFiltro(isNovaPagina);
			let isPedidoVenda = isVenda();

			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('numero');
				setSortOrder(-1);
				campoOrdenacao = 'numero';
				sentidoOrdenacao = -1;
			}
			const url = construirUrl(
				`${services.GESTOR}/v1/${urlVendaOuOrcamento}/resumo`,
				filtro || '?',
				size,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			if (location.pathname === '/pedido_venda') {
				isPedidoVenda = true;
				setIsPedidoVenda(true);
			} else if (location.pathname === '/orcamento') {
				isPedidoVenda = false;
				setIsPedidoVenda(false);
			}

			asyncGetTotalizadores(urlVendaOuOrcamento, interval, ({ data: totais }) => {
				const cardsTotalizadores = {
					pendente: {
						quantidade: 0,
						valor: 0,
					},
					finalizado: {
						quantidade: 0,
						valor: 0,
					},
					cancelada: {
						quantidade: 0,
						valor: 0,
					},
				};

				totais &&
					totais.forEach((item) => {
						if (isPedidoVenda) {
							if (item.tipo === Tipo.PEDIDO_VENDA) {
								if (item.status === Status.PENDENTE) {
									cardsTotalizadores.pendente = {
										quantidade: item.quantidade,
										valor: item.valor,
									};
								} else if (item.status === Status.FINALIZADO) {
									cardsTotalizadores.finalizado = {
										quantidade: item.quantidade,
										valor: item.valor,
									};
								} else if (item.status === Status.CANCELADA) {
									cardsTotalizadores.cancelada = {
										quantidade: item.quantidade,
										valor: item.valor,
									};
								}
							}
						} else if (item.tipo === Tipo.ORCAMENTO) {
							if (item.status === Status.PENDENTE) {
								cardsTotalizadores.pendente = {
									quantidade: item.quantidade,
									valor: item.valor,
								};
							} else if (item.status === Status.FINALIZADO) {
								cardsTotalizadores.finalizado = {
									quantidade: item.quantidade,
									valor: item.valor,
								};
							} else if (item.status === Status.CANCELADA) {
								cardsTotalizadores.cancelada = {
									quantidade: item.quantidade,
									valor: item.valor,
								};
							}
						}
					});
				setCards(cardsTotalizadores);
			});

			asyncGetVendasOrcamentos(url, ({ data: vendas }) => {
				setRegistros(
					isPedidoVenda
						? vendas.content.filter((item) => item.tipo === 'PEDIDO_VENDA')
						: vendas.content.filter((item) => item.tipo === 'ORCAMENTO')
				);
				setTotalRecords(vendas.totalElements);
				setFirstRender(false);
			});
		}
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`emissao>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};emissao<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function handleEditItem(item) {
		atualizarUrl(history, `/${isVenda() ? 'pedido_venda' : 'orcamento'}/cadastro/${item.id}`);
	}

	function isVenda() {
		if (match.path === '/pedido_venda') {
			return true;
		} else if (match.path === '/pedido_venda/cadastro') {
			return true;
		} else if (match.path === '/pedido_venda/cadastro/:id') {
			return true;
		} else {
			return false;
		}
	}

	function handleRemoveItem(item) {
		const isPedidoVenda = isVenda();
		const { numero } = item;

		confirm(
			'Atenção',
			`Você tem certeza que deseja <b>excluir</b> ${isPedidoVenda ? 'a venda ' : 'o orçamento '} número ${numero}?`,
			() => {
				const mensagem = montarMensagemToast(item.tipo, Operacao.EXCLUIR);
				asyncDeleteVenda(urlVendaOuOrcamento, item.id, mensagem, () => {
					pesquisar();
				});
			}
		);
	}

	async function handlePrintItem(item) {
		const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id;
		await asyncImprimirVenda(urlVendaOuOrcamento, item.id, organizacaoId, ({ data: pdf }) => {
			const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = urlVendaOuOrcamento === 'orcamentos' ? 'Orçamento' : 'Pedido de Venda';
					}, 250);
				};
			}
		});
	}

	function handleCancelItem(item) {
		asyncCancelarPedidoOrcamento(urlVendaOuOrcamento, item.id, item.motivoCancelamento, () => {
			pesquisar();
		});
	}

	function handleGenerateSale(item) {
		try {
			if (item.temProdutoInativo) {
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}
			atualizarUrl(history, '/pedido_venda/importar_orcamento', item.id);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function handleGenerateBudget(item) {
		asyncGetVenda(urlVendaOuOrcamento, item.id, ({ data: venda }) => {
			const mensagem = montarMensagemToast(item.status, Operacao.EDITAR);
			asyncUpdateVenda(urlVendaOuOrcamento, { ...venda, tipo: Tipo.ORCAMENTO }, mensagem, () => {
				pesquisar();
			});
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header={isPedidoVenda ? 'Pedidos de venda' : 'Orçamentos de venda'}>
				<FormActions>
					<Link
						to={
							podeInserir
								? {
										pathname: `/${isPedidoVenda ? 'pedido_venda' : 'orcamento'}/cadastro`,
									}
								: { pathname: `/${isPedidoVenda ? 'pedido_venda' : 'orcamento'}` }
						}
					>
						<Button
							className="step-listagem-novo p-button-primary"
							label={isPedidoVenda ? 'Novo pedido' : 'Novo orçamento'}
							icon="fa fa-plus"
							title={isPedidoVenda ? 'Inserir um novo pedido de venda' : 'Inserir um novo orçamento'}
							onClick={null}
							podeInserir={podeInserir}
							disabled={!podeInserir}
							style={{ margin: '5px 5px 5px 0' }}
						/>
					</Link>
				</FormActions>

				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={(e) => handleChangeInterval(e)} interval={interval} />
						</span>
						<InputSearch
							className="step-listagem-input-search"
							onPesquisar={pesquisarCallback}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="VendasInputSearch"
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={isPedidoVenda ? OptionsFiltroAvancadoPedido : OptionsFiltroAvancadoOrcamento}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(event) => setDescricaoFiltroAvancado(event)}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<CardsOrcamentoVendas
							isMobile={isMobile}
							isPedidoVenda={isPedidoVenda}
							interval={interval}
							setPage={setPage}
							setValorCard={setValorCard}
							pesquisarCallback={pesquisarCallback}
							cards={cards}
							selectedCard={selectedCard}
							setSelectedCard={setSelectedCard}
						/>
					</Grid>
					<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaVendasOrcamentos
							registros={registros}
							totalRecords={totalRecords}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							setSortField={(sortField) => setSortField(sortField)}
							setSortOrder={(sortOrder) => setSortOrder(sortOrder)}
							onEditItem={handleEditItem}
							onRemoveItem={handleRemoveItem}
							onPrint={handlePrintItem}
							onReject={handleCancelItem}
							onGenerateSale={handleGenerateSale}
							onGenerateBudget={handleGenerateBudget}
							onPageChange={(event) => {
								setSize(event.rows);
								setRows(event.rows);
								setPage(event.page);
							}}
							onPesquisar={pesquisarCallback}
							history={history}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(VendasOrcamentos);
