import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { converterContaParaFormulario } from './Util/contaConverter';
import { Modal, AutoProgressBar, Form, FormActions, FormContent, If, Message } from 'components';
import {
	usuarioPossuiPermissao,
	recursos,
	permissoes,
	estadosCadastro,
	mensagensDeValidacao,
	copiarObjeto,
} from 'Common';
import { asyncGetRegistro } from './Requests';
import FormConta from './components/FormConta';
import FormDadosBancarios from './components/FormDadosBancarios';
import FormConvenios from './components/FormConvenios';
import { bancosHomologados } from './components/FormConvenios/ModalNovoConvenio/Util';
import ActionButtons from './components/ActionButtons';

const initialValue = {
	id: '',
	nome: '',
	tipo: 'DINHEIRO',
	saldoInicial: 0,
	favoritaReceita: false,
	favoritaDespesa: false,
	emitirBoletos: false,
	recebePix: false,
	saldoAtual: null,
	situacao: 'ATIVA',
	contaBancaria: {
		banco: null,
		numeroConta: null,
		digitoConta: null,
		agencia: null,
		digitoAgencia: null,
		codigoBeneficiario: null,
		codigoBancoCorrespondente: null,
		codigoContaEmpresa: null,
		validacaoAPIAtiva: true,
		clientIdPix: null,
		clientSecretPix: null,
		chavePix: null,
		percentualMultaPix: 0,
		percentualJurosPix: 0,
		percentualDescontoPix: 0,
		diasValidadePix: 180,
	},
	convenios: [],
};

function ModalContaView(props) {
	const {
		visible,
		onHide,
		dirty,
		values,
		isModal,
		esconderBotaoExcluir,
		insertJustOne,
		isMobile,
		isTablet,
		isLessHd,
		registroSelecionado,
	} = props;

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.INSERIR)
	);
	const [podeEditar, setPodeEditar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.EDITAR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CONTAS_FINANCEIRAS, permissoes.EXCLUIR)
	);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	const camposObrigatorios = values.emitirBoletos;

	useEffect(() => {
		if (registroSelecionado) {
			asyncSelectRegistro(registroSelecionado);
		} else {
			novo();
		}

		setTimeout(() => {
			document.getElementById('ContasInputFieldNomeConta')?.focus();
		}, 500);
	}, []);

	async function asyncSelectRegistro(idConta) {
		await asyncGetRegistro(idConta.id ?? idConta, ({ data: contaApi }) => {
			let conta = converterContaParaFormulario(contaApi);
			props.resetForm({ values: conta });
		});
	}

	function novo() {
		props.resetForm({ values: copiarObjeto(initialValue) });
	}

	function montarTextoValidacoesPlugBoletos() {
		return (
			<div>
				{props.errors.message?.length > 1 ? (
					<span style={{ fontWeight: 'bold' }}>Erros de validação encontrados:</span>
				) : (
					<span style={{ fontWeight: 'bold' }}>Erro de validação encontrado:</span>
				)}
				<br />

				{props.errors.message?.map((erro, index) =>
					erro ? (
						<div key={`erro-message-${index}`} style={{ marginTop: '8px' }}>
							<span>{`- ${erro}`}</span>
							<br />
						</div>
					) : null
				)}
			</div>
		);
	}

	return (
		<Modal
			header={values.id ? 'Editar conta' : 'Cadastrar nova conta'}
			visible={visible}
			onHide={() => {
				novo();
				onHide(values);
			}}
		>
			<AutoProgressBar />
			<Form style={{ boxShadow: 'black 0 0 0 0', padding: '0' }}>
				<FormActions>
					<ActionButtons
						isModal={isModal}
						esconderBotaoExcluir={esconderBotaoExcluir}
						insertJustOne={insertJustOne}
						informacoesPermissoes={informacoesPermissoes}
						dirty={dirty}
						values={values}
						novo={novo}
						handleSubmit={props.handleSubmit}
						initialValues={props.initialValues}
						resetForm={props.resetForm}
						setFieldError={props.setFieldError}
						onHide={onHide}
						propsForm={props}
					/>
				</FormActions>
				<FormContent>
					<If test={props.errors?.message}>
						<Message severity="error" text={montarTextoValidacoesPlugBoletos()} />
					</If>
					<FormConta
						informacoesPermissoes={informacoesPermissoes}
						values={values}
						setFieldValue={props.setFieldValue}
						isMobile={isMobile}
					/>
					<If test={['CORRENTE', 'POUPANCA'].includes(values.tipo)}>
						<FormDadosBancarios
							camposObrigatorios={camposObrigatorios}
							informacoesPermissoes={informacoesPermissoes}
							values={values.contaBancaria}
							bancoEmissorBoletos={values.emitirBoletos}
							bancoRecebedorPix={values.recebePix}
							setFieldValue={props.setFieldValue}
							isTablet={isTablet}
							isLessHd={isLessHd}
							errors={props.errors.contaBancaria}
							emitirBoletos={values.emitirBoletos}
						/>
					</If>
					<If test={bancosHomologados.includes(values.contaBancaria.banco?.value) && values.emitirBoletos}>
						<FormConvenios
							camposObrigatorios={camposObrigatorios}
							informacoesPermissoes={informacoesPermissoes}
							values={values}
							setFieldValue={props.setFieldValue}
							resetForm={props.resetForm}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							errors={props.errors}
							emitirBoletos={values.emitirBoletos}
						/>
					</If>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalConta = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};
		let contaBancariaErrors = {};
		let errorsMessage = [];
		if ((values.tipo === 'CORRENTE' || values.tipo === 'POUPANCA') && values.recebePix === true) {
			if (!values.contaBancaria.clientIdPix) {
				contaBancariaErrors = { ...contaBancariaErrors, clientIdPix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.clientSecretPix) {
				contaBancariaErrors = { ...contaBancariaErrors, clientSecretPix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.chavePix) {
				contaBancariaErrors = { ...contaBancariaErrors, chavePix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.percentualMultaPix && values.contaBancaria.percentualMultaPix !== 0) {
				contaBancariaErrors = { ...contaBancariaErrors, percentualMultaPix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.percentualJurosPix && values.contaBancaria.percentualJurosPix !== 0) {
				contaBancariaErrors = { ...contaBancariaErrors, percentualJurosPix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.percentualDescontoPix && values.contaBancaria.percentualDescontoPix !== 0) {
				contaBancariaErrors = { ...contaBancariaErrors, percentualDescontoPix: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.diasValidadePix && values.contaBancaria.diasValidadePix !== 0) {
				contaBancariaErrors = { ...contaBancariaErrors, diasValidadePix: mensagensDeValidacao.OBRIGATORIO };
			}
		}

		if ((values.tipo === 'CORRENTE' || values.tipo === 'POUPANCA') && values.emitirBoletos === true) {
			if (!values.contaBancaria.banco) {
				contaBancariaErrors = { ...contaBancariaErrors, banco: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.numeroConta) {
				contaBancariaErrors = { ...contaBancariaErrors, numeroConta: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.agencia) {
				contaBancariaErrors = { ...contaBancariaErrors, agencia: mensagensDeValidacao.OBRIGATORIO };
			}
			if (!values.contaBancaria.codigoBeneficiario) {
				contaBancariaErrors = { ...contaBancariaErrors, codigoBeneficiario: mensagensDeValidacao.OBRIGATORIO };
			}

			if (values.convenios.length > 0) {
				let errorsMessageConvenio = [];

				values.convenios?.forEach((convenio) => {
					//BB
					if (values.contaBancaria.banco?.value === 'cce6d2ff-71ed-48a9-88ff-20932616acf9') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiKey) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API KEY" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiSecret) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API SECRET" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Santander
					if (values.contaBancaria.banco?.value === '41b59254-ad4f-45b2-9172-39fd8cc827c3') {
						if (!convenio.apiEstacao) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ESTAÇÃO" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Unicred
					if (values.contaBancaria.banco?.value === '42bdc03d-06c1-4fcb-ac9e-6a9d99117099') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiKey) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API KEY" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiSecret) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API SECRET" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiEstacao) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ESTAÇÃO" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Bradesco
					if (values.contaBancaria.banco?.value === 'd5dc03be-d0f4-4063-b992-60ea79df91de') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiKey) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API KEY" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiSecret) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API SECRET" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Itau
					if (values.contaBancaria.banco?.value === 'ac9a6bb7-f836-45b0-bed2-88122067450f') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiSecret) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API SECRET" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Sicredi
					if (values.contaBancaria.banco?.value === 'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiKey) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API KEY" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiSecret) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API SECRET" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
						if (!convenio.apiEstacao) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ESTAÇÃO" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
					//Sicoob
					if (values.contaBancaria.banco?.value === '207cf63b-167a-42da-acb7-eb7be66fde67') {
						if (!convenio.apiId) {
							errorsMessageConvenio = [
								...errorsMessageConvenio,
								`Campo "API ID" do convênio número ${convenio.numero} é obrigatório.`,
							];
						}
					}
				});

				if (Object.keys(errorsMessageConvenio).length > 0) {
					errorsMessage = [...errorsMessage, ...errorsMessageConvenio];
				}
			}

			if (values.contaBancaria.validacaoAPIAtiva) {
				//Bradesco
				if (values.contaBancaria.banco?.value === 'd5dc03be-d0f4-4063-b992-60ea79df91de') {
					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}
					if (!values.contaBancaria.codigoContaEmpresa) {
						errorsMessage = [...errorsMessage, `Código conta empresa: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}
					if (values.contaBancaria.codigoBeneficiario !== values.contaBancaria.numeroConta) {
						errorsMessage = [...errorsMessage, `Código beneficiário deve ser igual ao número da conta.`];
					}
				}
				//Sicredi
				if (values.contaBancaria.banco?.value === 'fe79b0ad-602f-4fa9-a1c8-6a0e5a6447ad') {
					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}

					if (String(values.contaBancaria.agencia).length < 6) {
						errorsMessage = [...errorsMessage, `Agência deve conter 6 dígitos, composto de agência + posto.`];
					}
				}
				//BB
				if (values.contaBancaria.banco?.value === 'cce6d2ff-71ed-48a9-88ff-20932616acf9') {
					if (String(values.contaBancaria.agencia)?.length > 5) {
						errorsMessage = [...errorsMessage, `A agência deve conter no máximo 5 caracteres.`];
					}

					if (!values.contaBancaria.digitoAgencia) {
						errorsMessage = [...errorsMessage, `Dígito agência: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}

					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}

					if (!values.contaBancaria.codigoContaEmpresa) {
						errorsMessage = [...errorsMessage, `Código conta empresa: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}
				}
				//Santander
				if (values.contaBancaria.banco?.value === '41b59254-ad4f-45b2-9172-39fd8cc827c3') {
					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}
				}
				//Caixa
				if (values.contaBancaria.banco?.value === 'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e') {
					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}
				}
				//Itaú
				if (values.contaBancaria.banco?.value === 'ac9a6bb7-f836-45b0-bed2-88122067450f') {
					if (!values.contaBancaria.digitoConta) {
						errorsMessage = [...errorsMessage, `Dígito número: ${mensagensDeValidacao.OBRIGATORIO}.`];
					}

					if (values.contaBancaria.codigoBeneficiario !== values.contaBancaria.numeroConta) {
						errorsMessage = [...errorsMessage, `Código beneficiário deve ser igual ao número da conta.`];
					}
				}

				if (values.convenios.length > 0) {
					let errorsMessageConvenio = [];

					values.convenios?.forEach((convenio) => {
						//Bradesco
						if (values.contaBancaria.banco?.value === 'd5dc03be-d0f4-4063-b992-60ea79df91de') {
							if (String(convenio.numeroCarteira)?.length > 2) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Campo "Número carteira" do convênio número ${convenio.numero} deve conter até 2 caracteres.`,
								];
							}
						}
						//Banrisul
						if (values.contaBancaria.banco?.value === '0d771543-f868-440f-90bc-8919e9943e24') {
							if (
								!['1', '2', '3', 'B', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'N', 'P', 'R', 'S', 'T', 'U']?.includes(
									convenio.numeroCarteira
								)
							) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Campo "Número carteira" do convênio número ${convenio.numero} deve ser um dos seguintes valores: 1, 2, 3, B, D, E, F, G, H, I, J, K, N, P, R, S, T, U.`,
								];
							}
							if (convenio.numero !== values.contaBancaria.codigoBeneficiario) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Convênio número ${convenio.numero} deve ser igual ao campo "Código beneficiário" da conta.`,
								];
							}
						}
						//Sicoob
						if (values.contaBancaria.banco?.value === '207cf63b-167a-42da-acb7-eb7be66fde67') {
							if (!['1', '3']?.includes(convenio.numeroCarteira)) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Campo "Número carteira" do convênio número ${convenio.numero} deve ser um dos valores: 1, 3.`,
									,
								];
							}
						}
						//Santander
						if (values.contaBancaria.banco?.value === '41b59254-ad4f-45b2-9172-39fd8cc827c3') {
							if (!['ECR', 'RCR', 'CSR', 'PCR', '101', '102', '201', '104', '1']?.includes(convenio.numeroCarteira)) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Campo "Número carteira" do convênio número ${convenio.numero} deve ser um dos seguintes valores: ECR, RCR, CSR, PCR, 101, 102, 201, 104 ou 1.`,
								];
							}
						}
						//Caixa
						if (values.contaBancaria.banco?.value === 'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e') {
							if (!['SR', 'CR', 'CS', 'RG', '01', '11', '14', '24']?.includes(convenio.numeroCarteira)) {
								errorsMessageConvenio = [
									...errorsMessageConvenio,
									`Campo "Número carteira" do convênio número ${convenio.numero} deve ser um dos seguintes valores: SR, CR, CS, RG, 01, 11, 14 ou 24.`,
								];
							}
						}
					});

					if (Object.keys(errorsMessageConvenio).length > 0) {
						errorsMessage = [...errorsMessage, ...errorsMessageConvenio];
					}
				}
			}
		}

		if (Object.keys(contaBancariaErrors)?.length > 0) {
			errors = { ...errors, contaBancaria: contaBancariaErrors };
		}

		if (Object.keys(errorsMessage)?.length > 0) {
			errors = { ...errors, message: errorsMessage };
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalContaView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export default withRouter(connect(mapStateToProps)(ModalConta));
