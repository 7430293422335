import { gerarUUID } from 'Common';

function converterDadosComponenteValorPrestacaoParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		componente: values.componente,
		valor: values.valor,
	};
}

function converterDadosQuantidadeCargaParaForm(values) {
	return {
		id: values.id,
		tempId: values.tempId ? values.tempId : values.id ? null : gerarUUID(),
		unidadeMedida: values.unidadeMedida,
		tipoMedida: values.tipoMedida,
		quantidade: values.quantidade,
	};
}

export { converterDadosComponenteValorPrestacaoParaForm, converterDadosQuantidadeCargaParaForm };
