import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import { opcoesCodigo, helpMessage, opcoesPreco, opcoesOrdenacao, opcoesSaldo } from './util/constantes';
import { isValid, parseISO, formatISO, format } from 'date-fns';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import Dropdown from '../../../../../components/select/Dropdown';
import { asyncImprimirInventario } from '../../../../cadastros/produtos/Produtos/Requests';
import Checkbox from '../../../../../components/input/Checkbox';
import If from '../../../../../components/If';
import MultiSelectProdutos from '../../../../../components/select/MultiSelectProdutos';
import { services } from '../../../../../Common/Constantes/api';
import ModalRelatorio from '../../../../../components/ModalRelatorio';

const initialValue = {
	data: formatISO(new Date()),
	codigo: 'CODIGO',
	preco: 'ULTIMO_CUSTO',
	saldo: 'TODOS',
	ordenacao: 'NOME',
	produtos: null,
	filtrarProdutos: false,
};

function InventarioEstoque(props) {
	const { visible, onHide, values, handleSubmit, validateForm, isValid, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('RelEstoqueInputDateDataReferencia')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();
		if (isValid) {
			await asyncImprimirInventario(
				format(parseISO(values.data), 'yyyy-MM-dd'),
				values.codigo == null ? '' : values.codigo,
				values.preco == null ? '' : values.preco,
				values.ordenacao == null ? '' : values.ordenacao,
				values.saldo == null ? '' : values.saldo,
				retornaListaProdutos(),
				({ data: pdf }) => {
					let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					let arquivoURL = URL.createObjectURL(arquivoPDF);
					let relatorio = window.open(arquivoURL);
					if (relatorio) {
						relatorio.onload = () => {
							setTimeout(() => {
								relatorio.document.title = `Relatório inventário de estoque-${format(
									parseISO(values.data),
									'dd-MM-yyyy'
								)}`;
							}, 250);
						};
					}
					onHide();
				}
			);
		}
	}

	function retornaListaProdutos() {
		let retorno = '';
		if (values.produtos) {
			for (let i = 0; i < values.produtos.length; i++) {
				if (retorno !== '') {
					retorno = retorno + ',';
				}
				retorno = retorno + values.produtos[i].value;
			}
		}
		return retorno;
	}

	const parametros = (
		<>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputDate}
					label="Data referência"
					name="data"
					obrigatorio
					helpMessage={helpMessage.data}
					value={values.data}
					touched={true}
					id="RelEstoqueInputDateDataReferencia"
				/>

				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Imprimir inventário de produtos específicos"
					name="filtrarProdutos"
					onChange={(e) => {
						values.produtos = [];
						setFieldValue('filtrarProdutos', !values.filtrarProdutos);
					}}
					checked={values.filtrarProdutos}
				/>
			</Grid>
			<Grid>
				<If test={values.filtrarProdutos}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="produtos"
						label="Produtos"
						autofocus
						isMulti
						component={MultiSelectProdutos}
						value={values.produtos}
						helpMessage={helpMessage.produto}
						onChange={(e) => {
							setFieldValue('produtos', e);
							setFieldValue('saldo', 'TODOS');
						}}
						url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
						parametros={';tipo!="SERVICO";controlarEstoque==true'}
					/>
				</If>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Código a utilizar"
					name="codigo"
					onChange={(e) => setFieldValue('codigo', e.value)}
					options={opcoesCodigo}
					value={values.codigo}
					showClear={false}
				/>

				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Preço a utilizar"
					name="preco"
					onChange={(e) => setFieldValue('preco', e.value)}
					options={opcoesPreco}
					value={values.preco}
					showClear={false}
				/>

				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Condição saldo"
					name="saldo"
					onChange={(e) => setFieldValue('saldo', e.value)}
					options={opcoesSaldo}
					value={values.saldo}
					showClear={false}
				/>

				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Ordenar por"
					name="ordenacao"
					onChange={(e) => setFieldValue('ordenacao', e.value)}
					options={opcoesOrdenacao}
					value={values.ordenacao}
					showClear={false}
				/>
			</Grid>
		</>
	);
	return (
		<ModalRelatorio
			header={'Relatório inventário de estoque'}
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={isValid}
		/>
	);
}

const ModalInventarioEstoque = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.data || values.data === '') {
			errors.data = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(InventarioEstoque);

export default ModalInventarioEstoque;
