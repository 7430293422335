import propTypes from 'prop-types';
import { Grid, Col, NotificacaoTotalRegistros, If } from '../../index';
import { formatarMonetario } from '../../../Common';

const styleCardContent = {
	borderRadius: '5px',
	cursor: 'default',
	padding: '7px',
	height: '100%',
};

const styleValue = {
	fontSize: '18px',
	fontWeight: 'bold',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'end',
	paddingBottom: '0px',
};

function SimpleCard({ colors, title, value = 0, numberOfElements = 0, helpMessage, styleCard = {} }) {
	const valorFormatado = formatarMonetario(value);

	return (
		<div className="p-card" style={{ ...styleCardContent, ...styleCard }}>
			<Grid justifyBetween verticalAlignCenter>
				<Col col="6" title="Total de registros da categoria">
					<If test={numberOfElements}>
						<NotificacaoTotalRegistros
							colors={colors}
							style={{ cursor: 'default' }}
							numberOfElements={numberOfElements}
						/>
					</If>
				</Col>
				<Col col="12" style={{ textAlign: 'end' }}>
					<span title={helpMessage} style={{ color: '#424242', fontSize: '14px' }}>
						{title}
					</span>
				</Col>
			</Grid>
			<Grid>
				<Col title={valorFormatado} style={{ ...styleValue, color: colors.strongColor }}>
					{valorFormatado}
				</Col>
			</Grid>
		</div>
	);
}

SimpleCard.propTypes = {
	numberOfElements: propTypes.number,
	value: propTypes.any.isRequired,
	colors: propTypes.object.isRequired,
};

export default SimpleCard;
