import { mensagensDeValidacao } from 'Common';
import { isValid } from 'date-fns';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';

export function validatePagamentos(pagamentos, valorTotal) {
	const errorsPagamentos = [];
	let valorTotalPagamentos = 0.0;

	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento, index) => {
			const isAVista = pagamento.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA;
			const isAPrazo = pagamento.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
			const isSemPagamento = pagamento.condicaoPagamento?.registro.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;
			let valorTotalParcelas = 0.0;

			valorTotalPagamentos = Number((valorTotalPagamentos + pagamento.valor).toFixed(2));

			if (!pagamento.condicaoPagamento) {
				errorsPagamentos[index] = {
					...errorsPagamentos[index],
					condicaoPagamento: mensagensDeValidacao.OBRIGATORIO,
				};
			}

			if (isAVista) {
				if (!pagamento.formaPagamento) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						formaPagamento: mensagensDeValidacao.OBRIGATORIO,
					};
				}
				if (!pagamento.conta) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						conta: mensagensDeValidacao.OBRIGATORIO,
					};
				}
			}

			if (isAPrazo) {
				const errorsParcelas = [];
				const hasMoreOneFormaPagamento = pagamentos.length > 1;

				if (!pagamento.quantidadeParcelas) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						quantidadeParcelas: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				pagamento.parcelas &&
					pagamento.parcelas.forEach((parcela, indexParcela) => {
						if (!parcela.vencimento || (parcela.vencimento && !isValid(new Date(parcela.vencimento)))) {
							errorsParcelas[indexParcela] = {
								...errorsParcelas[indexParcela],
								vencimento: mensagensDeValidacao.DATA_INVALIDA,
							};

							if (hasMoreOneFormaPagamento) {
								errorsPagamentos[0] = {
									...errorsPagamentos[0],
									vencimentoParcela: `Pagamento ${pagamento.sequencial}: Informe uma data válida para o vencimento da parcela`,
									showMessage: true,
								};
							}
						}

						if (!parcela.valor) {
							errorsParcelas[indexParcela] = {
								...errorsParcelas[indexParcela],
								valor: mensagensDeValidacao.OBRIGATORIO,
							};

							if (hasMoreOneFormaPagamento) {
								errorsPagamentos[0] = {
									...errorsPagamentos[0],
									valorParcela: `Pagamento ${pagamento.sequencial}: Valor da parcela é um campo obrigatório`,
									showMessage: true,
								};
							}
						} else {
							valorTotalParcelas = Number((valorTotalParcelas + parcela.valor).toFixed(2));
						}

						if (!parcela.formaPagamento) {
							errorsParcelas[indexParcela] = {
								...errorsParcelas[indexParcela],
								formaPagamento: mensagensDeValidacao.OBRIGATORIO,
							};

							if (hasMoreOneFormaPagamento) {
								errorsPagamentos[0] = {
									...errorsPagamentos[0],
									formaPagamentoParcela: `Pagamento ${pagamento.sequencial}: Forma de pagamento da parcela é um campo obrigatório`,
									showMessage: true,
								};
							}
						}

						if (!parcela.conta) {
							errorsParcelas[indexParcela] = {
								...errorsParcelas[indexParcela],
								conta: mensagensDeValidacao.OBRIGATORIO,
							};

							if (hasMoreOneFormaPagamento) {
								errorsPagamentos[0] = {
									...errorsPagamentos[0],
									contaParcela: `Pagamento ${pagamento.sequencial}: Conta da parcela é um campo obrigatório`,
									showMessage: true,
								};
							}
						}

						if (indexParcela === pagamento.parcelas.length - 1) {
							if (valorTotalParcelas !== pagamento.valor) {
								errorsPagamentos[0] = {
									...errorsPagamentos[0],
									valorTotal: `Pagamento ${pagamento.sequencial}: Valor total das parcelas é diferente do valor da forma de pagamento`,
									showMessage: true,
								};
							}
						}
					});

				if (errorsParcelas.length > 0) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						parcelas: errorsParcelas,
					};
				}
			}

			if (!pagamento.categoria && !isSemPagamento) {
				errorsPagamentos[index] = {
					...errorsPagamentos[index],
					categoria: mensagensDeValidacao.OBRIGATORIO,
				};
			}

			if (isNaN(pagamento.valor)) {
				errorsPagamentos[index] = {
					...errorsPagamentos[index],
					valor: mensagensDeValidacao.OBRIGATORIO,
				};
			}

			if ((isAVista || isAPrazo) && pagamento.valor <= 0) {
				errorsPagamentos[index] = {
					...errorsPagamentos[index],
					valor: mensagensDeValidacao.OBRIGATORIO,
				};
				valorTotalPagamentos = null;
			}

			if (index === pagamentos.length - 1) {
				if (valorTotalPagamentos !== valorTotal && !isSemPagamento) {
					errorsPagamentos[0] = {
						...errorsPagamentos[0],
						valorTotal: 'Valor total dos pagamentos é diferente do valor total do documento',
						showMessage: true,
					};
				}
			}
		});
	} else {
		errorsPagamentos[0] = {
			...errorsPagamentos[0],
			semFormaPagamento: 'Informe pelo menos uma forma de pagamento',
			showMessage: true,
		};
	}

	return errorsPagamentos.length > 0 ? errorsPagamentos : [];
}
