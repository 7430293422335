import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { GoBookmark } from 'react-icons/go';

import {
	Paginacao,
	ButtonEditarTable,
	ButtonExcluirTable,
	Badge,
	Tutorial,
	tutorialStepsListagens,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	PesquisaAvancada,
} from 'components';

import {
	salvarConfiguracaoUsuario,
	permissoes,
	services,
	usuarioPossuiPermissao,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	recursos,
	removerCaracteres,
	construirUrl,
	removerElemento,
} from 'Common';

import { optionsFiltroAvancado } from './Util/constantes';
import { atualizarUrl } from '../../../Util';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';
import { asyncDeleteOperacaoFiscal, asyncGetOperacoesFiscais } from './Requests';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

const ColorsSituacao = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

const ColorsIndicacaoMovimento = {
	bgEntrada: 'rgb(179, 229, 252)',
	textEntrada: 'rgb(1, 87, 155)',
	bgSaida: '#FFCDD2',
	textSaida: '#B71C1C',
};

function OperacoesFiscais(props) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('InputSearchOperacoesFiscais')) {
				document.getElementById('InputSearchOperacoesFiscais').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

		let result = `?query=(codigo=contains="*${pesquisaCodigo}*",descricao=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('codigo');
			setSortOrder(1);
			campoOrdenacao = 'codigo';
			sentidoOrdenacao = 1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/tributacoes/operacoes_fiscais/resumo`,
			filtro,
			rows,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		await asyncGetOperacoesFiscais(url, ({ data: operacoes }) => {
			setRegistros(operacoes.content);
			setTotalElements(operacoes.totalElements);

			if (!exibirBloqueadas && (filtroAvancado === 'situacao=="INATIVO"' || filtroAvancado === 'situacao!="ATIVO"')) {
				setExibirBloqueadas(true);
			}
			setFirstRender(false);
		});
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return field;
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteOperacaoFiscal(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/tributacoes/operacoes_fiscais/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(ColorsSituacao.textAtivo, ColorsSituacao.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(
					ColorsSituacao.textInativo,
					ColorsSituacao.bgInativo,
					'Inativo',
					styleBackground,
					styleDescription
				);
			}
			default:
				return row.situacao;
		}
	}

	function renderCodigo(row) {
		return <span style={{ display: 'flex', alignItems: 'center' }}>{aplicarEstiloInativa(row, row.codigo)}</span>;
	}

	function renderDescricao(row) {
		return (
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{aplicarEstiloInativa(row, row.descricao)}
				{row.favoritoEntrada || row.favoritoSaida ? (
					<span>
						<GoBookmark
							size={22}
							color="#fbc02d"
							title={`Operação de ${row.indicacaoMovimento === 'ENTRADA' ? 'entrada' : 'saída'} favorita`}
						/>
					</span>
				) : null}
			</span>
		);
	}

	function renderIndicacaoMovimento(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.indicacaoMovimento === 'ENTRADA' ? '0.2rem 1.1rem' : '0.2rem 1.5rem',
		};

		switch (row.indicacaoMovimento) {
			case 'ENTRADA': {
				return Badge(
					ColorsIndicacaoMovimento.textEntrada,
					ColorsIndicacaoMovimento.bgEntrada,
					'Entrada',
					styleBackground,
					styleDescription
				);
			}
			case 'SAIDA': {
				return Badge(
					ColorsIndicacaoMovimento.textSaida,
					ColorsIndicacaoMovimento.bgSaida,
					'Saída',
					styleBackground,
					styleDescription
				);
			}
			default:
				return row.indicacaoMovimento;
		}
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Operações fiscais">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova operação"
						onClick={() => {
							props.history.push('/tributacoes/operacoes_fiscais/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchOperacoesFiscais"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql, () => {})}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir operações inativas
								</label>
							</span>
						}
					>
						<Column
							className="step-listagem-order"
							field="codigo"
							header="Código"
							body={(row) => renderCodigo(row)}
							sortable={true}
							style={{ overflow: 'hidden', width: '130px' }}
						/>
						<Column
							field="descricao"
							header="Descrição"
							body={(row) => renderDescricao(row, row.descricao)}
							sortable={true}
						/>
						<Column
							field="indicacaoMovimento"
							header="Indicação movimento"
							body={(row) => renderIndicacaoMovimento(row)}
							sortable={true}
							style={{ width: '15rem' }}
						/>
						<Column field="situacao" header="Status" body={renderSituacao} sortable={true} style={{ width: '12rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7rem' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(OperacoesFiscais);
