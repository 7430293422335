import { del, exibirToast, get, post, put, services } from 'Common';

export async function getComponentesValoresPrestacao(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function getComponenteValorPrestacao(idComponente, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/transporte/componentes_valores_prestacao/${idComponente}`,
		null,
		onSuccess,
		onError
	);
}

export async function createComponenteValorPrestacao(componente, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/transporte/componentes_valores_prestacao`,
		componente,
		null,
		exibirToast(onSuccess, 'Componente de valor de prestação criado com sucesso'),
		onError
	);
}

export async function updateComponenteValorPrestacao(componente, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transporte/componentes_valores_prestacao/${componente.id}`,
		componente,
		null,
		exibirToast(onSuccess, 'Componente de valor de prestação atualizado com sucesso'),
		onError
	);
}

export async function deleteComponenteValorPrestacao(idComponente, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/transporte/componentes_valores_prestacao/${idComponente}`,
		null,
		exibirToast(onSuccess, 'Componente de valor de prestação excluído'),
		onError
	);
}
