import { useState } from 'react';
import { Field, useFormikContext } from 'formik';

import {
	Col,
	Dropdown,
	Grid,
	If,
	InputDate,
	InputField,
	LinkVerDados,
	SingleSelectCfop,
	SingleSelectMunicipio,
	SingleSelectPessoa,
} from 'components';
import { keyFilterConsultaRsql } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { ModalDadosPessoa } from './components/ModalDadosPessoa';
import {
	CTE_FINALIDADE,
	CTE_FINALIDADE_OPTIONS,
	CTE_GLOBALIZADO_OPTIONS,
	CTE_TIPO,
	CTE_TIPO_OPTIONS,
	CTE_TOMADOR,
	CTE_TOMADOR_OPTIONS,
	TIPO_DOCUMENTO_ANTERIOR,
} from '../../Util/constantes';
import { OutrosParticipantes } from './components/OutrosParticipantes';
import { converterPessoaParaForm } from '../../Util/CTeConverter';

function InformacoesPrincipais() {
	const { values, setFieldValue, setValues, errors } = useFormikContext();
	const {
		visibleModalDadosPessoa,
		setVisibleModalDadosPessoa,
		isSituacaoFinalCTe,
		informacoesPermissoes,
		setActiveTabDocumentos,
		activeIndexOutrosParcipantes,
		setActiveIndexOutrosParcipantes,
	} = useContextCTe();

	const [pessoaSelecionada, setPessoaSelecionada] = useState(null);

	function handleChangeTipo(e) {
		switch (e.value) {
			case CTE_TIPO.NORMAL: {
				setValues({
					...values,
					tipo: e.value,
					cteSubstituicao: {
						chaveAcesso: null,
						tomadorInformadoDiferente: false,
					},
					cteComplemento: {
						chaveAcesso: null,
					},
				});

				setActiveTabDocumentos(0);

				break;
			}
			case CTE_TIPO.COMPLEMENTO_VALORES: {
				setValues({
					...values,
					tipo: e.value,
					cteSubstituicao: {
						chaveAcesso: null,
						tomadorInformadoDiferente: false,
					},
				});

				setActiveTabDocumentos(2);

				break;
			}
			case CTE_TIPO.SUBSTITUICAO: {
				setValues({
					...values,
					tipo: e.value,
					cteComplemento: {
						chaveAcesso: null,
					},
				});

				setActiveTabDocumentos(3);

				break;
			}
			default:
				break;
		}
	}

	function handleChangeFinalidade(e) {
		if (e.value === CTE_FINALIDADE.NORMAL) {
			setValues({
				...values,
				finalidade: e.value,
				documentoAnterior: {
					emissor: null,
					tipoDocumento: TIPO_DOCUMENTO_ANTERIOR.CTE,
					ctes: [],
					documentosPapel: [],
				},
			});

			setActiveTabDocumentos(0);
		} else {
			setFieldValue('finalidade', e.value);
			setActiveTabDocumentos(1);
		}
	}

	function handleChangeTomador(e) {
		setFieldValue('tomador', e.value);

		if ([CTE_TOMADOR.EXPEDIDOR, CTE_TOMADOR.RECEBEDOR, CTE_TOMADOR.OUTROS].includes(e.value)) {
			setActiveIndexOutrosParcipantes(0);
		}

		if (e.value !== CTE_TOMADOR.OUTROS) {
			setFieldValue('outros', null);
		}
	}

	async function handleChangeRemetente(event) {
		if (event) {
			const pessoaConvertida = await converterPessoaParaForm('remetente', event.registro);
			setFieldValue('remetente', pessoaConvertida);
		} else {
			setFieldValue('remetente', null);
		}
	}

	async function handleChangeDestinatario(event) {
		if (event) {
			const pessoaConvertida = await converterPessoaParaForm('destinatario', event.registro);
			setFieldValue('destinatario', pessoaConvertida);
		} else {
			setFieldValue('destinatario', null);
		}
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Tipo"
					name="tipo"
					options={CTE_TIPO_OPTIONS}
					onChange={handleChangeTipo}
					value={values?.tipo}
					showClear={false}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Finalidade"
					name="finalidade"
					options={CTE_FINALIDADE_OPTIONS}
					onChange={handleChangeFinalidade}
					value={values?.finalidade}
					showClear={false}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="2"
					xl="2"
					component={InputDate}
					label="Emissão"
					name="emissao"
					value={values.emissao}
					onChange={(e) => setFieldValue('emissao', e.target?.value)}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="2"
					xl="2"
					name="rntrc"
					label="RNTRC"
					type="number"
					component={InputField}
					keyfilter={keyFilterConsultaRsql}
					size={8}
					useOnBlurPerformatico
					onBlur={(value) => setFieldValue('rntrc', value)}
					disabled={isSituacaoFinalCTe}
					obrigatorio
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="2"
					xl="2"
					component={Dropdown}
					label="CT-e globalizado"
					name="cteGlobalizado"
					value={values.cteGlobalizado}
					options={CTE_GLOBALIZADO_OPTIONS}
					showClear={false}
					disabled={isSituacaoFinalCTe}
					onChange={(e) => setFieldValue('cteGlobalizado', e.value)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="9"
					xl="9"
					component={SingleSelectCfop}
					label="CFOP"
					name="cfop"
					obrigatorio
					value={values.cfop}
					isCte
					onChange={(event) => setFieldValue('cfop', event)}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="3"
					xl="3"
					component={Dropdown}
					label="Tomador"
					name="tomador"
					options={CTE_TOMADOR_OPTIONS}
					onChange={handleChangeTomador}
					value={values?.tomador}
					showClear={false}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={SingleSelectMunicipio}
					label="Local de emissão"
					name="localEmissao"
					value={values.localEmissao}
					onChange={(event) => setFieldValue('localEmissao', event)}
					keyfilter={keyFilterConsultaRsql}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={SingleSelectMunicipio}
					label="Local início prestação"
					name="localInicioPrestacao"
					value={values.localInicioPrestacao}
					onChange={(event) => setFieldValue('localInicioPrestacao', event)}
					keyfilter={keyFilterConsultaRsql}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={SingleSelectMunicipio}
					label="Local término prestação"
					name="localFimPrestacao"
					value={values.localFimPrestacao}
					onChange={(event) => setFieldValue('localFimPrestacao', event)}
					keyfilter={keyFilterConsultaRsql}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Col sm="12" md="12" lg="6" xl="6" style={{ padding: '0px' }}>
					<Field
						label="Remetente"
						name="remetente"
						component={SingleSelectPessoa}
						value={values?.remetente}
						onChange={(value) => handleChangeRemetente(value)}
						errors={errors.remetente}
						disabled={isSituacaoFinalCTe}
						touched
						{...informacoesPermissoes}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: `${errors?.remetente ? 'space-between' : 'flex-end'}`,
						}}
					>
						<If test={values.remetente}>
							<LinkVerDados
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									position: 'relative',
									margin: '-8px 30px -15px 0px',
								}}
								onClick={() => {
									setPessoaSelecionada(values.remetente);
									setVisibleModalDadosPessoa(true);
								}}
								label="Ver dados do remetente"
							/>
						</If>
					</div>
				</Col>
				<Col sm="12" md="12" lg="6" xl="6" style={{ padding: '0px' }}>
					<Field
						label="Destinatário"
						name="destinatario"
						component={SingleSelectPessoa}
						value={values?.destinatario}
						onChange={(value) => handleChangeDestinatario(value)}
						errors={errors.destinatario}
						disabled={isSituacaoFinalCTe}
						touched
						{...informacoesPermissoes}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: `${errors?.destinatario ? 'space-between' : 'flex-end'}`,
						}}
					>
						<If test={values.destinatario}>
							<LinkVerDados
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									position: 'relative',
									margin: '-8px 30px -15px 0px',
								}}
								onClick={() => {
									setPessoaSelecionada(values.destinatario);
									setVisibleModalDadosPessoa(true);
								}}
								label="Ver dados do destinatário"
							/>
						</If>
					</div>
				</Col>
				<OutrosParticipantes
					setPessoaSelecionada={setPessoaSelecionada}
					activeIndexOutrosParcipantes={activeIndexOutrosParcipantes}
					setActiveIndexOutrosParcipantes={setActiveIndexOutrosParcipantes}
				/>
			</Grid>

			<If test={visibleModalDadosPessoa}>
				<ModalDadosPessoa
					visible={visibleModalDadosPessoa}
					pessoa={pessoaSelecionada}
					onHide={() => {
						setPessoaSelecionada(null);
						setVisibleModalDadosPessoa(false);
					}}
					setFieldValueCte={setFieldValue}
				/>
			</If>
		</>
	);
}

export { InformacoesPrincipais };
