import { Field, useFormikContext, withFormik } from 'formik';

import { Col, Dropdown, Form, FormActions, FormContent, Grid, InputField, Modal } from 'components';
import { mensagensDeValidacao } from 'Common';

import { validarFormulario } from 'views/Util';
import {
	CTE_CARTA_CORRECAO_CAMPOS_OPTIONS,
	CTE_CARTA_CORRECAO_GRUPO_OPTIONS,
	CTE_CARTA_CORRECAO_ITEM_INITIAL_VALUE,
} from '../../Utils/constantes';
import { ActionButtons } from '../ActionButtons';

function ModalDadosCorrecaoImpl({ correcaoFinalizada, visible, onHide, onSave }) {
	const { values, setFieldValue, validateForm, handleSubmit } = useFormikContext();

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values.registro);
		}
	}

	return (
		<Modal
			header="Dados carta de correção"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} onHandleSave={onHandleSave} />
					</Col>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Grupo"
							name="grupo"
							obrigatorio
							touched
							options={CTE_CARTA_CORRECAO_GRUPO_OPTIONS}
							allowNegative={false}
							value={String(values?.registro?.grupo)?.toUpperCase()}
							onChange={(event) => setFieldValue('registro.grupo', event.value)}
							disabled={correcaoFinalizada}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Dropdown}
							label="Campo para alteração"
							name="campo"
							obrigatorio
							touched
							options={CTE_CARTA_CORRECAO_CAMPOS_OPTIONS[String(values?.registro?.grupo)?.toUpperCase()] || []}
							allowNegative={false}
							value={String(values?.registro?.campo)?.toUpperCase()}
							onChange={(event) => setFieldValue('registro.campo', event.value)}
							disabled={correcaoFinalizada || !values?.registro?.grupo}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Novo valor"
							name="valor"
							obrigatorio
							touched
							size={500}
							allowNegative={false}
							value={values?.registro?.valor}
							onChange={(event) => setFieldValue('registro.valor', event.target.value)}
							disabled={correcaoFinalizada || !values?.registro?.campo}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosCorrecao = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues({ registroSelecionado, itens }) {
		if (registroSelecionado) {
			return { registro: registroSelecionado, itens: itens };
		} else {
			return { registro: CTE_CARTA_CORRECAO_ITEM_INITIAL_VALUE, itens: itens };
		}
	},

	validate(values) {
		const errors = {};

		if (!values.registro?.grupo) {
			errors.grupo = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.registro?.campo) {
			errors.campo = mensagensDeValidacao.OBRIGATORIO;
		} else {
			values.itens?.forEach((item) => {
				if (item.campo === values.registro?.campo && item.tempId !== values.registro?.tempId) {
					errors.campo = 'Campo já informado em outro registro';
				}
			});
		}

		if (!values.registro?.valor) {
			errors.valor = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosCorrecaoImpl);

export { ModalDadosCorrecao };
