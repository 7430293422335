import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { format, formatISO } from 'date-fns';

import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	ModalLoadingTransmissao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import {
	baixarArquivo,
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	salvarConfiguracaoUsuario,
	services,
} from 'Common';

import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { currentMonth } from 'views/transporte/MDFe/Util/functions';
import { Cards } from './components/Cards';
import { TabelaNFSe } from './components/TabelaNFSe';
import { asyncGetResumoNfse, asyncGetTotalizadoresCard, asyncDeleteNfse, asyncGetDownloadXmls } from './Requests';
import { useContextNFSe } from './Context';
import { optionsFiltroAvancado, SituacaoNFSECards } from './Util/constantes';

function NFSe(props) {
	const { history, isMobile, isTablet, isLessHd } = props;

	const { cardTotalSelected, podeInserir } = useContextNFSe();

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [filtroData, setFiltroData] = useState(
		`emissao>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};emissao<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);

	const [registros, setRegistros] = useState('');
	const [cards, setCards] = useState({
		notasPendentes: {
			valor: 0,
			quantidade: 0,
		},
		notasAguardando: {
			valor: 0,
			quantidade: 0,
		},
		notasRejeitadas: {
			valor: 0,
			quantidade: 0,
		},
		notasCanceladas: {
			valor: 0,
			quantidade: 0,
		},
		notasTransmitidas: {
			valor: 0,
			quantidade: 0,
		},
	});
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [optsFiltroAvancado] = useState(optionsFiltroAvancado);
	const [firstRender, setFirstRender] = useState(true);
	const [mostrarLoading, setMostrarLoading] = useState(false);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('NfseInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, cardTotalSelected]);

	function handleClickNovo() {
		atualizarUrl(history, '/nfses/cadastro');
	}

	function buscarFiltro() {
		let filtroRSQL = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`emissao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`tomadorNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`ordemServicoNumero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`total=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`)
			.concat(`;(${filtroData})`);

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (cardTotalSelected) {
			filtroRSQL = filtroRSQL.concat(`;situacao==(${cardTotalSelected})`);
		}

		return filtroRSQL;
	}

	function buscarFiltroParaDownloadXmls() {
		let filtro = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`emissao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`tomador=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtro = filtro.concat(`;(${filtroData})`);
		} else {
			filtro = filtro.concat(`;(${currentMonth()})`);
		}
		return filtro;
	}

	async function pesquisar() {
		if (interval.dataInicial != null && interval.dataFinal != null) {
			const filtro = buscarFiltro();
			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('emissao');
				setSortOrder(-1);
				campoOrdenacao = 'emissao';
				sentidoOrdenacao = -1;
			}

			const url = construirUrl(
				`${services.GESTOR}/v1/nfses/resumo`,
				filtro || '?',
				size,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			await asyncGetTotalizadoresCard(interval, ({ data: totais }) => {
				const newCards = cards;

				totais &&
					totais.forEach((element) => {
						if (element.status === SituacaoNFSECards.AGUARDANDO) {
							newCards.notasAguardando = {
								...element,
							};
						} else if (element.status === SituacaoNFSECards.CANCELADO) {
							newCards.notasCanceladas = {
								...element,
							};
						} else if (element.status === SituacaoNFSECards.REJEITADO) {
							newCards.notasRejeitadas = {
								...element,
							};
						} else if (element.status === SituacaoNFSECards.TRANSMITIDO) {
							newCards.notasTransmitidas = {
								...element,
							};
						} else if (element.status === SituacaoNFSECards.PENDENTE) {
							newCards.notasPendentes = {
								...element,
							};
						}
					});
				setCards(newCards);
			});

			await asyncGetResumoNfse(url, ({ data: notas }) => {
				setRegistros(notas.content);
				setTotalRecords(notas.totalElements);
				setFirstRender(false);
			});
		}
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function handleChangePesquisa(data) {
		setValorPesquisa(data);
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`emissao>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};emissao<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onEditItem(item) {
		atualizarUrl(history, `/nfses/cadastro`, item.id);
	}

	function onRemoveItem(item) {
		confirmarExclusao(async () => {
			await asyncDeleteNfse(item.id, 'Registro excluído com sucesso', () => pesquisarCallback());
		});
	}

	function baixarArquivosXml() {
		setMostrarLoading(true);
		asyncGetDownloadXmls(
			buscarFiltroParaDownloadXmls(),
			({ data }) => {
				baixarArquivo(
					data,
					`ArquivosXmlNFSe_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(
						interval.dataFinal,
						'dd-MM-yyyy'
					)}.zip`
				);
				setMostrarLoading(false);
			},
			() => {
				setMostrarLoading(false);
			}
		);
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Nota fiscal de serviço (NFS-e)">
				<FormActions>
					<Button
						className="step-listagem-novo p-button-primary"
						label="Nova NFS-e"
						icon="fa fa-plus"
						title="Inserir uma nova nota fiscal de serviço"
						podeInserir={podeInserir}
						disabled={!podeInserir}
						onClick={() => handleClickNovo()}
					/>
					<Button
						label="Download XMLs"
						className="step-listagem-novo p-button-primary"
						icon="fa fa-file-archive-o"
						title="Download dos arquivos XMLs das NFS-es filtradas"
						style={{ margin: '5px' }}
						onClick={() => baixarArquivosXml()}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span>
							<DateInterval onChange={handleChangeInterval} interval={interval} />
						</span>
						<InputSearch
							id="NfseInputSearch"
							className="step-listagem-input-search"
							value={valorPesquisa}
							onPesquisar={pesquisarCallback}
							onChange={handleChangePesquisa}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<Cards cards={cards} />
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<TabelaNFSe
						registros={registros}
						totalRecords={totalRecords}
						onEdit={onEditItem}
						onRemove={onRemoveItem}
						onPageChange={(e) => {
							setSize(e.rows);
							setRows(e.rows);
							setPage(e.page);
						}}
						size={size}
						page={page}
						rows={rows}
						setSortField={(sortField) => setSortField(sortField)}
						setSortOrder={(sortOrder) => {
							setSortOrder(sortOrder);
						}}
						sortField={sortField}
						sortOrder={sortOrder}
						history={history}
						isMobile={isMobile}
						isTablet={isTablet}
						isLessHd={isLessHd}
					/>
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export default connect(mapStateToProps)(NFSe);
