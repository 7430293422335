import { Switch, withRouter } from 'react-router-dom';
import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import { MDFe } from 'views/transporte/MDFe';
import { MDFeForm } from 'views/transporte/MDFe/Form';
import MDFeProvider from 'views/transporte/MDFe/Context';
import { RelatoriosTransporte } from 'views/transporte/Relatorios';
import CTeProvider from 'views/transporte/CTe/Context';
import { CTe } from 'views/transporte/CTe';
import { CTeForm } from 'views/transporte/CTe/Form';

export const pathTransporteRoutes = [
	'/ctes',
	'/ctes/cadastro',
	'/ctes/cadastro/:id',
	'/mdfes',
	'/mdfes/cadastro',
	'/mdfes/cadastro/:id',
	'/relatorios_transporte',
];

function TransporteRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/ctes"
				render={(props) => (
					<CTeProvider {...props}>
						<CTe {...props} />
					</CTeProvider>
				)}
				recurso={recursos.TRANSPORTE_CTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/ctes/cadastro', '/ctes/cadastro/:id']}
				render={(props) => (
					<CTeProvider {...props}>
						<CTeForm {...props} />
					</CTeProvider>
				)}
				recurso={recursos.TRANSPORTE_CTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/mdfes"
				render={(props) => (
					<MDFeProvider>
						<MDFe {...props} />
					</MDFeProvider>
				)}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/mdfes/cadastro', '/mdfes/cadastro/:id']}
				render={(props) => (
					<MDFeProvider {...props}>
						<MDFeForm {...props} />
					</MDFeProvider>
				)}
				recurso={recursos.TRANSPORTE_MDFE}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/relatorios_transporte']}
				render={(props) => <RelatoriosTransporte {...props} />}
				recurso={recursos.TRANSPORTE_RELATORIOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(TransporteRoutes);
