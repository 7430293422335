import { useRef } from 'react';
import { Field } from 'formik';
import { Grid, InputField, InputMoney, Dropdown, Checkbox, If, Button, Col, Divider } from 'components';
import { keyFilterConsultaRsql } from 'Common';
import { helpMessage } from '../../Help';
import { bancosHomologados } from '../FormConvenios/ModalNovoConvenio/Util';
import { OverlayPanel } from 'primereact/overlaypanel';

function FormConta(props) {
	const { values, setFieldValue, informacoesPermissoes, isMobile } = props;

	const panelAjudaIntegracaoPix = useRef(null);

	function onChangeEmitirBoletos() {
		setFieldValue('emitirBoletos', !values.emitirBoletos);

		if (!bancosHomologados.includes(values.contaBancaria.banco?.value)) {
			setFieldValue('contaBancaria.banco', null);
		}
	}

	function onChangeRecebePix() {
		setFieldValue('recebePix', !values.recebePix);
		if (!values.recebePix) {
			setFieldValue('contaBancaria.chavePix', null);
			setFieldValue('contaBancaria.clientIdPix', null);
			setFieldValue('contaBancaria.clientSecretPix', null);
			if (!values.contaBancaria.banco) {
				let bancoSulCredi = {
					value: '6dffc893-5c6e-c6e6-9ef1-a5807551b087',
					label: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel (273)',
					registro: {
						id: '6dffc893-5c6e-c6e6-9ef1-a5807551b087',
						codigo: '273',
						descricao: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel',
						emiteBoleto: false,
					},
				};
				setFieldValue('contaBancaria.banco', bancoSulCredi);
			}
		}
	}

	return (
		<div className={'card-default'} style={{ padding: '8px', margin: '8px 0 8px 0' }}>
			<Grid>
				<Field
					sm="12"
					md="5"
					lg="5"
					xl="5"
					component={InputField}
					label="Nome da conta"
					obrigatorio
					keyfilter={keyFilterConsultaRsql}
					name="nome"
					size={255}
					helpMessage={helpMessage.nome}
					id="ContasInputFieldNomeConta"
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					label="Saldo inicial"
					name="saldoInicial"
					size={12}
					disabled={values.situacao === 'INATIVA'}
					title={values.situacao === 'INATIVA' ? 'Não é possível atualizar o saldo de uma conta inativa.' : null}
					helpMessage={helpMessage.saldoInicial}
					onChange={(e) => setFieldValue('saldoInicial', e.target.value)}
					value={values.saldoInicial}
					allowNegative={false}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					options={[
						{ label: 'Dinheiro', value: 'DINHEIRO' },
						{ label: 'Conta corrente', value: 'CORRENTE' },
						{ label: 'Conta poupança', value: 'POUPANCA' },
						{ label: 'Investimento', value: 'INVESTIMENTO' },
						{ label: 'Outro', value: 'OUTRO' },
					]}
					label="Tipo da conta"
					obrigatorio
					name="tipo"
					showClear={false}
					helpMessage={helpMessage.tipoConta}
					value={values.tipo}
					onChange={(e) => setFieldValue('tipo', e.value)}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid style={{ paddingTop: '8px' }}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Checkbox}
					name="favoritaReceita"
					label="Conta favorita para receitas"
					helpMessage={helpMessage.favoritaReceita}
					checked={values.favoritaReceita}
					disabled={values.situacao === 'INATIVA'}
					title={values.situacao === 'INATIVA' ? 'Não é possível favoritar uma conta inativa' : null}
					onChange={() => setFieldValue('favoritaReceita', !values.favoritaReceita)}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={Checkbox}
					name="favoritaDespesa"
					label="Conta favorita para despesas"
					checked={values.favoritaDespesa}
					helpMessage={helpMessage.favoritaDespesa}
					disabled={values.situacao === 'INATIVA'}
					title={values.situacao === 'INATIVA' ? 'Não é possível favoritar uma conta inativa' : null}
					onChange={() => setFieldValue('favoritaDespesa', !values.favoritaDespesa)}
					{...informacoesPermissoes}
				/>
				<If test={['CORRENTE', 'POUPANCA'].includes(values.tipo)}>
					<Field
						sm="12"
						md="2"
						lg="2"
						xl="2"
						component={Checkbox}
						name="emitirBoletos"
						label="Emite boletos"
						checked={values.emitirBoletos}
						helpMessage={helpMessage.emitirBoletos}
						disabled={values.situacao === 'INATIVA'}
						title={'Emitir boletos a partir desta conta'}
						onChange={() => onChangeEmitirBoletos()}
						{...informacoesPermissoes}
					/>
					<Field
						sm="6"
						md="2"
						lg="2"
						xl="2"
						component={Checkbox}
						name="recebePix"
						label="Recebe pix"
						checked={values.recebePix}
						helpMessage={helpMessage.recebePix}
						disabled={values.situacao === 'INATIVA'}
						title={'Receber pix a partir desta conta (habilita integração com a ModoBank)'}
						onChange={() => onChangeRecebePix()}
						{...informacoesPermissoes}
					/>
					<Col sm="5" md="1" lg="1" xl="1">
						<Button
							type="button"
							color="info"
							icon="fa fa-question"
							title={'Ajuda com a integração com o ModoBank'}
							hidden={false}
							raised={true}
							rounded={true}
							style={{
								position: 'absolute',
								zIndex: '9999',
								width: '32px',
								height: '32px',
								right: '1.7rem',
								top: isMobile ? '28rem' : '',
							}}
							onClick={(e) => {
								panelAjudaIntegracaoPix.current?.toggle(e);
							}}
							enableShortCut={false}
							disabled={!values?.recebePix}
						></Button>
					</Col>
				</If>
			</Grid>
			<OverlayPanel
				ref={panelAjudaIntegracaoPix}
				id="overlayPanel-emitir-boletos"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => {}}
				showCloseIcon
			>
				<label style={{ fontSize: '1rem', paddingBottom: '1rem' }}>Integração pix - ModoBank</label>
				<Divider
					styleContainer={{
						marginLeft: '0rem',
					}}
				/>
				<label style={{ fontSize: '0.8rem', paddingBottom: '1rem' }}>
					Para utilizar o recebimento via pix, você precisa possuir uma conta na ModoBank. Para mais informações entre
					em contato com nosso suporte ou acesse:
				</label>
				<br />
				<br />
				<a href="https://www.modobank.com" target="_blank">
					https://www.modobank.com
				</a>
				<br />
				<br />
			</OverlayPanel>
		</div>
	);
}

export default FormConta;
