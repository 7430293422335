import { ButtonCancelar, Grid, estadosBotaoCancelar } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function ActionButtons({ onHide }) {
	const { informacoesPermissoes } = useContextCTe();

	return (
		<Grid style={{ paddingLeft: '8px' }}>
			<ButtonCancelar onClick={onHide} estadoBotao={estadosBotaoCancelar.VOLTAR} {...informacoesPermissoes} />
		</Grid>
	);
}

export { ActionButtons };
