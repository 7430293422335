import { Field, useFormikContext } from 'formik';

import {
	Checkbox,
	Dropdown,
	Grid,
	InputDouble,
	InputField,
	SingleSelectCfop,
	SingleSelectCst,
	SingleSelectMunicipio,
	SingleSelectPessoa,
	Subtitle,
	typesCst,
} from 'components';

import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function TabTransportes() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<>
			<Grid>
				<Subtitle subtitle="Configurações MDF-e" />
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Habilitar importação de NF-es de todas as filiais para o MDF-e"
					name="parametrizacao.transportes.mdfeImportarNfeTodasFiliais"
					value={values.parametrizacao.transportes?.mdfeImportarNfeTodasFiliais}
					onChange={(e) => setFieldValue('parametrizacao.transportes.mdfeImportarNfeTodasFiliais', e.checked)}
					checked={values.parametrizacao.transportes?.mdfeImportarNfeTodasFiliais}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Habilitar importação de CT-es de todas as filiais para o MDF-e"
					name="parametrizacao.transportes.mdfeImportarCteTodasFiliais"
					value={values.parametrizacao.transportes?.mdfeImportarCteTodasFiliais}
					onChange={(e) => setFieldValue('parametrizacao.transportes.mdfeImportarCteTodasFiliais', e.checked)}
					checked={values.parametrizacao.transportes?.mdfeImportarCteTodasFiliais}
					disabled={!podeEditarEmpresa}
				/>
			</Grid>
			<Grid>
				<Subtitle subtitle="Configurações CT-e" />
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					size={8}
					label="RNTRC"
					name="parametrizacao.transportes.cteRntrc"
					value={values.parametrizacao.transportes?.cteRntrc}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteRntrc', event.target.value)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="8"
					lg="8"
					xl="8"
					label="CST padrão"
					name="parametrizacao.transportes.cteCst"
					component={SingleSelectCst}
					type={typesCst.CST_CTE}
					value={values.parametrizacao.transportes?.cteCst}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteCst', event)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="CFOP interna"
					name="parametrizacao.transportes.cteCfopIntraestadual"
					isClearable
					isCte
					component={SingleSelectCfop}
					value={values?.parametrizacao.transportes?.cteCfopIntraestadual}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteCfopIntraestadual', event)}
					placeholder="Pesquisar cfop interna"
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="CFOP interestadual"
					name="parametrizacao.transportes.cteCfopInterestadual"
					isClearable
					isCte
					component={SingleSelectCfop}
					value={values?.parametrizacao.transportes?.cteCfopInterestadual}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteCfopInterestadual', event)}
					placeholder="Pesquisar cfop interestadual"
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="Local de início padrão"
					name="parametrizacao.transportes.cteLocalInicio"
					component={SingleSelectMunicipio}
					value={values.parametrizacao.transportes.cteLocalInicio}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteLocalInicio', event)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					label="Local de término padrão"
					name="parametrizacao.transportes.cteLocalTermino"
					component={SingleSelectMunicipio}
					value={values.parametrizacao.transportes.cteLocalTermino}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteLocalTermino', event)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="% Base Cálculo ICMS"
					name="parametrizacao.transportes.ctePercentualBaseIcms"
					value={values?.parametrizacao.transportes.ctePercentualBaseIcms}
					onChange={(event) => setFieldValue('parametrizacao.transportes.ctePercentualBaseIcms', event.target.value)}
					suffix="%"
					allowNegative={false}
					maxValue={100}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Alíquota ICMS"
					name="parametrizacao.transportes.ctePercentualIcms"
					value={values?.parametrizacao.transportes.ctePercentualIcms}
					onChange={(event) => setFieldValue('parametrizacao.transportes.ctePercentualIcms', event.target.value)}
					suffix="%"
					allowNegative={false}
					maxValue={100}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Tomador padrão"
					name="parametrizacao.transportes.cteTomadorPadrao"
					value={values.parametrizacao.transportes.cteTomadorPadrao}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteTomadorPadrao', event.value)}
					options={[
						{ label: 'Remetente', value: 'REMETENTE' },
						{ label: 'Destinatário', value: 'DESTINATARIO' },
					]}
					showClear
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectPessoa}
					label="Remetente padrão"
					name="parametrizacao.transportes.cteRemetente"
					value={values.parametrizacao.transportes?.cteRemetente}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteRemetente', event)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectPessoa}
					label="Destinatário padrão"
					name="parametrizacao.transportes.cteDestinatario"
					value={values.parametrizacao.transportes?.cteDestinatario}
					onChange={(event) => setFieldValue('parametrizacao.transportes.cteDestinatario', event)}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Habilitar importação de NF-es de todas as filiais para o CT-e"
					name="parametrizacao.transportes.cteImportarNfeTodasFiliais"
					value={values.parametrizacao.transportes?.cteImportarNfeTodasFiliais}
					onChange={(e) => setFieldValue('parametrizacao.transportes.cteImportarNfeTodasFiliais', e.checked)}
					checked={values.parametrizacao.transportes?.cteImportarNfeTodasFiliais}
					disabled={!podeEditarEmpresa}
				/>
			</Grid>
		</>
	);
}

export { TabTransportes };
