import { Field, useFormikContext, withFormik } from 'formik';

import {
	AutoProgressBar,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	InputMask,
	Modal,
	SingleSelectMunicipio,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { INITIAL_VALUE_EMISSOR_DOCUMENTO_ANTERIOR_MODAL } from 'views/transporte/CTe/Form/Util/constantes';
import { mensagensDeValidacao } from 'Common';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { ActionButtons } from './components/ActionButtons';

function ModalDadosEmissorDocumentoAnteriorImpl({ visible, onHide, setFieldValueCte }) {
	const { informacoesPermissoes, isSituacaoFinalCTe } = useContextCTe();
	const { values, errors, setFieldValue } = useFormikContext();

	function onChangeValores(field, event) {
		setFieldValue(`${field}`, event?.target?.value ?? null);
	}

	function onChangeSelect(field, event) {
		setFieldValue(`${field}`, event ?? null);
	}

	function onChangeTipoPessoa(element) {
		setFieldValue('tipoPessoa', element.value);

		if (element.value === TIPO_PESSOA.FISICA) {
			setFieldValue('cnpj', null);
		} else if (element.value === TIPO_PESSOA.JURIDICA) {
			setFieldValue('cpf', null);
		}
	}

	return (
		<Modal
			header="Dados do emissor"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<AutoProgressBar />
			<Form className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons onHide={onHide} setFieldValueCte={setFieldValueCte} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="8"
							lg="9"
							xl="9"
							component={InputField}
							label="Nome"
							value={values.nome}
							disabled
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Tipo"
							name="tipoPessoa"
							onChange={(element) => onChangeTipoPessoa(element)}
							options={[
								{ label: 'Física', value: 'FISICA' },
								{ label: 'Jurídica', value: 'JURIDICA' },
							]}
							value={values.tipoPessoa}
							errors={errors.tipoPessoa}
							showClear={false}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<If test={values.tipoPessoa === TIPO_PESSOA.FISICA}>
							<Field
								sm="12"
								md="4"
								lg="3"
								xl="3"
								component={InputMask}
								mask="000.000.000-00"
								placeholder="   .   .   -  "
								label="CPF"
								name="cpf"
								obrigatorio
								useFormTouched={false}
								useFormErrors={false}
								value={String(values.cpf)}
								touched={!!errors.cpf}
								errors={errors.cpf}
								onChange={(element) => onChangeValores('cpf', element)}
								disabled={isSituacaoFinalCTe}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={values.tipoPessoa === TIPO_PESSOA.JURIDICA}>
							<Field
								sm="12"
								md="4"
								lg="3"
								xl="3"
								component={InputMask}
								mask="00.000.000/0000-00"
								placeholder="  .   .   /    -  "
								label="CNPJ"
								name="cnpj"
								obrigatorio
								useFormTouched={false}
								useFormErrors={false}
								value={values.cnpj}
								onChange={(element) => onChangeValores('cnpj', element)}
								touched={!!errors.cnpj}
								errors={errors.cnpj}
								disabled={isSituacaoFinalCTe}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={InputField}
							size={14}
							type="number"
							label="Inscrição estadual"
							name="inscricaoEstadual"
							value={values.inscricaoEstadual}
							obrigatorio
							onBlur={(e) => {
								setFieldValue('inscricaoEstadual', e.target.value);
							}}
							useFormTouched={false}
							useFormErrors={false}
							touched={!!errors.inscricaoEstadual}
							errors={errors.inscricaoEstadual}
							disabled={isSituacaoFinalCTe}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="6"
							xl="6"
							component={SingleSelectMunicipio}
							label="Município "
							name="municipio"
							isClearable={false}
							obrigatorio
							onChange={(element) => onChangeSelect('municipio', element)}
							disabled={isSituacaoFinalCTe}
							value={values.municipio}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDadosEmissorDocumentoAnterior = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.emissor) {
			return props.emissor?.registro;
		}

		return INITIAL_VALUE_EMISSOR_DOCUMENTO_ANTERIOR_MODAL;
	},

	validate(values) {
		const errors = {};

		if (values.tipoPessoa === TIPO_PESSOA.FISICA) {
			if (!values.cpf) {
				errors.cpf = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCPF(values.cpf)) {
				errors.cpf = mensagensDeValidacao.CPF_INVALIDO;
			}
		} else if (values.tipoPessoa === TIPO_PESSOA.JURIDICA) {
			if (!values.cnpj) {
				errors.cnpj = mensagensDeValidacao.OBRIGATORIO;
			} else if (!isValidCNPJ(values.cnpj)) {
				errors.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
			}
		}

		if (!values.inscricaoEstadual) {
			errors.inscricaoEstadual = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.municipio) {
			errors.municipio = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosEmissorDocumentoAnteriorImpl);

export { ModalDadosEmissorDocumentoAnterior };
