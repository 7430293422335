import { Col, Dropdown, Form, FormActions, FormContent, Grid, InputDate, Message, Modal } from "components";
import { Field, useFormikContext, withFormik } from "formik";
import { validarFormulario } from "views/Util";
import { mensagensDeValidacao } from "Common";
import { ActionButtons } from "./components/ActionButtons";
import { converterRegistroPorPeriodoParaApi } from "../../Util/gestaoFechamentoConverter";
import { INITIAL_VALUES_LOTE, TIPO_ALTERACAO_OPTIONS } from "../../Util/constantes";
import { updateFechamentosPorPeriodo } from "../../Requests";


function ModalAlteracaoEmLoteImpl({ visible, onHide }) {
	const { values, setFieldValue, validateForm, handleSubmit } = useFormikContext();

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			const registro = converterRegistroPorPeriodoParaApi(values);
			updateFechamentosPorPeriodo(
				registro,
				() => {
					onHide();
				}
			);
		}
	}

	function montarInformacaoMessage() {
		return (
			<div style={{ marginBottom: '7px' }}>
				Apenas os registros cujas datas de início e fim estejam dentro do período informado serão alterados
			</div>
		);
	}

	return (
		<Modal
			header="Abertura/Fechamento em lote"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} onHandleSave={onHandleSave} />
					</Col>
				</FormActions>
				<FormContent>
					<Message style={{ marginBottom: '7px' }} text={montarInformacaoMessage()} />
					<Grid>
						<Field
							sm="4"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							showClear={false}
							label="Tipo de alteração"
							name="acao"
							obrigatorio
							touched
							options={TIPO_ALTERACAO_OPTIONS}
							allowNegative={false}
							value={values.acao}
							onChange={(e) => setFieldValue('acao', e.value)}
						/>
						<Field
							sm="4"
							md="4"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data início"
							name="dataInicial"
							obrigatorio
							touched
							value={values?.dataInicial}
							onChange={(e) => setFieldValue('dataInicial', e.target.value)}
						/>
						<Field
							sm="4"
							md="4"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data final"
							name="dataFinal"
							obrigatorio
							touched
							allowNegative={false}
							value={values?.dataFinal}
							onChange={(e) => setFieldValue('dataFinal', e.target.value)}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalAlteracaoEmLote = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues() {
		return { ...INITIAL_VALUES_LOTE };
	},

	validate(values) {
		const errors = {};

		if (!values.dataInicial) {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}
		if (values.dataFinal < values.dataInicial) {
			errors.dataFinal = 'Data final deve ser maior que a inicial';
		}
		if (!values.dataFinal) {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.acao) {
			errors.acao = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalAlteracaoEmLoteImpl);

export { ModalAlteracaoEmLote };