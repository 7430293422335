import { withFormik } from 'formik';
import { formatISO } from 'date-fns';

import { Col, Form, FormActions, FormContent, Modal } from 'components';

import { ActionButtons } from './components/ActionButtons';

function ModalComprovanteEntregaImpl({ visible, onHide }) {
	return (
		<Modal
			header="Comprovante de entrega do CT-e"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} />
					</Col>
				</FormActions>
				<FormContent>
					<h1>ModalComprovanteEntrega</h1>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalComprovanteEntrega = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,

	mapPropsToValues() {
		return {
			id: null,
			emissao: formatISO(new Date()),
			documentoPessoa: null,
			nomePessoa: '',
			urlArquivo: null,
			hashEntrega: null,
			hashDataEntrega: null,
			informacoesEntrega: '',
			chaveNFe: null,
		};
	},

	handleSubmit: () => {},
})(ModalComprovanteEntregaImpl);

export { ModalComprovanteEntrega };
