export const helpProdutosForm = {
	situacao:'Define se o produto ou serviço está ativo ou inativo. Caso esteja inativo, ele não poderá mais ser utilizado em outros locais do sistema.',
	codigo: 'Identificador único criado pelo sistema que tem como objetivo identificar de maneira simples e rápida um produto ou serviço.',    
	sku: 'Identificador único que tem como objetivo identificar de maneira simples e rápida um produto ou serviço.',
	nome: 'Nome do produto ou serviço.',
	preco: 'Preço do produto.',
	unidadeMedida: 'Unidade de medida do produto ou serviço',
	codigoDeBarras: 'Código de barras do produto ou serviço',
	estoqueMinimo: 'Valor mínimo de estoque.',
	estoqueReservado: 'Quantidade de produtos reservados.',
	estoqueSaldo: 'Quantidade de produtos em estoque.',
	alteradoEm:
		'Data da ultima alteração do produto ou serviço. É um valor pré-definido pelo sistema e não permite edições.',
	criadoEm:
		'Data em que o cadastro do produto ou serviço foi efetuado. É um valor pré-definido pelo sistema e não permite edições.',
	observacoes: 'Observações gerais sobre o produto ou serviço.',
	ultimoCusto: 'Custo do produto na última movimentação de entrada.',
	tipo: 'Define se será produto ou serviço.',
	controlarEstoque:
		'Habilita o controle de estoque para este produto. Ex: Ao efetuar uma venda deste produto será gerada movimentação de saída de estoque.',
	origem: 'Origem da mercadoria',
	ncm: 'Nomenclatura Comum do Mercosul (✓-Tributação cadastrada, ✕-Tributação não cadastrada)',
	cest: 'Código Especificador da Substituição Tributária',
	pesoBruto: 'Peso bruto do produto',
	pesoLiquido: 'Peso líquido do produto',
	codigoAnp: 'Código Agência Nacional do Petróleo',
	utilizarDocumentosDigitais: 'Define se o produto ou serviço será utilizado no módulo de documentos digitais',
	utilizaBalanca: 'Define se o produto irá solicitar o peso da balança no módulo NFC-e',
	exportaArquivoBalanca: 'Define se o produto será exportado para os arquivos de carga de balança',
	unidadeMedidaEntrada: 'Unidade de medida do produto ou serviço utilizado no módulo de compras',
	precoAtacado: 'Preço de atacado do produto.',
	codigoServico: 'Código de atividade do serviço (✓-Tributação cadastrada, ✕-Tributação não cadastrada)',
};
