const ORIGEM_PRODUTO = {
	NACIONAL: 'NACIONAL',
	ESTRANGEIRA: 'ESTRANGEIRA',
	ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO: 'ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO',
	NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO: 'NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO',
	NACIONAL_COM_PROCESSO_PRODUTIVO: 'NACIONAL_COM_PROCESSO_PRODUTIVO',
	NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO: 'NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO',
	ESTRANGEIRA_IMPORTACAO_DIRETA: 'ESTRANGEIRA_IMPORTACAO_DIRETA',
	ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC: 'ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC',
	NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO: 'NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO',
};

const FINALIDADE_NFE = {
	NORMAL: '1',
	COMPLEMENTAR: '2',
	AJUSTE: '3',
	DEVOLUCAO: '4',
};

const FORMA_PAGAMENTO = [
	{
		label: 'Dinheiro',
		value: '99cb0dcc-a932-46fd-a953-9834ab73bdc0',
		registro: {
			id: '99cb0dcc-a932-46fd-a953-9834ab73bdc0',
			descricao: 'Dinheiro',
			podeExcluir: false,
		},
	},
	{
		label: 'Cheque',
		value: 'fba93b18-ba77-4ac3-8463-851e9d483d97',
		registro: {
			id: 'fba93b18-ba77-4ac3-8463-851e9d483d97',
			descricao: 'Cheque',
			podeExcluir: false,
		},
	},
	{
		label: 'Cartão de crédito',
		value: '72c87cb7-e208-4f5d-aabb-8b55fe88da6a',
		registro: {
			id: '72c87cb7-e208-4f5d-aabb-8b55fe88da6a',
			descricao: 'Cartão de crédito',
			podeExcluir: false,
		},
	},
	{
		label: 'Cartão de débito',
		value: '5383f6c6-c9cf-456e-9c93-1ccc52aa99fb',
		registro: {
			id: '5383f6c6-c9cf-456e-9c93-1ccc52aa99fb',
			descricao: 'Cartão de débito',
			podeExcluir: false,
		},
	},
	{
		label: 'Crédito na loja',
		value: 'aed5c683-b53a-4adc-8ecc-31c5f20ce54e',
		registro: {
			id: 'aed5c683-b53a-4adc-8ecc-31c5f20ce54e',
			descricao: 'Crédito na loja',
			podeExcluir: false,
		},
	},
	{
		label: 'Vale alimentação',
		value: '86476c91-59fe-45d5-a94b-23e536bb272e',
		registro: {
			id: '86476c91-59fe-45d5-a94b-23e536bb272e',
			descricao: 'Vale alimentação',
			podeExcluir: false,
		},
	},
	{
		label: 'Vale refeição',
		value: '3f046d8d-ae04-4ad1-b8c8-5de9d949b637',
		registro: {
			id: '3f046d8d-ae04-4ad1-b8c8-5de9d949b637',
			descricao: 'Vale refeição',
			podeExcluir: false,
		},
	},
	{
		label: 'Vale presente',
		value: '01f62d80-d3d2-4739-808b-269b286c9e14',
		registro: {
			id: '01f62d80-d3d2-4739-808b-269b286c9e14',
			descricao: 'Vale presente',
			podeExcluir: false,
		},
	},
	{
		label: 'Vale combustível',
		value: 'fc10de1e-545d-4928-919d-673e3ac795ca',
		registro: {
			id: 'fc10de1e-545d-4928-919d-673e3ac795ca',
			descricao: 'Vale combustível',
			podeExcluir: false,
		},
	},
	{
		label: 'Boleto bancário',
		value: '4b279da3-0134-4178-8038-15244fa9ce53',
		registro: {
			id: '4b279da3-0134-4178-8038-15244fa9ce53',
			descricao: 'Boleto bancário',
			podeExcluir: false,
		},
	},
	{
		label: 'Depósito bancário',
		value: '71693ab8-0849-49e3-a82d-2274b0809537',
		registro: {
			id: '71693ab8-0849-49e3-a82d-2274b0809537',
			descricao: 'Depósito bancário',
			podeExcluir: false,
		},
	},
	{
		label: 'Pix - Pagamento instantâneo',
		value: 'b632f0be-7291-499d-b32f-634cbb7f7d16',
		registro: {
			id: 'b632f0be-7291-499d-b32f-634cbb7f7d16',
			descricao: 'Pix - Pagamento instantâneo',
			podeExcluir: false,
		},
	},
	{
		label: 'Transferência bancária, Carteira digital',
		value: '15f623f8-c0f9-4183-9512-fb982ea595de',
		registro: {
			id: '15f623f8-c0f9-4183-9512-fb982ea595de',
			descricao: 'Transferência bancária, Carteira digital',
			podeExcluir: false,
		},
	},
	{
		label: 'Programa de fidelidade, Cashback, Crédito virtual',
		value: '60ae9e87-b399-4e7f-b5c2-3776f9bccc6b',
		registro: {
			id: '60ae9e87-b399-4e7f-b5c2-3776f9bccc6b',
			descricao: 'Programa de fidelidade, Cashback, Crédito virtual',
			podeExcluir: false,
		},
	},
	{
		label: 'Outros',
		value: '71efae5f-79e6-4940-8b3c-bd04e4128ca0',
		registro: {
			id: '71efae5f-79e6-4940-8b3c-bd04e4128ca0',
			descricao: 'Outros',
			podeExcluir: false,
		},
	},
	{
		label: 'Sem pagamento',
		value: 'e7adb613-2817-441c-befa-bf7714475216',
		registro: {
			id: 'e7adb613-2817-441c-befa-bf7714475216',
			descricao: 'Sem pagamento',
			podeExcluir: false,
		},
	},
];

const INDICACAO_PAGAMENTO = {
	A_VISTA: {
		value: 'bc8cc6f7-35dd-4ccd-a0cf-2f3203025b16',
		label: 'À vista',
		registro: {
			id: 'bc8cc6f7-35dd-4ccd-a0cf-2f3203025b16',
			descricao: 'À vista',
			tipo: 'A_VISTA',
			favorita: true,
		},
	},
	A_PRAZO: {
		value: '713ae629-33e7-4a4d-91e5-c571d1421566',
		label: 'À prazo',
		registro: {
			id: '713ae629-33e7-4a4d-91e5-c571d1421566',
			descricao: 'À prazo',
			tipo: 'A_PRAZO',
			favorita: false,
		},
	},
	SEM_PAGAMENTO: {
		value: '82984bf9-91a7-4c72-9569-7e884b6a779c',
		label: 'Sem pagamento',
		registro: {
			id: '82984bf9-91a7-4c72-9569-7e884b6a779c',
			descricao: 'Sem pagamento',
			tipo: 'SEM_PAGAMENTO',
			favorita: false,
		},
	},
};

const INDICADOR_TOTAL = {
	NAO_COMPOEM_TOTAL_NFE: 'NAO_COMPOEM_TOTAL_NFE',
	COMPOEM_TOTAL_NFE: 'COMPOEM_TOTAL_NFE',
};

const MODALIDADE_TRANSPORTE = {
	EMITENTE: 'EMITENTE',
	DESTINATARIO_REMETENTE: 'DESTINATARIO_REMETENTE',
	TERCEIROS: 'TERCEIROS',
	SEM_FRETE: 'SEM_FRETE',
};

const MODULOS_IMPORTACAO = {
	COMPRAS: 'COMPRAS',
	CTES: 'CTES',
};

export {
	ORIGEM_PRODUTO,
	FINALIDADE_NFE,
	FORMA_PAGAMENTO,
	INDICACAO_PAGAMENTO,
	INDICADOR_TOTAL,
	MODALIDADE_TRANSPORTE,
	MODULOS_IMPORTACAO,
};
