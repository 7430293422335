import { estadosCadastro } from 'Common/Constantes/autorizacao';
import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];

export const situacaoOptions = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const indicacaoMovimentoOptions = [
	{ label: 'Saída', value: 'SAIDA' },
	{ label: 'Entrada', value: 'ENTRADA' },
];

export const parametrosOptions = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

export function buscarEstadoCadastro(props) {
	return props.values?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
}
