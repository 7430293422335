import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { Menu } from 'primereact/menu';

import { construirUrl, services } from 'Common';
import {
	Button,
	Card,
	DescricaoFiltroAvancado,
	Grid,
	If,
	PesquisaAvancada,
	SingleSelectProduto,
	ToastTypes,
	confirm,
	notify,
	tipoCampos,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';
import {
	createTabelaPrecoProduto,
	deleteTabelaPrecoProdutos,
	importarTodosProdutosEServicosTabelaPreco,
	importarTodosProdutosTabelaPreco,
	importarTodosServicosTabelaPreco,
	readTabelaPrecoProdutoFiltro,
	readTabelaPrecoProdutoGrupoFiltro,
	readTabelaPrecoProdutos,
	updateTabelaPrecoProdutos,
} from 'views/cadastros/vendas/TabelaPreco/Requests';
import { OPTIONS_PRODUTOS_FILTRO_AVANCADO } from 'views/cadastros/vendas/TabelaPreco/Util/constantes';

import { ListaProdutos } from './components/ListaProdutos';
import { ModalImportarProdutosPorGrupo } from './components/ModalImportarProdutosPorGrupo';
import { ModalAlteracaoEmLote } from './components/ModalAlteracaoEmLote';
import { ModalAlteracaoCusto } from './components/ModalAlteracaoCusto';
import { ModalImportarProdutosOutraTabela } from './components/ModalImportarProdutosOutraTabela';
import { ModalReplicarProdutosParaOutrasTabelas } from '../../../ModalReplicarProdutosParaOutrasTabelas';

function TabelaPrecoProdutosImpl({
	cabecalhoTabelaPreco,
	setFieldValueForm,
	valuesForm,
	resetFormPrincipal,
	setValuesForm,
	visibleModalReplicarProdutosOutrasTabelas,
	setVisibleModalReplicarProdutosOutrasTabelas,
}) {
	const { values, setFieldValue, resetForm } = useFormikContext();
	const {
		desabilitarCampos,
		isMobile,
		isTablet,
		informacoesPermissoes,
		isCanceled,
		isHeaderEditing,
		isHeaderSave,
		utilizaTabelaPrecoParaServico,
		forceClearFilter,
		setForceClearFilter,
	} = useContextTabelaPreco();
	const { filtroAvancado, setFiltroAvancado, descricaoFiltroAvancado, setDescricaoFiltroAvancado } =
		useContextPesquisa();

	const [produto, setProduto] = useState(null);
	const [menuImportacao, setMenuImportacao] = useState(null);
	const [menuAlterarEmLote, setMenuAlterarEmLote] = useState(null);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('item');
	const [totalElements, setTotalElements] = useState(null);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [deveEditarArraysControle, setDeveEditarArraysControle] = useState(false);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(OPTIONS_PRODUTOS_FILTRO_AVANCADO);
	const [visibleModalImportarProdutosPorGrupo, setVisibleModalImportarProdutosPorGrupo] = useState(false);
	const [visibleModalImportarProdutosDeOutraTabela, setVisibleModalImportarProdutosDeOutraTabela] = useState(false);
	const [visibleModalAlteracaoCusto, setVisibleModalAlteracaoCusto] = useState(false);
	const [visibleModalAlteracaoEmLote, setVisibleModalAlteracaoEmLote] = useState(false);
	const [dadosModalAlteracaoEmLote, setDadosModalAlteracaoEmLote] = useState({
		label: null,
		field: null,
	});

	const itensMenuImportacao = montarItensMenuImportacao();
	const itensMenuAlterarEmLote = montarItensMenuAlterarEmLote();

	useEffect(() => {
		if (cabecalhoTabelaPreco.id) {
			fetch();
		} else {
			resetForm({ values: { produtos: [], produtosEdicao: [], produtosExclusao: [] } });
		}

		if (isFirstRender) {
			setIsFirstRender(false);
		}
	}, []);

	useEffect(async () => {
		if (cabecalhoTabelaPreco?.id) {
			if (values.produtosEdicao?.length > 0 || values.produtosExclusao?.length > 0) {
				const url = `${services.GESTOR}/v1/vendas/tabelas_preco/${cabecalhoTabelaPreco?.id}/produtos`;
				if (values.produtosEdicao?.length > 0) {
					await updateTabelaPrecoProdutos(`${url}/alterar`, values.produtosEdicao, () => {
						setFieldValue('produtosEdicao', []);
					});
				}
				if (values.produtosExclusao?.length > 0) {
					await deleteTabelaPrecoProdutos(`${url}/excluir`, values.produtosExclusao, () => {
						setFieldValue('produtosExclusao', []);
					});
				}
			}

			fetch();
		} else {
			resetForm({ values: { produtos: [], produtosEdicao: [], produtosExclusao: [] } });
		}
	}, [rows, page, filtroAvancado, cabecalhoTabelaPreco?.id]);

	useEffect(() => {
		const precisaLimparProdutosFormPrincipal =
			values.produtosEdicao?.length === 0 &&
			values.produtosExclusao?.length === 0 &&
			(valuesForm.produtosEdicao?.length > 0 || valuesForm.produtosExclusao?.length > 0);

		if (values.produtosEdicao?.length > 0 && !deveEditarArraysControle) {
			setFieldValueForm('produtosEdicao', values.produtosEdicao);
		}

		if (values.produtosExclusao?.length > 0 && !deveEditarArraysControle) {
			setFieldValueForm('produtosExclusao', values.produtosExclusao);
		}

		if (precisaLimparProdutosFormPrincipal) {
			if (isHeaderEditing.current) {
				setValuesForm({ ...valuesForm, produtosEdicao: [], produtosExclusao: [] });
			} else {
				resetFormPrincipal({ ...valuesForm, produtosEdicao: [], produtosExclusao: [] });
			}
		}
	}, [values.produtosEdicao, values.produtosExclusao]);

	useEffect(() => {
		if (isCanceled.current) {
			setDeveEditarArraysControle(true);
			fetch();
			isCanceled.current = false;
		}
	}, [isCanceled.current]);

	useEffect(() => {
		if (isHeaderSave.current) {
			fetch();
			isHeaderSave.current = false;
		}
	}, [isHeaderSave.current]);

	async function fetch(idParaDuplicar) {
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;

		if (isFirstRender) {
			setSortField('item');
			setSortOrder(-1);
			campoOrdenacao = 'item';
			sentidoOrdenacao = -1;
		}
		if (idParaDuplicar || cabecalhoTabelaPreco?.id) {
			const filtro = getFilter();
			const url = construirUrl(
				`${services.GESTOR}/v1/vendas/tabelas_preco/${idParaDuplicar ?? cabecalhoTabelaPreco?.id}/produtos`,
				filtro || '?',
				rows,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			await readTabelaPrecoProdutos(url, ({ data }) => {
				setFieldValue('produtos', data.content);
				setFieldValue('produtosEdicao', []);
				setFieldValue('produtosExclusao', []);

				setTotalElements(data.totalElements);

				setDeveEditarArraysControle(false);
			});
		}
	}

	function getFilter() {
		let filter = '?';

		if (filtroAvancado) {
			filter = filter.concat(`query=(${filtroAvancado})`);
		}

		return filter;
	}

	function montarItensMenuImportacao() {
		const itens = [];

		itens.push({
			label: 'Importar produtos e serviços',
			disabled: !utilizaTabelaPrecoParaServico,
			command: () => {
				importarTodosProdutosEServicosTabelaPreco(
					cabecalhoTabelaPreco?.id,
					() => {
						fetch();
					},
					() => {
						notify('Erro desconhecido ao importar produtos e serviços', ToastTypes.ERROR);
					}
				);
			},
		});

		itens.push({
			label: 'Importar todos os produtos',
			command: () => {
				importarTodosProdutosTabelaPreco(
					cabecalhoTabelaPreco?.id,
					() => {
						fetch();
					},
					() => {
						notify('Erro desconhecido ao importar todos os produtos', ToastTypes.ERROR);
					}
				);
			},
		});

		itens.push({
			label: 'Importar todos os serviços',
			disabled: !utilizaTabelaPrecoParaServico,
			command: () => {
				importarTodosServicosTabelaPreco(
					cabecalhoTabelaPreco?.id,
					() => {
						fetch();
					},
					() => {
						notify('Erro desconhecido ao importar todos os serviços', ToastTypes.ERROR);
					}
				);
			},
		});

		itens.push({
			label: 'Importar por grupo',
			command: () => setVisibleModalImportarProdutosPorGrupo(true),
		});
		itens.push({
			label: 'Importar de outra tabela',
			command: () => setVisibleModalImportarProdutosDeOutraTabela(true),
		});

		return itens;
	}

	function montarItensMenuAlterarEmLote() {
		const itens = [];

		itens.push({
			label: 'Atualizar lucro %',
			command: () => {
				setVisibleModalAlteracaoEmLote(true);
				setDadosModalAlteracaoEmLote({ label: 'lucro %', field: 'LUCRO_PERCENTUAL' });
			},
		});

		itens.push({
			label: 'Atualizar preço de venda',
			command: () => {
				setVisibleModalAlteracaoEmLote(true);
				setDadosModalAlteracaoEmLote({ label: 'preço de venda', field: 'PRECO_VENDA' });
			},
		});

		itens.push({
			label: 'Atualizar comissão %',
			command: () => {
				setVisibleModalAlteracaoEmLote(true);
				setDadosModalAlteracaoEmLote({ label: 'comissão %', field: 'COMISSAO_PERCENTUAL' });
			},
		});

		itens.push({
			label: 'Atualizar desconto máx. %',
			command: () => {
				setVisibleModalAlteracaoEmLote(true);
				setDadosModalAlteracaoEmLote({
					label: 'desconto máximo %',
					field: 'DESCONTO_MAXIMO_PERCENTUAL',
				});
			},
		});

		itens.push({
			label: 'Atualizar custo dos produtos',
			command: () =>
				confirm(
					'Atualizar custo dos produtos',
					'A atualização de custo é um procedimento em lote, no qual atualizará o custo e recalculará o preço de venda de todos os produtos da tabela, deseja continuar?',
					async () => {
						setVisibleModalAlteracaoCusto(true);
					},
					() => {},
					'Sim',
					'Não'
				),
		});

		return itens;
	}

	function addNewProduto(event) {
		if (cabecalhoTabelaPreco?.id && event.value) {
			createTabelaPrecoProduto(
				`${services.GESTOR}/v1/vendas/tabelas_preco/${cabecalhoTabelaPreco?.id}/produtos/inserir/${event.value}`,
				({ data }) => {
					setFieldValue('produtos', [data, ...values.produtos]);
				}
			);
		}

		setProduto(null);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}
		const promises = [
			readTabelaPrecoProdutoFiltro(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Produto',
						name: 'produtoId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({
							label: `${option.codigo} - ${option.nome}${
								option.sku && option.sku.trim() !== '' ? ` - [Ref: ${option.sku}]` : ''
							}`,
							value: option.id,
						});
					});

					addOption(newOption);
				}
			}),
			readTabelaPrecoProdutoGrupoFiltro(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Grupo',
						name: 'produtoGrupoId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({
							label: `${option.nivel} - ${option.descricao}`,
							value: option.id,
						});
					});

					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	return (
		<>
			<Card>
				<Grid style={isMobile || isTablet ? { flexDirection: 'column-reverse' } : { alignItems: 'center' }}>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={SingleSelectProduto}
						colStyle={{ padding: '0px', marginRight: !isTablet && !isMobile ? '16px' : '0px' }}
						url={`${services.GESTOR}/v1/produtos`}
						name={null}
						onChange={(event) => addNewProduto(event)}
						value={produto}
						placeholder="Adicione um produto"
						filtroAdicionalUrl={utilizaTabelaPrecoParaServico ? '' : ';tipo!="SERVICO"'}
						disabled={desabilitarCampos || !cabecalhoTabelaPreco.id}
						menuPortalTarget={document.body}
						{...informacoesPermissoes}
					/>
					<div
						style={
							isMobile
								? {
										display: 'grid',
										margin: '8px 0px',
										rowGap: '4px',
									}
								: {
										display: 'grid',
										gridTemplateColumns: 'repeat(3, 1fr)',
										margin: '8px 0px',
										flex: '1',
										columnGap: '4px',
									}
						}
					>
						<PesquisaAvancada
							className="step-tabela-preco-filtrar-informacoes"
							optionsFiltros={filtroAvancadoOptions}
							onPesquisarClick={(filtro) => setFiltroAvancado(filtro)}
							onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
							onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
							onOpen={fetchOptionsFiltroAvancado}
							label="Filtro produtos"
							styleButton={{ width: '100%' }}
							disabledButton={desabilitarCampos || !cabecalhoTabelaPreco.id}
							forceClearFilter={forceClearFilter}
							setForceClearFilter={setForceClearFilter}
						/>
						<Button
							className="p-button-primary step-tabela-preco-importacao"
							label="Importação"
							icon="fa fa-download"
							style={{ width: '100%' }}
							aria-controls="popup_menu"
							aria-haspopup
							onClick={(event) => {
								menuImportacao.toggle(event);
							}}
							disabled={desabilitarCampos || !cabecalhoTabelaPreco.id}
						/>
						<Button
							className="p-button-primary step-tabela-preco-alterar-lote"
							label="Alterar em lote"
							icon="fa fa-wrench"
							style={{ width: '100%' }}
							aria-controls="popup_menu"
							aria-haspopup
							onClick={(event) => {
								menuAlterarEmLote.toggle(event);
							}}
							disabled={desabilitarCampos || !cabecalhoTabelaPreco.id}
						/>
					</div>
				</Grid>
				<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />

				<ListaProdutos
					totalElements={totalElements}
					setTotalElements={setTotalElements}
					rows={rows}
					setRows={setRows}
					page={page}
					setPage={setPage}
					cabecalhoTabelaPreco={cabecalhoTabelaPreco}
				/>
			</Card>

			<Menu
				model={itensMenuImportacao}
				popup
				style={{ width: '240px' }}
				ref={(elemento) => setMenuImportacao(elemento)}
			/>
			<Menu
				model={itensMenuAlterarEmLote}
				popup
				style={{ width: '240px' }}
				ref={(elemento) => setMenuAlterarEmLote(elemento)}
			/>

			<If test={visibleModalImportarProdutosPorGrupo}>
				<ModalImportarProdutosPorGrupo
					visible={visibleModalImportarProdutosPorGrupo}
					onCloseModal={() => {
						setVisibleModalImportarProdutosPorGrupo(false);
					}}
					fetch={fetch}
					idTabelaPreco={cabecalhoTabelaPreco.id}
				/>
			</If>
			<If test={visibleModalImportarProdutosDeOutraTabela}>
				<ModalImportarProdutosOutraTabela
					visible={visibleModalImportarProdutosDeOutraTabela}
					onCloseModal={() => {
						setVisibleModalImportarProdutosDeOutraTabela(false);
					}}
					fetch={fetch}
					idTabelaPreco={cabecalhoTabelaPreco.id}
				/>
			</If>
			<If test={visibleModalAlteracaoCusto}>
				<ModalAlteracaoCusto
					visible={visibleModalAlteracaoCusto}
					onCloseModal={() => {
						setVisibleModalAlteracaoCusto(false);
					}}
					fetch={fetch}
					idTabelaPreco={cabecalhoTabelaPreco.id}
				/>
			</If>
			<If test={visibleModalAlteracaoEmLote}>
				<ModalAlteracaoEmLote
					visible={visibleModalAlteracaoEmLote}
					onCloseModal={() => {
						setVisibleModalAlteracaoEmLote(false);
						setDadosModalAlteracaoEmLote({
							label: null,
							field: null,
						});
					}}
					fetch={fetch}
					idTabelaPreco={cabecalhoTabelaPreco.id}
					dadosModalAlteracaoEmLote={dadosModalAlteracaoEmLote}
				/>
			</If>
			<If test={visibleModalReplicarProdutosOutrasTabelas}>
				<ModalReplicarProdutosParaOutrasTabelas
					visible={visibleModalReplicarProdutosOutrasTabelas}
					onCloseModal={() => setVisibleModalReplicarProdutosOutrasTabelas(false)}
					fetch={fetch}
					idTabelaPreco={cabecalhoTabelaPreco.id}
				/>
			</If>
		</>
	);
}

const TabelaPrecoProdutos = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return { produtos: [], produtosEdicao: [], produtosExclusao: [] };
	},

	validate() {
		const errors = {};
		return errors;
	},

	handleSubmit: () => {},
})(TabelaPrecoProdutosImpl);

export { TabelaPrecoProdutos };
