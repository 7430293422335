import { ButtonCancelar, ButtonNovo, ButtonSalvar, estadosBotaoCancelar, estadosBotaoNovo } from 'components';
import { useFormikContext } from 'formik';

function ActionButtons({ onHide, onHandleSave }) {
	const { dirty, resetForm } = useFormikContext();

	function cancelar() {
		if (dirty) {
			resetForm();
		} else {
			onHide();
		}
	}

	function onClickSalvar(){
		onHandleSave(true);
	}

	function onClickSalvarENovo(){
		onHandleSave();
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar 
				onClick={onClickSalvar} 
				disabled={!dirty} 
			/>
			<ButtonNovo 
				estadoBotao={estadosBotaoNovo.SALVAR}
				onClick={onClickSalvarENovo} 
				label="Salvar e novo [Enter]"
				disabled={!dirty}
			/>			
		</>
	);
}

export { ActionButtons };
