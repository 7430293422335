import { Button, ButtonCancelar, Grid, ToastTypes, estadosBotaoCancelar, notify } from 'components';
import { isAfter, parseISO } from 'date-fns';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { buscarCadastroPessoaEmitenteNFe } from 'views/transporte/CTe/Requests';

function ActionButtons({ cte, onHide, registrosSelecionados, setRegistrosSelecionados, importarDadosNFeParaCTe }) {
	const { informacoesPermissoes } = useContextCTe();

	function handleClickCancelar() {
		setRegistrosSelecionados(null);
		onHide();
	}

	async function validarNotasExisteEmitentesDiferentes() {
		if (registrosSelecionados?.length > 1) {
			const idPrimeiroEmitente = registrosSelecionados[0]?.emitente?.id;

			const existeEmitentesDiferentes = await registrosSelecionados?.some(
				(registro) => registro.emitente?.id !== idPrimeiroEmitente
			);

			return existeEmitentesDiferentes;
		}

		return false;
	}

	async function handleClickImportarNotas() {
		if (registrosSelecionados?.length > 0) {
			if (await validarNotasExisteEmitentesDiferentes()) {
				notify('As NF-es devem ser do mesmo emitente para a importação', ToastTypes.ERROR);
				return;
			}

			const arrayEmitente = await registrosSelecionados
				?.map((registro) => ({
					id: registro?.emitente?.id,
					cpf: registro?.emitente?.cpf,
					cnpj: registro?.emitente?.cnpj,
				}))
				.filter(
					(primeiroObjeto, index, array) =>
						array.findIndex((segundoRegistro) => segundoRegistro.id === primeiroObjeto.id) === index
				);

			buscarCadastroPessoaEmitenteNFe(
				arrayEmitente,
				async ({ data }) => {
					if (data?.length > 0) {
						const novosRegistros = await registrosSelecionados?.map((registro) => {
							const emitenteAtualizado = data.find(
								(emitente) =>
									emitente.pessoaFisica?.cpf === registro.emitente.cpf ||
									emitente.pessoaJuridica?.cnpj === registro.emitente.cnpj
							);

							return emitenteAtualizado ? { ...registro, emitente: { pessoa: emitenteAtualizado } } : registro;
						});

						const registrosConsolidados = await consolidarInformacoes(novosRegistros);
						importarDadosNFeParaCTe(cte, registrosConsolidados, () => {
							setRegistrosSelecionados(null);
							onHide();
						});
					}
				},
				(error) => {
					console.error('Erro ao buscar emitente das notas para importação no CT-e: ', error);
				}
			);
		}
	}

	async function consolidarInformacoes(registros) {
		if (registros.length > 0) {
			const resultado = {
				destinatario: registros[0].destinatario,
				emitente: registros[0].emitente,
				nfes: [],
				transporte: {
					modalidadeFrete: registros[0].transporte.modalidadeFrete,
					volume: {
						quantidade: 0,
						pesoLiquido: 0,
					},
				},
				totais: {
					valorFrete: 0,
					valorNfe: 0,
				},
			};

			let dataMaisRecente = parseISO(registros[0].emissao);

			await registros.forEach((registro) => {
				const dataEmissaoAtual = parseISO(registro.emissao);

				if (isAfter(dataEmissaoAtual, dataMaisRecente)) {
					dataMaisRecente = dataEmissaoAtual;
					resultado.destinatario = registro.destinatario;
					resultado.emitente = registro.emitente;
					resultado.transporte.modalidadeFrete = registro.transporte.modalidadeFrete;
				}

				resultado.transporte.volume.quantidade += registro.transporte.volume.quantidade || 0;
				resultado.transporte.volume.pesoLiquido += registro.transporte.volume.pesoLiquido || 0;
				resultado.totais.valorFrete += registro.totais.valorFrete || 0;
				resultado.totais.valorNfe += registro.totais.valorNfe || 0;

				resultado.nfes?.push({
					id: null,
					chaveAcesso: registro.chave,
				});
			});

			return resultado;
		}

		return {};
	}

	return (
		<Grid style={{ paddingLeft: '8px' }}>
			<ButtonCancelar
				onClick={handleClickCancelar}
				estadoBotao={estadosBotaoCancelar.VOLTAR}
				{...informacoesPermissoes}
			/>
			<Button
				className="p-button-success"
				label="Importar notas"
				icon="fa fa-send"
				onClick={handleClickImportarNotas}
				style={{ margin: '5px', marginLeft: '0px' }}
				disabled={!registrosSelecionados || registrosSelecionados?.length === 0}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { ActionButtons };
