import { Field } from 'formik';
import InputDouble from 'components/input/InputDouble';

function FieldsModalDiferimento(props) {
	const { validacaoNovaTributacao, setFieldValue, values } = props;

	return (
		<>
			<Field
				sm="6"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				label="% Diferimento"
				name="diferimento"
				value={values.diferimento}
				onChange={(event) => setFieldValue('diferimento', event.target.value)}
				suffix="%"
				allowNegative={false}
				MaxValue={100}
				disabled={validacaoNovaTributacao}
			/>
		</>
	);
}

export default FieldsModalDiferimento;
