import { useState } from 'react';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { tabsCadastro } from '../../../views/cadastros/Pessoas/Util/constantes';

export default function SingleSelectIntermediador(props) {
	const { url, service } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR)
	);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVA";tipo=="JURIDICA"&page=${page}&size=50&sort=nome`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({
				label: montarLabel({
					...novoRegistro,
					cnpj: novoRegistro.cnpj,
				}),
				value: novoRegistro.id,
				registro: {
					nome: novoRegistro.nome,
					cnpj: novoRegistro.cnpj,
				},
			});
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return `${row.nome} - ${row.cnpj}`;
	}

	return (
		<>
			<ModalPessoas
				service={service}
				visible={visible}
				onHide={onHide}
				tipo="JURIDICA"
				tabSelecionada={tabsCadastro.PESSOA_JURIDICA}
				validarIntermediador
			/>
			<SingleSelect
				titleBotaoNovo="Adicionar novo intermediador"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				{...props}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectIntermediador.defaultProps = {
	label: 'Intermediador',
};

SingleSelectIntermediador.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
