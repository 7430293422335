import { CardTotalizadorListagem, Col, Grid } from 'components';

import { colorsStatus } from 'components/body/Util/constantes';
import { CTE_SITUACAO } from '../../Util/constantes';
import { useContextCTe } from '../../Context';

function CTeCardsListagem({ cards, selectedCard, setSelectedCard, setValorCard }) {
	const { isMobile } = useContextCTe();

	function handleSelectCardNaoEnviados() {
		if (selectedCard === CTE_SITUACAO.NAO_ENVIADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(CTE_SITUACAO.NAO_ENVIADO);
			setValorCard(`situacao==${CTE_SITUACAO.NAO_ENVIADO},situacao==${CTE_SITUACAO.AGUARDANDO_AUTORIZACAO} `);
		}
	}

	function handleSelectCardRejeitados() {
		if (selectedCard === CTE_SITUACAO.REJEITADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(CTE_SITUACAO.REJEITADO);
			setValorCard(`situacao==${CTE_SITUACAO.REJEITADO}`);
		}
	}

	function handleSelectCardCancelados() {
		if (selectedCard === CTE_SITUACAO.CANCELADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(CTE_SITUACAO.CANCELADO);
			setValorCard(`situacao==${CTE_SITUACAO.CANCELADO}`);
		}
	}

	function handleSelectCardAutorizados() {
		if (selectedCard === CTE_SITUACAO.TRANSMITIDO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(CTE_SITUACAO.TRANSMITIDO);
			setValorCard(`situacao==${CTE_SITUACAO.TRANSMITIDO}`);
		}
	}

	return (
		<Grid justifyBetween>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="ctesNaoEnviados"
					title="Não enviados"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de CT-es ainda não enviados para o sefaz'}
					titleFiltro="Clique para filtrar pelos CT-es não enviados"
					colors={colorsStatus.NAO_ENVIADA}
					value={cards.ctesNaoEnviados?.valor}
					selectable
					numberOfElements={cards.ctesNaoEnviados?.quantidade}
					selected={selectedCard === CTE_SITUACAO.NAO_ENVIADO}
					onSelect={handleSelectCardNaoEnviados}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="ctesRejeitados"
					title="Rejeitados"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de CT-es rejeitados pelo sefaz'}
					titleFiltro="Clique para filtrar pelos CT-es rejeitados"
					colors={colorsStatus.REJEITADA}
					selectable
					value={cards.ctesRejeitados?.valor}
					numberOfElements={cards.ctesRejeitados?.quantidade}
					selected={selectedCard === CTE_SITUACAO.REJEITADO}
					onSelect={handleSelectCardRejeitados}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="ctesCancelados"
					title="Cancelados"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de CT-es cancelados'}
					titleFiltro="Clique para filtrar pelos CT-es cancelados"
					colors={colorsStatus.CANCELADA}
					selectable
					value={cards.ctesCancelados?.valor}
					numberOfElements={cards.ctesCancelados?.quantidade}
					selected={selectedCard === CTE_SITUACAO.CANCELADO}
					onSelect={handleSelectCardCancelados}
				/>
			</Col>
			<Col xs="12" sm="6" md="6" lg="3" xl="3">
				<CardTotalizadorListagem
					name="ctesTransmitidos"
					title="Transmitidos"
					helpMessage={isMobile ? '' : 'Exibe o valor total e o número de CT-es transmitidos com sucesso'}
					titleFiltro="Clique para filtrar pelos CT-es transmitidos"
					colors={colorsStatus.TRANSMITIDA}
					selectable
					value={cards.ctesTransmitidos?.valor}
					numberOfElements={cards.ctesTransmitidos?.quantidade}
					selected={selectedCard === CTE_SITUACAO.TRANSMITIDO}
					onSelect={handleSelectCardAutorizados}
				/>
			</Col>
		</Grid>
	);
}

export { CTeCardsListagem };
