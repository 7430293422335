import {
	DADOSLOCALSTORAGE,
	buscarItemLocalStorage,
	buscarMascaraTelefone,
	mensagensDeValidacao,
	replaceCaracteresEspeciais,
	services,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	FormActions,
	FormContent,
	Grid,
	InputMask,
	Modal,
	estadosBotaoCancelar,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';

function ModalCompartilharPorWhatsAppImpl({ idMdfe, visible, onHide, numero }) {
	const { values, isValid, errors, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const [organizacao] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao);

	async function compartilharPorWhatsApp() {
		handleSubmit();
		await validateForm();

		if (isValid) {
			enviarPorWhatsapp();
		}
	}

	function enviarPorWhatsapp() {
		if (Object.keys(errors).length === 0 && !!values.telefone) {
			const texto = `Olá, segue as informações do MDF-e Nº ${numero}. Ficamos a inteira disposição para mais informações.

			%0D%0A%0D%0ACordialmente ${organizacao.nome}. 			
			%0D%0A%0D%0APdf: ${services.GESTOR}/v1/mdfes/${idMdfe}/organizacoes/${organizacao.id}/imprimir
			%0D%0A%0D%0AXml: ${services.GESTOR}/v1/mdfes/${idMdfe}/organizacoes/${organizacao.id}/xml`;

			window.open(`https://web.whatsapp.com/send?phone=55${values.telefone}&text=${replaceCaracteresEspeciais(texto)}`);

			onHide();
		}
	}

	return (
		<Modal header="Enviar por WhatsApp" visible={visible} onHide={onHide} width="500px">
			<FormActions style={{ margin: '8px 0' }}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				<Button
					label="Enviar por WhatsApp"
					icon="fa fa-send"
					onClick={compartilharPorWhatsApp}
					style={{ margin: '5px' }}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputMask}
						mask={buscarMascaraTelefone(values.telefone)}
						placeholder="(  )      -    "
						label="Número de telefone"
						value={values.telefone}
						name="telefone"
						onChange={(e) => setFieldValue('telefone', e.target.value)}
						obrigatorio
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalCompartilharPorWhatsApp = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues: (props) => ({
		telefone: props.telefone,
	}),

	validate(values) {
		const errors = {};

		if (!values.telefone) {
			errors.telefone = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalCompartilharPorWhatsAppImpl);

export { ModalCompartilharPorWhatsApp };
