import { services } from 'Common/Constantes/api';
import { del, get, post, put, exibirToast } from 'Common/Requisicoes';

export async function asyncGetPesquisaTecnico(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetTecnico(idTecnico, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tecnicos/${idTecnico}`, null, onSuccess, onError);
}

export async function asyncDeleteTecnico(idTecnico, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tecnicos/${idTecnico}`,
		null,
		exibirToast(onSuccess, 'Técnico excluído com sucesso'),
		onError
	);
}

export async function asyncCreateTecnico(tecnico, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tecnicos`,
		tecnico,
		null,
		exibirToast(onSuccess, 'Técnico criado com sucesso'),
		onError
	);
}

export async function asyncUpdateTecnico(tecnico, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tecnicos/${tecnico.id}`,
		tecnico,
		null,
		exibirToast(onSuccess, 'Técnico atualizado com sucesso'),
		onError
	);
}

export async function asyncGetTecnicoMesmoNome(nome, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tecnicos?query=nome=="${nome}"`, null, onSuccess, onError);
}
