import { format, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatarMonetario } from 'Common';
import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	NenhumRegistroEncontrado,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';

function TabelaNotaFiscal({ setRegistroSelecionado }) {
	const { setVisibleModalDadosNotaFiscal, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue } = useFormikContext();

	function renderNumeroSerie(row) {
		return renderField(`${row.numero}/${row.serie}`);
	}

	function renderEmissao(row) {
		const label = row.emissao ? format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '-';
		return renderField(label);
	}

	function renderCFOP(row) {
		return renderField(row.cfop?.registro?.codigo);
	}

	function renderPeso(row) {
		return renderField(formatarMonetario(row.peso));
	}

	function renderValor(row) {
		return renderField(formatarMonetario(row.valorTotalNotaFiscal));
	}

	function renderField(field) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{field}</div>;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalDadosNotaFiscal(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const notasFiscais = values.documento?.notasFiscais || [];
		const updatedNotas = notasFiscais.filter((notaFiscal) =>
			notaFiscal.tempId ? notaFiscal.tempId !== row.tempId : notaFiscal.id !== row.id
		);
		setFieldValue('documento.notasFiscais', updatedNotas);
	}

	return (
		<>
			<DataTable
				className="table"
				responsive
				value={values.documento?.notasFiscais || []}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="numeroSerie"
					header="Número/Série"
					body={(event) => renderNumeroSerie(event)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="emissao"
					header="Emissão"
					body={(event) => renderEmissao(event)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="cfop"
					header="CFOP"
					body={(event) => renderCFOP(event)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="peso"
					header="Peso KG"
					body={(event) => renderPeso(event)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="valorTotalNotaFiscal"
					header="Valor"
					body={(event) => renderValor(event)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					className="step-listagem-acoes"
					body={(e) => renderOpcoes(e)}
					header="Ações"
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar nota fiscal"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setVisibleModalDadosNotaFiscal(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>
		</>
	);
}

export { TabelaNotaFiscal };
