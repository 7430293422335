import { converterListProdutosToPrint } from 'views/cadastros/produtos/Etiquetas/Util/converterProdutos';
import { TIPO_ALINHAMENTO } from '../components/EtiquetaCampos/components/ModalCampo/components/RichTextConfiguration/Util/constantes';
import { COEFICIENTE_CM_PARA_PIXEL, COEFICIENTE_FONT_SIZE_PARA_PIXEL, TIPO_PAGINA_CAMPOS } from './constantes';

function isPagina(tipo) {
	return tipo === TIPO_PAGINA_CAMPOS.PAGINA;
}
function isCampos(tipo) {
	return tipo === TIPO_PAGINA_CAMPOS.CAMPOS;
}

function converterMilimetrosEmPixel(value, escalaVisualizacao) {
	const valorEmCm = value / 10;
	return valorEmCm * COEFICIENTE_CM_PARA_PIXEL * escalaVisualizacao;
}

function converterPixelEmMilimetros(value, escalaVisualizacao) {
	const valorEmPixel = (value / COEFICIENTE_CM_PARA_PIXEL) * 10;
	return valorEmPixel * escalaVisualizacao;
}

function converterFontSizeEmPixel(fontSize, escalaVisualizacao) {
	return fontSize * COEFICIENTE_FONT_SIZE_PARA_PIXEL * escalaVisualizacao;
}

function buscarAlinhamentoTextoEtiqueta(alinhamento) {
	switch (alinhamento) {
		case TIPO_ALINHAMENTO.ESQUERDA:
			return 'flex-start';
		case TIPO_ALINHAMENTO.CENTRO:
			return 'center';

		case TIPO_ALINHAMENTO.DIREITA:
			return 'flex-end';
	}
}

function isCampoTextoLivre(values) {
	return values?.includes('textoLivre');
}

function isCodigoBarra(values) {
	return ['produtoBarrasCodigo', 'produtoImagemBarras', 'produtoBarrasReferencia'].includes(values);
}

function isCampoValor(values) {
	return ['produtoPrecoVenda', 'produtoPrecoAtacado'].includes(values);
}

function isCampoPesoOuQuantidade(values) {
	return ['produtoPesoLiquido', 'produtoPesoBruto', 'produtoQuantidade'].includes(values);
}

function getExamplePreviewEtiquetas(values) {
	const etiqueta = {
		tipoPapel: values.tipoPapel,
		alturaPagina: values.alturaPagina,
		larguraPagina: values.larguraPagina,
		margemSuperior: values.margemSuperior,
		margemEsquerda: values.margemEsquerda,
		margemDireita: values.margemDireita,
		margemInferior: values.margemInferior,
		etiquetasPorLinha: values.etiquetasPorLinha,
		linhasPorPagina: values.linhasPorPagina,
		alturaEtiqueta: values.alturaEtiqueta,
		larguraEtiqueta: values.larguraEtiqueta,
		espacamentoLinha: values.espacamentoLinha,
		espacamentoColuna: values.espacamentoColuna,
		exibirContornoEtiqueta: values.exibirContornoEtiqueta,
		exibirContornoCampos: values.exibirContornoCampos,
		campos: values.campos?.map((campo) => ({
			campo: { nome: campo.campo?.registro?.nome, descricao: campo.campo?.label },
			posicaoTopo: campo.posicaoVertical,
			posicaoEsquerda: campo.posicaoHorizontal,
			altura: campo.altura,
			largura: campo.largura,
			fonte: 'Tahoma',
			tamanhoFonte: campo.tamanhoFonte,
			rotacao: campo.rotacao,
			negrito: campo.negrito,
			italico: campo.italico,
			sublinhado: campo.sublinhado,
			quebraLinha: campo.quebraLinha,
			alinhamento: campo.alinhamento,
			tamanhoAutomatico: campo.tamanhoAutomatico,
			zoom: campo.zoom,
			mostrarCodigoNaBarra: campo.mostrarCodigoNaBarra,
			tipoBarras: campo.tipoCodigoDeBarra,
			corFonte: 0,
		})),
	};

	const produtos = [
		{
			codigo: 1,
			referencia: '1',
			codigoBarras: '123456789',
			nome: 'Produto de exemplo',
			unidadeMedida: {
				unidade: 'UN',
				descricao: 'Unidade',
			},
			unidadeMedidaEntrada: {
				unidade: 'UN',
				descricao: 'Unidade',
			},
			precoVenda: 100,
			precoAtacado: 50,
			pesoLiquido: 10,
			pesoBruto: 10,
			saldo: 10,
			quantidade: values.etiquetasPorLinha * values.linhasPorPagina,
			observacao: 'Observação de exemplo',
		},
	];

	const config = {
		etiquetaInicial: 1,
		exibirContorno: {
			label: 'Sim',
			value: true,
		},
	};
	return converterListProdutosToPrint(produtos, etiqueta, config);
}

export {
	isPagina,
	isCampos,
	converterMilimetrosEmPixel,
	converterPixelEmMilimetros,
	converterFontSizeEmPixel,
	buscarAlinhamentoTextoEtiqueta,
	isCampoTextoLivre,
	isCodigoBarra,
	isCampoValor,
	isCampoPesoOuQuantidade,
	getExamplePreviewEtiquetas,
};
