import { services } from 'Common/Constantes/api';
import { del, get, post, put, exibirToast } from 'Common/Requisicoes';
import { notify, ToastTypes } from 'components/Toast';

export async function asyncGetNcms(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetNcm(idNcm, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/tributacoes/ncm/${idNcm}`, null, onSuccess, onError);
}

export async function asyncCreateNcm(ncm, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/ncm`,
		ncm,
		null,
		exibirToast(onSuccess, 'NCM criada com sucesso'),
		onError
	);
}

export async function asyncCreateNcmExtensao(ncm, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/tributacoes/ncm/extensao`,
		ncm,
		null,
		exibirToast(onSuccess, 'NCM com extensão criada'),
		onError
	);
}

export async function asyncUpdateNcm(ncm, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/tributacoes/ncm/${ncm.id}`,
		ncm,
		null,
		exibirToast(onSuccess, 'NCM atualizada'),
		onError
	);
}

export async function asyncDeleteNcm(idNcm, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/tributacoes/ncm/${idNcm}`,
		null,
		() => {
			notify('NCM excluída com sucesso', ToastTypes.SUCCESS, 5, onSuccess());
		},
		({ response }) => {
			if (response.status === 400) {
				notify(response.data.message, ToastTypes.ERROR, 5);
			}

			typeof onError === 'function' && onError();
		},
		true,
		false
	);
}

export async function asyncGetNcmByCodigoExtensao(codigoNcm, extensaoNcm, onSuccess, onError) {
	let query = `${services.GESTOR}/v1/tributacoes/ncm?query=codigoNcm.codigo==${codigoNcm}`;

	if (!!extensaoNcm) {
		query = query + `;extensao==${extensaoNcm ?? ''}`;
	}
	await get(query, null, onSuccess, onError);
}
