import { useEffect, useState } from 'react';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../../components/Button/ButtonCancelar';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../../components/Button/ButtonSalvar';
import Form from '../../../../../../components/Form';
import FormActions from '../../../../../../components/FormActions';
import FormContent from '../../../../../../components/FormContent';
import Grid from '../../../../../../components/Grid';
import InputField from '../../../../../../components/input/InputField';
import AutoProgressBar from '../../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../../components/Modal';
import If from '../../../../../../components/If';
import { asyncPostGrupos, asyncPutGrupos } from '../../Requests';
import { mensagensDeValidacao } from '../../../../../../Common/Constantes/mensagens';
import ButtonExcluir from '../../../../../../components/Button/ButtonExcluir';

const initialValue = {
	id: null,
	descricao: '',
	nivel: '',
	codigoNivel: 0,
	grupoPai: '',
	nivelPai: '',
};

function ModalGrupo(props) {
	const [dadosFormulario, setDadosFormulario] = useState([]);
	const {
		visible,
		onHide,
		header,
		dirty,
		informacoesPermissoes,
		novoNivel,
		codigoNivel,
		grupoPai,
		grupoEdicao,
		inserirGrupo,
		editarGrupo,
		values,
		resetForm,
		validateForm,
		deletarNivel,
		isValid,
	} = props;

	useEffect(() => {
		let newValues = initialValue;
		newValues.nivel = novoNivel;
		newValues.codigoNivel = codigoNivel;
		if (grupoEdicao) {
			newValues.id = grupoEdicao.id;
			newValues.descricao = grupoEdicao.descricao;
		} else {
			newValues.id = null;
			newValues.descricao = '';
		}

		if (grupoPai) {
			newValues.nivelPai = grupoPai.label;
			newValues.grupoPai = grupoPai.id;
		} else {
			newValues.nivelPai = '';
			newValues.grupoPai = null;
		}
		setDadosFormulario(newValues);
		setTimeout(() => {
			document.getElementById('inputDescricaoGrupo').focus();
		}, 500);
	}, []);

	useEffect(() => {
		resetForm(dadosFormulario);
	}, [dadosFormulario]);

	function onCancelar() {
		if (dirty) {
			resetForm({ values: dadosFormulario?.values });
			setTimeout(() => {
				document.getElementById('inputDescricaoGrupo').focus();
			}, 500);
		} else {
			onHide();
		}
	}

	async function onSalvar() {
		await validateForm();
		if (isValid) {
			if (!values.id) {
				await asyncPostGrupos(values, (e) => {
					values.id = e.data.id;
					inserirGrupo(values);
					onHide();
				});
			} else {
				await asyncPutGrupos(values.id, values).then((e) => {
					editarGrupo(values.descricao);
					onHide();
				});
			}
		}
	}

	function onExcluir() {
		deletarNivel(grupoEdicao);
	}

	return (
		<Modal header={header} visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<Grid style={{ margin: '0' }}>
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={onCancelar}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={onSalvar}
							{...informacoesPermissoes}
						/>

						<ButtonExcluir onClick={onExcluir} disabled={!grupoEdicao} {...informacoesPermissoes} />
					</Grid>
				</FormActions>
				<FormContent>
					<Grid>
						<If test={grupoPai != null}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputField}
								label="Grupo Pai"
								autoFocus
								disabled={true}
								name="nivelPai"
								size={255}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={InputField}
							label="Nível"
							autoFocus
							obrigatorio
							disabled={true}
							name="nivel"
							size={255}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							id={'inputDescricaoGrupo'}
							component={InputField}
							label="Descrição"
							autoFocus
							obrigatorio
							name="descricao"
							touched={false}
							size={120}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

ModalGrupo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (values.descricao && values.descricao.trim() === '') {
			errors.descricao = 'A Descrição deve ser informada';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string()
			.max(120, 'A descrição não pode ultrapassar 120 caracteres')
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalGrupo);

export default ModalGrupo;
