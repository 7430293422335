import { createContext, useContext, useState } from 'react';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Colors } from '../Util/constantes';

const ContextNFSe = createContext();

export default function NFSeProvider(props) {
	const { children } = props;

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.INSERIR));
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.EXCLUIR));
	const [atualizarTotal, setAtualizarTotal] = useState(false);
	const [modalServicoVisible, setModalServicoVisible] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [color, setColor] = useState(Colors.AGUARDANDO);
	const [disableFields, setDisableFields] = useState(false);
	const [existeParcelaRecebida, setExisteParcelaRecebida] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [municipioId, setMunicipioId] = useState(null);
	const [ufId, setUfId] = useState(null);
	const [clienteUsaPrecoAtacado, setClienteUsaPrecoAtacado] = useState(false);
	const [cardTotalSelected, setCardTotalSelected] = useState(null);
	const [messageLoadingTransmissao, setMessageLoadingTransmissao] = useState("Transmitindo NFS-e...");	
	const [exibirLoadingTransmissao, setExibirLoadingTransmissao] = useState(false);	
	const [showMessageTransmissao, setShowMessageTransmissao] = useState(false);
	const [showMessageParametrosFiscais, setShowMessageParametrosFiscais] = useState(false);
	const [errosTransmissao, setErrosTransmissao] = useState({});
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsappVisible, setModalEnviarPorWhatsappVisible] = useState(false);
	const [recalcularTodosOsServicosComTabelaPreco, setRecalcularTodosOsServicosComTabelaPreco] = useState(false);
	const [favoritos, setFavoritos] = useState({
		conta: null,
		formaPagamento: null,
		condicaoPagamento: null,
		categoria: null,
	});
	const [functionsForm, setFunctionsForm] = useState({
		handleChangeInput: () => {},
		handleChangeSelect: () => {},
		updateStatesContext: () => {},
	});

	return (
		<ContextNFSe.Provider
			value={{
				// state geral
				podeExcluir,
				setPodeExcluir,
				podeInserir,
				setPodeInserir,
				podeEditar,
				setPodeEditar,
				activeTab,
				setActiveTab,
				atualizarTotal,
				setAtualizarTotal,
				modalServicoVisible,
				setModalServicoVisible,
				color,
				setColor,
				disableFields,
				setDisableFields,
				existeParcelaRecebida,
				setExisteParcelaRecebida,
				isFirstRender,
				setIsFirstRender,
				municipioId,
				setMunicipioId,
				clienteUsaPrecoAtacado,
				setClienteUsaPrecoAtacado,
				cardTotalSelected,
				setCardTotalSelected,
				messageLoadingTransmissao,
				setMessageLoadingTransmissao,
				exibirLoadingTransmissao,
				setExibirLoadingTransmissao,
				ufId,
				setUfId,
				functionsForm,
				setFunctionsForm,
				showMessageTransmissao,
				setShowMessageTransmissao,
				showMessageParametrosFiscais,
				setShowMessageParametrosFiscais,
				errosTransmissao,
				setErrosTransmissao,
				modalEnviarPorWhatsappVisible,
				setModalEnviarPorWhatsappVisible,
				modalEnviarPorEmailVisible,
				setModalEnviarPorEmailVisible,
				recalcularTodosOsServicosComTabelaPreco,
				setRecalcularTodosOsServicosComTabelaPreco,
				favoritos,
				setFavoritos,
			}}
		>
			{children || null}
		</ContextNFSe.Provider>
	);
}

export const useContextNFSe = () => useContext(ContextNFSe);
