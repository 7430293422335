import { If, Modal } from 'components';
import ObjetoDeServicoForm from '../Form';

export default function ModalObjetoDeServico({ visible, onHide, history, cliente }) {
	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de objeto de serviço"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={visible}>
				<ObjetoDeServicoForm hideModal={onHide} isModal history={history} cliente={cliente} />
			</If>
		</Modal>
	);
}
