import propTypes from 'prop-types';
import SelectableCard from './components/SelectableCard';
import SimpleCard from './components/SimpleCard';

function CardTotalizadorListagem(props) {
	const { selectable } = props;

	if (selectable) {
		return <SelectableCard {...props} />;
	} else {
		return <SimpleCard {...props} />;
	}
}

CardTotalizadorListagem.propTypes = {
	selectable: propTypes.bool,
	selected: propTypes.bool,
	onSelect: propTypes.func,
	value: propTypes.oneOfType([propTypes.number.isRequired, propTypes.string.isRequired]),
	primaryColor: propTypes.string.isRequired,
	icon: propTypes.any,
	name: propTypes.string.isRequired,
};

export default CardTotalizadorListagem;
