import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import {
	asyncCreateTributacaoMunicipal,
	asyncDeleteTributacaoMunicipal,
	asyncUpdateTributacaoMunicipal,
} from 'views/cadastros/fiscal/TributacaoMunicipal/Requests';
import { converterTributacaoMunicipalParaApi } from 'views/cadastros/fiscal/TributacaoMunicipal/Util/tributacaoMunicipalConverter';

export function ActionButton(props) {
	const {
		values,
		dirty,
		initialValue,
		handleReset,
		history,
		informacoesPermissoes,
		CADASTROURL,
		PESQUISAURL,
		resetForm,
		handleSubmit,
		setIsNewTributacao,
		isModal,
		hideModal,
	} = props;

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	function handleClickCancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function handleClickSalvar(e, novoCallback) {
		handleSubmit();
		if (await validarFormulario(props)) {
			let dadosFormulario = converterTributacaoMunicipalParaApi(values);
			if (values.id) {
				await asyncUpdateRegistro(dadosFormulario, novoCallback);
				resetForm({ values: values });
			} else {
				await asyncCreateRegistro(dadosFormulario, novoCallback);
				if (typeof novoCallback === 'undefined' && !informacoesPermissoes.podeEditar) {
					history.goBack();
				}
			}
		}
	}

	const handleClickNovo = dirty ? (event) => handleClickSalvar(event, novo) : novo;

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
	}

	function handleClickExcluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncCreateRegistro(dadosFormulario, novoCallback) {
		await asyncCreateTributacaoMunicipal(dadosFormulario, async ({ data: tributacao }) => {
			setIsNewTributacao(true);
			let dados = {
				...values,
				id: tributacao?.id,
				codigo: tributacao?.codigo,
			};
			resetForm({ values: dados });

			if (novoCallback) {
				novoCallback();
			} else if (isModal) {
				hideModal(dados);
			} else {
				atualizarUrl(history, CADASTROURL, tributacao.id, metodosAtualizarUrl.POP);
			}
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoCallback) {
		await asyncUpdateTributacaoMunicipal(dadosFormulario, () => {
			if (novoCallback) {
				novoCallback();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteTributacaoMunicipal(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	function handleClickDuplicar() {
		atualizarUrl(history, `/tributacoes/municipal/duplicar/${values.id}`);
	}

	return (
		<>
			<ButtonCancelar onClick={handleClickCancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={handleClickSalvar} disabled={!dirty} />
			<ButtonNovo
				onClick={handleClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={!values.id && !dirty}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir onClick={handleClickExcluir} hidden={!values.id} disabled={!informacoesPermissoes.podeExcluir} />
			<Button
				className="p-button"
				label="Duplicar"
				icon="pi pi-copy"
				onClick={handleClickDuplicar}
				hidden={!values.id || dirty}
			/>
		</>
	);
}
