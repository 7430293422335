import { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Divider, NenhumRegistroEncontrado, ButtonExcluirTable } from 'components';
import { asyncDeleteModeloNcm } from '../../Requests';

export default function ModelosCadastrados(props) {
	const { modelos, setModelos } = props;

	const [expandedRows, setExpandedRows] = useState(null);

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ButtonExcluirTable onClick={() => onDeleteModeloTributacao(row)} />
			</div>
		);
	}

	function onDeleteModeloTributacao(row) {
		asyncDeleteModeloNcm(row.id, () => {
			refreshListaModelos(row.id);
		});
	}

	function refreshListaModelos(idModeloDeletado) {
		const listaAtualizada = modelos.filter((modelo) => {
			if (modelo.id !== idModeloDeletado) {
				return modelo;
			}
			return null;
		});
		setModelos(listaAtualizada);
	}

	const rowExpansionTemplated = (data) => (
		<DataTable
			className="table modal-novo-modelo-ncm"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={data.tributacoes}
			emptyMessage={<NenhumRegistroEncontrado />}
			style={{
				overflowY: 'scroll',
				maxHeight: '20rem',
				width: '100%',
			}}
		>
			<Column
				field="operacao"
				header="Operação Fiscal"
				name="operacao"
				body={(row) => `${row.codigoOperacao} - ${row.descricaoOperacao}`}
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					wordBreak: 'break-all',
				}}
			/>
			<Column
				field="tributacao"
				header="Tributação Estadual"
				name="tributacao"
				body={(row) => `${row.codigoTributacao} - ${row.descricaoTributacao}`}
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					wordBreak: 'break-all',
				}}
			/>
		</DataTable>
	);

	return (
		<>
			<Divider
				label="Modelos Cadastrados"
				styleLabel={{ fontSize: '18px', fontWeight: '500' }}
				styleContainer={{ margin: '1rem 0rem' }}
			/>
			<DataTable
				className="table modal-novo-modelo-ncm"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={modelos}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ overflowY: 'scroll', maxHeight: '20rem' }}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplated}
				expandedRows={expandedRows}
				dataKey="codigo"
			>
				<Column expander style={{ width: '3em' }} />
				<Column
					field="codigo"
					header="Código"
					name="codigo"
					body={(row) => row.codigo}
					sortable
					style={{
						width: '10rem',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						wordBreak: 'break-all',
					}}
				/>
				<Column
					field="descricao"
					header="Nome do Modelo"
					name="descricao"
					body={(row) => row.descricao}
					sortable
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						wordBreak: 'break-all',
					}}
				/>
				<Column body={renderOpcoes} header="Ações" style={{ width: '7rem' }} />
			</DataTable>
		</>
	);
}
