import { useEffect } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';

import { Dropdown, FormActions, FormContent, Grid, InputDate, InputField, Modal } from 'components';
import { mensagensDeValidacao } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import {
	INITIAL_VALUE_TRANSPORTE_PAPEL,
	TIPO_DOCUMENTO_ANTERIOR_PAPEL_OPTIONS,
} from 'views/transporte/CTe/Form/Util/constantes';
import { validarFormulario } from 'views/Util';
import { ActionButtons } from './ActionButtons';
import { converterDadosDocumentoPapelParaForm } from '../../../../Utils/converterDocumentosFiscais';

function ModalDadosDocumentoPapelView({ onHide, onSave }) {
	const { visibleModalDadosDocumentoPapel, setVisibleModalDadosDocumentoPapel, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext();

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('id-cte-documentos-anterior-documento-papel-emissao')?.focus();
		}, 200);
	}, []);

	async function onHandleSave() {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			onSave(values);
		}
	}

	return (
		<Modal
			visible={visibleModalDadosDocumentoPapel}
			setVisible={setVisibleModalDadosDocumentoPapel}
			header="Dados documento papel"
			onHide={onHide}
		>
			<FormActions>
				<ActionButtons {...{ onHide, onHandleSave }} />
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="8"
						lg="8"
						xl="8"
						label="Tipo documento"
						name="tipoDocumento"
						component={Dropdown}
						options={TIPO_DOCUMENTO_ANTERIOR_PAPEL_OPTIONS}
						value={values.tipoDocumento}
						showClear={false}
						onChange={(e) => setFieldValue('tipoDocumento', e)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						id="id-cte-documentos-anterior-documento-papel-emissao"
						label="Emissão"
						name="emissao"
						component={InputDate}
						value={values.emissao}
						onChange={(e) => setFieldValue('emissao', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Série"
						name="serie"
						component={InputField}
						value={values.serie}
						size={3}
						onChange={(e) => setFieldValue('serie', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Sub série"
						name="subSerie"
						component={InputField}
						value={values.subSerie}
						size={2}
						onChange={(e) => setFieldValue('subSerie', e.target.value)}
						disabled={isSituacaoFinalCTe}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						label="Número documento fiscal"
						name="numeroDocumento"
						component={InputField}
						value={values.numeroDocumento}
						size={30}
						onChange={(e) => setFieldValue('numeroDocumento', e.target.value)}
						obrigatorio
						disabled={isSituacaoFinalCTe}
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalDadosDocumentoPapel = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return converterDadosDocumentoPapelParaForm(props.registroSelecionado);
		} else {
			return INITIAL_VALUE_TRANSPORTE_PAPEL;
		}
	},

	validate(values) {
		const errors = {};

		if (!values.emissao) {
			errors.emissao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.serie) {
			errors.serie = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.numeroDocumento) {
			errors.numeroDocumento = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalDadosDocumentoPapelView);

export { ModalDadosDocumentoPapel };
