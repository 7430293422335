import { useState } from 'react';
import { If, Modal } from 'components';
import CodigoServicoForm from '../Form';

export default function ModalCodigoServicoTributacao(props) {
	const [hideBackground, setHideBackground] = useState(false);

	return (
		<Modal
			className={props.visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de código de serviço"
			visible={props.visible}
			onHide={props.onHide}
			styleModal={{ maxWidth: '1200px' }}
			hideBackground={hideBackground}
		>
			<If test={props.visible}>
				<CodigoServicoForm
					hideModal={props.onHide}
					isModal
					history={props.history}
					setHideBackground={setHideBackground}
					codigoServico={props.codigoServico}
				/>
			</If>
		</Modal>
	);
}
