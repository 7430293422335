export const helpPlanosForm = {
	ilimitado: 'Quando verdadeiro, o sistema não validará quantidade de uso, será totalmente liberado para uso.',
	suporteChat:
		'Quando verdadeiro, a organização com esse plano poderá entrar em contato com o suporte através do chat do Tomtiket.',
	descricao: 'Identificador do plano para seleção.',
	usuarios: 'Define a quantidade de usuários que poderão ser criados.',
	armazenamento: 'Define a quantidade que o usuário terá de armazenamento.',
	cadastrosPessoas: 'Define a quantidade de cadastros de pessoas que poderá ser inserido no sistema.',
	cadastrosProdutos: 'Define a quantidade de cadastros de produtos que poderá ser inserido no sistema.',
	documentosMensais: 'Define a quantidade de documento que poderá ser inserido no sistema.',
	contasFinanceiras: 'Define a quantidade de contas financeiras que poderá ser inserido no sistema.',
	nfesMensais: 'Define a quantidade de NF-es que poderá ser inserido no sistema.',
	nfcesMensais: 'Define a quantidade de NFC-es que poderá ser inserido no sistema.',
	orcamentosMensais: 'Define a quantidade de orçamentos que poderá ser inserido no sistema.',
	pedidosMensais: 'Define a quantidade de pedidos de venda que poderá ser inserido no sistema.',
	comprasMensais: 'Define a quantidade de compras que poderá ser inserido no sistema.',
	ordensMensais: 'Define a quantidade de ordens de serviço que poderá ser inserido no sistema.',
	nfsesMensais: 'Define a quantidade de NFS-es que poderá ser inserido no sistema.',
	mdfesMensais: 'Define a quantidade de MDF-es que poderá ser inserido no sistema.',
	ctesMensais: 'Define a quantidade de CT-es que poderá ser inserido no sistema.',
	boletosMensais: 'Define a quantidade de boletos que poderá ser gerado no sistema.',
	valor: 'Valor da mensalidade cobrado pelo plano.',
};
