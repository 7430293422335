import { Field, useFormikContext } from 'formik';

import { Checkbox, Grid, Subtitle } from 'components';

import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';

function TabControladoria() {
	const { podeEditarEmpresa, marcarCheckBoxUsaGestaoFechamentoControladoria, setMarcarCheckBoxUsaGestaoFechamentoControladoria } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Subtitle subtitle="Configurações gestão de fechamento" />
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Habilitar gestão de fechamento"
				name="parametrizacao.controladoria.usaGestaoFechamento"
				value={marcarCheckBoxUsaGestaoFechamentoControladoria}
				onChange={(e) => {
					setFieldValue('parametrizacao.controladoria.usaGestaoFechamento', e.checked)
					setMarcarCheckBoxUsaGestaoFechamentoControladoria(e.checked)
				}}
				checked={values.parametrizacao.controladoria?.usaGestaoFechamento}
				disabled={!podeEditarEmpresa}
			/>
		</Grid>
	);
}

export { TabControladoria };
