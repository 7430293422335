import { Field, useFormikContext } from 'formik';

import { Grid, InputMoney, InputPercentage, SingleSelectCst, typesCst } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function TabImpostos() {
	const { informacoesPermissoes, isSituacaoFinalCTe } = useContextCTe();
	const { values, setFieldValue } = useFormikContext();

	return (
		<>
			<Grid style={{ padding: '0px' }}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={SingleSelectCst}
					label="CST"
					name="impostos.cstCsosn"
					value={values?.impostos.cstCsosn}
					useFormErrors={false}
					useFormTouched={false}
					type={typesCst.CST_CTE}
					isClearable={false}
					onChange={(event) => setFieldValue('impostos.cstCsosn', event)}
					obrigatorio
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
			</Grid>
			<Grid style={{ padding: '0px' }}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputPercentage}
					label="% BC ICMS"
					name="impostos.percentualBaseCalculoIcms"
					value={values?.impostos?.percentualBaseCalculoIcms}
					useOnBlurPerformatico
					onBlur={(valor) => setFieldValue('impostos.percentualBaseCalculoIcms', valor)}
					suffix="%"
					allowNegative={false}
					maxValue={100}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					label="BC ICMS"
					name="impostos.valorBaseCalculoIcms"
					value={values?.impostos?.valorBaseCalculoIcms}
					touched
					allowNegative={false}
					useOnBlurPerformatico
					onBlur={(valor) => setFieldValue('impostos.valorBaseCalculoIcms', valor)}
					size={13}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputPercentage}
					label="% ICMS"
					name="impostos.aliquotaIcms"
					value={values?.impostos?.aliquotaIcms}
					useOnBlurPerformatico
					onBlur={(valor) => setFieldValue('impostos.aliquotaIcms', valor)}
					suffix="%"
					allowNegative={false}
					maxValue={100}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputMoney}
					label="Valor ICMS"
					name="impostos.valorIcms"
					value={values?.impostos?.valorIcms}
					touched
					allowNegative={false}
					useOnBlurPerformatico
					onBlur={(valor) => setFieldValue('impostos.valorIcms', valor)}
					size={13}
					disabled={isSituacaoFinalCTe}
					{...informacoesPermissoes}
				/>
			</Grid>
		</>
	);
}

export { TabImpostos };
