import { useEffect } from 'react';
import useStateCallback from '../../../Common/Hooks/useStateCallback';
import { buscarHiddenDeAcordoComAsPermissoes } from '../../../Common/Autorizacao/ManipulacaoDeComponentes';
import { renderizarValidacao } from '../../../Common/TratamentoDeErro/validacoesDeCampos';
import Col from '../../Col';
import InputDouble from '../InputDouble';
import './Styles/index.css';
import Button from 'components/Button';

function InputDoubleCalculateButton(props) {
	const {
		label,
		name,
		value,
		errors,
		colId,
		colClassName,
		col,
		sm,
		md,
		lg,
		xl,
		style,
		styleLabel,
		helpMessage,
		labelSize,
		obrigatorio,
		disabled,
		disabledButton,
		sizeValor,
		informacoesPermissoes,
		onClick,
		titleButton,
		className,
	} = props;

	const [valor, setValor] = useStateCallback(0);

	const inputStyle = {
		width: 'calc(100% - 32px)',
		padding: '0px',
	};

	const inputDoubleCalculateButtonStyle = {
		fontSize: '16px',
		height: '32px',
		width: '32px',
		borderRadius: '0 3px 3px 0',
	};

	const styleDiv = {
		alignItems: 'flex-end',
	};

	const styleInput = {
		borderRadius: '3px 0 0 3px',
	};

	useEffect(() => {
		setValor(value ?? 0);
	}, []);

	useEffect(() => {
		if (valor !== value) {
			setValor(value ?? 0);
		}
	}, [value]);

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(props.podeVisualizar, props.hidden))
			return { display: 'none', ...props.colStyle };
		return props.colStyle;
	}

	function changeValor(e) {
		let valorTemp = e.target.value ?? 0;
		setValor(valorTemp);
		if (typeof props.onChange === 'function') {
			props.onChange(valorTemp, 0);
		}
	}

	function blurValor(e) {
		if (typeof props.onBlur === 'function') {
			props.onBlur(valor, 0);
		}
	}

	function onClickButton(e) {
		if (!disabledButton && typeof onClick === 'function') {
			onClick(e);
		}
	}

	return (
		<Col id={colId} className={colClassName} col={col} sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<div className="p-inputgroup" style={styleDiv}>
				<InputDouble
					id={`InputDoubleCalculateButton-${name ? name : props.field?.name}`}
					className={className}
					colStyle={inputStyle}
					name={name}
					obrigatorio={obrigatorio}
					label={label}
					labelSize={labelSize}
					value={valor}
					onChange={changeValor}
					onBlur={blurValor}
					style={{ ...styleInput, ...style }}
					styleLabel={styleLabel}
					size={sizeValor}
					helpMessage={helpMessage}
					prefix="R$ "
					placeholder="R$ 0,00"
					touched
					allowNegative={false}
					disabled={disabled}
					{...informacoesPermissoes}
					autoComplete="off"
				/>
				<Button
					color="primary"
					title={titleButton}
					className="input-double-calculate-button"
					style={style ? style : inputDoubleCalculateButtonStyle}
					icon="fa fa-calculator"
					onClick={onClickButton}
				/>
			</div>
			{renderizarValidacao(errors, errors ? true : false)}
		</Col>
	);
}

InputDoubleCalculateButton.defaultProps = {
	sizeValor: 15,
};

export default InputDoubleCalculateButton;
