import { services, TiposPercentualValor } from 'Common';
import { Grid, InputDouble, InputSelectPercentualOrValor, Pagamentos } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextCTe } from 'views/transporte/CTe/Context';
import { colorsStatus } from 'views/transporte/CTe/Form/Util/constantes';
import { aplicarPercentual } from 'views/Util';

function TabPagamentos({
	informacoesPermissoes,
	favoritos,
	totalizadores,
	classNameTabView,
	podeEditar,
	setRecalcularTodosOsProdutosComTabelaPreco,
}) {
	const { values, setFieldValue, errors } = useFormikContext();
	const { isFirstRender, isSituacaoFinalCTe, setAtualizarTotalizadores } = useContextCTe();

	function onChangePagamentos(pagamentos) {
		setFieldValue('pagamentos', pagamentos);
	}

	function onChangePagamento(index, value) {
		setFieldValue(`pagamentos[${index}]`, value);
	}

	function onChangePagamentoField(index, field, value) {
		setFieldValue(`pagamentos[${index}].[${field}]`, value);
	}

	function onBlurTotalDesconto(tipo, value, percentual) {
		const valorDesconto =
			tipo === TiposPercentualValor.VALOR ? value : aplicarPercentual(totalizadores.totalFrete, percentual);
		if (!isFirstRender && valorDesconto !== totalizadores.totalDesconto) {
			setFieldValue('totalizadores.totalDesconto', valorDesconto);
			setAtualizarTotalizadores(true);
		}
	}

	return (
		<>
			<Grid>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Valor do CT-e"
					name="totalizadores.totalFrete"
					value={totalizadores.totalFrete}
					decimalScale={2}
					prefix="R$ "
					allowNegative={false}
					size={11}
					disabled
					touched
					{...informacoesPermissoes}
				/>
				<Field
					sm="6"
					md="4"
					lg="4"
					xl="4"
					component={InputSelectPercentualOrValor}
					size={15}
					label="Desconto"
					name="totalizadores.totalDesconto"
					value={values.totalizadores.totalDesconto}
					onBlur={(tipo, value, percentual) => onBlurTotalDesconto(tipo, value, percentual)}
					placeholder="0,00"
					allowNegative={false}
					disabled={isSituacaoFinalCTe}
					informacoesPermissoes={informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					prefix="R$ "
					label="Valor líquido"
					name="totalizadores.totalLiquido"
					value={totalizadores.totalLiquido}
					decimalScale={2}
					allowNegative={false}
					size={11}
					disabled
					touched
					{...informacoesPermissoes}
				/>
			</Grid>
			<Pagamentos
				idOrigem={values.id}
				pagamentos={values.pagamentos}
				valorTotal={totalizadores.totalLiquido}
				dataBaseParcela={values.emissao}
				urls={{
					urlBase: `${services.GESTOR}/v1/ctes`,
					condicaoPagamento: `${services.GESTOR}/v1/ctes/relacoes/condicoes_pagamento`,
					formaPagamento: `${services.GESTOR}/v1/ctes/relacoes/formas_pagamento`,
					conta: `${services.GESTOR}/v1/ctes/relacoes/contas`,
					categoria: `${services.GESTOR}/v1/ctes/relacoes/categorias/receitas`,
				}}
				informacoesPermissoes={{ ...informacoesPermissoes, podeEditar }}
				disabledFields={isSituacaoFinalCTe}
				favoritos={favoritos}
				hideTabFinanceiro={values.situacao !== 'FINALIZADO' && values.situacao !== 'TRANSMITIDO'}
				onChangePagamentos={onChangePagamentos}
				onChangePagamento={onChangePagamento}
				onChangePagamentoField={onChangePagamentoField}
				setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
				errors={errors?.pagamentos}
				classNameTabView={`tab-view-no-border ${classNameTabView[values.situacao]}`}
				color={colorsStatus[values.situacao].strongColor}
				hiddenButtonAdd={isSituacaoFinalCTe}
				stylePagamento={{ padding: '0px 0px 0px 0px' }}
			/>

			<br />
		</>
	);
}

export { TabPagamentos };
