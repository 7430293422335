import { useFormikContext } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format, parseISO } from 'date-fns';

import {
	Button,
	ButtonEditarTable,
	Col,
	Form,
	FormActions,
	FormContent,
	Modal,
	NenhumRegistroEncontrado,
} from 'components';
import { baixarArquivo } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { getPdfCTeCartaCorrecao, getXmlCTeCartaCorrecao } from 'views/transporte/CTe/Requests';
import { ActionButtons } from './components/ActionButtons';

function ModalHistoricoCorrecoes({ visible, onHide }) {
	const { values } = useFormikContext();
	const { setRegistroSelecionadoCartaCorrecao, setVisibleModalCorrecao } = useContextCTe();

	function renderSequencial(row) {
		return <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{row.numeroSequencial}</div>;
	}

	function renderEmissao(row) {
		const label = format(parseISO(row?.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						margin: '2px',
					}}
					className="p-button p-button-success"
					icon="fa fa-download"
					title="Download do XML da carta de correção"
					onClick={() => downloadXMLCartaCorrecao(row)}
				/>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						margin: '2px',
					}}
					className="p-button p-button-primary"
					icon="fa fa-print"
					title="Imprimir da carta de correção"
					onClick={() => downloadPDFCartaCorrecao(row)}
				/>
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionadoCartaCorrecao(row);
		setVisibleModalCorrecao(true);
	}

	function downloadXMLCartaCorrecao(cartaCorrecao) {
		getXmlCTeCartaCorrecao(values.id, cartaCorrecao.arquivoXml, ({ data: file }) => {
			baixarArquivo(file, `carta_correcao_prot_${cartaCorrecao.protocolo}`, 'application/xml');
		});
	}

	function downloadPDFCartaCorrecao(cartaCorrecao) {
		getPdfCTeCartaCorrecao(
			values.id, cartaCorrecao.arquivoXml,
			({ data: pdf }) => {
				const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const documento = window.open(arquivoURL);
				if (documento) {
					documento.onload = () => {
						setTimeout(() => {
							documento.document.title = `CC-e Protocolo Nº ${cartaCorrecao.protocolo}`;
						}, 250);
					};
				}
			});
	}	

	return (
		<Modal
			header="Correções efetuadas no CT-e"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1024px' }}
			closeOnEsc={false}
		>
			<Form>
				<FormActions>
					<Col style={{ paddingLeft: '0px' }}>
						<ActionButtons onHide={onHide} />
					</Col>
				</FormActions>
				<FormContent>
					<DataTable
						className="table"
						responsive
						value={values.correcoes || []}
						sortField="numeroSequencial"
						sortOrder={-1}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="numeroSequencial"
							header="Sequencial"
							body={(e) => renderSequencial(e)}
							style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
						/>
						<Column
							field="emissao"
							header="Emissão"
							body={(e) => renderEmissao(e)}
							style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
						/>
						<Column
							className="step-listagem-acoes"
							header="Ações"
							body={(e) => renderOpcoes(e)}
							style={{ width: '100px' }}
						/>
					</DataTable>
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalHistoricoCorrecoes };
