import { useEffect, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	copiarObjeto,
	gerarUUID,
	getSafeValue,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import {
	Form,
	FormActions,
	FormContent,
	INITIAL_VALUE_PAGAMENTO,
	ModalLoadingTransmissao,
	notify,
	Prompt,
	ToastTypes,
	Tutorial,
	tutorialStepsCte,
	useGenerateParcelas,
	useRecalculatePagamentos,
	validatePagamentos,
} from 'components';

import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { MODALIDADE_TRANSPORTE } from 'Common/dfe/NFe/Utils/constantes';
import { useContextCTe } from '../Context';
import {
	getCategoriaFavoritaReceita,
	getComponenteValorPrestacaoFavorito,
	getCondicaoPagamentoFavorita,
	getContaFavorita,
	getCTe,
	getFormaPagamentoDinheiro,
	getMercadoriaTransporteFavorito,
	getParticipante,
	getUsuarioFavorito,
} from '../Requests';
import { CADASTRO_URL, CTE_SITUACAO } from '../Util/constantes';
import { ActionButtons } from './components/ActionButtons';
import { ErrorsMessage } from './components/ErrorsMessage';
import { EventosPosteriores } from './components/EventosPosteriores';
import { Header } from './components/Header';
import { HeaderForm } from './components/HeaderForm';
import { InformacoesPrincipais } from './components/InformacoesPrincipais';
import { TabsCargas } from './components/TabsCargas';
import { TabsComplementares } from './components/TabsComplementares';
import { TabsDocumentos } from './components/TabsDocumentos';
import {
	CTE_FINALIDADE,
	CTE_TIPO,
	CTE_TIPO_DOCUMENTOS,
	CTE_TOMADOR,
	CTE_UNIDADE_MEDIDA,
	INITIAL_VALUES,
} from './Util/constantes';
import {
	converterCTeParaForm,
	converterCTeSingleSelectParaForm,
	converterPessoaEnderecoFavoritoParaForm,
	converterPessoaParaForm,
} from './Util/CTeConverter';

import './Styles/index.css';

function CTeFormImpl({ match, history }) {
	const { values, dirty, resetForm, setFieldValue, setValues } = useFormikContext();
	const {
		messageLoading,
		exibirLoadingTransmissao,
		setErrorsTransmissao,
		setActiveTabDocumentos,
		setActiveTabCargas,
		setActiveTabComplementares,
		setIsSituacaoFinalCTe,
		setIsFirstRender,
		atualizarTotalizadores,
		setAtualizarTotalizadores,
	} = useContextCTe();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [favoritosPagamento, setFavoritosPagamento] = useState({});
	const [totalizadores, setTotalizadores] = useState({
		totalCarga: values.totalizadores.totalCarga,
		totalFrete: values.totalizadores.totalFrete,
		totalDesconto: values.totalizadores.totalDesconto,
		totalLiquido: values.totalizadores.totalLiquido,
	});
	const [desabilitarRecalcularPagamento, setDesabilitarRecalcularPagamento] = useState(false);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CTE);
	const dadosLogin = buscarDadosLoginLocalStorage();
	const dadosParametrizacaoCte = dadosLogin?.filialConectada?.parametrosTransportes;

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: totalizadores?.totalLiquido,
		dataBaseParcela: values.emissao,
		favoritos: favoritosPagamento,
	});

	useRecalculatePagamentos(
		{
			valorTotal: totalizadores?.totalLiquido,
			dataBaseParcela: values.emissao,
			favoritos: favoritosPagamento,
			desabilitarRecalculoParcelas: desabilitarRecalcularPagamento,
		},
		[totalizadores?.totalLiquido]
	);

	useEffect(async () => {
		const { id } = match.params;

		if (deveExibirTutorial) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CTE, false, null, false);
		}

		if (validarUUID(id)) {
			fetchRegistro(id);
		} else {
			await newRegistro();
		}
		setErrorsTransmissao({});

		setTimeout(() => {
			document.getElementById('id-select-cte-tomador')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setIsSituacaoFinalCTe(
			[CTE_SITUACAO.AGUARDANDO_AUTORIZACAO, CTE_SITUACAO.CANCELADO, CTE_SITUACAO.TRANSMITIDO].includes(values.situacao)
		);
	}, [values.situacao]);

	useEffect(() => {
		if (atualizarTotalizadores) {
			let totalFrete = 0;
			values.servicos.forEach((servico) => {
				totalFrete += servico.valor;
			});

			const totalLiquido = totalFrete - values.totalizadores.totalDesconto;

			if (totalFrete !== totalizadores.totalFrete) {
				setFieldValue('totalizadores.totalFrete', totalFrete);
			}
			if (totalLiquido !== totalizadores.totalLiquido) {
				setFieldValue('totalizadores.totalLiquido', totalLiquido);
			}
			setTotalizadores({
				...totalizadores,
				totalFrete: totalFrete,
				totalLiquido: totalLiquido,
				totalCarga: values.totalizadores.totalCarga,
				totalDesconto: values.totalizadores.totalDesconto,
			});

			setAtualizarTotalizadores(false);
		}
	}, [atualizarTotalizadores]);

	async function fetchRegistro(id) {
		await getCTe(id, ({ data: cteApi }) => {
			resetForm({ values: converterCTeParaForm(cteApi) });
			atualizarUrl(history, CADASTRO_URL, cteApi.id, metodosAtualizarUrl.POP);
			setIsFirstRender(false);
			setAtualizarTotalizadores(true);
		});
	}

	async function newRegistro() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		setActiveTabDocumentos(0);
		setActiveTabCargas(0);
		setActiveTabComplementares(0);

		const valoresGet = {
			...copiarObjeto(INITIAL_VALUES),
			serie: dadosLogin?.filialConectada?.parametrosFiscalCTe?.serie,
			localEmissao: converterCTeSingleSelectParaForm(
				dadosLogin?.filialConectada?.endereco?.municipio,
				`${dadosLogin?.filialConectada?.endereco?.municipio?.nome} - ${dadosLogin?.filialConectada?.endereco?.municipio?.estado?.sigla}`
			),
			informacoesComplementares: {
				...INITIAL_VALUES.informacoesComplementares,
				setor: converterCTeSingleSelectParaForm(dadosLogin?.setores[0]) || null,
			},
		};

		let idRemetente = null;
		let idDestinatario = null;

		if (Object.keys(dadosParametrizacaoCte).length > 0) {
			if (dadosParametrizacaoCte.cteRntrc) {
				valoresGet.rntrc = dadosParametrizacaoCte.cteRntrc;
			}

			if (dadosParametrizacaoCte.cteTomadorPadrao) {
				valoresGet.tomador = dadosParametrizacaoCte.cteTomadorPadrao;
			}

			if (dadosParametrizacaoCte.cteLocalInicio) {
				valoresGet.localInicioPrestacao = converterCTeSingleSelectParaForm(
					dadosParametrizacaoCte.cteLocalInicio,
					`${dadosParametrizacaoCte.cteLocalInicio?.nome} - ${dadosParametrizacaoCte.cteLocalInicio?.estado?.sigla}`
				);
			}

			if (dadosParametrizacaoCte.cteLocalTermino) {
				valoresGet.localFimPrestacao = converterCTeSingleSelectParaForm(
					dadosParametrizacaoCte.cteLocalTermino,
					`${dadosParametrizacaoCte.cteLocalTermino?.nome} - ${dadosParametrizacaoCte.cteLocalTermino?.estado?.sigla}`
				);
			}

			if (dadosParametrizacaoCte.cteCst) {
				valoresGet.impostos = {
					...valoresGet.impostos,
					cstCsosn: converterCTeSingleSelectParaForm(
						dadosParametrizacaoCte.cteCst,
						`${dadosParametrizacaoCte.cteCst?.codigo} - ${dadosParametrizacaoCte.cteCst?.descricao}`
					),
				};
			}

			if (dadosParametrizacaoCte.ctePercentualIcms) {
				valoresGet.impostos = {
					...valoresGet.impostos,
					aliquotaIcms: dadosParametrizacaoCte.ctePercentualIcms,
				};
			}

			if (dadosParametrizacaoCte.ctePercentualBaseIcms) {
				valoresGet.impostos = {
					...valoresGet.impostos,
					percentualBaseCalculoIcms: dadosParametrizacaoCte.ctePercentualBaseIcms,
				};
			}

			if (dadosParametrizacaoCte.cteRemetente) {
				idRemetente = dadosParametrizacaoCte.cteRemetente.id;
			}

			if (dadosParametrizacaoCte.cteDestinatario) {
				idDestinatario = dadosParametrizacaoCte.cteDestinatario.id;
			}

			if (dadosParametrizacaoCte.cteLocalInicio && dadosParametrizacaoCte.cteLocalTermino) {
				if (dadosParametrizacaoCte.cteLocalInicio?.estado?.id === dadosParametrizacaoCte.cteLocalTermino?.estado?.id) {
					valoresGet.cfop = converterCTeSingleSelectParaForm(
						dadosParametrizacaoCte.cteCfopIntraestadual,
						`${dadosParametrizacaoCte.cteCfopIntraestadual?.codigo} - ${dadosParametrizacaoCte.cteCfopIntraestadual?.descricao}`
					);
				} else {
					valoresGet.cfop = converterCTeSingleSelectParaForm(
						dadosParametrizacaoCte.cteCfopInterestadual,
						`${dadosParametrizacaoCte.cteCfopInterestadual?.codigo} - ${dadosParametrizacaoCte.cteCfopInterestadual?.descricao}`
					);
				}
			} else if (dadosParametrizacaoCte.cteCfopIntraestadual) {
				valoresGet.cfop = converterCTeSingleSelectParaForm(
					dadosParametrizacaoCte.cteCfopIntraestadual,
					`${dadosParametrizacaoCte.cteCfopIntraestadual?.codigo} - ${dadosParametrizacaoCte.cteCfopIntraestadual?.descricao}`
				);
			} else if (dadosParametrizacaoCte.cteCfopInterestadual) {
				valoresGet.cfop = converterCTeSingleSelectParaForm(
					dadosParametrizacaoCte.cteCfopInterestadual,
					`${dadosParametrizacaoCte.cteCfopInterestadual?.codigo} - ${dadosParametrizacaoCte.cteCfopInterestadual?.descricao}`
				);
			}
		}

		await getComponenteValorPrestacaoFavorito(({ data: response }) => {
			if (response.content?.length > 0) {
				const componenteValorPrestacaoFavorito = converterCTeSingleSelectParaForm(response.content[0]);
				valoresGet.servicos = [
					{
						id: null,
						tempId: gerarUUID(),
						componente: componenteValorPrestacaoFavorito,
						valor: componenteValorPrestacaoFavorito?.registro?.valor ?? 0,
					},
				];

				valoresGet.totalizadores = {
					...valoresGet.totalizadores,
					totalFrete: componenteValorPrestacaoFavorito?.registro?.valor ?? 0,
					totalLiquido: componenteValorPrestacaoFavorito?.registro?.valor ?? 0,
				};
			}
		});

		const promises = [
			getParticipante(idRemetente, async ({ data: response }) => {
				valoresGet.remetente = await converterPessoaParaForm('remetente', response);
			}),
			getParticipante(idDestinatario, async ({ data: response }) => {
				valoresGet.destinatario = await converterPessoaParaForm('destinatario', response);
			}),
			getUsuarioFavorito(({ data: response }) => {
				if (response.content?.length > 0) {
					valoresGet.informacoesComplementares.usuario = converterCTeSingleSelectParaForm(response.content[0]);
				}
			}),
			getMercadoriaTransporteFavorito(({ data: response }) => {
				if (response.content?.length > 0) {
					valoresGet.informacaoCarga.produtoPredominante = converterCTeSingleSelectParaForm(response.content[0]);
				}
			}),
			getFavoritosPagamentos(valoresGet.totalizadores?.totalLiquido, (favoritosPagamentos) => {
				valoresGet.pagamentos = favoritosPagamentos;
			}),
		];

		await Promise.all(promises).then(() => {
			resetForm({ values: valoresGet });
			setIsFirstRender(false);
			setAtualizarTotalizadores(true);
		});
	}

	async function getFavoritosPagamentos(totalLiquido, callback) {
		const pagamentos = [
			{
				...INITIAL_VALUE_PAGAMENTO,
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categoria: null,
				condicaoPagamento: null,
				formaPagamento: null,
				valor: totalLiquido,
			},
		];
		const favoritos = {};
		const promises = [
			getContaFavorita(({ data: response }) => {
				const contaFavorita = converterCTeSingleSelectParaForm(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritos.conta = contaFavorita;
			}),
			getCategoriaFavoritaReceita(({ data: response }) => {
				const categoria = converterCTeSingleSelectParaForm(response.content[0]);
				pagamentos[0].categoria = categoria;
				favoritos.categoria = categoria;
			}),
			getCondicaoPagamentoFavorita(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterCTeSingleSelectParaForm(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritos.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterCTeSingleSelectParaForm(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritos.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterCTeSingleSelectParaForm(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritos.conta = contaConvertida;
					}

					if (formaPagamento.categoria) {
						const categoriaConvertida = converterCTeSingleSelectParaForm(formaPagamento.categoria);
						pagamentos[0].categoria = categoriaConvertida;
						favoritos.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await getFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}
				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritos,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritosPagamento(favoritos);
		});

		if (typeof callback === 'function') {
			callback(pagamentos);
		}
	}

	async function importarDadosNFeParaCTe(value, dados, onSuccess) {
		const cte = await copiarObjeto(value);
		const nfeJaImportada = await cte.documento?.nfes?.some((nfe) => nfe.chaveAcesso === dados.chaveAcesso);
		let totalFrete = 0;
		let componentePrestacaoFavorito = null;

		if (nfeJaImportada) {
			notify('NF-e já foi importada no CT-e', ToastTypes.ERROR);
		} else if (cte.remetente && cte.remetente?.value !== dados.emitente?.pessoa.id) {
			notify('NF-e com remetente diferente ao informado no CT-e', ToastTypes.ERROR);
		} else {
			// TOMADOR
			if (dados?.transporte?.modalidadeFrete === MODALIDADE_TRANSPORTE.EMITENTE) {
				cte.tomador = CTE_TOMADOR.REMETENTE;
			} else if (dados?.transporte?.modalidadeFrete === MODALIDADE_TRANSPORTE.DESTINATARIO_REMETENTE) {
				cte.tomador = CTE_TOMADOR.DESTINATARIO;
			}

			// EMITENTE
			if (dados.emitente?.pessoa && Object.keys(dados.emitente?.pessoa)?.length > 0) {
				cte.remetente = await converterPessoaParaForm('remetente', dados.emitente?.pessoa, dados.emitente?.municipio);

				if (dados.emitente?.municipio) {
					cte.localInicioPrestacao = converterCTeSingleSelectParaForm(
						dados.emitente?.municipio,
						`${dados.emitente?.municipio?.nome} - ${dados.emitente?.municipio?.estado?.sigla}`
					);
				} else {
					cte.localInicioPrestacao = await buscarMunicipioParticipanteParaImportarNFe(dados.emitente?.pessoa);
				}
			}

			// DESTINATARIO
			if (dados.destinatario?.pessoa && Object.keys(dados.destinatario?.pessoa)?.length > 0) {
				cte.destinatario = await converterPessoaParaForm(
					'destinatario',
					dados.destinatario?.pessoa,
					dados.destinatario?.municipio
				);

				cte.localFimPrestacao = converterCTeSingleSelectParaForm(
					dados.destinatario?.municipio,
					`${dados.destinatario?.municipio?.nome} - ${dados.destinatario?.municipio?.estado?.sigla}`
				);
			}

			// SERVICOS
			if (dados.totais?.valorFrete > 0) {
				componentePrestacaoFavorito = await buscarComponentePrestacaoParaImportarNFe(cte.servicos);
				if (componentePrestacaoFavorito) {
					componentePrestacaoFavorito.valor =
						getSafeValue(componentePrestacaoFavorito.valor) > 0
							? getSafeValue(componentePrestacaoFavorito.valor)
							: getSafeValue(dados.totais.valorFrete || 0);
				} else {
					await getComponenteValorPrestacaoFavorito(({ data: response }) => {
						if (response.content?.length > 0) {
							componentePrestacaoFavorito = converterCTeSingleSelectParaForm(response.content[0]);
							cte.servicos = [
								{
									id: null,
									tempId: gerarUUID(),
									componente: componentePrestacaoFavorito,
									valor:
										getSafeValue(componentePrestacaoFavorito?.registro?.valor) > 0
											? getSafeValue(componentePrestacaoFavorito?.registro?.valor)
											: getSafeValue(dados.totais.valorFrete || 0),
								},
							];
						}
					});
				}
			}

			if (cte.totalizadores?.totalFrete) {
				totalFrete = cte.totalizadores?.totalFrete;
			} else {
				totalFrete = await cte.servicos?.reduce((total, servico) => total + servico.valor, 0);
			}

			// DOCUMENTOS
			if (dados.nfes?.length > 0) {
				cte.documento = {
					...cte.documento,
					tipoDocumento: CTE_TIPO_DOCUMENTOS.NFE,
					nfes: [...(cte.documento?.nfes || []), ...dados.nfes],
					outros: [],
					notasFiscais: [],
				};
			} else if (dados.chaveAcesso) {
				cte.documento = {
					...cte.documento,
					tipoDocumento: CTE_TIPO_DOCUMENTOS.NFE,
					nfes: [...(cte.documento?.nfes || []), { id: null, chaveAcesso: dados.chaveAcesso }],
					outros: [],
					notasFiscais: [],
				};
			}

			// INFORMACOES CARGA
			cte.informacaoCarga = {
				...cte.informacaoCarga,
				valorCarga: getSafeValue(cte.informacaoCarga.valorCarga) + getSafeValue(dados.totais?.valorNfe),
				quantidades: await buscarVolumesDaNFeImportada(cte.informacaoCarga?.quantidades, dados.transporte?.volume),
			};

			// TOTALIZADORES
			cte.totalizadores = {
				...cte.totalizadores,
				totalCarga: getSafeValue(cte.totalizadores.totalCarga) + getSafeValue(dados.totais?.valorNfe),
				totalFrete: getSafeValue(totalFrete),
				totalLiquido: getSafeValue(totalFrete) - getSafeValue(cte.totalizadores.totalDesconto),
			};

			setValues(cte);
			setAtualizarTotalizadores(true);

			if (typeof onSuccess === 'function') {
				onSuccess();
			}
		}
	}

	async function buscarMunicipioParticipanteParaImportarNFe(pessoa) {
		if (pessoa.enderecos?.length > 0) {
			const enderecoFavorito = await converterPessoaEnderecoFavoritoParaForm(pessoa.enderecos);

			return enderecoFavorito?.municipio;
		}

		return null;
	}

	async function buscarComponentePrestacaoParaImportarNFe(servicos) {
		if (servicos?.length > 0) {
			return (await servicos.find((servico) => servico.componente?.registro?.favorito)) || servicos[0];
		} else {
			return null;
		}
	}

	async function buscarVolumesDaNFeImportada(quantidades, volume) {
		const cteQuantidades = quantidades;

		if (volume) {
			if (volume.quantidade) {
				const existeQuantidadeEmUnidade = quantidades?.findIndex(
					(quantidade) => quantidade.tipoMedida === CTE_UNIDADE_MEDIDA.UNIDADE
				);

				if (existeQuantidadeEmUnidade >= 0) {
					cteQuantidades[existeQuantidadeEmUnidade].quantidade += parseInt(volume.quantidade, 10);
				} else {
					cteQuantidades.push({
						id: null,
						tempId: gerarUUID(),
						tipoMedida: 'Volumes',
						unidadeMedida: {
							value: CTE_UNIDADE_MEDIDA.UNIDADE,
							label: '03 - UNIDADE',
						},
						quantidade: parseInt(volume.quantidade, 10),
					});
				}
			}

			if (volume.pesoLiquido) {
				const existeQuantidadeEmKg = quantidades?.findIndex(
					(quantidade) => quantidade.tipoMedida === CTE_UNIDADE_MEDIDA.KG
				);

				if (existeQuantidadeEmKg >= 0) {
					cteQuantidades[existeQuantidadeEmKg].quantidade += parseFloat(volume.pesoLiquido);
				} else {
					cteQuantidades.push({
						id: null,
						tempId: gerarUUID(),
						tipoMedida: 'Peso declarado',
						unidadeMedida: {
							value: CTE_UNIDADE_MEDIDA.KG,
							label: '01 - KG',
						},
						quantidade: parseFloat(volume.pesoLiquido),
					});
				}
			}
		}

		return cteQuantidades;
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsCte}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ModalLoadingTransmissao visible={exibirLoadingTransmissao} message={messageLoading} />
			<Form
				header={<HeaderForm title="Conhecimento de transporte eletrônico (CT-e)" />}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						fetchRegistro={fetchRegistro}
						newRegistro={newRegistro}
						setDesabilitarRecalcularPagamento={setDesabilitarRecalcularPagamento}
						importarDadosNFeParaCTe={importarDadosNFeParaCTe}
					/>
				</FormActions>
				<FormContent>
					<ErrorsMessage />
					<Header />
					<InformacoesPrincipais />
					<TabsDocumentos />
					<TabsCargas totalizadores={totalizadores} setTotalizadores={setTotalizadores} />
					<TabsComplementares favoritosPagamento={favoritosPagamento} totalizadores={totalizadores} />
				</FormContent>
			</Form>
			<EventosPosteriores fetchRegistro={fetchRegistro} importarDadosNFeParaCTe={importarDadosNFeParaCTe} />
		</>
	);
}

const CTeForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};
		const errorsParaMessage = {};
		const errorsPagamentos = validatePagamentos(values.pagamentos, values.totalizadores?.totalLiquido);

		// Validações campos padrões
		if (!values.tipo) {
			errors.tipo = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.finalidade) {
			errors.finalidade = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.emissao) {
			errors.emissao = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.rntrc) {
			errors.rntrc = mensagensDeValidacao.OBRIGATORIO;
		} else if (values.rntrc.length !== 8) {
			errors.rntrc = 'RNTRC deve ter 8 dígitos';
		}
		if (!values.cfop) {
			errors.cfop = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.localEmissao) {
			errors.localEmissao = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.localInicioPrestacao) {
			errors.localInicioPrestacao = mensagensDeValidacao.OBRIGATORIO;
		}
		if (!values.localFimPrestacao) {
			errors.localFimPrestacao = mensagensDeValidacao.OBRIGATORIO;
		}

		// Validações informações tomador e participantes
		if (!values.tomador) {
			errors.tomador = mensagensDeValidacao.OBRIGATORIO;
		} else {
			switch (values.tomador) {
				case CTE_TOMADOR.REMETENTE: {
					if (!values.remetente || Object.keys(values.remetente)?.length === 0) {
						errors.remetente = mensagensDeValidacao.OBRIGATORIO;
					}
					break;
				}
				case CTE_TOMADOR.DESTINATARIO: {
					if (!values.destinatario || Object.keys(values.destinatario)?.length === 0) {
						errors.destinatario = mensagensDeValidacao.OBRIGATORIO;
					}
					break;
				}
				case CTE_TOMADOR.EXPEDIDOR: {
					if (!values.expedidor || Object.keys(values.expedidor)?.length === 0) {
						errors.expedidor = mensagensDeValidacao.OBRIGATORIO;
					}
					break;
				}
				case CTE_TOMADOR.RECEBEDOR: {
					if (!values.recebedor || Object.keys(values.recebedor)?.length === 0) {
						errors.recebedor = mensagensDeValidacao.OBRIGATORIO;
					}
					break;
				}
				case CTE_TOMADOR.OUTROS: {
					if (!values.outros || Object.keys(values.outros)?.length === 0) {
						errors.outros = mensagensDeValidacao.OBRIGATORIO;
					}
					break;
				}
				default: {
					break;
				}
			}
		}

		// Validações por tipo/finalidade
		if ([CTE_TIPO.NORMAL, CTE_TIPO.SUBSTITUICAO].includes(values.tipo)) {
			if (!values.informacaoCarga?.produtoPredominante) {
				errors.informacaoCarga = {
					...errors.informacaoCarga,
					produtoPredominante: mensagensDeValidacao.OBRIGATORIO,
				};
			}
			if (!values.informacaoCarga?.valorCarga) {
				errorsParaMessage.informacaoCarga = {
					...errorsParaMessage.informacaoCarga,
					valorCarga: 'Obrigatório informar valor de carga',
				};
				errors.informacaoCarga = {
					...errors.informacaoCarga,
					valorCarga: mensagensDeValidacao.OBRIGATORIO,
				};
			}
			if (!values.informacaoCarga?.quantidades || values.informacaoCarga?.quantidades?.length <= 0) {
				errorsParaMessage.informacaoCarga = {
					...errorsParaMessage.informacaoCarga,
					quantidades: 'Obrigatório informar pelo menos uma quantidade de carga',
				};
			}

			if (
				values.finalidade === CTE_FINALIDADE.REDESPACHO_INTERMEDIARIO &&
				(values.documento?.nfes?.length > 0 ||
					values.documento?.notasFiscais?.length > 0 ||
					values.documento?.outros?.length > 0)
			) {
				errorsParaMessage.documento = {
					...errorsParaMessage.documento,
					documentos:
						'Para redespacho intermediário, quando tipo do CT-e for normal ou substituição, NÃO deve ser informado documentos',
				};
			} else if (
				values.finalidade !== CTE_FINALIDADE.REDESPACHO_INTERMEDIARIO &&
				values.documento?.nfes?.length <= 0 &&
				values.documento?.notasFiscais?.length <= 0 &&
				values.documento?.outros?.length <= 0
			) {
				errorsParaMessage.documento = {
					...errorsParaMessage.documento,
					documentos: 'Obrigatório adicionar pelo menos um documento ao CT-e',
				};
			}

			if (
				[CTE_FINALIDADE.REDESPACHO, CTE_FINALIDADE.REDESPACHO_INTERMEDIARIO, CTE_FINALIDADE.SUBCONTRATACAO].includes(
					values.finalidade
				) &&
				(values.documentoAnterior?.ctes?.length <= 0 || values.documentoAnterior?.outros <= 0)
			) {
				errorsParaMessage.documentoAnterior = {
					...errorsParaMessage.documentoAnterior,
					documentosAnterior:
						'Para redespacho, redespacho intermediário ou subcontratação, quando tipo do CT-e for normal ou substituição, deve ser informado ao menos um documento anterior',
				};
			}
		}

		if (values.finalidade === CTE_FINALIDADE.REDESPACHO_INTERMEDIARIO) {
			if (values?.remetente) {
				errorsParaMessage.camposPrincipais = {
					...errorsParaMessage.camposPrincipais,
					remetente: 'Quando redespacho intermediário, remetente não deve ser informado',
				};
			}
			if (values?.destinatario) {
				errorsParaMessage.camposPrincipais = {
					...errorsParaMessage.camposPrincipais,
					destinatario: 'Quando redespacho intermediário, destinatário não deve ser informado',
				};
			}

			if (!values?.expedidor) {
				errorsParaMessage.camposPrincipais = {
					...errorsParaMessage.camposPrincipais,
					expedidor: 'Quando redespacho intermediário, expedidor deve ser informado',
				};
				errors.expedidor = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values?.recebedor) {
				errorsParaMessage.camposPrincipais = {
					...errorsParaMessage.camposPrincipais,
					recebedor: 'Quando redespacho intermediário, recebedor deve ser informado',
				};
				errors.recebedor = mensagensDeValidacao.OBRIGATORIO;
			}
		} else {
			if (!values?.remetente) {
				errors.remetente = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values?.destinatario) {
				errors.destinatario = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		if (values.tipo === CTE_TIPO.SUBSTITUICAO) {
			if (values.finalidade !== CTE_FINALIDADE.NORMAL) {
				errorsParaMessage.camposPrincipais = {
					...errorsParaMessage.camposPrincipais,
					finalidade: 'Para substituição, a finalidade do CT-e deve ser normal',
				};
				errors.finalidade = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.cteSubstituicao?.chaveAcesso) {
				errors.cteSubstituicao = {
					...errors.cteSubstituicao,
					chaveAcesso: mensagensDeValidacao.OBRIGATORIO,
				};
				errorsParaMessage.cteSubstituicao = {
					...errorsParaMessage.cteSubstituicao,
					chaveAcesso: 'Para substituição, é obrigatório informar a chave de acesso do CT-e substituído',
				};
			}
		} else if (values.tipo === CTE_TIPO.COMPLEMENTO_VALORES) {
			if (!values.cteComplemento?.chaveAcesso) {
				errors.cteComplemento = {
					...errors.cteComplemento,
					chaveAcesso: mensagensDeValidacao.OBRIGATORIO,
				};
				errorsParaMessage.cteComplemento = {
					...errorsParaMessage.cteComplemento,
					chaveAcesso: 'Para complemento, é obrigatório informar a chave de acesso do CT-e complementado',
				};
			}
		}

		// Validações aba serviço
		if (!values.servicos || (values.servicos?.length <= 0 && values.tipo !== CTE_TIPO.COMPLEMENTO_VALORES)) {
			errorsParaMessage.servicos = {
				...errorsParaMessage.servicos,
				componentesValorPrestacao: 'Obrigatório informar pelo menos um componente do valor de prestação',
			};
		}

		if (!values.impostos?.cstCsosn) {
			errorsParaMessage.impostos = {
				...errorsParaMessage.impostos,
				cstCsosn: 'Obrigatório informar o CST/CSOSN',
			};

			errors.impostos = {
				...errors.impostos,
				cstCsosn: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		// Validações aba totalizadores
		if (!values.totalizadores?.totalCarga) {
			errors.totalizadores = {
				...errors.totalizadores,
				totalCarga: mensagensDeValidacao.OBRIGATORIO,
			};
		}
		if (!values.totalizadores?.totalFrete) {
			errors.totalizadores = {
				...errors.totalizadores,
				totalFrete: mensagensDeValidacao.OBRIGATORIO,
			};
		}
		if (!values.totalizadores?.totalLiquido) {
			errors.totalizadores = {
				...errors.totalizadores,
				totalLiquido: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		if (Object.keys(errorsParaMessage)?.length > 0) {
			errors.errorsParaMessage = errorsParaMessage;
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		return errors;
	},

	handleSubmit: () => {},
})(CTeFormImpl);

export { CTeForm };
