import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format, formatISO, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Icon } from '@iconify/react';

import {
	baixarArquivo,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	ModalLoadingTransmissao,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tipoCampos,
	tutorialStepsListagens,
} from 'components';

import { colorsStatus } from 'components/body/Util/constantes';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { formatarPlaca } from 'views/cadastros/transporte/Veiculos/Util/function';
import { CADASTRO_URL, MDFE_INFORMACOES_SITUACAO, MDFE_SITUACAO } from './Form/Util/constantes';
import {
	asyncGetDownloadXmls,
	deleteMDFe,
	readCondutores,
	readImprimirMDFe,
	readMDFes,
	readSeguradoras,
	readSetores,
	readTalizadoresCard,
	readUsuarios,
	readVeiculos,
	readXmlMDFe,
} from './Requests';
import { OPTIONS_FILTRO_AVANCADO, Status } from './Util/constantes';
import { currentMonth } from './Util/functions';
import { ModalConsultaNaoEncerrados } from './components/ModalConsultaNaoEncerrados';
import CardTotalizadorListagem from './components/CardTotalizadorListagem';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '0 5px',
};

function MDFeImpl({ history, isMobile }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroData,
		setFiltroData,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(1);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(OPTIONS_FILTRO_AVANCADO);
	const [visibleModaConsultaNaoEncerrados, setVisibleModaConsultaNaoEncerrados] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [mostrarLoading, setMostrarLoading] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const itensMenu = montarItensMenu();
	const menu = useRef(null);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.EXCLUIR);
	const localStorage = buscarDadosLoginLocalStorage();
	const disableConsultaNaoEncerrados =
		!localStorage?.filialConectada?.parametrosFiscalMDFe ||
		(localStorage?.filialConectada?.parametrosFiscalMDFe &&
			(!localStorage?.filialConectada?.parametrosFiscalCertificado ||
				!localStorage.filialConectada.parametrosFiscalMDFe?.serie ||
				!localStorage.filialConectada.parametrosFiscalMDFe?.numeroInicial));
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'numero';

	const [valorCard, setValorCard] = useState('');
	const [cards, setCards] = useState({
		manifestosNaoEnviados: {
			valor: 0,
			quantidade: 0,
		},
		manifestosEncerrados: {
			valor: 0,
			quantidade: 0,
		},
		manifestosCancelados: {
			valor: 0,
			quantidade: 0,
		},
		manifestosAutorizados: {
			valor: 0,
			quantidade: 0,
		},
	});

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			fetch();
		}
	});

	useEffect(() => {
		fetch();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('input-search-condutores')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [interval, filtroAvancado, rows, sortFieldLocal, sortOrder, page, selectedCard]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		const filtro = getFilter();
		const url = construirUrl(
			`${services.GESTOR}/v1/mdfes/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readTalizadoresCard(interval, ({ data: totais }) => {
			const newCards = {};

			totais &&
				totais.forEach((element) => {
					if (element.status === Status.CANCELADO) {
						newCards.manifestosCancelados = {
							quantidade: element.quantidade,
							valor: element.valor,
						};
					} else if (element.status === Status.TRANSMITIDO) {
						newCards.manifestosAutorizados = {
							quantidade: element.quantidade,
							valor: element.valor,
						};
					} else if (element.status === Status.ENCERRADO) {
						newCards.manifestosEncerrados = {
							quantidade: element.quantidade,
							valor: element.valor,
						};
					} else {
						newCards.manifestosNaoEnviados = {
							quantidade: newCards.manifestosNaoEnviados
								? newCards.manifestosNaoEnviados.quantidade + element.quantidade
								: element.quantidade,
							valor: newCards.manifestosNaoEnviados
								? newCards.manifestosNaoEnviados.valor + element.valor
								: element.valor,
						};
					}
				});
			setCards(newCards);
		});

		await readMDFes(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.totalElements);
			setFirstRender(false);
		});
	}

	function buscarFiltroParaDownloadXmls() {
		let filtro = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`serie=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`chave=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`protocolo=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtro = filtro.concat(`;(${filtroData})`);
		} else {
			filtro = filtro.concat(`;(${currentMonth()})`);
		}
		return filtro;
	}

	function getFilter() {
		let filter = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`serie=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`chave=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`protocolo=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`ufCarregamentoSigla=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`ufDescarregamentoSigla=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filter = filter.concat(`;(${filtroData})`);
		} else {
			filter = filter.concat(`;(${currentMonth()})`);
		}

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		if (valorCard) {
			filter = filter.concat(`;(${valorCard})`);
		}

		return filter;
	}

	async function fetchFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readVeiculos(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Veículo de tração',
						name: 'veiculoTracaoId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: formatarPlaca(option.placa), value: option.id });
					});

					addOption(newOption);
				}
			}),
			readSetores(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Setor',
						name: 'setorId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
			readUsuarios(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Usuário',
						name: 'usuarioId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
			readCondutores(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Condutor',
						name: 'condutores.condutor.id',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
			readSeguradoras(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Seguradora',
						name: 'seguradoras.seguradora.id',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function montarItensMenu() {
		const itens = [];

		if (registroSelecionado) {
			itens.push({
				label: 'Imprimir DAMDF-e',
				icon: 'fa fa-print',
				command: () => handleClickImprimir(),
				disabled: ![MDFE_SITUACAO.CANCELADO, MDFE_SITUACAO.ENCERRADO, MDFE_SITUACAO.TRANSMITIDO].includes(
					registroSelecionado.situacao
				),
			});
			itens.push({
				label: 'Baixar XML do MDF-e',
				icon: 'fa fa-download',
				command: () => handleClickBaixarXml(),
				disabled: ![MDFE_SITUACAO.CANCELADO, MDFE_SITUACAO.ENCERRADO, MDFE_SITUACAO.TRANSMITIDO].includes(
					registroSelecionado.situacao
				),
			});
		}

		return itens;
	}

	function isSituacaoFinalMDFe(row) {
		return [
			MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO,
			MDFE_SITUACAO.CANCELADO,
			MDFE_SITUACAO.DENEGADO,
			MDFE_SITUACAO.ENCERRADO,
			MDFE_SITUACAO.TRANSMITIDO,
		].includes(row.situacao);
	}

	function renderOptions(row) {
		let disableBtnOpcoes = false;
		let titleBtnOpcoes = 'Opções';

		if (row.status === MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO) {
			titleBtnOpcoes = 'Aguarde até que o documento fiscal seja transmitido';
			disableBtnOpcoes = false;
		}

		if (row.status === MDFE_SITUACAO.REJEITADO) {
			titleBtnOpcoes = 'Documento fiscal rejeitado';
			disableBtnOpcoes = false;
		}
		if (row.status === MDFE_SITUACAO.NAO_ENVIADO) {
			titleBtnOpcoes = 'Documento fiscal não transmitido';
			disableBtnOpcoes = false;
		}

		if (row.status === MDFE_SITUACAO.DENEGADO) {
			titleBtnOpcoes = 'Documento fiscal denegado';
			disableBtnOpcoes = false;
		}

		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable
					onClick={() => handleClickExcluir(row)}
					podeExcluir={podeExcluir}
					disabled={isSituacaoFinalMDFe(row)}
				/>
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					title={titleBtnOpcoes}
					disabled={disableBtnOpcoes}
					aria-controls="popup_menu"
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.current.toggle(event);
					}}
				/>
			</div>
		);
	}

	function baixarArquivosXml() {
		setMostrarLoading(true);
		asyncGetDownloadXmls(
			buscarFiltroParaDownloadXmls(),
			({ data }) => {
				baixarArquivo(
					data,
					`ArquivosXmlMDFe_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(
						interval.dataFinal,
						'dd-MM-yyyy'
					)}.zip`
				);
				setMostrarLoading(false);
			},
			() => {
				setMostrarLoading(false);
			}
		);
	}

	function handleClickBaixarXml() {
		readXmlMDFe(registroSelecionado.id, ({ data: file }) => {
			baixarArquivo(file, registroSelecionado.chave, 'application/xml');
		});
	}

	async function handleClickImprimir() {
		await readImprimirMDFe(registroSelecionado.id, (document) => {
			const arquivoPDF = new Blob([document.data], {
				type: 'application/pdf',
			});
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = `MDF-e Nº ${registroSelecionado.numero}`;
					}, 250);
				};
			}
		});
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteMDFe(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function handleChangeInterval(interval) {
		setPage(0);
		setInterval(interval);
		setFiltroData(
			`criadoEm>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};criadoEm<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function renderFieldSituacao(row) {
		const styleBadge = {
			borderRadius: '20px',
			fontWeight: 'bold',
			fontSize: '13px',
			display: 'flex',
			height: '1.5rem',
			width: '7rem',
			alignItems: 'center',
			justifyContent: 'center',
		};
		const situacaoMDFe = MDFE_INFORMACOES_SITUACAO[row.situacao];

		return Badge(situacaoMDFe?.strongColor, situacaoMDFe?.lightColor, situacaoMDFe?.name, styleBadge);
	}

	function renderFieldData(row) {
		const label = format(parse(row.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return <span title={label}>{label}</span>;
	}

	function renderFieldChave(row) {
		return (
			<span
				title={row.chave}
				style={{
					maxHeight: '60px',
					overflow: 'hidden',
					display: 'inline-flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '20%' : '0',
				}}
			>
				{row.chave ? row.chave : ''}
			</span>
		);
	}

	function handleSelectCardManifestosNaoEnviados() {
		if (selectedCard === Status.NAO_ENVIADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(Status.NAO_ENVIADO);
			setValorCard(
				`situacao==${Status.NAO_ENVIADO},situacao==${Status.REJEITADO},situacao==${Status.AGUARDANDO_AUTORIZACAO} `
			);
		}
	}

	function handleSelectCardManifestosEncerrados() {
		if (selectedCard === Status.ENCERRADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(Status.ENCERRADO);
			setValorCard(`situacao==${Status.ENCERRADO}`);
		}
	}

	function handleSelectCardManifestosAutorizados() {
		if (selectedCard === Status.TRANSMITIDO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(Status.TRANSMITIDO);
			setValorCard(`situacao==${Status.TRANSMITIDO}`);
		}
	}

	function handleSelectCardmanifestosCancelados() {
		if (selectedCard === Status.CANCELADO) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(Status.CANCELADO);
			setValorCard(`situacao==${Status.CANCELADO}`);
		}
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Manifesto de documento fiscal eletrônico (MDF-e)">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo MDF-e"
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
						style={{ height: '32px' }}
					/>
					<Button
						label={isMobile ? 'Não encerrados' : 'Consultar não encerrados'}
						onClick={() => setVisibleModaConsultaNaoEncerrados(true)}
						podeInserir={podeInserir}
						disabled={disableConsultaNaoEncerrados}
						style={{ gap: '5px', height: '32px' }}
						icon={<Icon icon="mdi:truck-delivery-outline" width="1.4em" />}
					/>
					<Button
						label="Download XMLs"
						className="step-listagem-novo p-button-primary"
						icon="fa fa-file-archive-o"
						title="Download dos arquivos XMLs dos MDF-es filtrados"
						style={{ margin: '5px' }}
						onClick={() => baixarArquivosXml()}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} />
						</span>	
						<InputSearch
							id="input-search-mdfe"
							className="step-listagem-input-search"
							onPesquisar={fetchCallback}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={filtroAvancadoOptions}
								onPesquisarClick={fetchFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="manifestosNaoEnviados"
								title="Não enviados"
								helpMessage={
									isMobile ? '' : 'Exibe o valor total e o número de notas fiscais ainda não enviadas para o sefaz'
								}
								titleFiltro="Clique para filtrar pelas notas fiscais não enviadas"
								colors={colorsStatus.NAO_ENVIADA}
								selectable
								numberOfElements={cards.manifestosNaoEnviados.quantidade}
								selected={selectedCard === Status.NAO_ENVIADO}
								onSelect={handleSelectCardManifestosNaoEnviados}
								showValue={false}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="manifestosCancelados"
								title="Cancelados"
								helpMessage={isMobile ? '' : 'Exibe o valor total e o número de notas fiscais canceladas ou denegadas'}
								titleFiltro="Clique para filtrar pelas notas fiscais canceladas/denegadas"
								colors={colorsStatus.CANCELADA}
								value={cards.manifestosCancelados.valor}
								selectable
								numberOfElements={cards.manifestosCancelados.quantidade}
								selected={selectedCard === Status.CANCELADO}
								onSelect={handleSelectCardmanifestosCancelados}
								showValue={false}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="manifestosTransmitidos"
								title="Transmitidos"
								helpMessage={isMobile ? '' : 'Exibe o valor total e o número de notas fiscais transmitidas com sucesso'}
								titleFiltro="Clique para filtrar pelas notas fiscais transmitidas"
								colors={colorsStatus.TRANSMITIDA}
								value={cards.manifestosAutorizados.valor}
								selectable
								numberOfElements={cards.manifestosAutorizados.quantidade}
								selected={selectedCard === Status.TRANSMITIDO}
								onSelect={handleSelectCardManifestosAutorizados}
								showValue={false}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="manifestosEncerrados"
								title="Encerrados"
								helpMessage={
									isMobile
										? ''
										: 'Exibe o valor total e o número de notas fiscais que tiveram tentativa de transmissão mas foram rejeitadas pelo sefaz'
								}
								titleFiltro="Clique para filtrar pelas notas fiscais rejeitadas"
								colors={colorsStatus.AGUARDANDO_AUTORIZACAO}
								value={cards.manifestosEncerrados.valor}
								selectable
								numberOfElements={cards.manifestosEncerrados.quantidade}
								selected={selectedCard === Status.ENCERRADO}
								onSelect={handleSelectCardManifestosEncerrados}
								showValue={false}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Menu model={itensMenu} popup style={{ width: '200px' }} ref={(elemento) => (menu.current = elemento)} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="numero"
							header="Série/N°"
							body={(row) => `${row.serie ?? '-'} / ${row.numero ?? '-'}`}
							style={{ width: '110px' }}
							sortable
						/>
						<Column field="emissao" header="Emissão" body={renderFieldData} style={{ width: '110px' }} sortable />
						<Column field="chave" header="Chave" body={renderFieldChave} sortable />
						<Column
							field="ufCarregamentoSigla"
							header="UF de carregamento"
							body={(row) => row.ufCarregamentoSigla}
							style={{ width: '130px' }}
							sortable
						/>
						<Column
							field="ufDescarregamentoSigla"
							header="UF de descarga"
							body={(row) => row.ufDescarregamentoSigla}
							style={{ width: '130px' }}
							sortable
						/>
						<Column
							header="Situação"
							field="situacao"
							sortable
							body={renderFieldSituacao}
							style={{ color: '#333333', width: '130px' }}
						/>
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
			<If test={visibleModaConsultaNaoEncerrados}>
				<ModalConsultaNaoEncerrados
					visible={visibleModaConsultaNaoEncerrados}
					onHide={() => setVisibleModaConsultaNaoEncerrados(false)}
					history={history}
					callback={fetch}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export const MDFe = withRouter(connect(mapStateToProps)(MDFeImpl));
