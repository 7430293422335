import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativo', value: 'ATIVO' },
			{ label: 'Inativo', value: 'INATIVO' },
		],
	},
];
