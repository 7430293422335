import { useEffect, useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';
import { formatarNcm } from '../../../Common/Mascara';
import ModalNcmTributacao from '../../../views/cadastros/fiscal/Ncm/Modal';

export default function SingleSelectNcm(props) {
	const {
		listarTributacaoNcm = true,
		listarTributacoesCadastradas = false,
		esconderBotao = false,
		service,
		mostrarSimbolo,
		value,
	} = props;
	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		if (row.extensao) {
			if (mostrarSimbolo) {
				if (row.cadastrado) {
					return '✓ - ' + formatarNcm(row.codigo) + '-' + row.extensao + ' - ' + row.descricao;
				} else {
					return '✕ - ' + formatarNcm(row.codigo) + '-' + row.extensao + ' - ' + row.descricao;
				}
			} else {
				return formatarNcm(row.codigo) + '-' + row.extensao + ' - ' + row.descricao;
			}
		} else {
			if (mostrarSimbolo) {
				if (row.cadastrado) {
					return '✓ - ' + formatarNcm(row.codigo) + ' - ' + row.descricao;
				} else {
					return '✕ - ' + formatarNcm(row.codigo) + ' - ' + row.descricao;
				}
			} else {
				return formatarNcm(row.codigo) + ' - ' + row.descricao;
			}
		}
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id && isMounted) {
			props.onChange({
				label: montarLabel({
					codigo: novoRegistro.codigoNcm?.label,
					descricao: novoRegistro.descricao,
					extensao: novoRegistro.extensao,
					cadastrado: true,
				}),
				registro: {
					id: novoRegistro.id,
					codigo: novoRegistro.codigo,
					descricao: novoRegistro.descricao,
					situacao: novoRegistro.situacao,
					cadastrado: true,
					utilizado: false,
				},
				value: novoRegistro.id,
			});
			setResetPesquisa(true);
		}
	}

	function buscarUrlPesquisa(pesquisa, page) {
		let codigoPesquisa = pesquisa?.replaceAll('.', '');
		if (listarTributacaoNcm) {
			return String(`${services.GESTOR}/v1/ncms?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		} else if (listarTributacoesCadastradas) {
			return String(`${services.GESTOR}/v1/ncms?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat('cadastrado==true;')
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		} else {
			return String(`${services.GESTOR}/v1/ncms?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat(`cadastrado==${listarTributacaoNcm};`)
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		}
	}

	function onkeydown(e) {
		let newValue = e.target.value;
		let valueArray = newValue.split('');
		const selectionLength = e.target.selectionEnd - e.target.selectionStart;
		if (e.key === 'Backspace') {
			if (selectionLength === 0 && e.target.selectionStart > 0) {
				valueArray.splice(e.target.selectionStart - 1, selectionLength + 1);
			} else {
				valueArray.splice(e.target.selectionStart, selectionLength);
			}
		} else if (e.key === 'Delete') {
			if (selectionLength === 0) {
				valueArray.splice(e.target.selectionStart, selectionLength + 1);
			} else {
				valueArray.splice(e.target.selectionStart, selectionLength);
			}
		} else {
			valueArray.splice(e.target.selectionStart, selectionLength, e.key);
		}
		newValue = valueArray.join('');

		if (e.key !== 'Backspace') {
			if (!isNaN(newValue) && newValue.length == 5 && !newValue.includes('.')) {
				e.target.value = e.target.value + '.';
			} else if (!isNaN(newValue) && newValue.length == 8) {
				e.target.value = e.target.value + '.';
			}
		}
	}

	return (
		<>
			<ModalNcmTributacao
				service={service}
				visible={visible}
				onHide={onHide}
				ncm={value && value.registro && value.registro.cadastrado === false ? value : null}
			/>
			<SingleSelect
				titleBotaoNovo={'Adicionar nova NCM'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				esconderBotao={esconderBotao}
				onKeyDown={onkeydown}
				{...props}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				disabledButton={!podeVisualizar}
				onChange={props.onChange}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				warnings={value && value.registro && value.registro.cadastrado === false ? 'NCM sem tributação cadastrada' : ''}
			/>
		</>
	);
}

SingleSelectNcm.defaultProps = {
	label: 'NCM',
};
