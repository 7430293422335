import { Field } from 'formik';
import { Divider, Grid, If, InputField } from 'components';
import { keyFilterConsultaRsql } from 'Common';

function FieldsConvenioApi(props) {
	const { bancoSelecionado, validarConvenioFieldsPorBanco, informacoesPermissoes, excecaoPorBanco } = props;

	return (
		<Grid>
			<If
				//diferente de banrisul ou caixa
				test={
					!['0d771543-f868-440f-90bc-8919e9943e24', 'c9c29c8e-56b0-4e53-bf87-2ad12c0fa94e'].includes(
						`${bancoSelecionado?.value}`
					)
				}
			>
				<Divider
					label="Informações API do banco"
					styleContainer={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem' }}
					styleLine={{ opacity: '0.7', color: 'rgb(177, 177, 177)' }}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('apiId')}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="API id"
					name="apiId"
					keyfilter={keyFilterConsultaRsql}
					size={255}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('apiKey')}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="API key"
					name="apiKey"
					keyfilter={keyFilterConsultaRsql}
					size={255}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('apiSecret') || excecaoPorBanco}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="API secret"
					name="apiSecret"
					keyfilter={keyFilterConsultaRsql}
					size={255}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('apiEstacao')}>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputField}
					label="API estação"
					name="apiEstacao"
					keyfilter={keyFilterConsultaRsql}
					size={255}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
		</Grid>
	);
}

export default FieldsConvenioApi;
