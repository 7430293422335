import { useEffect, useRef } from 'react';
import { buscarDadosLoginLocalStorage, copiarObjeto, gerarUUID, permissoes, recursos, usuarioPossuiModulos, usuarioPossuiPermissao, validarUUID } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonFinalizarENovo,
	ButtonNovo,
	ButtonSalvar,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { rateioParcelas } from 'components/body/Vendas/components/Parcelas/Util/functions';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { atualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../../Util';
import {
	asyncCreateVenda,
	asyncDeleteVenda,
	asyncEstornarPedido,
	asyncFinalizarPedido,
	asyncUpdateVenda,
	estornarDevolucaoDoPedidoDeVenda,
} from '../../../Requests';
import { Operacao, SITUACAO_DEVOLUCAO, Status, Tipo } from '../../../Util/constantes';
import { montarMensagemToast } from '../../../Util/funcoes';
import { converterVendaParaApi } from '../../Util/PedidovendasOrcamentosConverter';
import { Condicoes, TypeErrors } from '../../Util/constantes';

export default function ActionButtons(props) {
	const {
		match,
		history,
		duplicarVenda,
		asyncSelectRegistro,
		novo,
		acessarNfe,
		acessarNfce,
		podeInserir,
		setTabPagamentoSelecionada,
		setModalHistoricoVisible,
		setModalMotivoCancelamentoVisible,
		setModalDevolverItens,
		setModalItensDevolvidos,
		informacoesPermissoes,
		existeParcelaRecebida,
		urlVendaOuOrcamento,
		moduloUtilizado,
		setAtualizarImpostosProdutos,
		isVenda,
		totalizadores,
		podeEditar,
		setPodeEditar,
		setPodeInserir,
		setPodeExcluir,
		generateVenda,
		credencial,
		erroProdutoInativo,
		atualizarParcelas,
		isMobile,
		setAtualizarTotal,
	} = props;

	const { values, dirty, resetForm, handleSubmit, validateForm, setValues, initialValues } = useFormikContext();

	const menuOpcoes = useRef(null);
	const menuNotas = useRef(null);

	const hasErroFinalizarENovo = useRef(false);

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const itensOpcoes = buscaritensOpcoes();
	const itensOpcoesNotas = buscaritensOpcoesNotas();
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	useEffect(() => {
		if (validarUUID(match.params.id) && hasErroFinalizarENovo.current) {
			asyncSelectRegistro(match.params.id);
			hasErroFinalizarENovo.current = false;
		}
	}, [match.params]);

	function buscaritensOpcoes() {
		const res = [];

		if (values.id && !dirty) {
			const duplicar = {
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicarVenda(values.id),
			};
			res.push(duplicar);
		}

		if (values.id) {
			const historico = {
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setModalHistoricoVisible(true),
			};
			res.push(historico);
		}

		if (values.id && !dirty && values.status !== Status.CANCELADA && !values.nfe && !values.nfce && !(values.tipo === Tipo.ORCAMENTO && values.status === Status.FINALIZADO)) {
			res.push({
				separator: true,
				visible: true,
			});
		}

		if (
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status !== Status.CANCELADA &&
			values.status !== Status.FINALIZADO
		) {
			res.push({
				label: 'Recalcular pedido',
				icon: 'fa fa-calculator',
				command: () => {
					recalcularPedido();
				},
			});
		}

		if (values.id && (!dirty || values.status === 'CANCELADA') && !values.nfe && !values.nfce && !values.pedido) {
			const historico = {
				label:
					values.status === 'CANCELADA'
						? 'Motivo do cancelamento'
						: values.tipo === Tipo.PEDIDO_VENDA
							? 'Cancelar pedido'
							: 'Cancelar orçamento',
				icon: values.status === 'CANCELADA' ? 'fa fa-eye' : 'fa fa-times',
				command: () =>
					values.orcamentoId && values.status !== 'CANCELADA'
						? confirm(
								'Confirmação',
								'Pedido de venda com um orçamento vínculado, ao cancelá-lo, o orçamento também será inutilizado. Deseja continuar?',
								() => setModalMotivoCancelamentoVisible(true)
							)
						: setModalMotivoCancelamentoVisible(true),
				disabled: values.tipo === Tipo.PEDIDO_VENDA ? !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.CANCELAR) : !podeEditar,		
			};
			res.push(historico);
		}

		if (
			values.id &&
			!dirty &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status !== Status.CANCELADA &&
			values.status !== Status.FINALIZADO
		) {
			res.push({
				label: 'Finalizar pedido',
				icon: 'fa fa-check',
				command: () => {
					finalizarPedido();
				},
				disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.FINALIZAR),
			});
		}

		if (
			values.id &&
			!values.nfe &&
			!values.nfce &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status === Status.FINALIZADO
		) {
			res.push({
				label: 'Estornar pedido',
				icon: 'fa fa-reply',
				command: () => {
					estornarPedido();
				},
				disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.ESTORNAR),
			});
		}

		if (
			values.id &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status === Status.FINALIZADO &&
			values.situacaoDevolucao !== SITUACAO_DEVOLUCAO.DEVOLVIDO
		) {
			res.push({
				label: 'Devolver pedido',
				icon: 'fa fa-reply-all',
				command: () => setModalDevolverItens(true),
				disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.DEVOLVER),
			});			
		}

		if (
			values.id &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status === Status.FINALIZADO &&
			values.situacaoDevolucao === SITUACAO_DEVOLUCAO.DEVOLVIDO
		) {
			res.push({
				label: 'Estornar devolução',
				icon: 'fa fa-reply-all',
				command: () => {
					estornarDevolucaoDoPedidoDeVenda(values.id, () => {
						asyncSelectRegistro(match.params.id);
						notify('Estorno da devolucão concluído', ToastTypes.SUCCESS);
					});
				},
				disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.DEVOLVER),
			});
		}

		if (
			values.id &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status === Status.FINALIZADO &&
			values.situacaoDevolucao !== SITUACAO_DEVOLUCAO.NAO_DEVOLVIDO
		) {
			res.push({
				label: 'Itens devolvidos',
				icon: 'fa fa-list',
				command: () => setModalItensDevolvidos(true),
			});
		}

		if (
			values.id &&
			!values.nfe &&
			!values.nfce &&
			values.tipo === Tipo.PEDIDO_VENDA &&
			values.status === Status.FINALIZADO
		) {
			res.push({
				label: values.documentoAssinaturaId ? 'Acessar assinatura' : 'Gerar assinatura',
				icon: 'fa fa-file-text-o',
				command: () => (values.documentoAssinaturaId ? acessarAssinatura() : gerarDocumento()),
				disabled: !usuarioPossuiModulos(['DOCUMENTOS']),
				title: values.documentoAssinaturaId ? 'Acessar assinatura' : 'Gerar assinatura',
			});
		}

		return res;
	}

	function buscaritensOpcoesNotas() {
		const res = [];
		res.push({
			label: 'Gerar NF-e',
			icon: 'fa fa-file-text-o',
			command: () => {
				gerarNfe();
			},
		});
		res.push({
			label: 'Gerar NFC-e',
			icon: 'fa fa-file-text-o',
			command: () => {
				gerarNfce();
			},
		});
		return res;
	}

	async function cancelar() {
		if (dirty) {
			if (values.id) {
				resetForm({
					values: {
						...copiarObjeto(initialValues),
						tipo: moduloUtilizado === 'PEDIDO_VENDA' ? 'PEDIDO_VENDA' : 'ORCAMENTO',
					},
				});
				setAtualizarTotal(true);
			} else {
				novo({ isCancelarVenda: true });
			}
		} else {
			voltarParaAPesquisa(history, `/${moduloUtilizado === 'PEDIDO_VENDA' ? 'pedido_venda' : 'orcamento'}`);
		}

		if (initialValues.condicao === Condicoes.A_VISTA) {
			setTabPagamentoSelecionada(0);
		}
	}

	async function calcularTotalParcelas() {
		let totalParcelas = 0;
		await values.parcelas?.forEach((parcela) => {
			totalParcelas += parcela.valor;
		});

		return totalParcelas;
	}

	async function recalcularParcelas() {
		const parcelasTemp = [];

		const rateio = rateioParcelas(totalizadores.totalLiquido, values.quantidadeParcelas);

		for (let i = 0; i < values.quantidadeParcelas; i++) {
			const oldParcela = values.parcelas && values.parcelas[i];
			let valorParcela = rateio.valorPorParcela;

			if (values.quantidadeParcelas - 1 === i) {
				valorParcela = rateio.valorUltimaParcela;
			}

			const idParcela = oldParcela ? oldParcela.id : null;
			const vencimento = formatISO(
				isValid(new Date(oldParcela?.vencimento))
					? parseISO(oldParcela.vencimento)
					: addMonths(parseISO(values.dataEmissao), i + 1)
			);
			const descricao = oldParcela ? oldParcela.descricao : '';
			const forma = oldParcela ? oldParcela.forma : 'DINHEIRO';

			parcelasTemp.push({
				id: idParcela,
				vencimento: vencimento,
				valor: valorParcela,
				descricao: descricao,
				forma: forma,
			});
		}

		await atualizarParcelas(parcelasTemp);
		return parcelasTemp;
	}

	async function salvar(e, novoOnSuccess, vendaAtualizada) {
		const valuesVenda = { ...values, totalizadores: totalizadores };
		if (values.condicao === Condicoes.A_PRAZO && (await calcularTotalParcelas()) !== totalizadores.totalLiquido) {
			valuesVenda.parcelas = await recalcularParcelas();
		}

		if (valuesVenda.produtos?.length > 0) {
			valuesVenda.produtos = valuesVenda.produtos.filter((produto) => produto.produto);

			if (valuesVenda.produtos?.length === 0) {
				valuesVenda.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesVenda.produtos.length; i++) {
				valuesVenda.produtos[i].item = i + 1;
			}
		}

		const comissaoVenda = calcularComissaoVenda(valuesVenda);
		valuesVenda.comissaoTotalPercentual = comissaoVenda.percentual;
		valuesVenda.comissaoTotalValor = comissaoVenda.valor;

		setValues(valuesVenda);

		if (
			await validarFormulario({
				validateForm,
				values: valuesVenda,
			})
		) {
			if (valuesVenda.id) {
				updateVenda(
					{
						...valuesVenda,
						totalizadores: totalizadores,
						motivoCancelamento: vendaAtualizada?.motivoCancelamento,
						novoOnSuccess,
					},
					await novoOnSuccess
				);
			} else {
				createVenda({ ...valuesVenda, totalizadores: totalizadores }, await novoOnSuccess);
			}
		} else {
			handleSubmit();
		}
	}

	function calcularComissaoVenda(venda) {
		const comissao = { percentual: 0, valor: 0 };

		if (venda.produtos?.length > 0) {
			const percentualTotal =
				venda.produtos.reduce((total, item) => total + item.comissaoPercentual || 0, 0) / venda.produtos.length;

			comissao.percentual = parseFloat(percentualTotal.toFixed(2));
			comissao.valor = parseFloat((venda.totalizadores.totalLiquido * (percentualTotal / 100)).toFixed(2));
		}

		return comissao;
	}

	async function onFinalizarENovo() {
		const valuesVenda = { ...values, totalizadores: totalizadores };
		if (values.condicao === Condicoes.A_PRAZO && (await calcularTotalParcelas()) !== totalizadores.totalLiquido) {
			valuesVenda.parcelas = await recalcularParcelas();
		}

		if (valuesVenda.produtos?.length > 0) {
			valuesVenda.produtos = valuesVenda.produtos.filter((produto) => produto.produto);

			if (valuesVenda.produtos?.length === 0) {
				valuesVenda.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesVenda.produtos.length; i++) {
				valuesVenda.produtos[i].item = i + 1;
			}
		}

		const comissaoVenda = calcularComissaoVenda(valuesVenda);
		valuesVenda.comissaoTotalPercentual = comissaoVenda.percentual;
		valuesVenda.comissaoTotalValor = comissaoVenda.valor;

		setValues(valuesVenda);

		if (await validarFormulario({ validateForm, values: valuesVenda })) {
			if (values.id) {
				const mensagem = montarMensagemToast(initialValues.status, Operacao.EDITAR);
				await asyncUpdateVenda(
					urlVendaOuOrcamento,
					converterVendaParaApi({ ...values, totalizadores: totalizadores }),
					mensagem,
					({ data: venda }) => {
						asyncFinalizarPedido(venda.id, () => {
							novo();
						});
					}
				);
			} else {
				const mensagem = montarMensagemToast(initialValues.status, Operacao.INSERIR);
				await asyncCreateVenda(
					urlVendaOuOrcamento,
					converterVendaParaApi({ ...values, totalizadores: totalizadores }),
					mensagem,
					({ data: venda }) => {
						if (urlVendaOuOrcamento === 'orcamentos') {
							atualizarUrl(history, `/orcamento/cadastro`, venda.id);
						} else {
							atualizarUrl(history, `/pedido_venda/cadastro`, venda.id);
						}
						asyncFinalizarPedido(
							venda.id,
							() => {
								novo();
							},
							() => {
								hasErroFinalizarENovo.current = true;
							}
						);
					}
				);
			}
		}
	}

	async function updateVenda(valuesVenda, novoOnSuccess) {
		if (valuesVenda) {
			const mensagem = montarMensagemToast(initialValues.status, Operacao.EDITAR);
			await asyncUpdateVenda(urlVendaOuOrcamento, converterVendaParaApi(valuesVenda), mensagem, ({ data: venda }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({
						values: {
							...valuesVenda,
							id: venda.id,
							numero: venda.numero,
						},
					});
				}
			});
		}
	}

	async function createVenda(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(initialValues.status, Operacao.INSERIR);

			await asyncCreateVenda(urlVendaOuOrcamento, converterVendaParaApi(values), mensagem, ({ data: venda }) => {
				if (novoOnSuccess) {
					novoOnSuccess(venda);
				} else {
					if (urlVendaOuOrcamento === 'orcamentos') {
						atualizarUrl(history, `/orcamento/cadastro`, venda.id);
					} else {
						atualizarUrl(history, `/pedido_venda/cadastro`, venda.id);
					}
					resetForm({
						values: {
							...values,
							id: venda.id,
							numero: venda.numero,
						},
					});
				}
			});
		}
	}

	function recalcularPedido() {
		setAtualizarImpostosProdutos(true);
	}

	async function finalizarPedido(idVenda, onSuccess, onError) {
		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			await asyncFinalizarPedido(
				values.id || idVenda,
				async ({ data }) => {
					await asyncSelectRegistro(data.id);

					setPodeEditar(false);
					setPodeInserir(false);
					setPodeExcluir(false);

					if (typeof onSuccess === 'function') {
						await onSuccess();
					}
				},
				async () => {
					if (typeof onError === 'function') {
						await onError();
					}
				}
			);

			setTabPagamentoSelecionada(!credencial.filialConectada?.parametrosFinancas?.gerarFinanceiroNaNfe ? 1 : 0);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function estornarPedido() {
		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			asyncEstornarPedido(values.id, ({ data }) => {
				asyncSelectRegistro(data.id);
			});
			setPodeEditar(true);
			setPodeInserir(true);
			setPodeExcluir(true);
			setTabPagamentoSelecionada(0);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function excluir() {
		const mensagem = montarMensagemToast(initialValues.status, Operacao.EXCLUIR);
		confirm('Atenção', 'Tem certeza que deseja excluir?', () => {
			asyncDeleteVenda(urlVendaOuOrcamento, values.id, mensagem, () => {
				voltarParaAPesquisa(history, isVenda() ? '/pedido_venda' : '/orcamento');
			});
		});
	}

	function renderBotaoNotaFiscal() {
		const style = {
			margin: '5px',
		};

		if (values.nfce) {
			return (
				<Button
					label="Acessar NFC-e"
					className="p-button-success"
					icon="fa fa-link"
					hidden={dirty || values.tipo !== Tipo.PEDIDO_VENDA || !values.id}
					onClick={() => acessarNfce()}
					style={style}
				/>
			);
		} else if (values.nfe) {
			return (
				<Button
					label="Acessar NF-e"
					className="p-button-success"
					icon="fa fa-link"
					hidden={dirty || values.tipo !== Tipo.PEDIDO_VENDA || !values.id}
					onClick={() => acessarNfe()}
					style={style}
				/>
			);
		} else {
			return (
				<Button
					label={
						buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
						buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
							? 'Gerar fiscal'
							: 'Gerar NF-e'
					}
					className="p-button-success"
					icon="fa fa-file-text-o"
					hidden={
						dirty ||
						values.tipo !== Tipo.PEDIDO_VENDA ||
						values.status === (Status.CANCELADA || Status.FINALIZADO) ||
						!values.id
					}
					onClick={(event) => {
						buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
						buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
							? menuNotas.current.toggle(event)
							: gerarNfe();
					}}
					disabled={!podeInserir}
					title={!podeInserir && !values.nfe ? 'Você não possui permissão para executar essa ação' : ''}
					style={style}
				/>
			);
		}
	}

	async function gerarNfe() {
		handleSubmit();
		await validateForm();

		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			if (isValid) {
				atualizarUrl(history, `/nfes/importar_pedido/${values.id}`);
			}
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function gerarNfce() {
		atualizarUrl(history, `/nfces/importar_pedido/${values.id}`);
	}

	function gerarDocumento() {
		atualizarUrl(history, `/documentos/assinatura/importar_pedido/${values.id}`);
	}

	function acessarAssinatura() {
		atualizarUrl(history, `/documentos/assinatura/cadastro/${values.documentoAssinaturaId}`);
	}

	async function gerarPedidoVenda() {
		const pedidoVenda = copiarObjeto(values);
		generateVenda(pedidoVenda);
	}

	return (
		<>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
			<Menu model={itensOpcoesNotas} style={{ minWidth: '230px' }} popup ref={menuNotas} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || existeParcelaRecebida} onClick={salvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonFinalizarENovo
				hidden={
					values.status !== 'PENDENTE' ||
					(!values.id && !dirty) ||
					!credencial.filialConectada?.parametrosVendas.finalizarENovo ||
					moduloUtilizado !== 'PEDIDO_VENDA'
				}
				onClick={onFinalizarENovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={excluir} {...informacoesPermissoes} />

			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current.toggle(event)}
			/>
			{renderBotaoNotaFiscal()}
			<NormalButton
				hidden={
					!values.id ||
					values.tipo === 'PEDIDO_VENDA' ||
					values.status === 'FINALIZADO' ||
					values.status === 'CANCELADA'
				}
				type="button"
				label={isMobile ? 'Gerar pedido' : 'Gerar pedido de venda'}
				icon="fa fa-shopping-cart"
				style={{
					backgroundColor: '#34a835',
					margin: '5px',
					borderColor: '#34a835',
				}}
				onClick={gerarPedidoVenda}
				{...informacoesPermissoes}
			/>
		</>
	);
}
