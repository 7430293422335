import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';

export const SITUACAO_OPTIONS = [
	{ label: 'Ativo', value: 'ATIVO' },
	{ label: 'Inativo', value: 'INATIVO' },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{ label: 'Codigo', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Codigo de barra', name: 'codigoBarra', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_OPTIONS,
	},
];

export const COLORS = {
	bgAtivo: '#DCEDC8',
	textAtivo: '#1B5E20',
	bgInativo: '#FFCDD2',
	textInativo: '#B71C1C',
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const SECAO_STYLE = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 0 1rem 0',
};

export const CODIGO_STYLE = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

export const INITIAL_VALUES = {
	id: null,
	codigo: null,
	codigoBarra: '',
	nome: '',
	favorito: false,
	situacao: 'ATIVO',
};

export const CADASTRO_URL = 'transporte/mercadoria/cadastro';
export const PESQUISA_URL = 'transporte/mercadoria';
