import { useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { buscarDadosLoginLocalStorage, copiarObjeto, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import {
	Button,
	Col,
	Grid,
	Modal,
	MultiSelectFiliais,
	NenhumRegistroEncontrado,
	Form,
	FormActions,
	FormContent,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	estadosBotaoCancelar,
	SingleSelectFilial,
	InputDouble,
	Checkbox,
	InputSelectMultiplicacaoOrDivisao,
	If,
} from 'components';
import { helpProdutosForm } from '../../../Help';

export default function FieldsEstoqueFiliais({
	values,
	setFieldValue,
	informacoesPermissoes,
	myRef,
	dirty,
	tipoPredefinido,
}) {
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(1);
	const [visibleModalReplicacao, setVisibleModalReplicacao] = useState(false);
	const [filialOrigem, setFilialOrigem] = useState(null);
	const [filiaisDestino, setFiliaisDestino] = useState([]);
	const [resetPesquisaFiliaisDestino, setResetPesquisaFiliaisDestino] = useState(false);

	const menuAlterarEmLote = useRef(null);
	const itensMenu = buscaritensMenu();

	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;

	const podeVisualizarMovimentacaoEstoque = usuarioPossuiPermissao(
		recursos.ESTOQUE_MOVIMENTACOES,
		permissoes.VISUALIZAR
	);
	const podeInserirMovimentacaoEstoque = usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR);

	const desabilitarBotaoAlterarEstoque =
		!podeInserirMovimentacaoEstoque || !podeVisualizarMovimentacaoEstoque || !informacoesPermissoes.podeEditar;

	const styleButtonEditar = {
		color: '#006095',
		cursor: 'pointer',
		width: '32px',
		height: '32px',
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		fontSize: '22px',
		boxShadow: 'none',
		margin: 'unset',
		border: 'unset',
	};

	function buscaritensMenu() {
		const res = [];
		const replicar = {
			label: 'Replicar informações para filiais',
			command: handleExibirModalReplicacao,
		};
		res.push(replicar);
		return res;
	}

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function renderFatorDeConversao(row, index) {
		function changeFatorConversao(event) {
			const filial = copiarObjeto(row);
			filial.fatorConversao = event ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		function changeOperacaoFatorConversao(event) {
			const filial = copiarObjeto(row);
			filial.operacaoFatorConversao = event ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (
			<span>
				<InputSelectMultiplicacaoOrDivisao
					value={row.fatorConversao}
					valueOperacao={row.operacaoFatorConversao}
					onChange={changeFatorConversao}
					onChangeOperacao={changeOperacaoFatorConversao}
					id="ProdutoInputFatorConversao"
					{...informacoesPermissoes}
				/>
			</span>
		);
	}

	function renderEstoqueMinimo(row, index) {
		function changeEstoqueMinimo(event) {
			const filial = copiarObjeto(row);
			filial.estoqueMinimo = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (
			<span>
				<InputDouble
					name="estoqueMinimo"
					id="estoqueMinimo"
					onChange={(e) => changeEstoqueMinimo(e)}
					value={row.estoqueMinimo}
					decimalScale={decimaisQtd}
					size={13}
					helpMessage={helpProdutosForm.estoqueMinimo}
					{...informacoesPermissoes}
				/>
			</span>
		);
	}

	function renderEstoqueReservado(row, index) {
		function changeEstoqueReservado(event) {
			const filial = copiarObjeto(row);
			filial.estoqueReservado = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (
			<span>
				<InputDouble
					name="estoqueReservado"
					id="estoqueReservado"
					onChange={(e) => changeEstoqueReservado(e)}
					value={row.estoqueReservado}
					decimalScale={decimaisQtd}
					size={13}
					disabled
					helpMessage={helpProdutosForm.estoqueReservado}
					{...informacoesPermissoes}
				/>
			</span>
		);
	}

	function renderEstoqueSaldo(row, index) {
		function changeEstoqueSaldo(event) {
			const filial = copiarObjeto(row);
			filial.estoqueSaldo = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (
			<span className="p-inputgroup" style={{ paddingRight: row.filialConectada && values.id ? '35px' : '0px' }}>
				<InputDouble
					name="estoqueSaldo"
					id="estoqueSaldo"
					onChange={(e) => changeEstoqueSaldo(e)}
					value={row.estoqueSaldo}
					decimalScale={decimaisQtd}
					size={13}
					disabled
					helpMessage={helpProdutosForm.estoqueSaldo}
					{...informacoesPermissoes}
				/>
				<If test={row.filialConectada && values.id}>
					<Button
						icon="fa fa-wrench"
						title={
							desabilitarBotaoAlterarEstoque || dirty || !row.filialConectada
								? ''
								: 'Ajustar estoque da filial conectada'
						}
						style={styleButtonEditar}
						styleContentButton={{
							display: 'flex',
							alignItems: 'center',
						}}
						disabled={desabilitarBotaoAlterarEstoque || dirty || !row.filialConectada || !values.id}
						onClick={(e) => myRef.current.toggle(e)}
					/>
				</If>
			</span>
		);
	}

	function renderNomeFilial(row) {
		return `${row.filialCodigo} - ${row.filialNomeApresentacao}`;
	}

	function handleChangeFilialOrigem(event) {
		setFilialOrigem(event);
		setFiliaisDestino(filiaisDestino.filter((item) => item.value !== event?.value));
		setResetPesquisaFiliaisDestino(true);
	}

	function handleConfirmar() {
		const valorOperacaoFatorConversao =
			values.filiais.filter((item) => item.filialId === filialOrigem.value)[0].operacaoFatorConversao ?? 0;
		const valorFatorConversao =
			values.filiais.filter((item) => item.filialId === filialOrigem.value)[0].fatorConversao ?? 0;
		const valorEstoqueMinimo =
			values.filiais.filter((item) => item.filialId === filialOrigem.value)[0].estoqueMinimo ?? 0;
		const filiais = copiarObjeto(values.filiais);
		filiais.forEach((item) => {
			const filial = item;
			if (filiaisDestino.filter((item) => item.value === filial.filialId).length > 0) {
				filial.operacaoFatorConversao = valorOperacaoFatorConversao;
				filial.fatorConversao = valorFatorConversao;
				filial.estoqueMinimo = valorEstoqueMinimo;
			}
		});
		setFieldValue(`filiais`, filiais);
		setVisibleModalReplicacao(false);
	}

	function handleExibirModalReplicacao() {
		setFilialOrigem(null);
		setFiliaisDestino([]);
		setVisibleModalReplicacao(true);
	}

	function filtrarOptionsFilialDestino(options) {
		return options.filter((item) => item.value !== filialOrigem?.value);
	}

	return (
		<>
			<Grid>
				<Col>
					<Fieldset className="fieldset">
						<Grid>
							<Col sm="8" md="9" lg="10" xl="10" style={{ padding: '0rem 0.5rem 0rem 0rem' }}>
								<Checkbox
									label="Controlar estoque deste produto"
									name="controlarEstoque"
									helpMessage={helpProdutosForm.controlarEstoque}
									onChange={() => {
										setFieldValue('controlarEstoque', !values.controlarEstoque);
									}}
									checked={values.controlarEstoque}
									disabled={Boolean(tipoPredefinido)}
									{...informacoesPermissoes}
								/>
							</Col>
							<If test={values.controlarEstoque}>
								<Col sm="4" md="3" lg="2" xl="2">
									<Button
										className="p-button-primary step-tabela-preco-alterar-lote"
										label="Alterar em lote"
										icon="fa fa-wrench"
										style={{ width: '100%' }}
										aria-controls="popup_menu"
										aria-haspopup
										disabled={values.filiais.length === 1}
										onClick={(event) => {
											menuAlterarEmLote.current.toggle(event);
										}}
									/>
								</Col>
								<DataTable
									className="table"
									rowClassName="table-row"
									cellClassName="table-row-cell"
									responsive
									value={values.filiais}
									sortField={sortField}
									sortOrder={sortOrder}
									onSort={onSort}
									emptyMessage={<NenhumRegistroEncontrado />}
									style={{ width: '100%' }}
								>
									<Column
										field="filialCodigo"
										header="Filial"
										body={renderNomeFilial}
										style={{ width: '40%' }}
										sortable
									/>
									<Column
										field="fatorConversao"
										header="Fator de conversão"
										body={(row, index) => renderFatorDeConversao(row, index.rowIndex)}
										style={{ width: '15%' }}
										sortable
									/>
									<Column
										field="estoqueMinimo"
										header="Mínimo"
										body={(row, index) => renderEstoqueMinimo(row, index.rowIndex)}
										style={{ width: '15%' }}
										sortable
									/>
									<Column
										field="estoqueReservado"
										header="Reservado"
										body={(row, index) => renderEstoqueReservado(row, index.rowIndex)}
										style={{ width: '15%' }}
										sortable
									/>
									<Column
										field="estoqueSaldo"
										header="Saldo"
										body={(row, index) => renderEstoqueSaldo(row, index.rowIndex)}
										style={{ width: '15%' }}
										sortable
									/>
								</DataTable>
							</If>
						</Grid>
					</Fieldset>
				</Col>
			</Grid>
			<Menu model={itensMenu} style={{ minWidth: '255px' }} popup ref={menuAlterarEmLote} />
			<Modal
				closeOnEsc
				showCloseIcon
				visible={visibleModalReplicacao}
				onHide={() => setVisibleModalReplicacao(false)}
				header="Replicar informações para filiais"
			>
				<Form>
					<FormActions>
						<ButtonCancelar
							estadoBotao={estadosBotaoCancelar.VOLTAR}
							onClick={() => setVisibleModalReplicacao(false)}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.CONFIRMAR}
							onClick={handleConfirmar}
							disabled={filiaisDestino.length === 0}
							{...informacoesPermissoes}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Col>
								<SingleSelectFilial
									label="Filial de origem"
									value={filialOrigem}
									onChange={handleChangeFilialOrigem}
									style={{ width: '100%', backgroundColor: 'fff' }}
								/>
							</Col>
							<Col>
								<MultiSelectFiliais
									label="Filial(is) de destino"
									style={{ width: '100%' }}
									value={filiaisDestino}
									disabled={!filialOrigem}
									onChange={setFiliaisDestino}
									onlyUserFiliais
									resetPesquisa={resetPesquisaFiliaisDestino}
									setResetPesquisa={setResetPesquisaFiliaisDestino}
									converterOptions={filtrarOptionsFilialDestino}
								/>
							</Col>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
		</>
	);
}
