import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	ButtonAdicionarItem,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	NenhumRegistroEncontrado,
} from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { defineColorStatus } from 'views/transporte/CTe/Util/functions';

function TabelaNfe({ setRegistroSelecionado }) {
	const { setVisibleModalDadosNFe, isSituacaoFinalCTe, isMobile } = useContextCTe();
	const { values, setFieldValue } = useFormikContext();

	function renderChave(row) {
		return (
			<div style={{ overflow: 'hidden', overflowWrap: 'break-word', fontSize: isMobile ? '12px' : '14px' }}>
				{row.chaveAcesso}
			</div>
		);
	}

	function handleEditarRegistroTable(row) {
		setRegistroSelecionado(row);
		setVisibleModalDadosNFe(true);
	}

	function handleDeletarRegistroTable(row) {
		confirm('Confirmação de exclusão', 'Deseja excluir o registro?', () => {
			removerRegistro(row);
		});
	}

	function removerRegistro(row) {
		const nfes = values.documento?.nfes || [];
		const updatedNfes = nfes.filter((nfe) => (nfe.tempId ? nfe.tempId !== row.tempId : nfe.id !== row.id));
		setFieldValue('documento.nfes', updatedNfes);
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => handleEditarRegistroTable(row)} />
				<ButtonExcluirTable
					title="Excluir"
					onClick={() => handleDeletarRegistroTable(row)}
					disabled={isSituacaoFinalCTe}
				/>
			</div>
		);
	}

	return (
		<>
			<DataTable
				className="table"
				responsive
				value={values.documento?.nfes || []}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="chaveAcesso"
					header="Chave"
					body={(e) => renderChave(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					className="step-listagem-acoes"
					header="Ações"
					body={(e) => renderOpcoes(e)}
					style={{ width: '100px' }}
				/>
			</DataTable>
			<ButtonAdicionarItem
				label="Adicionar chave NF-e"
				style={{
					color: defineColorStatus(values.situacao),
				}}
				onClick={() => {
					setVisibleModalDadosNFe(true);
				}}
				tabIndex={-1}
				disabled={isSituacaoFinalCTe}
			/>
		</>
	);
}

export { TabelaNfe };
