import { useState } from 'react';
import { Column } from 'primereact/column';
import { useFormikContext } from 'formik';
import { DataTable } from 'primereact/datatable';

import { ButtonEditarTable, ButtonExcluirTable, ButtonNovo, Card, If, NenhumRegistroEncontrado } from 'components';

import { useContextTabelaPreco } from 'views/cadastros/vendas/TabelaPreco/Context';

import { ModalAlteracaoPorCondicaoPagamento } from './components/ModalAlteracaoPorCondicaoPagamento';

function AlteracaoPorCondicaoPagamento() {
	const { values, setFieldValue } = useFormikContext();
	const { isMobile, informacoesPermissoes, desabilitarCampos, isHeaderEditing } = useContextTabelaPreco();

	const [modalCondicaoVisible, setModalCondicaoVisible] = useState(false);
	const [isNovoRegistro, setIsNovoRegistro] = useState(false);
	const [indexRegistro, setIndexRegistro] = useState(null);

	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('condicao');

	function renderAcoes(row) {
		return (
			<div>
				<ButtonEditarTable title="Editar" onClick={() => onEdit(row)} />
				<ButtonExcluirTable onClick={() => onRemove(row)} disabled={desabilitarCampos} {...informacoesPermissoes} />
			</div>
		);
	}

	function onEdit(row) {
		setModalCondicaoVisible(true);
		values?.alteracoesPorCondicao.forEach((item, index) => {
			if (item.id === row.id) {
				setIndexRegistro(index);
			}
		});
	}

	function onRemove(row) {
		const alteracoesPorCondicao = values.alteracoesPorCondicao?.filter((item) =>
			item.idTemporario ? item?.idTemporario !== row?.idTemporario : item?.id !== row?.id
		);
		setFieldValue('alteracoesPorCondicao', alteracoesPorCondicao);
		isHeaderEditing.current = true;
	}

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderFieldCondicao(row) {
		if (row.condicao) {
			return `${row.condicao?.label}`;
		}
		return null;
	}

	return (
		<>
			<ButtonNovo
				label="Nova condição"
				onClick={() => {
					isHeaderEditing.current = true;
					setModalCondicaoVisible(true);
					setIsNovoRegistro(true);
					setIndexRegistro(values?.alteracoesPorCondicao?.length ?? 0);
				}}
				disabled={desabilitarCampos}
			/>
			<Card>
				<DataTable
					className="table"
					value={values?.alteracoesPorCondicao}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={atualizarOrdenacao}
					responsive
					emptyMessage={<NenhumRegistroEncontrado />}
					style={{
						paddingBottom: '16px',
						overflowY: 'auto',
						overflowX: 'hidden',
						maxHeight: isMobile ? '20.6rem' : '13.8rem',
					}}
				>
					<Column field="condicao" header="Condição" body={(row) => renderFieldCondicao(row)} sortable />
					<Column
						header="Ações"
						body={renderAcoes}
						style={{
							width: '6rem',
						}}
					/>
				</DataTable>
			</Card>
			<If test={modalCondicaoVisible}>
				<ModalAlteracaoPorCondicaoPagamento
					visible={modalCondicaoVisible}
					isNovoRegistro={isNovoRegistro}
					indexRegistro={indexRegistro}
					alteracaoCondicaoPagamento={values.alteracoesPorCondicao[indexRegistro]}
					alteracoesPorCondicao={values.alteracoesPorCondicao}
					setFieldValueTabelaPreco={setFieldValue}
					onCloseModal={() => {
						setIndexRegistro(null);
						setModalCondicaoVisible(false);
						setIsNovoRegistro(false);
					}}
				/>
			</If>
		</>
	);
}

export { AlteracaoPorCondicaoPagamento };
