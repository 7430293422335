import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const optionsFiltroAvancado = [
	{ label: 'Série', name: 'serie', type: tipoCampos.STRING },
	{ label: 'Número', name: 'numero', type: tipoCampos.STRING },
	{ label: 'Nome fornecedor', name: 'fornecedor', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizada', value: 'FINALIZADA' },
			{ label: 'Cancelada', value: 'CANCELADA' },
		],
	},
];

export const NotasEntrada = {
	cores: {
		verde: '#1b5e20',
		verdeClaro: '#dcedc8',
		azul: '#006095',
		preto: '#fff0',
		cinza: '#888888',
	},
};

export const ColorsCard = {
	PENDENTE: {
		lightColor: '#B3E5FC',
		strongColor: '#063970',
	},
	FINALIZADA: {
		lightColor: '#dcedc8',
		strongColor: '#1b5e20',
	},
	CANCELADA: {
		lightColor: '#FFCDD2',
		strongColor: '#B71C1C',
	},
};

export const operacaoStatus = {
	PENDENTE: 'PENDENTE',
	FINALIZADA: 'FINALIZADA',
	CANCELADA: 'CANCELADA',
};

export const CLASSNAME_TABVIEW_COLOR = {
	PENDENTE: { class: 'tab-view-compra-nota-padrao', color: '#01579B' },
	FINALIZADA: { class: 'tab-view-compra-nota-finalizada', color: '#1b5e20' },
	CANCELADA: { class: 'tab-view-compra-nota-cancelada', color: '#B71C1C' },
};

export const ColorsSituacao = {
	bgPendente: '#B3E5FC',
	textPendente: '#006095',
	bgFinalizada: '#DCEDC8',
	textFinalizada: '#24812a',
	bgCancelada: '#FFCDD2 ',
	textCancelada: '#B71C1C ',
};

export const CondicaoDropdown = [
	{ label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
	{ label: 'À vista', value: 'A_VISTA' },
	{ label: 'A prazo', value: 'A_PRAZO' },
];

export const optionsModalidadeFrete = [
	{ label: 'Por conta do emitente', value: 'EMITENTE' },
	{ label: 'Por conta do destinatário', value: 'DESTINATARIO_REMETENTE' },
	{ label: 'Por conta de terceiros', value: 'TERCEIROS' },
	{ label: 'Sem frete', value: 'SEM_FRETE' },
];

export const contaPagarStatus = {
	NAO_PAGA: 'NAO_PAGA',
	PARCIALMENTE_PAGA: 'PARCIALMENTE_PAGA',
	PAGA: 'PAGA',
};

export const MessagesErrors = {
	OBRIGATORIO: 'Obrigatório',
	CAMPO_OBRIGATORIO: 'Campo obrigatório',
	CHAVE_ACESSO_INVALIDA: 'Chave de acesso inválida',
};
