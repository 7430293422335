import { useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Col, Dropdown, FormContent, Grid, If, LinkVerDados, SingleSelectPessoa } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { TIPO_DOCUMENTO_ANTERIOR, TIPO_DOCUMENTO_ANTERIOR_OPTIONS } from 'views/transporte/CTe/Form/Util/constantes';
import { converterEmissorDocumentoAnteriorParaForm } from 'views/transporte/CTe/Form/Util/CTeConverter';
import { ModalDadosCTe } from './components/ModalDadosCTe';
import { ModalDadosDocumentoPapel } from './components/ModalDadosDocumentoPapel';
import TableDadosCte from './components/TableDadosCte';
import TableDadosDocumentoPapel from './components/TableDadosDocumentoPapel';
import {
	converterDadosDocumentoFiscalEletronicoParaForm,
	converterDadosDocumentoPapelParaForm,
} from '../../Utils/converterDocumentosFiscais';
import { ModalDadosEmissorDocumentoAnterior } from './components/ModalDadosEmissorDocumentoAnterior';

function TabDocumentosAnterior() {
	const {
		visibleModalDadosCTe,
		setVisibleModalDadosCTe,
		visibleModalDadosDocumentoPapel,
		setVisibleModalDadosDocumentoPapel,
		visibleModalDadosEmissorDocumentoAnterior,
		setVisibleModalDadosEmissorDocumentoAnterior,
		isSituacaoFinalCTe,
		informacoesPermissoes,
	} = useContextCTe();
	const { values, setFieldValue, setValues, errors } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [emissorSelecionado, setEmissorSelecionado] = useState(null);

	function handleSaveDadosCTe(event) {
		const ctes = values.documentoAnterior?.ctes || [];
		const newItem = converterDadosDocumentoFiscalEletronicoParaForm(event);

		let updatedCtes;
		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedCtes = ctes.map((cte) => {
				const cteId = cte.id || cte.tempId;
				if (cteId === registroId) {
					return newItem;
				}
				return cte;
			});
		} else {
			const chaveAcessoJaExiste = ctes.some((cte) => cte.chaveAcesso === newItem.chaveAcesso);

			if (chaveAcessoJaExiste) {
				return;
			}

			updatedCtes = [...ctes, newItem];
		}

		setFieldValue('documentoAnterior.ctes', updatedCtes);
		if (registroSelecionado) {
			setRegistroSelecionado(null);
		}
	}

	function handleSaveDadosDocumentoPapel(event) {
		const documentosPapel = values.documentoAnterior?.documentosPapel || [];
		const newItem = converterDadosDocumentoPapelParaForm(event);

		let updatedDocumentosPapel;
		if (registroSelecionado) {
			const registroId = registroSelecionado.id || registroSelecionado.tempId;
			updatedDocumentosPapel = documentosPapel.map((documentoPapel) => {
				const documentoPapelId = documentoPapel.id || documentoPapel.tempId;
				if (documentoPapelId === registroId) {
					return newItem;
				}
				return documentoPapel;
			});
		} else {
			updatedDocumentosPapel = [...documentosPapel, newItem];
		}

		setFieldValue('documentoAnterior.documentosPapel', updatedDocumentosPapel);
		setRegistroSelecionado(null);
		setVisibleModalDadosDocumentoPapel(false);
	}

	async function handleChangeEmissor(event) {
		if (event) {
			const pessoaConvertida = await converterEmissorDocumentoAnteriorParaForm(event.registro);

			setFieldValue('documentoAnterior.emissor', pessoaConvertida);
		} else {
			setFieldValue('documentoAnterior.emissor', null);
		}
	}

	function handleChangeTipoDocumento(value) {
		switch (value) {
			case TIPO_DOCUMENTO_ANTERIOR.CTE: {
				setValues({
					...values,
					documentoAnterior: {
						...values.documentoAnterior,
						tipoDocumento: value,
						documentosPapel: [],
					},
				});
				break;
			}
			case TIPO_DOCUMENTO_ANTERIOR.OUTROS: {
				setValues({
					...values,
					documentoAnterior: {
						...values.documentoAnterior,
						tipoDocumento: value,
						ctes: [],
					},
				});
				break;
			}
			default:
				break;
		}
	}

	return (
		<>
			<div>
				<FormContent>
					<Grid>
						<Col sm="12" md="8" lg="8" xl="8" style={{ padding: '0px', marginBottom: '8px' }}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								label="Emissor"
								name="documentoAnterior.emissor"
								component={SingleSelectPessoa}
								value={values.documentoAnterior?.emissor}
								errors={errors.documentoAnterior?.emissor}
								onChange={(event) => handleChangeEmissor(event)}
								disabled={isSituacaoFinalCTe}
								touched
								{...informacoesPermissoes}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: `${errors?.documentoAnterior?.emissor ? 'space-between' : 'flex-end'}`,
								}}
							>
								<If test={values.documentoAnterior?.emissor}>
									<LinkVerDados
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											position: 'relative',
											margin: '-8px 30px -15px 0px',
										}}
										onClick={() => {
											setEmissorSelecionado(values.documentoAnterior?.emissor);
											setVisibleModalDadosEmissorDocumentoAnterior(true);
										}}
										label="Ver dados do Emissor"
									/>
								</If>
							</div>
						</Col>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							label="Tipo doc. anterior"
							name="documentoAnterior.tipoDocumento"
							component={Dropdown}
							showClear={false}
							options={TIPO_DOCUMENTO_ANTERIOR_OPTIONS}
							value={values.documentoAnterior?.tipoDocumento}
							onChange={({ value }) => handleChangeTipoDocumento(value)}
							disabled={isSituacaoFinalCTe}
						/>
					</Grid>
				</FormContent>
			</div>
			<If test={values.documentoAnterior?.tipoDocumento === TIPO_DOCUMENTO_ANTERIOR.CTE}>
				<TableDadosCte {...{ setRegistroSelecionado }} />
			</If>
			<If test={values.documentoAnterior?.tipoDocumento === TIPO_DOCUMENTO_ANTERIOR.OUTROS}>
				<TableDadosDocumentoPapel {...{ setRegistroSelecionado }} />
			</If>
			<If test={visibleModalDadosCTe}>
				<ModalDadosCTe
					onHide={() => setVisibleModalDadosCTe(false)}
					onSave={(event) => handleSaveDadosCTe(event)}
					{...{ registroSelecionado }}
				/>
			</If>
			<If test={visibleModalDadosDocumentoPapel}>
				<ModalDadosDocumentoPapel
					onHide={() => setVisibleModalDadosDocumentoPapel(false)}
					onSave={(event) => handleSaveDadosDocumentoPapel(event)}
					{...{ registroSelecionado }}
				/>
			</If>
			<If test={visibleModalDadosEmissorDocumentoAnterior}>
				<ModalDadosEmissorDocumentoAnterior
					visible={visibleModalDadosEmissorDocumentoAnterior}
					emissor={emissorSelecionado}
					onHide={() => {
						setEmissorSelecionado(null);
						setVisibleModalDadosEmissorDocumentoAnterior(false);
					}}
					setFieldValueCte={setFieldValue}
				/>
			</If>
		</>
	);
}

export { TabDocumentosAnterior };
