import { useFormikContext } from 'formik';

import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';

function ActionButtons({ onHide, onHandleSave }) {
	const { dirty, resetForm } = useFormikContext();

	function cancelar() {
		if (dirty) {
			resetForm();
		} else {
			onHide();
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar onClick={onHandleSave} disabled={!dirty} />
		</>
	);
}

export { ActionButtons };
