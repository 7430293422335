import { useFormikContext } from 'formik';
import { MdFormatListBulleted, MdFormatListBulletedAdd } from 'react-icons/md';

import { buscarDadosLoginLocalStorage, gerarUUID } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	NormalButton,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { createMDFe, deleteMDFe, readMDFe, transmitirMDFe, updateMDFe } from 'views/transporte/MDFe/Requests';

import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { converterMDFeParaApi, converterMDFeParaNovoRegistro } from '../../Util/MDFeConverter';
import { CADASTRO_URL, MDFE_SITUACAO, MDFE_TIPO_EMITENTE, PESQUISA_URL } from '../../Util/constantes';

function ActionButtons({ novoRegistro, fetchRegistro }) {
	const { values, dirty, handleSubmit, validateForm, resetForm, setValues } = useFormikContext();
	const {
		informacoesPermissoes,
		isSituacaoFinalMDFe,
		podeEditar,
		history,
		setExibirLoadingTransmissao,
		setMessageLoading,
		setErrosTransmissao,
		setVisibleModalHistorico,
		setVisibleModalCancelamento,
		setVisibleModalCondutorIndicado,
		setVisibleModalIncluirPagamento,
		setVisibleModalIncluirDFe,
		setVisibleModalEncerramento,
		setVisibleModalImportacaoNFe,
		setVisibleModalImportacaoCTe,
		setActiveTabPrincipais,
		setActiveTabServicos,
		setActiveTabComplementares,
		setResetCampos,
		setResetCamposCancelamento,
	} = useContextMDFe();

	const dadosLocais = buscarDadosLoginLocalStorage();
	const menuEventos = useRef(null);
	const menuOpcoes = useRef(null);

	const isCancelado = Boolean(values.situacao === MDFE_SITUACAO.CANCELADO);
	const isTransmitido = Boolean(values.situacao === MDFE_SITUACAO.TRANSMITIDO);
	const isEncerramento = Boolean(values.situacao === MDFE_SITUACAO.ENCERRADO);
	const isSituacaoFinal = Boolean(
		[MDFE_SITUACAO.CANCELADO, MDFE_SITUACAO.DENEGADO, MDFE_SITUACAO.ENCERRADO, MDFE_SITUACAO.TRANSMITIDO].includes(
			values.situacao
		)
	);
	const desabilitarBotaoTransmitir =
		dirty || isSituacaoFinalMDFe || !podeEditar || !organizacaoPodeTransmitirMDFe() || !values.id;

	const itensEventos = getItensEventos();
	const itensOpcoes = getItensOpcoes();

	function handleClickCancelar() {
		if (dirty) {
			setResetCamposCancelamento(true);
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function handleClickSalvar(novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				updateRegistro(values, novoOnSuccess);
			} else {
				createRegistro(values, novoOnSuccess);
			}
		}
	}

	function handleClickNovo() {
		setResetCampos(true);

		if (dirty) {
			handleClickSalvar(novoRegistro);
		} else {
			novoRegistro();
		}
	}

	function handleClickExcluir() {
		confirmarExclusao(deleteRegistro);
	}

	async function deleteRegistro() {
		await deleteMDFe(values.id, () => {
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function createRegistro(formulario, onSuccess) {
		await createMDFe(converterMDFeParaApi(formulario), ({ data: mdfe }) => {
			if (typeof onSuccess === 'function') {
				onSuccess();
			}

			atualizarUrl(history, CADASTRO_URL, mdfe.id, metodosAtualizarUrl.POP);
			resetForm({ values: { ...formulario, id: mdfe.id } });
		});
	}

	async function updateRegistro(formulario, onSuccess) {
		await updateMDFe(converterMDFeParaApi(formulario), ({ data: response }) => {
			if (typeof onSuccess === 'function') {
				onSuccess();
			}

			atualizarUrl(history, CADASTRO_URL, response.id, metodosAtualizarUrl.POP);
			resetForm({ values: formulario });
		});
	}

	async function handleClickTransmitir() {
		setExibirLoadingTransmissao(true);
		setMessageLoading('Transmitindo MDF-e...');
		await transmitirMDFe(
			values.id,
			async () => {
				setErrosTransmissao({});
				setExibirLoadingTransmissao(false);
				setMessageLoading('');
				await fetchRegistro(values.id);
			},
			async (error) => {
				let errorData = [];
				if (error.response?.data?.rejeicao?.length > 0) {
					errorData = error.response.data?.rejeicao;
				} else if (error.response?.data?.details?.length > 0) {
					errorData = [
						{
							id: gerarUUID(),
							mensagem: `Validação interna - ${error.response?.data?.details?.map((detail) => `${detail} `)}`,
						},
					];
				}
				console.warn('rejeição:', error.response.data);

				setExibirLoadingTransmissao(false);
				setMessageLoading('');
				await fetchRegistro(values.id);

				if (errorData?.length > 0) {
					errorData?.forEach((error) => {
						setErrosTransmissao({ id: error.id, mensagem: error.mensagem });
					});
				}
			}
		);
	}

	async function handleClickEncerramento() {
		setVisibleModalEncerramento(true);
	}

	async function handleClickDuplicar() {
		await readMDFe(values.id, ({ data: mdfe }) => {
			atualizarUrl(history, '/mdfes/cadastro', null, metodosAtualizarUrl.POP);

			setValues(converterMDFeParaNovoRegistro(mdfe));
		});
		setActiveTabPrincipais(values.informacaoComplementar.permiteCarregamentoPosterior ? 1 : 0);
		setActiveTabServicos(0);
		setActiveTabComplementares(0);
		setResetCampos(true);
	}

	function organizacaoPodeTransmitirMDFe() {
		if (dadosLocais?.filialConectada?.parametrosFiscalMDFe) {
			if (
				!dadosLocais?.filialConectada?.parametrosFiscalCertificado ||
				!dadosLocais.filialConectada.parametrosFiscalMDFe.serie ||
				!dadosLocais.filialConectada.parametrosFiscalMDFe.numeroInicial
			) {
				return false;
			}
		}
		return true;
	}

	function getTitleButtonTransmitir() {
		if (dadosLocais?.filialConectada?.parametrosFiscalMDFe) {
			if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de MDF-e';
			} else if (!dadosLocais?.filialConectada?.parametrosFiscalMDFe?.serie) {
				return 'Série do MDF-e não configurado';
			}
		}
		return '';
	}

	function getItensEventos() {
		const itens = [];

		itens.push({
			label: 'Encerramento',
			icon: 'fa fa-file-text-o',
			command: () => setVisibleModalEncerramento(true),
			visible: isEncerramento,
		});

		itens.push({
			label: isCancelado ? 'Motivo do cancelamento' : 'Cancelar MDF-e',
			icon: isCancelado ? 'pi pi-file-excel' : 'fa fa-times',
			command: () => setVisibleModalCancelamento(true),
			visible: isCancelado || isTransmitido,
		});

		itens.push({
			label: 'Indicar condutor',
			icon: 'fa fa-truck',
			command: () => setVisibleModalCondutorIndicado(true),
			visible: isSituacaoFinal,
		});

		itens.push({
			label: 'Indicar pagamento',
			icon: 'fa fa-money',
			command: () => setVisibleModalIncluirPagamento(true),
			visible: isSituacaoFinal && values.tipoEmitente === MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO,
		});

		itens.push({
			label: 'Incluir DF-e',
			icon: 'fa fa-file-text-o',
			command: () => setVisibleModalIncluirDFe(true),
			visible: isSituacaoFinal && values.informacaoComplementar.permiteCarregamentoPosterior,
		});

		return itens.filter((element) => element.visible);
	}

	function getItensOpcoes() {
		const itens = [];

		itens.push({
			label: 'Importar NF-es',
			icon: 'fa fa-file-text-o',
			command: () => setVisibleModalImportacaoNFe(true),
			visible: values.tipoEmitente === MDFE_TIPO_EMITENTE.CARGA_PROPRIA && !isSituacaoFinal,
		});

		itens.push({
			label: 'Importar CT-es',
			icon: 'fa fa-file-text-o',
			command: () => setVisibleModalImportacaoCTe(true),
			visible: values.tipoEmitente === MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO && !isSituacaoFinal,
		});

		itens.push({
			separator: true,
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Duplicar',
			icon: 'pi pi-copy',
			command: handleClickDuplicar,
			visible: !dirty && values.id,
		});

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setVisibleModalHistorico(true),
			visible: !dirty && values.id,
		});

		return itens.filter((element) => element.visible);
	}

	return (
		<>
			<Menu model={itensEventos} popup ref={menuEventos} />
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={handleClickCancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || isSituacaoFinalMDFe} onClick={handleClickSalvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={handleClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id}
				disabled={dirty || isSituacaoFinalMDFe}
				onClick={handleClickExcluir}
				{...informacoesPermissoes}
			/>
			<Button
				className="p-button-success"
				label="Transmitir MDF-e"
				icon="fa fa-send"
				title={getTitleButtonTransmitir()}
				onClick={handleClickTransmitir}
				style={{ margin: '5px' }}
				hidden={isSituacaoFinal}
				disabled={desabilitarBotaoTransmitir}
				{...informacoesPermissoes}
			/>
			<Button
				className="p-button-success"
				label="Encerrar MDF-e"
				icon="fa fa-send"
				onClick={handleClickEncerramento}
				style={{ margin: '5px' }}
				hidden={!isTransmitido}
				{...informacoesPermissoes}
			/>
			<NormalButton
				type="button"
				label="Eventos"
				icon={<MdFormatListBulletedAdd size={20} />}
				style={{ margin: '5px', gap: '5px' }}
				hidden={dirty || !values.id || (!isTransmitido && !isEncerramento && !isCancelado)}
				onClick={(event) => menuEventos.current.toggle(event)}
				{...informacoesPermissoes}
			/>
			<NormalButton
				type="button"
				label="Opções"
				icon={<MdFormatListBulleted size={20} />}
				style={{ margin: '5px', gap: '5px' }}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
