import {
	estadosBotaoCancelar,
	ButtonCancelar,
	estadosBotaoNovo,
	ButtonSalvar,
	ButtonNovo,
	ButtonExcluir,
} from 'components';

import { confirmarExclusao } from '../../../../../../Util/ExclusaoDeRegistros';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../../Util';
import { converterOperacaoParaApi } from '../../../Util/operacoesFiscaisConverter';
import { asyncDeleteOperacaoFiscal, asyncCreateOperacaoFiscal, asyncUpdateOperacaoFiscal } from '../../../Requests';

export default function ActionButtons(props) {
	const {
		informacoesPermissoes,
		setIsNewOperacao,
		values,
		initialValue,
		dirty,
		resetForm,
		history,
		PESQUISAURL,
		dadosBase,
		setDadosBase,
		handleSubmit,
		CADASTROURL,
	} = props;

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (event) => salvar(event, novo) : novo;

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: initialValue });
	}

	async function salvar(event, novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario(props)) {
			let dadosFormulario = converterOperacaoParaApi(values);
			if (values?.id) {
				asyncUpdateOperacaoFiscal(dadosFormulario, novoOnSuccess);
				setDadosBase(dadosFormulario);
				resetForm({ values: dadosFormulario });
			} else {
				setDadosBase(values);
				asyncCreateOperacaoFiscal(dadosFormulario, async ({ data: operacaoFiscal }) => {
					setIsNewOperacao(true);
					let dados = { ...dadosFormulario, id: operacaoFiscal?.id, codigo: operacaoFiscal?.codigo };
					setDadosBase(dados);
					resetForm({ values: dados });
					if (novoOnSuccess) {
						novoOnSuccess();
					} else if (props.isModal) {
						props.hideModal(dados);
					} else {
						atualizarUrl(history, CADASTROURL, operacaoFiscal.id, metodosAtualizarUrl.POP);
					}
				});
			}
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({
				values: dadosBase?.values,
			});
		} else if (props.isModal) {
			props.hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteOperacaoFiscal(values?.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}
	return (
		<>
			<ButtonCancelar {...informacoesPermissoes} estadoBotao={estadoBotaoCancelar} onClick={cancelar} />
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />
			<ButtonNovo
				{...informacoesPermissoes}
				onClick={onClickNovo}
				hidden={!values?.id && !dirty}
				estadoBotao={estadoBotaoNovo}
			/>
			<ButtonExcluir {...informacoesPermissoes} hidden={!values?.id} onClick={excluir} />
		</>
	);
}
