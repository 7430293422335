import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { converterModeloServicoParaApi } from '../../../Util/servicoModeloConverter';
import { asyncCreateModeloDeServico, asyncDeleteModeloDeServico, asyncUpdateModeloDeServico } from '../../../Request';

const PESQUISA_URL = '/servicos/modelo';
const CADASTRO_URL = '/servicos/modelo/cadastro';

function ActionButtons({ history, informacoesPermissoes, isModal, hideModal, marcaJaCadastrada }) {
	const { values, dirty, handleReset, resetForm, handleSubmit, validateForm } = useFormikContext();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	const onClickNovo = dirty ? (event) => salvar(event, novo) : novo;

	function cancelar() {
		if (dirty) {
			handleReset();
		} else if (isModal) {
			hideModal(values);
		} else {
			voltarParaAPesquisa(history, PESQUISA_URL);
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			const dadosFormulario = converterModeloServicoParaApi(values);
			if (values.id) {
				await asyncUpdateRegistro(dadosFormulario, novoOnSuccess);
			} else {
				await asyncCreateRegistro(dadosFormulario, novoOnSuccess);
				if (typeof novoOnSuccess === 'undefined' && !informacoesPermissoes.podeEditar) {
					history.goBack();
				}
			}
		}
	}

	function novo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		resetForm({
			values: {
				id: null,
				nome: '',
				marca: null,
				situacao: 'ATIVO',
			},
		});

		setTimeout(() => {
			document.getElementById('InputNomeModeloServico').focus();
		}, 300);
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteModeloDeServico(values.id, () => {
			handleReset();
			voltarParaAPesquisa(history, PESQUISA_URL);
		});
	}

	async function asyncUpdateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncUpdateModeloDeServico(dadosFormulario, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function asyncCreateRegistro(dadosFormulario, novoOnSuccess) {
		await asyncCreateModeloDeServico(dadosFormulario, ({ data: modelo }) => {
			const dados = { ...values, id: modelo.id };
			resetForm({ values: dados });

			if (novoOnSuccess) {
				novoOnSuccess();
			} else if (isModal) {
				hideModal(dados);
			} else {
				atualizarUrl(history, CADASTRO_URL, modelo.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={(!values.id && !dirty) || !!marcaJaCadastrada}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir onClick={excluir} hidden={!values.id} disabled={!informacoesPermissoes.podeExcluir} />
		</>
	);
}

export default ActionButtons;
