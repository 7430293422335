import { exibirToast, post, services } from 'Common';

async function transmitirCTeCartaCorrecao(idCTe, correcao, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/ctes/${idCTe}/correcao`,
		correcao,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export { transmitirCTeCartaCorrecao };
