import { useFormikContext } from 'formik';
import { FaKey } from 'react-icons/fa';

import { Col, Grid, If, notify, ToastTypes } from 'components';
import { copiarParaAreaTransferencia } from 'Common';

import { CTE_INFORMACOES_SITUACAO } from 'views/transporte/CTe/Util/constantes';
import { useContextCTe } from 'views/transporte/CTe/Context';
import { CHAVE_STYLE, NAME_STYLE, NUMERO_STYLE, SECAO_STYLE, SITUACAO_STYLE } from '../../Util/constantes';

function Header() {
	const { values } = useFormikContext();
	const { isMobile } = useContextCTe();

	const backgroundColor = values.situacao ? CTE_INFORMACOES_SITUACAO[values.situacao].lightColor : null;
	const color = values.situacao ? CTE_INFORMACOES_SITUACAO[values.situacao].strongColor : null;
	const name = values.situacao ? CTE_INFORMACOES_SITUACAO[values.situacao].name : null;
	const description = values.situacao ? CTE_INFORMACOES_SITUACAO[values.situacao].description : null;

	return (
		<Grid justifyBetween verticalAlignCenter style={{ margin: '1rem 0px 0px 0px' }}>
			<Col sm="5" lg="6" xl="6">
				<div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
					<div style={SECAO_STYLE}>
						<div>Série</div>
						<div
							style={{ ...NUMERO_STYLE, color: color }}
							title={values.serie ? 'Série definida nas configurações do sistema' : 'Série não configurada'}
						>
							<span>{values.serie || '-'}</span>
						</div>
					</div>
					<div
						style={SECAO_STYLE}
						title={!values.numero ? 'O número do CT-e é gerado automaticamente pelo sistema' : null}
					>
						<div>Número</div>
						<div style={{ ...NUMERO_STYLE, color: color }}>{values.numero || '-'}</div>
					</div>
				</div>
			</Col>
			<Col sm="7" lg="4" xl="4">
				<If test={values.chave}>
					<>
						<div title={description} style={{ ...SITUACAO_STYLE, backgroundColor, color }}>
							<div style={NAME_STYLE}>{name}</div>
						</div>
						<div
							title="Clique para copiar"
							style={{ ...CHAVE_STYLE, color }}
							onClick={() => {
								copiarParaAreaTransferencia(values.chave);
								notify('Chave de acesso copiada para área de transferência', ToastTypes.SUCCESS);
							}}
						>
							<FaKey /> {values.chave}
						</div>
					</>
				</If>
				<If test={!values.chave}>
					<div title={description} style={{ ...SITUACAO_STYLE, backgroundColor, color }}>
						<div style={NAME_STYLE}>{name}</div>
					</div>
				</If>
			</Col>
		</Grid>
	);
}

export { Header };
