const CTE_CARTA_CORRECAO_GRUPO = {
	IDE: 'ide',
	TOMA4: 'toma4',
	ENDERTOMA: 'enderToma',
	COMPL: 'compl',
	EMIT: 'emit',
	ENDEREMIT: 'enderEmit',
	REM: 'rem',
	ENDERREME: 'enderReme',
	EXPED: 'exped',
	ENDEREXPED: 'enderExped',
	RECEB: 'receb',
	ENDERRECEB: 'enderReceb',
	DEST: 'dest',
	ENDERDEST: 'enderDest',
	VPREST: 'vPrest',
	COMP: 'Comp',
	IMP: 'imp',
	INFCARGA: 'infCarga',
	INFQ: 'infQ',
	EMIDOCANT: 'emiDocAnt',
	INFCTESUB: 'infCteSub',
};

const CTE_CARTA_CORRECAO_GRUPO_OPTIONS = [
	{
		label: `Identificação do CT-e ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.IDE} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.IDE).toUpperCase(),
	},
	{
		label: `Tomador - Outros ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.TOMA4} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.TOMA4).toUpperCase(),
	},
	{
		label: `Endereço tomador - Outros ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDERTOMA} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDERTOMA).toUpperCase(),
	},
	{
		label: `Dados complementares ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.COMPL} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.COMPL).toUpperCase(),
	},
	{
		label: `Emitente ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.EMIT} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.EMIT).toUpperCase(),
	},
	{
		label: `Endereço emitente ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDEREMIT} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDEREMIT).toUpperCase(),
	},
	{
		label: `Remetente ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.REM} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.REM).toUpperCase(),
	},
	{
		label: `Endereço remetente ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDERREME} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDERREME).toUpperCase(),
	},
	{
		label: `Expedidor ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.EXPED} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.EXPED).toUpperCase(),
	},
	{
		label: `Endereço expedidor ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDEREXPED} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDEREXPED).toUpperCase(),
	},
	{
		label: `Recebedor ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.RECEB} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.RECEB).toUpperCase(),
	},
	{
		label: `Endereço recebedor ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDERRECEB} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDERRECEB).toUpperCase(),
	},
	{
		label: `destinatário ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.DEST} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.DEST).toUpperCase(),
	},
	{
		label: `Endereço destinatário ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.ENDERDEST} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.ENDERDEST).toUpperCase(),
	},
	{
		label: `Valores da prestação de serviço ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.VPREST} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.VPREST).toUpperCase(),
	},
	{
		label: `Componente do valor da prestação de serviço ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.COMP} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.COMP).toUpperCase(),
	},
	{
		label: `Impostos ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.IMP} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.IMP).toUpperCase(),
	},
	{
		label: `Informações da carga ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.INFCARGA} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.INFCARGA).toUpperCase(),
	},
	{
		label: `Informações da quantidade da carga ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.INFQ} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.INFQ).toUpperCase(),
	},
	{
		label: `Emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.EMIDOCANT} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.EMIDOCANT).toUpperCase(),
	},
	{
		label: `Informações do CT-e de substituição ( Tag: ${CTE_CARTA_CORRECAO_GRUPO.INFCTESUB} )`,
		value: String(CTE_CARTA_CORRECAO_GRUPO.INFCTESUB).toUpperCase(),
	},
];

const CTE_CARTA_CORRECAO_CAMPOS = {
	IDE: {
		NATOP: 'natOp',
		TPIMP: 'tpImp',
		TPCTE: 'tpCTe',
		PROCEMI: 'procEmi',
		VERPROC: 'verProc',
		INDGLOBALIZADO: 'indGlobalizado',
		CMUNENV: 'cMunEnv',
		XMUNENV: 'xMunEnv',
		UFENV: 'UFEnv',
		TPSERV: 'tpServ',
		XMUNINI: 'xMunIni',
		UFINI: 'UFIni',
		CMUNFIM: 'cMunFim',
		XMUNFIM: 'xMunFim',
		UFFIM: 'UFFim',
		RETIRA: 'retira',
		XDETRETIRA: 'xDetRetira',
		INDIETOMA: 'indIEToma',
	},
	TOMA4: { TOMA: 'toma', XNOME: 'xNome', XFANT: 'xFant', FONE: 'fone' },
	ENDERTOMA: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
		CPAIS: 'cPais',
		XPAIS: 'xPais',
		EMAIL: 'email',
	},
	COMPL: { XCARACAD: 'xCaracAd', XCARACSER: 'xCaracSer', XEMI: 'xEmi', XOBS: 'xObs' },
	EMIT: { IEST: 'IEST', XNOME: 'xNome', XFANT: 'xFant' },
	ENDEREMIT: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
		UF: 'UF',
		FONE: 'fone',
	},
	REM: { XNOME: 'xNome', XFANT: 'xFant', FONE: 'fone' },
	ENDERREME: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
	},
	EXPED: { XNOME: 'xNome', FONE: 'fone' },
	ENDEREXPED: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
	},
	RECEB: { XNOME: 'xNome', FONE: 'fone' },
	ENDERRECEB: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
	},
	DEST: { XNOME: 'xNome', FONE: 'fone', ISUF: 'ISUF' },
	ENDERDEST: {
		XLGR: 'xLgr',
		NRO: 'nro',
		XCPL: 'xCpl',
		XBAIRRO: 'xBairro',
		CMUN: 'cMun',
		XMUN: 'xMun',
		CEP: 'CEP',
	},
	VPREST: { VREC: 'vRec' },
	COMP: { XNOME: 'xNome' },
	IMP: { VTOTTRIB: 'vTotTrib', INFADFISCO: 'infAdFisco' },
	INFCARGA: { VCARGA: 'vCarga', PROPRED: 'proPred', XOUTCAT: 'xOutCat' },
	INFQ: { CUNID: 'cUnid', TPMED: 'tpMed', QCARGA: 'qCarga', VCARGAAVERB: 'vCargaAverb' },
	EMIDOCANT: { CNPJ: 'CNPJ', CPF: 'CPF', XNOME: 'xNome', IE: 'IE', UF: 'UF' },
	INFCTESUB: { CHCTE: 'chCte', INDALTERATOMA: 'indAlteraToma' },
};

const CTE_CARTA_CORRECAO_CAMPOS_OPTIONS = {
	IDE: [
		{
			label: `Natureza da operação ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.NATOP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.NATOP).toUpperCase(),
		},
		{
			label: `Tipo de impressão ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.TPIMP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.TPIMP).toUpperCase(),
		},
		{
			label: `Tipo do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.TPCTE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.TPCTE).toUpperCase(),
		},
		{
			label: `Identificador do precesso de emissão ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.PROCEMI} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.PROCEMI).toUpperCase(),
		},
		{
			label: `Versão do processo de emissão ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.VERPROC} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.VERPROC).toUpperCase(),
		},
		{
			label: `Indicador de CT-e globalizado ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.INDGLOBALIZADO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.INDGLOBALIZADO).toUpperCase(),
		},
		{
			label: `Código do município de envio do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.CMUNENV} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.CMUNENV).toUpperCase(),
		},
		{
			label: `Nome do município de envio do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNENV} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNENV).toUpperCase(),
		},
		{
			label: `Sigla da UF de envio do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.UFENV} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.UFENV).toUpperCase(),
		},
		{
			label: `Tipo do serviço ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.TPSERV} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.TPSERV).toUpperCase(),
		},
		{
			label: `Nome do município de início do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNINI} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNINI).toUpperCase(),
		},
		{
			label: `Sigla da UF de início do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.UFINI} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.UFINI).toUpperCase(),
		},
		{
			label: `Nome do município de fim do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.CMUNFIM} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.CMUNFIM).toUpperCase(),
		},
		{
			label: `Código do município de fim do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNFIM} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.XMUNFIM).toUpperCase(),
		},
		{
			label: `Sigla da UF de fim do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.UFFIM} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.UFFIM).toUpperCase(),
		},
		{
			label: `Indicador se o recebedor retira no Aeroporto, Filial, Porto... ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.RETIRA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.RETIRA).toUpperCase(),
		},
		{
			label: `Detalhes do retira ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.XDETRETIRA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.XDETRETIRA).toUpperCase(),
		},
		{
			label: `Indicador de IE do tomador ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IDE.INDIETOMA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IDE.INDIETOMA).toUpperCase(),
		},
	],
	TOMA4: [
		{
			label: `Tomador do serviço ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.TOMA4.TOMA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.TOMA4.TOMA).toUpperCase(),
		},
		{
			label: `Nome do tomador ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.TOMA4.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.TOMA4.XNOME).toUpperCase(),
		},
		{
			label: `Nome fantasia ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.TOMA4.XFANT} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.TOMA4.XFANT).toUpperCase(),
		},
		{
			label: `Telefone ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.TOMA4.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.TOMA4.FONE).toUpperCase(),
		},
	],
	ENDERTOMA: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.NRO).toUpperCase(),
		},
		{
			label: `Complemento ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CEP).toUpperCase(),
		},
		{
			label: `Código do país ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CPAIS} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.CPAIS).toUpperCase(),
		},
		{
			label: `Nome do país ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XPAIS} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.XPAIS).toUpperCase(),
		},
		{
			label: `E-mail ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.EMAIL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERTOMA.EMAIL).toUpperCase(),
		},
	],
	COMPL: [
		{
			label: `Características adicionals do transporte ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.COMPL.XCARACAD} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.COMPL.XCARACAD).toUpperCase(),
		},
		{
			label: `Características adicionals do serviço ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.COMPL.XCARACSER} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.COMPL.XCARACSER).toUpperCase(),
		},
		{
			label: `Funcionário emissor do CT-e ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.COMPL.XEMI} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.COMPL.XEMI).toUpperCase(),
		},
		{
			label: `Observações gerais ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.COMPL.XOBS} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.COMPL.XOBS).toUpperCase(),
		},
	],
	EMIT: [
		{
			label: `Inscrição estadual do substituto tributário ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIT.IEST} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIT.IEST).toUpperCase(),
		},
		{
			label: `Nome do emitente ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIT.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIT.XNOME).toUpperCase(),
		},
		{
			label: `Nome fantasia do emitente( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIT.XFANT} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIT.XFANT).toUpperCase(),
		},
	],
	ENDEREMIT: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.NRO).toUpperCase(),
		},
		{
			label: `Complemento ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.CEP).toUpperCase(),
		},
		{
			label: `Sigla da UF ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.UF} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.UF).toUpperCase(),
		},
		{
			label: `Telefone ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREMIT.FONE).toUpperCase(),
		},
	],
	REM: [
		{
			label: `Nome do remetente ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.REM.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.REM.XNOME).toUpperCase(),
		},
		{
			label: `Nome fantasia do remetente ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.REM.XFANT} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.REM.XFANT).toUpperCase(),
		},
		{
			label: `Telefone do remetente ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.REM.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.REM.FONE).toUpperCase(),
		},
	],
	ENDERREME: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.NRO).toUpperCase(),
		},
		{
			label: `Complemento ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do municipio ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERREME.CEP).toUpperCase(),
		},
	],
	EXPED: [
		{
			label: `Nome do expedidor ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EXPED.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EXPED.XNOME).toUpperCase(),
		},
		{
			label: `Telefone do expedidor ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EXPED.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EXPED.FONE).toUpperCase(),
		},
	],
	ENDEREXPED: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.NRO).toUpperCase(),
		},
		{
			label: `Complemento( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDEREXPED.CEP).toUpperCase(),
		},
	],
	RECEB: [
		{
			label: `Nome do recebedor ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.RECEB.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.RECEB.XNOME).toUpperCase(),
		},
		{
			label: `Telefone do recebedor ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.RECEB.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.RECEB.FONE).toUpperCase(),
		},
	],
	ENDERRECEB: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.NRO).toUpperCase(),
		},
		{
			label: `Complemento( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERRECEB.CEP).toUpperCase(),
		},
	],
	DEST: [
		{
			label: `Nome do destinatário ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.DEST.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.DEST.XNOME).toUpperCase(),
		},
		{
			label: `Telefone do destinatário ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.DEST.FONE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.DEST.FONE).toUpperCase(),
		},
		{
			label: `Inscrição SUFRAMA do destinatário ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.DEST.ISUF} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.DEST.ISUF).toUpperCase(),
		},
	],
	ENDERDEST: [
		{
			label: `Logradouro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XLGR} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XLGR).toUpperCase(),
		},
		{
			label: `Número ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.NRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.NRO).toUpperCase(),
		},
		{
			label: `Complemento( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XCPL} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XCPL).toUpperCase(),
		},
		{
			label: `Bairro ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XBAIRRO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XBAIRRO).toUpperCase(),
		},
		{
			label: `Código do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.CMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.CMUN).toUpperCase(),
		},
		{
			label: `Nome do município ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XMUN} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.XMUN).toUpperCase(),
		},
		{
			label: `CEP ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.CEP} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.ENDERDEST.CEP).toUpperCase(),
		},
	],
	VPREST: [
		{
			label: `Valor a Receber ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.VPREST.VREC} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.VPREST.VREC).toUpperCase(),
		},
	],
	COMP: [
		{
			label: `Nome do componente do valor da prestação ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.COMP.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.COMP.XNOME).toUpperCase(),
		},
	],
	IMP: [
		{
			label: `Valor total dos tributos ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IMP.VTOTTRIB} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IMP.VTOTTRIB).toUpperCase(),
		},
		{
			label: `Informações adicionais de interesse do fisco ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.IMP.INFADFISCO} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.IMP.INFADFISCO).toUpperCase(),
		},
	],
	INFCARGA: [
		{
			label: `Valor total da carga ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.VCARGA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.VCARGA).toUpperCase(),
		},
		{
			label: `Produto predominante da carga ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.PROPRED} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.PROPRED).toUpperCase(),
		},
		{
			label: `Outras características da carga ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.XOUTCAT} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFCARGA.XOUTCAT).toUpperCase(),
		},
	],
	INFQ: [
		{
			label: `Código da unidade de medida ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFQ.CUNID} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFQ.CUNID).toUpperCase(),
		},
		{
			label: `Tipo da medida ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFQ.TPMED} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFQ.TPMED).toUpperCase(),
		},
		{
			label: `Quantidade da carga ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFQ.QCARGA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFQ.QCARGA).toUpperCase(),
		},
		{
			label: `Valor da carga para efeito de averbação ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFQ.VCARGAAVERB} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFQ.VCARGAAVERB).toUpperCase(),
		},
	],
	EMIDOCANT: [
		{
			label: `CNPJ do emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.CNPJ} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.CNPJ).toUpperCase(),
		},
		{
			label: `CPF do emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.CPF} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.CPF).toUpperCase(),
		},
		{
			label: `Nome do emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.XNOME} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.XNOME).toUpperCase(),
		},
		{
			label: `IE do emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.IE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.IE).toUpperCase(),
		},
		{
			label: `Sigla da UF do emissor do documento anterior ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.UF} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.EMIDOCANT.UF).toUpperCase(),
		},
	],
	INFCTESUB: [
		{
			label: `Chave de acesso do CT-e de substituição ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFCTESUB.CHCTE} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFCTESUB.CHCTE).toUpperCase(),
		},
		{
			label: `Indicador de CT-e Alteração de tomador ( Tag: ${CTE_CARTA_CORRECAO_CAMPOS.INFCTESUB.INDALTERATOMA} )`,
			value: String(CTE_CARTA_CORRECAO_CAMPOS.INFCTESUB.INDALTERATOMA).toUpperCase(),
		},
	],
};

const CTE_CARTA_CORRECAO_INITIAL_VALUES = {
	id: null,
	emissao: null,
	numeroSequencial: null,
	arquivoXml: null,
	correcoes: [],
};

const CTE_CARTA_CORRECAO_ITEM_INITIAL_VALUE = {
	id: null,
	tempId: null,
	item: null,
	grupo: null,
	campo: null,
	valor: null,
};

export {
	CTE_CARTA_CORRECAO_GRUPO,
	CTE_CARTA_CORRECAO_GRUPO_OPTIONS,
	CTE_CARTA_CORRECAO_CAMPOS,
	CTE_CARTA_CORRECAO_CAMPOS_OPTIONS,
	CTE_CARTA_CORRECAO_INITIAL_VALUES,
	CTE_CARTA_CORRECAO_ITEM_INITIAL_VALUE,
};
