import { Field, useFormikContext, withFormik } from 'formik';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	modulos,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiModulo,
	usuarioPossuiModulos,
	usuarioPossuiPermissao,
	validarUUID,
} from '../../../../Common';
import {
	AutoProgressBar,
	Checkbox,
	Col,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Message,
	Prompt,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsPessoa,
} from '../../../../components';
import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { asyncDownloadImagemPessoa, asyncGetPessoa } from '../Requests';
import { PESSOA_TIPO, SITUACAO, TipoPessoa } from '../Util/constantes';
import { converterPessoaParaFormulario } from '../Util/pessoaConverter';
import { helpPessoaForm } from './Help';
import { fieldsValidate } from './Util/validacoes';
import { ActionButtons } from './components/ActionButtons';
import { renderizarFieldsEstrangeiro } from './components/FieldsEstrangeiro';
import { renderizarFieldsInformacoesFiscais } from './components/FieldsInformacoesFiscais';
import { renderizarFieldsPessoaFisica } from './components/FieldsPessoaFisica';
import { renderizarFieldsPessoaJuridica } from './components/FieldsPessoaJuridica';
import ModalProdutos from './components/ModalProdutos';
import PessoaAdicionais from './components/tabs/PessoaAdicionais';
import PessoaCnae from './components/tabs/PessoaCnae';
import PessoaConfiguracoes from './components/tabs/PessoaConfiguracoes';
import PessoaContato from './components/tabs/PessoaContato';
import PessoaEmail from './components/tabs/PessoaEmail';
import PessoaEndereco from './components/tabs/PessoaEndereco';
import PessoaObservacoes from './components/tabs/PessoaObservacoes';
import PessoaTelefone from './components/tabs/PessoaTelefone';

const initialValue = {
	id: '',
	codigo: '-',
	cliente: true,
	fornecedor: false,
	transportador: false,
	nome: null,
	situacao: 'ATIVA',
	limiteCredito: 0,
	tipo: 'FISICA',
	observacao: null,

	//Pessoa física
	cpf: null,
	rg: null,
	genero: null,
	nascimento: null,
	pessoaPai: null,
	pessoaMae: null,
	pessoaConjuge: null,
	localTrabalho: null,

	//Pessoa jurídica
	cnpj: null,
	razaoSocial: null,
	inscricaoEstadual: '',
	simplesNacional: false,
	inscricaoSuframa: '',
	inscricaoMunicipal: '',

	//Pessoa estrangeira
	identificacao: '',

	enderecos: [],
	emails: [],
	telefones: [],
	contatos: [],
	cnaes: [],

	campoObrigatorioParaDocumentoFiscal: usuarioPossuiModulo(modulos.VENDAS),

	imagem: null,
	imagemFile: null,
	imagemUrl: null,

	//Fiscal
	consumidorFinal: true,
	indicadorInscricaoEstadual: 'NAO_CONTRIBUINTE',

	//Configuracoes
	configPrecoPraticado: 'PRECO_VENDA',
};

const CADASTROURL = '/pessoas/cadastro';
const PESQUISAURL = '/pessoas';

const secaoStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '0 0 1rem 0',
};

const codigoStyle = {
	fontSize: '24px',
	color: '#006095',
	fontWeight: 'bold',
};

function PessoaForm(props) {
	const {
		tabSelecionada,
		isModal,
		tipoPessoaNovoCadastro,
		history,
		isMobile,
		setHideBackground,
		appendTo = document.body,
		hideModal,
		initialValues,
	} = props;
	const { setFieldValue, values, resetForm, setFieldError, errors, setValues, dirty, handleSubmit } =
		useFormikContext();

	const [tabSelecionadaInterno, setTabSelecionadaInterno] = useState(0);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizarFiscal, setPodeVisualizarFiscal] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR)
	);
	const [podeEditar, setPodeEditar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EDITAR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.EXCLUIR));
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PESSOA)
	);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [imagemFileBase, setImagemFileBase] = useState(null);
	const [produtosIsVisible, setProdutosIsVisible] = useState(false);
	const [desabilitarCheckboxTipoPessoa, setDesabilitarCheckboxTipoPessoa] = useState({
		cliente: false,
		fornecedor: false,
		transportador: false,
	});

	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	const idPessoa = isModal ? props.idPessoa : values.id;
	const referenciaEstoqueValido = props.match.path === '/pessoas/cadastro/:id';

	useEffect(() => {
		setFieldValue('campoObrigatorioParaDocumentoFiscal', usuarioPossuiModulo(modulos.VENDAS));
		if (tabSelecionada) {
			setTabSelecionadaInterno(tabSelecionada);
		}
		const id = isModal ? props.idPessoa : props.match.params.id;
		if (validarUUID(id)) {
			asyncSelectRegistro(id);
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PESSOA, false, null, false);
		}

		document.getElementById('nomePessoa').focus();

		if (isModal) {
			switch (tipoPessoaNovoCadastro) {
				case TipoPessoa.CLIENTE:
					setFieldValue('cliente', true);
					setFieldValue('fornecedor', false);
					setFieldValue('transportador', false);
					setDesabilitarCheckboxTipoPessoa({ cliente: true, fornecedor: false, transportador: false });
					break;
				case TipoPessoa.FORNECEDOR:
					setFieldValue('cliente', false);
					setFieldValue('fornecedor', true);
					setFieldValue('transportador', false);
					setDesabilitarCheckboxTipoPessoa({ cliente: false, fornecedor: true, transportador: false });
					break;
				case TipoPessoa.TRANSPORTADOR:
					setFieldValue('cliente', false);
					setFieldValue('fornecedor', false);
					setFieldValue('transportador', true);
					setDesabilitarCheckboxTipoPessoa({ cliente: false, fornecedor: false, transportador: true });
					break;
				default:
					setFieldValue('cliente', true);
					setFieldValue('fornecedor', false);
					setFieldValue('transportador', false);
					setDesabilitarCheckboxTipoPessoa({ cliente: false, fornecedor: false, transportador: false });
					break;
			}
		}
	}, []);

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	function asyncSelectRegistro(idPessoa) {
		asyncGetPessoa(idPessoa, async ({ data: pessoa }) => {
			let pessoaConvertida = converterPessoaParaFormulario(pessoa);
			resetForm({ values: pessoaConvertida });
			if (!isModal) {
				atualizarUrl(history, CADASTROURL, pessoa.id, metodosAtualizarUrl.POP);
			}
			if (pessoaConvertida.imagem) {
				await asyncDownloadImagemPessoa(
					pessoaConvertida.id,
					({ data: imagem }) => {
						setImagemFileBase(imagem);
						resetForm({
							values: {
								...pessoaConvertida,
								imagemFile: imagem,
								imagemUrl: URL.createObjectURL(imagem),
							},
						});
					},
					() => {
						notify('Não foi possivel fazer download da imagem', ToastTypes.ERROR);
					},
					true,
					false
				);
			}
			if (!isModal) {
				selecionarTab({ index: 0 });
			}
		});
	}

	function selecionarTab(event) {
		setTabSelecionadaInterno(event.index);

		setTimeout(() => {
			switch (event.index) {
				case 5:
					document.getElementById('PessoasAdicionaisInputNomePai')?.focus();
					break;
				case 6:
					document.getElementById('PessoaObservacoesTextAreaObservacoes')?.focus();
					break;
				case 7:
					document
						.getElementById('PessoasConfiguracoesSelectPrecoPraticado')
						?.getElementsByTagName('input')[0]
						?.focus();
					break;
				default:
					break;
			}
		}, 500);
	}

	function FormatarTextoWarning(errors) {
		return (
			<span>
				{errors.map((erro) => {
					return (
						<span key={erro}>
							{erro}
							<br />
						</span>
					);
				})}
			</span>
		);
	}

	function filtrarStepsTutorial(steps) {
		if (usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS])) {
			return steps;
		} else {
			return steps.filter((step) => step.target != '.step-pessoa-tipo');
		}
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={filtrarStepsTutorial(tutorialStepsPessoa)}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Cadastro de pessoa" isModal={isModal} className="card-default screen-max-width">
				<If test={isModal}>
					<AutoProgressBar />
				</If>
				<FormActions className="screen-max-width">
					<ActionButtons
						initialValues={initialValues}
						initialValue={initialValue}
						hideModal={hideModal}
						selecionarTab={selecionarTab}
						idPessoa={idPessoa}
						isModal={isModal}
						referenciaEstoqueValido={referenciaEstoqueValido}
						informacoesPermissoes={informacoesPermissoes}
						setTabSelecionadaInterno={setTabSelecionadaInterno}
						imagemFileBase={imagemFileBase}
						setImagemFileBase={setImagemFileBase}
						PESQUISAURL={PESQUISAURL}
						CADASTROURL={CADASTROURL}
						setProdutosIsVisible={setProdutosIsVisible}
						history={history}
						appendTo={appendTo}
						isMobile={isMobile}
					/>
				</FormActions>
				<FormContent>
					<If test={values.errors?.length && props.submitCount > 0}>
						<Message
							severity="error"
							text={values.errors ? FormatarTextoWarning(values.errors) : null}
							colStyle={{ padding: '5px 0px', marginBottom: '8px' }}
						/>
					</If>
					<Grid style={{ display: 'flex' }}>
						<Col sm="3" md="3" lg="3" xl="3" style={{ height: '4rem' }}>
							<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
								<div
									style={secaoStyle}
									title={!values.codigo ? 'O código é gerado automaticamente pelo sistema' : null}
								>
									<div>Código</div>
									<div style={codigoStyle}>{values.codigo || '-'}</div>
								</div>
							</div>
						</Col>
						<Col
							sm="9"
							md="9"
							lg="9"
							xl="9"
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-end',
								alignItems: 'center',
								height: '4rem',
							}}
						>
							<Grid
								className="step-pessoa-tipo"
								hidden={false}
								style={{
									display: usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS]) ? 'flex' : 'none',
									flexDirection: isMobile ? 'column' : 'row',
									justifyContent: isMobile ? 'center' : 'flex-end',
									alignItems: 'center',
									width: '100%',
									paddingTop: '8px',
								}}
							>
								<Field
									sm="12"
									md="3"
									lg="3"
									xl="3"
									component={Checkbox}
									colStyle={{
										display: 'flex',
										justifyContent: isMobile ? 'flex-start' : 'center',
										paddingLeft: isMobile ? 'calc(100% - 9rem)' : '1rem',
									}}
									label="Cliente"
									name="cliente"
									helpMessage={helpPessoaForm.cliente}
									onChange={(e) => setFieldValue('cliente', e.checked)}
									checked={values.cliente}
									disabled={desabilitarCheckboxTipoPessoa.cliente}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="3"
									lg="3"
									xl="3"
									component={Checkbox}
									colStyle={{
										display: 'flex',
										justifyContent: isMobile ? 'flex-start' : 'center',
										paddingLeft: isMobile ? 'calc(100% - 9rem)' : '0',
									}}
									label="Fornecedor"
									name="fornecedor"
									helpMessage={helpPessoaForm.fornecedor}
									onChange={(e) => setFieldValue('fornecedor', e.checked)}
									checked={values.fornecedor}
									disabled={desabilitarCheckboxTipoPessoa.fornecedor}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="3"
									lg="3"
									xl="3"
									component={Checkbox}
									colStyle={{
										display: 'flex',
										justifyContent: isMobile ? 'flex-start' : 'center',
										paddingLeft: isMobile ? 'calc(100% - 9rem)' : '0',
									}}
									label="Transportador"
									name="transportador"
									helpMessage={helpPessoaForm.transportador}
									onChange={(e) => setFieldValue('transportador', e.checked)}
									checked={values.transportador}
									disabled={desabilitarCheckboxTipoPessoa.transportador}
									{...informacoesPermissoes}
								/>
							</Grid>
						</Col>
					</Grid>
					<Grid className="step-pessoa-informacoes">
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							component={InputField}
							label="Nome"
							onBlur={() => {
								if (values.nome) {
									setFieldValue('nome', values.nome.trim());
								}
							}}
							obrigatorio
							name="nome"
							id="nomePessoa"
							helpMessage={helpPessoaForm.nome}
							size={255}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Tipo"
							obrigatorio
							helpMessage={helpPessoaForm.tipo}
							name="tipo"
							showClear={false}
							onChange={(e) => {
								setFieldValue('tipo', e.value);
								selecionarTab({ index: 0 });
							}}
							options={PESSOA_TIPO}
							disabled={props.validarIntermediador}
							menuPortalTarget={appendTo}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="3"
							xl="3"
							component={Dropdown}
							label="Situação"
							name="situacao"
							helpMessage={helpPessoaForm.situacao}
							showClear={false}
							onChange={(e) => setFieldValue('situacao', e.value)}
							options={SITUACAO}
							menuPortalTarget={appendTo}
							{...informacoesPermissoes}
						/>
						<Grid
							hidden={values.tipo !== 'FISICA'}
							style={{
								width: '100%',
								marginTop: '0',
								marginLeft: 0,
								border: '5px solid #9e9e9e !important',
							}}
						>
							{renderizarFieldsPessoaFisica(
								values,
								setFieldValue,
								informacoesPermissoes,
								values.campoObrigatorioParaDocumentoFiscal,
								appendTo
							)}
						</Grid>
						<Grid hidden={values.tipo !== 'JURIDICA'} style={{ width: '100%', marginTop: '0', marginLeft: 0 }}>
							{renderizarFieldsPessoaJuridica(
								values,
								setFieldValue,
								informacoesPermissoes,
								setValues,
								setFieldError,
								errors,
								values.campoObrigatorioParaDocumentoFiscal
							)}
						</Grid>
						<Grid hidden={values.tipo !== 'ESTRANGEIRO'} style={{ width: '100%', marginTop: '0', marginLeft: 0 }}>
							{renderizarFieldsEstrangeiro(values, informacoesPermissoes)}
						</Grid>
						<If test={podeVisualizarFiscal}>
							<Grid style={{ width: '100%', marginTop: '0', marginLeft: 0 }}>
								{renderizarFieldsInformacoesFiscais(values, setFieldValue, informacoesPermissoes, props, appendTo)}
							</Grid>
						</If>
					</Grid>

					<Grid style={{ marginTop: '0' }}>
						<TabView
							className="tab-view step-pessoa-tabs"
							onTabChange={(event) => selecionarTab(event)}
							activeIndex={tabSelecionadaInterno}
							{...informacoesPermissoes}
						>
							<TabPanel
								header={
									usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS]) ? 'Endereços' : 'E-mails'
								}
							>
								<If test={usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaEndereco
										value={values.enderecos}
										onChange={(enderecos) => {
											setFieldValue('enderecos', enderecos);
											props.validateForm();
										}}
										estadoCadastro={estadosCadastro}
										podeEditar={informacoesPermissoes.podeEditar}
										setHideBackground={setHideBackground}
										appendTo={appendTo}
										{...informacoesPermissoes}
									/>
								</If>
								<If test={!usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaEmail
										value={values.emails}
										onChange={(emails) => setFieldValue('emails', emails)}
										estadoCadastro={estadosCadastro}
										podeEditar={informacoesPermissoes.podeEditar}
										setHideBackground={setHideBackground}
										appendTo={appendTo}
										{...informacoesPermissoes}
									/>
								</If>
							</TabPanel>
							<TabPanel
								header={
									usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS]) ? 'E-mails' : 'Endereços'
								}
							>
								<If test={!usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaEndereco
										value={values.enderecos}
										onChange={(enderecos) => {
											setFieldValue('enderecos', enderecos);
											props.validateForm();
										}}
										estadoCadastro={estadosCadastro}
										podeEditar={informacoesPermissoes.podeEditar}
										setHideBackground={setHideBackground}
										appendTo={appendTo}
										{...informacoesPermissoes}
									/>
								</If>
								<If test={usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaEmail
										value={values.emails}
										onChange={(emails) => setFieldValue('emails', emails)}
										estadoCadastro={estadosCadastro}
										podeEditar={informacoesPermissoes.podeEditar}
										setHideBackground={setHideBackground}
										appendTo={appendTo}
										{...informacoesPermissoes}
									/>
								</If>
							</TabPanel>
							<TabPanel header="Telefones">
								<PessoaTelefone
									value={values.telefones}
									onChange={(telefones) => setFieldValue('telefones', telefones)}
									estadoCadastro={estadosCadastro}
									podeEditar={informacoesPermissoes.podeEditar}
									setHideBackground={setHideBackground}
									appendTo={appendTo}
									{...informacoesPermissoes}
								/>
							</TabPanel>
							<TabPanel
								header="CNAEs"
								headerStyle={
									!usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS]) ? { display: 'none' } : null
								}
							>
								<If test={usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaCnae
										value={values.cnaes}
										onChange={(cnaes) => setFieldValue('cnaes', cnaes)}
										estadoCadastro={estadosCadastro}
										podeEditar={informacoesPermissoes.podeEditar}
										setHideBackground={setHideBackground}
										appendTo={appendTo}
										{...informacoesPermissoes}
									/>
								</If>
							</TabPanel>
							<TabPanel header="Contatos">
								<PessoaContato
									value={values.contatos}
									onChange={(contatos) => setFieldValue('contatos', contatos)}
									estadoCadastro={estadosCadastro}
									podeEditar={informacoesPermissoes.podeEditar}
									setHideBackground={setHideBackground}
									appendTo={appendTo}
									{...informacoesPermissoes}
								/>
							</TabPanel>
							<TabPanel header="Adicionais" headerStyle={values.tipo !== 'FISICA' ? { display: 'none' } : null}>
								<PessoaAdicionais {...props} {...informacoesPermissoes} />
							</TabPanel>
							<TabPanel header="Observações">
								<PessoaObservacoes {...props} {...informacoesPermissoes} />
							</TabPanel>
							<TabPanel
								header="Configurações"
								headerStyle={
									!usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS]) ? { display: 'none' } : null
								}
							>
								<If test={usuarioPossuiModulos([modulos.ESTOQUE, modulos.VENDAS, modulos.COMPRAS])}>
									<PessoaConfiguracoes {...props} {...informacoesPermissoes} />
								</If>
							</TabPanel>
						</TabView>
					</Grid>
				</FormContent>
			</Form>
			{produtosIsVisible ? (
				<ModalProdutos
					visible={produtosIsVisible}
					onHide={() => setProdutosIsVisible(false)}
					values={values}
					appendTo={appendTo}
				/>
			) : null}
		</>
	);
}

PessoaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.validarIntermediador) {
			return {
				...initialValue,
				tipo: props.tipo,
			};
		}
		return initialValue;
	},

	validate(values) {
		return fieldsValidate(values);
	},

	handleSubmit: () => {},
})(PessoaForm);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(PessoaForm));
