import { Field } from 'formik';
import SingleSelectCfop from 'components/select/SingleSelectCfop';
import SingleSelectCst, { typesCst } from 'components/select/SingleSelectCst';
import SingleSelectEstado from 'components/select/SingleSelectEstado';

function FieldsModalTributacaoEstadualUf(props) {
	const { setFieldValue, validacaoNovaTributacao, values, onChangeCstCsosn } = props;

	return (
		<>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={SingleSelectEstado}
				label="UF Destino"
				name="estado"
				id="modalTributacaoUf"
				value={values.estado}
				listaTributacoesUf={props.listaTributacoesUf}
				onChange={(event) => setFieldValue('estado', event)}
				obrigatorio
				disabled={validacaoNovaTributacao}
			/>
			<Field
				sm="12"
				md="9"
				lg="9"
				xl="9"
				component={SingleSelectCfop}
				label="CFOP"
				name="cfop"
				value={values.cfop}
				onChange={(event) => setFieldValue('cfop', event)}
				obrigatorio
				disabled={validacaoNovaTributacao}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				label="CST/CSOSN"
				name="cstCsosn"
				value={values.cstCsosn}
				component={SingleSelectCst}
				type={typesCst.CST_CSOSN}
				onChange={(event) => {
					setFieldValue('cstCsosn', event);
					onChangeCstCsosn();
				}}
				obrigatorio
				disabled={validacaoNovaTributacao}
			/>
		</>
	);
}

export default FieldsModalTributacaoEstadualUf;
