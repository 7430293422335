import { Field } from 'formik';
import { Fieldset } from 'primereact/fieldset';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao, estadosCadastro, permissoes, recursos } from 'Common';

import {
	confirm,
	Button,
	Col,
	Grid,
	If,
	Checkbox,
	InputDouble,
	InputMoney,
	InputSelectMultiplicacaoOrDivisao,
} from 'components';

import { asyncAjustarSaldo } from '../../../../Requests';
import { helpProdutosForm } from '../../../Help';

const styleButtonEditar = {
	color: '#006095',
	cursor: 'pointer',
	width: '32px',
	height: '32px',
	backgroundColor: 'transparent',
	borderColor: 'transparent',
	fontSize: '22px',
	boxShadow: 'none',
	marginTop: '25px',
};

const styleButtonCancelarAlteracaoEstoque = {
	color: '#e91224',
	cursor: 'pointer',
	padding: '5px',
	width: '32px',
	height: '32px',
	marginTop: '26px',
};

const styleButtonSalvarAlteracaoEstoque = {
	color: '#34a835',
	cursor: 'pointer',
	padding: '5px',
	width: '32px',
	height: '32px',
	marginTop: '26px',
};

const styleButtonSalvarAlteracaoEstoqueDisabled = {
	color: '#34a835',
	cursor: 'unset',
	opacity: '0.5',
	padding: '5px',
	width: '32px',
	height: '32px',
	marginTop: '26px',
};

export default function FieldsEstoque(props) {
	const {
		dirty,
		setFieldValue,
		values,
		tipoPredefinido,
		informacoesPermissoes,
		myRef,
		estoqueSaldoAnterior,
		setEstoqueSaldoAnterior,
		ajustarSaldoEmEstoque,
		setAjustarSaldoEmEstoque,
		setEstoqueSaldo,
		estoqueSaldo, // DIFERENTE DE VALUES.ESTOQUE SALDO...
	} = props;

	const { controlarEstoque, estoqueMinimo, ultimoCusto, sku, nome, id, codigo, filiais } = values;

	const podeVisualizarMovimentacaoEstoque = usuarioPossuiPermissao(
		recursos.ESTOQUE_MOVIMENTACOES,
		permissoes.VISUALIZAR
	);
	const podeInserirMovimentacaoEstoque = usuarioPossuiPermissao(recursos.ESTOQUE_MOVIMENTACOES, permissoes.INSERIR);

	const desabilitarBotaoAlterarEstoque =
		!podeInserirMovimentacaoEstoque || !podeVisualizarMovimentacaoEstoque || !informacoesPermissoes.podeEditar;

	const isEdicao = informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO;
	const isInsercao = !isEdicao;

	const indexFilialConectada =
		values.filiais.map((e) => e.filialConectada).indexOf(true) > -1
			? values.filiais.map((e) => e.filialConectada).indexOf(true)
			: 0;

	let titleAlterarEstoque = dirty
		? 'Finalize a edição do produto para alterar o estoque'
		: desabilitarBotaoAlterarEstoque;

	titleAlterarEstoque = titleAlterarEstoque
		? 'Você não tem permissão para executar esta ação'
		: 'Alterar estoque deste produto';

	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	return (
		<Grid>
			<Col>
				<Fieldset className="fieldset">
					<Grid>
						<Field
							col="12"
							component={Checkbox}
							label="Controlar estoque deste produto"
							name="controlarEstoque"
							helpMessage={helpProdutosForm.controlarEstoque}
							onChange={() => {
								setFieldValue('controlarEstoque', !controlarEstoque);
							}}
							checked={controlarEstoque}
							disabled={Boolean(tipoPredefinido)}
							{...informacoesPermissoes}
						/>
					</Grid>

					<If test={controlarEstoque}>
						<Grid>
							<Field
								sm="12"
								md="3"
								lg="3"
								xl="3"
								label="Fator de conversão"
								name="fatorConversao"
								component={InputSelectMultiplicacaoOrDivisao}
								onChange={(e) => setFieldValue(`filiais.${indexFilialConectada}.fatorConversao`, e.target?.value)}
								value={filiais[indexFilialConectada]?.fatorConversao}
								valueOperacao={
									filiais[indexFilialConectada] ? filiais[indexFilialConectada].operacaoFatorConversao : 'MULTIPLICACAO'
								}
								onChangeOperacao={(e) => setFieldValue(`filiais.${indexFilialConectada}.operacaoFatorConversao`, e)}
								id="ProdutoInputFatorConversao"
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="3"
								xl="3"
								component={InputDouble}
								disabled={ajustarSaldoEmEstoque}
								title={
									ajustarSaldoEmEstoque ? 'Finalize a movimentação de estoque para editar o estoque mínimo ' : null
								}
								label="Estoque mínimo"
								name="estoqueMinimo"
								onChange={(e) => setFieldValue(`filiais.${indexFilialConectada}.estoqueMinimo`, e.target.value)}
								value={filiais[indexFilialConectada]?.estoqueMinimo}
								decimalScale={decimaisQtd}
								size={13}
								helpMessage={helpProdutosForm.estoqueMinimo}
								{...informacoesPermissoes}
							/>
							<Field
								sm="12"
								md="6"
								lg="3"
								xl="3"
								component={InputDouble}
								disabled
								label="Estoque reservado"
								name="estoqueReservado"
								onChange={() => {}}
								value={filiais[indexFilialConectada]?.estoqueReservado}
								decimalScale={decimaisQtd}
								hidden={isInsercao}
								size={13}
								helpMessage={helpProdutosForm.estoqueReservado}
								{...informacoesPermissoes}
							/>
							<If test={isInsercao}>
								<Field
									sm="12"
									md="6"
									lg="3"
									xl="3"
									component={InputDouble}
									label="Saldo inicial"
									name="estoqueSaldo"
									id="estoqueSaldo"
									onChange={(e) => handleEstoqueSaldo(e)}
									value={values.estoqueSaldo}
									decimalScale={decimaisQtd}
									size={13}
									helpMessage={helpProdutosForm.estoqueSaldo}
									{...informacoesPermissoes}
								/>
								<Field
									sm="12"
									md="6"
									lg="3"
									xl="3"
									component={InputMoney}
									label="Custo unitário"
									name="ultimoCusto"
									size={12}
									decimalScale={decimaisPreco}
									obrigatorio={estoqueSaldo}
									disabled={!estoqueSaldo}
									title={!estoqueSaldo ? 'Informe um Saldo em estoque para habilitar o Último custo' : null}
									helpMessage={helpProdutosForm.ultimoCusto}
									onChange={(e) => setFieldValue('ultimoCusto', e.target.value)}
									value={ultimoCusto}
									{...informacoesPermissoes}
								/>
							</If>
							<If test={isEdicao}>
								<Col sm="12" md="6" lg="3" xl="3">
									<Grid>
										<InputDouble
											colStyle={{
												paddingBottom: '0px',
												width: ajustarSaldoEmEstoque ? 'calc(100% - 64px)' : 'calc(100% - 32px)',
											}}
											title={
												ajustarSaldoEmEstoque && desabilitarBotaoAlterarEstoque
													? null
													: 'Clique no ícone de edição para alterar o saldo em estoque'
											}
											label="Saldo em estoque"
											disabled={!ajustarSaldoEmEstoque}
											onKeyDown={(e) => e.key === 'Enter' && onSalvarAlteracoesEstoque()}
											name="estoqueSaldo"
											id="estoqueSaldo"
											onChange={(e) => handleEstoqueSaldo(e)}
											value={filiais[indexFilialConectada]?.estoqueSaldo}
											decimalScale={decimaisQtd}
											size={13}
											helpMessage={helpProdutosForm.estoqueSaldo}
											{...informacoesPermissoes}
										/>
										<If test={!ajustarSaldoEmEstoque}>
											<Button
												icon="fa fa-wrench"
												title={titleAlterarEstoque}
												style={styleButtonEditar}
												styleContentButton={{
													display: 'flex',
													alignItems: 'center',
												}}
												disabled={desabilitarBotaoAlterarEstoque || dirty}
												onClick={(e) => myRef.current.toggle(e)}
												tabIndex={-1}
											/>
										</If>
										<If test={ajustarSaldoEmEstoque}>
											<FaCheck
												style={
													estoqueSaldo !== null && estoqueSaldo >= 0
														? styleButtonSalvarAlteracaoEstoque
														: styleButtonSalvarAlteracaoEstoqueDisabled
												}
												title={
													estoqueSaldo !== null && estoqueSaldo >= 0
														? 'Salvar alteração de estoque'
														: 'Informe um saldo válido'
												}
												onClick={onSalvarAlteracoesEstoque}
											/>
											<FaTimes
												style={styleButtonCancelarAlteracaoEstoque}
												title="Cancelar alteração de estoque"
												onClick={onCancelarAlteracaoEstoque}
											/>
										</If>
										<If test={podeVisualizarMovimentacaoEstoque}>
											<Link
												id="botao-ver-movimentacoes-estoque-produto"
												title="Visualizar movimentações de estoque do produto"
												to={{
													pathname: `/movimentacoes_estoque`,
													state: {
														sku: sku,
														nome: nome,
														id: id,
														codigo: codigo,
													},
												}}
											>
												Visualizar movimentações
											</Link>
										</If>
									</Grid>
								</Col>
							</If>
						</Grid>
					</If>
				</Fieldset>
			</Col>
		</Grid>
	);

	function handleEstoqueSaldo(e) {
		setEstoqueSaldo(e.target.value);
		setFieldValue(`filiais.${indexFilialConectada}.estoqueSaldo`, e.target.value);
		if (isInsercao) {
			setFieldValue('estoqueSaldo', e.target.value);
		}
	}

	async function asyncAjustarSaldoEmEstoquePorProduto(idProduto, data) {
		if (estoqueSaldo !== estoqueSaldoAnterior) {
			await asyncAjustarSaldo(idProduto, data);
			setEstoqueSaldoAnterior(estoqueSaldo);
		}
		setAjustarSaldoEmEstoque(false);
	}

	function onCancelarAlteracaoEstoque() {
		setAjustarSaldoEmEstoque(false);
		setEstoqueSaldo(estoqueSaldoAnterior);
	}

	function onSalvarAlteracoesEstoque() {
		if (controlarEstoque && estoqueSaldo < estoqueMinimo && estoqueSaldo !== null && estoqueSaldo >= 0) {
			confirm(
				'Confirmação',
				'Novo saldo será menor que estoque mínimo, deseja continuar?',
				// Confirmou
				() => {
					movimentarNovoSaldoEstoque();
				},
				// Não Confirmou
				() => {
					setAjustarSaldoEmEstoque(false);
					setEstoqueSaldo(estoqueSaldoAnterior);
				}
			);
		} else {
			movimentarNovoSaldoEstoque();
		}
	}

	function movimentarNovoSaldoEstoque() {
		if (estoqueSaldo !== null && estoqueSaldo >= 0) {
			asyncAjustarSaldoEmEstoquePorProduto(id, {
				custo: ultimoCusto,
				novoSaldo: estoqueSaldo,
			});
		}
	}
}
