import { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from '../../Util';
import { confirmarExclusao } from '../../Util/ExclusaoDeRegistros';
import { asyncDeletePapel, asyncGetPesquisaPapeis } from './Requests';
import { PAPEL_TIPO } from './Util/constantes';

function Papeis({ ...props }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		filtroAvancado,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		totalRecords,
		setTotalRecords,
	} = useContextPesquisa();

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [firstRender, setFirstRender] = useState(true);

	const podeInserir = usuarioPossuiPermissao(recursos.PAPEIS, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EXCLUIR);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('PapeisInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [rows, page, sortField, sortOrder, filtroAvancado]);

	function pesquisar() {
		const filtro = `?query=nome=contains="*${valorPesquisa}*"`;
		const url = construirUrl(
			`${services.GESTOR}/v1/papeis_permissoes/resumo`,
			filtro,
			10,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetPesquisaPapeis(url, ({ data: papeis }) => {
			setRegistros(papeis.content);
			setTotalRecords(papeis.totalRecords);
			setFirstRender(false);
		});
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/papeis/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeletePapel(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalRecords(totalRecords - 1);
		});
	}

	function renderOpcoes(row) {
		const isNormal = row.tipo === PAPEL_TIPO.NORMAL;
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir && isNormal} />
			</div>
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Grupos de usuários e permissões">
				<FormActions>
					<ButtonNovo
						label="Novo grupo"
						className="step-listagem-novo"
						onClick={() => {
							props.history.push('/papeis/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar()}
							value={valorPesquisa}
							id="PapeisInputSearch"
							onChange={(value) => setValorPesquisa(value)}
						/>
					</Grid>
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column className="step-listagem-order" field="nome" header="Nome" sortable />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalRecords={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

export default Papeis;
