import { useFormikContext } from 'formik';

import { Message } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';

function ErrorsTransmissao() {
	const { values } = useFormikContext();
	const { errorsTransmissao } = useContextCTe();

	function montarMensagemTransmissao() {
		let mensagem;

		if (Object.keys(values.errors)?.length > 0) {
			mensagem = <p key={values.errors?.id}>{values.errors?.mensagem}</p>;
		}

		if (Object.keys(errorsTransmissao)?.length > 0) {
			mensagem = <p key={errorsTransmissao.id}>{errorsTransmissao.mensagem}</p>;
		}

		return mensagem;
	}

	return <Message severity="warn" text={montarMensagemTransmissao()} />;
}

export { ErrorsTransmissao };
