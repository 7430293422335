import { exibirToast, get, post, del, put, services } from 'Common';

export async function readVeiculos(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function readVeiculo(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/transporte/veiculos/${id}`, null, onSuccess, onError);
}

export async function createVeiculo(veiculo, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/transporte/veiculos`,
		veiculo,
		null,
		exibirToast(onSuccess, 'Veículo criado com sucesso'),
		onError
	);
}

export async function updateVeiculo(veiculo, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/transporte/veiculos/${veiculo.id}`,
		veiculo,
		null,
		exibirToast(onSuccess, 'Veículo atualizado com sucesso'),
		onError
	);
}

export async function deleteVeiculo(id, onSuccess, onError) {
    await del(
		`${services.GESTOR}/v1/transporte/veiculos/${id}`,
		null,
		exibirToast(onSuccess, 'Veículo excluído'),
		onError
	);
}
